@import "../variables";

$button-radius: 5px;

@mixin button-white {
    background-color: $white;
    color: $curious-blue;
    font-family: $opensans-bold;
    padding: 5px 20px;
    font-size: 12px;
    border-radius: $button-radius;

    &:hover {
        color: $curious-blue;
    }
}

@mixin button-green {
    font-family: $opensans-bold;
    font-size: 14px;
    background: $light-green;
    color: $white;
    border-radius: $button-radius;
    padding: 5px 20px;

    &:hover {
        color: $white;
    }
}

.btn {
    font-family: $opensans-regular;

    &.subscribe-email {
        @include media-breakpoint-down(sm) {
            padding: 0 10px;
        }
    }
}

.btn-secondary {
    font-size: 14px;
    line-height: 24px;
    background-color: $grey2;
    border: 1px solid $grey3;
    color: $emperor;
    border-radius: 0;
}

.btn-tertiary {
    font-size: 14px;
    line-height: 24px;
    background-color: $white;
    border: none;
    color: $mine-shaft;
    border-radius: 0;
}

#deletePaymentModal,
#deleteAddressModal,
.edit-profile-form,
.change-password-form,
.address-form,
.payment-form {
    .btn-outline-primary,
    .btn-outline-primary:hover {
        background: $dark-gray2;
        border-color: $dark-gray2;
        color: $night-rider;
    }
}

.btn-primary,
.btn-primary:hover {
    background-color: $lilac-purple;
    color: $white;
    font-size: 18px;
    font-weight: bold;
}

.btn-blue {
    background-color: $lilac-purple;
    border-radius: $button-radius;
    border: 1px solid $lilac-purple;
    color: $white;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0;

    @include media-breakpoint-up(sm) {
        line-height: 22px;
        padding: 9px 0;

        &:hover {
            background-color: $lilac-purple;
        }
    }

    &:disabled,
    &.disabled {
        background-color: $grey3;
        border-color: $grey3;
        color: $grey2;

        &:hover {
            cursor: no-drop;
        }
    }
}

.btn-picton-blue {
    background-color: $lilac-purple;
    color: $white;
    font-family: $opensans-bold;
    font-size: 15px;
    text-transform: uppercase;
    padding: 12px 25px;
    border: none;
    border-radius: 0;

    &.btn-lc-green {
        background-color: $light-green;
    }

    &:hover {
        color: $white;
    }

    @include media-breakpoint-up(sm) {
        font-size: 18px;
    }
}

.btn-white {
    @include button-white;
}

.btn-white-secondary {
    @include button-white;

    color: $black;
    border-color: $black;
    font-size: 14px;

    &:hover {
        color: $black;
    }
}

.btn-animated {
    &:hover {
        .icon-container {
            padding-right: 20px;

            &::after {
                opacity: 1;
                right: 0;
            }
        }
    }
}

.btn-navy-blue {
    background-color: $navy-blue;
    color: $white;
    font-size: 18px;
    line-height: 24px;
    padding: 6px 8px;
    border: none;
    border-radius: 0;

    &:hover {
        color: $white;
    }

    @include media-breakpoint-up(sm) {
        padding-left: 12px;
        padding-right: 12px;
    }
}

.icon-container {
    position: relative;
    transition: 0.5s;

    &::after {
        font-family: "FontAwesome", arial, sans-serif;
        position: absolute;
        opacity: 0;
        top: 0;
        right: 0;
        transition: 0.5s;
    }

    &.cart-icon {
        &::after {
            content: "\f07a";
        }
    }

    &.fast-buy-button-inner {
        &::after {
            content: "\f101";
            font-size: 20px;
            font-weight: 900;
        }
    }
}

.fa.fa-angle-double-right {
    font-size: 16px;
    font-weight: 900;
}

.btn-green {
    @include button-green;
}

.btn-green-big {
    @include button-green;

    font-size: 20px;
    padding: 7px 15px;
}

button {
    &:focus {
        outline: none;
    }
}

/** --CRO buttons styles-- **/
.CRO_generic_color {
    .cart-header .header-menu-icon.cart-user-icon {
        display: none;
    }

    .cart-header .sign-in-menu-container .user > a {
        display: flex;
        align-items: center;
    }

    .cart-header .sign-in-menu-container .user .header-menu-icon:not(.cart-user-icon) {
        display: block !important;
    }

    .btn {
        &:focus {
            box-shadow: none;
            border-color: transparent;
        }

        &:active {
            background: $primarybtn-color !important;
            color: $white !important;
            border-color: transparent !important;
            box-shadow: none !important;
        }

        &.btn-black {
            background: $primarybtn-color !important;
            border-color: $primarybtn-color !important;
            color: $white !important;

            &:hover,
            &:focus,
            &:active {
                background: $primarybtn-color !important;
                color: $white !important;
                border-color: transparent !important;
                box-shadow: none !important;
            }

            &:focus {
                box-shadow: none;
                border-color: transparent;
            }
        }
    }

    .btn-primary,
    .btn-primary:hover {
        background-color: $primarybtn-color !important;
        border-color: $primarybtn-color !important;
    }

    .btn-blue {
        background-color: $primarybtn-color;
        border: 1px solid $primarybtn-color;

        @include media-breakpoint-up(sm) {
            &:hover {
                background-color: $primarybtn-color;
            }
        }
    }

    .btn-picton-blue {
        background-color: $primarybtn-color !important;
        border-color: $primarybtn-color !important;

        &.btn-lc-green {
            background-color: $primarybtn-color !important;
            border-color: $primarybtn-color !important;
        }
    }
}
