@import "../variables";
@import "../base/fonts";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.slick-arrow {
    font-size: 20px;
    height: 25px;
    width: 25px;
    text-rendering: auto;
    text-indent: 0;
    white-space: nowrap;
    overflow: hidden;
    color: $white;
    text-decoration: none;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.6);
    text-align: center;
    position: absolute;
    z-index: 100;
    border: 0;
    padding: 0;
    margin: auto;

    &:hover {
        background: rgba(0, 0, 0, 0.7);
    }

    @include media-breakpoint-up(sm) {
        font-size: 45px;
        height: 54px;
        width: 54px;
        line-height: 52px;
    }

    &.slick-disabled {
        opacity: 0;
    }

    &.slick-hidden {
        display: none;
    }
}

.slick-dots {
    text-align: center;
    position: relative;
    margin: 4px 0 15px;
    padding: 0;

    li {
        display: inline;
        margin: 0 10px 0 0;

        button {
            border-radius: 50%;
            background: $carousel-bullet-default;
            text-indent: 100%;
            white-space: nowrap;
            overflow: hidden;
            border: 0;
            padding: 0;
            height: 8px;
            width: 8px;
            @include media-breakpoint-up(sm) {
                height: 15px;
                width: 15px;
            }

            &:hover {
                background: $carousel-bullet-active;
            }
        }

        &.slick-active {
            button {
                background: $carousel-bullet-active;
            }
        }

        &:last-child {
            margin: 0;
        }
    }
}

.arrow-next {
    right: 20px;
    top: 0;
    bottom: 0;
}

.arrow-prev {
    left: 20px;
    top: 0;
    bottom: 0;
}

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &::before,
    &::after {
        content: "";
        display: table;
    }

    &::after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide {
    float: left;
    min-height: 1px;

    [dir="rtl"] & {
        float: right;
    }

    img {
        display: block;
    }

    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}

.slick-initialized {
    .slick-slide {
        display: block;

        &.slick-cloned {
            max-height: 1px;

            .lazyload,
            .lazyloading {
                opacity: 1;
            }
        }
    }
}

.slick-product-tile {
    margin-bottom: 20px;

    .carousel-top,
    .carousel-bottom {
        text-align: center;
    }

    .before-headline,
    .after-headline {
        display: inline-block;
        vertical-align: middle;
    }

    h2 {
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        padding: 15px;
        margin-bottom: 15px;
        margin-top: 10px;
        font-size: 30px;
        font-family: $opensans-bold;
        font-weight: 600;

        @include media-breakpoint-down(md) {
            font-size: 20px;
        }
    }

    .slick-half-tiles .slick-list {
        padding: 0 9% 0 0 !important;

        @include media-breakpoint-down(xs) {
            padding: 0 33.33% 0 0 !important;
        }
    }

    .slick-slide {
        padding: 0 10px;
    }

    &.recommended-for-you-homepage {
        a.carousel-bottom-link {
            margin: 1em auto;
            display: inline-block;
            color: $white !important;
            background-color: $emperor;
            border-radius: 5px;
            text-transform: uppercase;
            text-decoration: none;
            font-size: 14px;
            font-weight: bold;
            padding: 0 3em;
            line-height: 2.5em;

            @include media-breakpoint-down(md) {
                padding: 0 1em;
                line-height: 2em;
            }
        }
    }

    &.top-rated-items-homepage {
        .before-headline,
        .after-headline {
            font-size: 20px;
            color: $light-yellow;
        }
    }
}

.homePage_under_50 {
    .slick-slide {
        @include media-breakpoint-down(md) {
            width: 100% !important;
        }
    }
}

.container-style_squad_two .col-12 {
    padding-right: 0 !important;
}

.container-style_squad .col-12 {
    padding-left: 0 !important;
}
