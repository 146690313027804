@import "~@sfra/scss/components/menu";

.main-menu.multilevel-dropdown {
    background-color: $concrete;
    width: 100%;
    max-width: 320px;
    z-index: 16;

    @include media-breakpoint-up(sm) {
        max-width: 512px;
    }

    @include media-breakpoint-up(md) {
        max-width: unset;
    }

    .nav-close {
        position: absolute;
        top: 0;
        right: -32px;
        width: 32px;
        height: 32px;
        background-color: #dedede;
        border: none;
        font-size: 16px;

        @include media-breakpoint-up(sm) {
            right: -40px;
            width: 40px;
            height: 40px;
            font-size: 20px;
        }
    }

    .nav-menu-container {
        height: 100%;
        overflow-y: scroll;

        @include media-breakpoint-up(md) {
            height: auto;
            overflow: visible;
        }

        .navbar {
            padding: 20px 10px 48px 15px;

            @include media-breakpoint-up(md) {
                padding: 0 15px;
            }

            .menu-group {
                .navbar-nav {
                    gap: 10px;

                    @include media-breakpoint-up(md) {
                        position: relative;
                        justify-content: space-between;
                    }

                    .nav-item {
                        display: flex;
                        position: relative;
                        align-items: center;
                        background-color: $white;
                        height: 60px;
                        border: none;
                        border-radius: 10px;
                        flex-shrink: 0;

                        @include media-breakpoint-up(md) {
                            align-items: stretch;
                            height: auto;
                        }

                        &.sub-categories {
                            > .nav-link {
                                font-family: $opensans-bold;

                                &::after {
                                    position: absolute;
                                    width: 12px;
                                    height: 12px;
                                    border: 3px solid #a8a9ad;
                                    border-top: 0;
                                    border-left: 0;
                                    border-radius: 0;
                                    content: "";
                                    right: 40px;
                                    margin-top: 5px;
                                    transform: rotate(-45deg);

                                    @include media-breakpoint-up(md) {
                                        content: none;
                                    }
                                }
                            }
                        }

                        &:not(.sub-categories) {
                            .category-slot {
                                display: none;
                            }
                        }

                        &:not(.level-2):not(.level-3) {
                            &.show {
                                > .nav-link {
                                    @include media-breakpoint-up(md) {
                                        position: relative;

                                        &::after {
                                            content: "";
                                            width: 10px;
                                            height: 10px;
                                            position: absolute;
                                            left: 50%;
                                            bottom: -5px;
                                            transform: rotate(45deg);
                                            background-color: $gray-4;
                                            border: none;
                                            margin: 0;
                                        }
                                    }
                                }
                            }

                            > .dropdown-menu {
                                position: fixed;

                                @include media-breakpoint-up(md) {
                                    position: absolute;
                                }
                            }

                            > .nav-link {
                                @include media-breakpoint-up(md) {
                                    max-width: 72px;
                                    font-size: 10px;
                                    word-wrap: break-word;
                                }

                                @include media-breakpoint-up(lg) {
                                    max-width: 94px;
                                    font-size: 13px;
                                }

                                @include media-breakpoint-up(xl) {
                                    max-width: fit-content;
                                    font-size: 15px;
                                }
                            }
                        }

                        &.level-2 {
                            @include media-breakpoint-up(md) {
                                max-width: 33%;
                                height: 40px;
                                align-items: center;
                            }

                            &.nav-see-all {
                                display: none;

                                @include media-breakpoint-up(md) {
                                    display: flex;
                                }
                            }

                            &.show > .nav-link {
                                @include media-breakpoint-up(md) {
                                    border-bottom: 2px solid #878dda;

                                    + .category-slot {
                                        display: block;
                                        position: absolute;
                                        width: 33%;
                                        top: 16px;
                                        left: 33%;
                                    }
                                }
                            }

                            > .nav-link {
                                @include media-breakpoint-up(md) {
                                    height: fit-content;
                                    padding: 5px 6px;
                                    border-bottom: 2px solid $white;
                                    text-align: left;

                                    &:hover {
                                        border-bottom: 2px solid #878dda;

                                        + .category-slot {
                                            display: block;
                                            position: absolute;
                                            width: 33%;
                                            top: 16px;
                                            left: 33%;
                                        }
                                    }
                                }
                            }

                            > .dropdown-menu {
                                &.show {
                                    @include media-breakpoint-up(md) {
                                        left: 33%;
                                        width: 33%;
                                        top: 0;
                                        border: none;
                                        box-shadow: none;
                                    }
                                }
                            }
                        }

                        &.level-3 {
                            @include media-breakpoint-up(md) {
                                max-width: 100%;
                                height: 40px;
                                align-items: center;
                            }

                            &.nav-see-all {
                                padding-left: 66px;
                                text-decoration: underline;
                                font-family: $opensans-regular;

                                @include media-breakpoint-up(md) {
                                    padding-left: 46px;
                                }
                            }

                            > .nav-link {
                                font-family: $opensans-regular;
                                height: fit-content;
                                padding-left: 6px;
                                text-align: left;

                                &:hover {
                                    font-family: $opensans-bold;
                                }
                            }
                        }

                        &.nav-title {
                            font-family: $opensans-bold;
                            font-size: 18px;
                        }

                        &.show {
                            > .dropdown-menu {
                                left: 0;
                            }
                        }

                        > .nav-link {
                            padding: 0 0 0 10px;
                            width: 100%;
                            font-family: $opensans-regular;
                            font-size: 16px;

                            @include media-breakpoint-up(md) {
                                padding: 10px 0;
                                width: fit-content;
                                font-family: $opensans-bold;
                                text-align: center;
                                color: $black;
                            }
                        }

                        .dropdown-menu {
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                            width: 100%;
                            max-width: 320px;
                            height: 100%;
                            padding: 20px 10px 48px 15px;
                            overflow-x: hidden;
                            overflow-y: scroll;
                            left: -100%;

                            @include media-breakpoint-up(sm) {
                                max-width: 512px;
                            }

                            @include media-breakpoint-up(md) {
                                opacity: 0;
                                display: none;
                                width: 720px;
                                max-width: unset;
                                height: 586px;
                                overflow-y: hidden;
                                padding: 16px 26px 40px;
                                border-top: 3px solid $gray-4;

                                &.show {
                                    top: 46px;
                                    opacity: 1;
                                    transition: opacity 0.2s;
                                    display: flex;

                                    &.right {
                                        left: unset;
                                        right: 0;
                                    }
                                }
                            }

                            @include media-breakpoint-up(lg) {
                                &.show {
                                    top: 54px;
                                }
                            }

                            @include media-breakpoint-up(xl) {
                                &.show {
                                    top: 38px;
                                }
                            }

                            > .category-slot {
                                @include media-breakpoint-up(md) {
                                    display: block;
                                    position: absolute;
                                    max-width: 33%;
                                    right: 0;
                                }
                            }
                        }

                        .category-thumbnail {
                            width: 60px;
                            height: 60px;

                            @include media-breakpoint-up(md) {
                                width: 40px;
                                height: 40px;
                            }
                        }
                    }

                    .back {
                        border: none;
                        border-radius: 10px;
                        height: 60px;
                        width: 100%;
                        background-color: $concrete;
                        font-family: $opensans-bold;
                        padding-left: 40px;
                        text-align: left;
                        color: $black;

                        &::after {
                            position: absolute;
                            width: 12px;
                            height: 12px;
                            border: 3px solid #a8a9ad;
                            border-top: 0;
                            border-left: 0;
                            border-radius: 0;
                            content: "";
                            right: 35px;
                            margin-top: 5px;
                            transform: rotate(135deg);
                        }
                    }
                }
            }
        }
    }
}

/** --CRO Menu styles-- **/
// .CRO_generic_color {
//     .five9-chat-button {
//         background: $primarybtn-color !important;
//     }
// }
