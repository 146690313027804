@import "variables";
@import "base/fonts";
@import "~@sfra/scss/cart";
@import "components/loginModal";
@import "components/statusBar";

input[type=checkbox],
input[type=radio] {
    margin: 0 !important;
}

input[type="radio"] {
    width: 17px;
    height: 17px;
    border-radius: 50%;
}

input[type="radio"]::before {
    content: "";
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
}

input[type="radio"]:checked {
    -webkit-appearance: none;
    border: 1px solid $light-green;
}

input[type="radio"]:checked::before {
    background: $light-green;
}

.cart-breadcrumbs {
    .breadcrumb {
        border: 0;
        padding: 0;
        font-size: 14px;
        font-family: $opensans-bold;

        > li + li::before {
            padding: 0 5px;
            color: $dark-gray3;
            content: "|\00a0";
        }

        > .active {
            color: $dark-gray3;
            text-transform: uppercase;
        }
    }
}

.red {
    color: red;
    font-weight: bold;
}

.tick {
    color: green;
    font-weight: bold;
}
.promotion-callout-not-applied-icon {
    color: red;
    font-weight: bold;
}

.shipping-methods-modal {
    .modal-dialog {
        max-width: 600px;

        .bottom-btn {
            padding: 15px 0 0;
            margin-top: 15px;
            text-align: right;
            border-top: 1px solid $mercury;
        }
    }
}

.select-payment-options {
    margin: 0;
    border-top: 1px solid $border-select-gray;
    border-bottom: 1px solid $border-select-gray;
    width: 190px;
    border-radius: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: $white url(../images/select-down-arrow.svg) no-repeat right top;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    padding: 6px 30px 6px 0;
    font-size: 12px;
    border-left: 0;
    border-right: 0;
}

.regular-cart-header {
    margin: 20px 0 0;
    padding-bottom: 0;
    font-family: $opensans-light;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-transform: uppercase;
    color: $persian-red2;

    &.checkout-header {
        margin-top: 0;
        font-size: 30px;
        font-weight: $font-wt-bold;

        @include media-breakpoint-up(sm) {
            margin: 0;
            padding-bottom: 0;
            font-size: 24px;
            line-height: auto;
        }
    }

    @include media-breakpoint-up(sm) {
        margin: 0;
        padding-bottom: 0;
        font-size: 24px;
        line-height: auto;
    }
}

.shipping-method-summary-link {
    font-size: 13px;
    color: $dark-gunmetal;

    @include media-breakpoint-up(sm) {
        display: block;
        padding-bottom: 15px;
    }

    &:hover {
        text-decoration: underline;
        color: $dark-gunmetal;
    }
}

.cart-items {
    margin-top: 25px;

    .checkout-block-items-container {
        scrollbar-width: thin;
        scrollbar-color: #868ce0;
        max-height: 688px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .checkout-block-items-container::-webkit-scrollbar {
        width: 5px;
    }

    .checkout-block-items-container::-webkit-scrollbar-track {
        background: transparent;
    }

    .checkout-block-items-container::-webkit-scrollbar-thumb {
        background-color: #868ce0;
        border-radius: 10px;
        border: none;
    }
}

.checkout-block-items {
    .checkout-block-items-container {
        max-height: 650px;
    }
}

.labels-header {
    background-color: $alabaster;
    padding: 10px 0;
    text-transform: capitalize;
    font-size: 13px;
    font-family: $opensans-bold;
}

.totals-summary {
    background: $solitude;
    padding: 15px 15px;
    margin-bottom: 10px;

    @include media-breakpoint-up(sm) {
        background: $alabaster;
        padding: 10px;
        margin-bottom: 0;
    }
}

.cart-notification {
    background-color: $notification-yellow-bg;
    border: 1px solid $notification-yellow-bd;
    padding-top: 9px;
    padding-bottom: 9px;
    font-size: 14px;
    margin-top: 28px;
    color: $notification-grey-text;
    font-weight: 600;
    text-align: center;

    @include media-breakpoint-down(md) {
        font-size: 12px;
    }
}

.approaching-discount {
    padding: 15px 10px 17px 65px;
    font-size: 15px;
    border: 1px solid $incredible-gray;
    position: relative;
    margin-bottom: 10px;

    @include media-breakpoint-up(sm) {
        margin-bottom: 0;
    }

    .approaching-banner {
        display: block;
    }

    &::before {
        content: "";
        position: absolute;
        background: url("../images/3607d2fee6a0d__icon-left.png") no-repeat left top;
        width: 66px;
        height: 64px;
        left: 0;
        top: 0;
    }

    .progress {
        margin-top: 10px;
        border-radius: 1rem;
    }

    .progress-bar {
        border-radius: 1rem;
        background: $reached-green;
    }

    &.in-progress {
        &::before {
            content: "";
            position: absolute;
            background: url("../images/3707e6d20aad2__orange_truck.png") no-repeat left top;
            width: 66px;
            height: 64px;
            left: 0;
            top: 0;
        }

        .approaching-banner .inner-container {
            span {
                font-family: $opensans-bold;
            }
        }

        .progress-bar {
            background: linear-gradient(90deg, $progress-orange, $progress-orange, $reached-green);
        }
    }

    .see-details-modal {
        margin-left: 10px;
        cursor: pointer;

        a {
            text-decoration: underline;
        }
    }
}

.product-info {
    border: 0;
    background: $white;
    padding: 20px 0;
    border-bottom: 2px solid $alabaster;
    position: relative;
    color: $night-rider;
    font-size: 13px;

    &.live-tv-line-item {
        padding-top: 27px;

        .product-image-wrap {
            margin-top: -22px;
        }
    }

    .live-tv-label-wrap {
        @include media-breakpoint-down(md) {
            padding-right: 16px;
        }
    }

    .live-tv-label {
        margin: 0 0 5px;
        line-height: 1.3;
        width: 100%;
    }

    .item-image-link {
        display: block;
    }

    .item-image {
        height: auto;
        width: auto;
        margin-right: 0;

        @include media-breakpoint-down(md) {
            padding-right: 16px;
        }

        .product-image {
            max-width: 100%;
            max-height: fit-content;
        }
    }

    .interest-fee-label {
        padding-left: 20px;
        font-family: $opensans-regular;
        font-style: italic;
        font-size: 12px;
        margin-bottom: 0;
    }

    .shipping-messages {
        flex-flow: column;
    }

    .remove-btn {
        color: $light-gray3;
        font-size: 12px;
        position: static;
        background-color: transparent;
        width: 61px;
        display: inline-block;

        @include media-breakpoint-down(md) {
            font-size: 11px;

            i.glyphicon-remove-sign {
                transform: translateY(1.5px);
                font-size: 10px;
            }
        }
    }

    .quantity-form {
        margin-top: 0;
        float: right;
        margin-right: 10px;
        font-family: $opensans-regular;
        position: relative;

        .form-control {
            border-radius: 0;
        }

        &::before {
            position: absolute;
            pointer-events: none;
            border-left: 1px solid $incredible-gray;
            width: 30px;
            height: 36px;
            right: 1px;
            top: 1px;
            background-color: $grey1;
            content: "";
        }

        &::after {
            position: absolute;
            pointer-events: none;
            content: "▼";
            font-size: 8px;
            right: 12px;
            top: 13px;
        }
    }

    .quantity,
    .save-for-later-quantity-form {
        width: 67px;
        min-width: fit-content;
    }

    .price-container {
        flex-flow: column;

        .strike-through {
            color: $grey7;
        }

        .price {
            width: 100%;
        }
    }

    .promotional-star {
        vertical-align: middle;
        font-weight: bold;
        font-size: 11px;
    }

    .budget-form {
        font-size: 13px;

        label {
            display: flex;
            margin-bottom: 10px;

            input {
                margin-right: 5px !important;
            }
        }

        .bp-logo {
            width: 20px;
            height: 14.5px;
            margin-right: 1px;
            vertical-align: baseline;
        }

        .form-group {
            margin-bottom: 0;
        }
    }

    .product-edit {
        .wishlist-heart {
            height: 12px;
            width: 12px;
        }
    }

    .availability-msg {
        width: 65px;
        display: inline-block;
        text-align: left;
        font-family: $opensans-bold;
        font-size: 11px;

        @include media-breakpoint-up(md) {
            position: absolute;
            top: -15px;
            left: -10px;
        }
    }

    .availability-msg.red-text-label {
        width: auto;
    }

    .red-shipping-label {
        color: $persian-red;
        font-size: 11px;
        font-family: $opensans-bold;
        display: inline-block;
    }

    .preorder-label {
        color: $persian-red;
        font-size: 11px;
        font-style: italic;
        font-weight: 600;
        display: inline-block;
    }

    .livetv-msg {
        font-size: 11px;
        font-style: italic;
    }

    .line-item-total {
        position: relative;
        text-align: center;

        .livetv-msg {
            position: absolute;
            margin: 10px 0 0;
            top: 100%;
        }
    }
}

.save-for-later-wrapper {
    margin-left: 6px;

    @include media-breakpoint-up(md) {
        margin-left: 20px;
    }

    .save-for-later {
        font-family: $opensans-bold;
        font-size: 11px;
    }

    .save-for-later-icon {
        font-size: 12px;
        color: $picton-blue;
    }
}

.bp-logo--small {
    width: 59px;
}

.bp-logo-blue {
    display: block;
}

.bp-logo-black {
    display: none;
}

.interest-fee-label {
    font-size: 10px;
    padding-left: 5px;
    color: $black;
}

.payment-options-out {
    .interest-fee-label {
        text-transform: capitalize;
        color: $blue;
        font-style: normal;
        font-size: 11px;
    }
}

.pricing-bp-block {
    @include media-breakpoint-down(md) {
        padding: 10px 0;
    }

    .price-container {
        @include media-breakpoint-down(md) {
            padding: 5px 0;
            margin: 7px 0;
            border-bottom: 1px solid $mercury;
            border-top: 1px solid $mercury;
        }
    }

    .mobile-remove-btn {
        margin: 10px 0 0;

        .remove-btn {
            @include media-breakpoint-down(md) {
                width: auto;
            }
        }
    }
}

.mobile-subtotal-blk {
    width: 100%;
    display: inline-block;
    text-align: center;

    label {
        margin-bottom: 0;
        text-transform: uppercase;
    }
}

.currency-symbol {
    display: inline-block;
}

span.currency-symbol-wrapper {
    font-size: 20px;
}

.toggle-symbol {
    display: none;
}

.totals {
    @include media-breakpoint-up(md) {
        border: 2px solid $alabaster;
        padding: 15px;
    }

    .heading-bold {
        font-size: 15px;
        font-family: $opensans-bold;
        padding-bottom: 5px;
    }

    .total {
        margin-top: 10px;
    }

    .bgp-msg {
        font-style: italic;
        font-size: 13px;
    }

    .danger {
        font-size: 12px;
        color: red;

        a {
            text-decoration: underline;
            color: red;
        }
    }
}

.heading-label {
    font-size: 13px;
    color: $night-rider;
    font-family: $opensans-bold;
    margin: 0;
}

.shipping-methods {
    .country-control {
        flex-wrap: wrap;

        .flag {
            vertical-align: baseline;
        }

        .justify-content-between {
            input {
                margin-top: 0;
            }
        }
    }

    .country-label {
        display: inline-block;
        padding: 0 5px;
        text-transform: capitalize;
        font-size: 13px;
    }

    .shipping-label {
        padding-left: 7px;
    }

    .shipping-label[for="ups-value"] {
        .shipping-method-cost {
            display: none;
        }
    }

    .delivery-time {
        font-size: 11px;
        padding-left: 20px;
    }

    .shippingItem {
        background-color: $white;
        padding: 13px 10px;

        @include media-breakpoint-down(lg) {
            &.transparent-background {
                background-color: transparent;
            }
        }
    }

    .bg-mobile-shipping {
        padding: 0;

        @include media-breakpoint-up(md) {
            background: transparent;
        }
    }

    .spacing-model-mobile {
        width: 100%;
        background: $white;
        padding-bottom: 10px;

        @include media-breakpoint-up(md) {
            width: auto;
            margin-left: 0;
            background: transparent;
            padding-bottom: 5px;
            padding-top: 0;
        }
    }

    .shipping-options-wrapper {
        padding: 5px;
        background-color: $solitude;
    }
}

.save-for-later-heading {
    font-size: 13px;
    font-family: $opensans-bold;

    @include media-breakpoint-up(sm) {
        font-size: 16px;
    }
}

.move-save-for-later-to-cart {
    font-family: $opensans-bold;
    font-size: 12px;
}

.cart-recommendation-carousel {
    padding-top: 15px;

    .slick-slide {
        padding: 0 10px;
    }

    .carousel-title {
        margin-bottom: 30px;
        font-size: 16px;
        text-align: left;
        font-family: $opensans-bold;
        letter-spacing: 0;
        color: $night-rider;
        text-transform: uppercase;
        opacity: 1;
    }

    @include media-breakpoint-down(md) {
        .slick-list {
            padding: 0 30% 0 0 !important;
        }
    }
}

.coupons-and-promos {
    .applied-coupon {
        border: 1px solid #549C62;
        border-radius: 4px;
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 10px 15px;
        color: #549C62;
    }

    .promo-code-label {
        margin-bottom: 8px;
    }

    .promo-code-submit {
        padding-left: 15px;
    }

    .promo-code-btn {
        font-family: $opensans-bold;
        border-color: $lilac-purple;
        font-size: 14px;
        border-radius: 0;

        &:not(.delete-coupon-confirmation-btn) {
            height: 30px;
            text-transform: uppercase;
            padding: 0 15px;

            &:active,
            &:focus {
                background-color: #868ce0 !important;
                box-shadow: none !important;
            }
        }
    }

    .promo-code-form {
        display: block;
    }

    .coupon-code-field {
        height: 30px;
        padding: 5px 10px;
        font-size: 12px;
        border-radius: 0;
        margin-bottom: 0;
    }

    .coupon-code-field::placeholder{
        padding: 8px;
    }
    .coupon-success {
        border: 3px solid #329f5c;
        border-radius: 3px;
    }

    .coupon-error {
        font-size: 13px;
    }

    .msg-bold {
        font-family: $opensans-bold;
        font-size: 13px;
    }

    .msg-regular {
        font-size: 13px;
    }

    .form-group {
        margin-bottom: 0;
    }
}

.payment-protection-text {
    font-family: $opensans-regular;
    font-weight: $font-wt-bold;
    font-size: 14px;

    @include media-breakpoint-up(sm) {
        font-size: 15px;
    }
}

.checkout-continue {
    position: static;
    box-shadow: none;

    @include media-breakpoint-down(sm) {
        border-top: 1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
    }

    .checkout-btn {
        background: $light-green;
        border-color: $light-green;
        font-weight: 600;
        font-size: 15px;
        width: 100%;
        padding: 10px 0 9px;
        margin-bottom: 10px;
        border-radius: 5px;
        text-transform: uppercase;
        letter-spacing: 1px;

        &.btn-green-big {
            background: $light-green;
        }

        &:focus,
        &:active {
            background-color: #329f5c !important;
            box-shadow: none !important;
        }
    }

    .help-with-order {
        text-align: center;
        font-size: 13px;
        margin-bottom: 15px;
        font-family: $opensans-regular;

        &-chat {
            text-align: start;
            font-size: 13px;
        }
    }

    @include media-breakpoint-down(md) {
        &.checkout-continue-ra div:not(.paypal-button-container) {
            padding: 0;
        }
    }
}

.mobile-checkout-continue {
    .checkout-btn {
        background: $light-green;
        font-weight: 600;
        font-size: 15px;
    }
}

.fixed-cart-elements {
    background: $white;

    &.is-sticky {
        left: 0;
        padding: 10px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    }
}

.navy-blue {
    margin: 8px 0 0 30px;

    span {
        color: $black;
        font-size: 15px;
        text-transform: uppercase;
        font-weight: 700;
        margin-top: 7px;
        margin-bottom: 10px;
        display: inline-block;
        text-decoration: none;

        &:hover {
            color: $black;
            text-decoration: none;
        }
    }
}

.cart-header-align {
    justify-content: space-between;

    .user-message {
        @include media-breakpoint-down(sm) {
            display: none !important;
        }
    }
}

.border-cart-header {
    box-shadow: 0 7px 20px #0000000d;
    border-bottom: 1px solid #ddd;
}

.cart.cart-page,
.checkout-continue-ra-checkout {
    .five9-chat {
        color: $light-gray3;
        text-align: start;
        font-size: 22px;
        margin-right: 10px;
        padding: 0;
        font-family: $opensans-bold;

        span {
            text-decoration: none;
        }

        i {
            padding-right: 5px;

            &::before {
                content: "\f086";
            }
        }
    }
}

// Totals summary
.order-total-summary-wrapper {
    background-color: $solitude;
    padding: 10px 15px;
    font-size: 13px;
    margin-bottom: 30px;
    font-weight: 600;
    font-family: $opensans-regular;

    @include media-breakpoint-up(sm) {
        padding: 15px;
        font-size: 15px;
    }

    .sub-total {
        font-weight: normal;
    }

    .budgetpay-message {
        .bp-emi-details {
            font-size: 11px;
        }
    }
}

.order-total-summary-title {
    color: $dark-gunmetal;
    font-family: $opensans-bold;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    padding-bottom: 8px;
    border-bottom: 1px solid $black;
    margin-bottom: 8px;

    @include media-breakpoint-up(sm) {
        padding-bottom: 5px;
        margin-bottom: 4px;
    }
}

.order-total-summary {
    font-size: 14px;
    line-height: 20px;

    @include media-breakpoint-up(sm) {
        padding-bottom: 15px;
        border-bottom: 1px solid $black;
    }
}

.highlited {
    font-family: $opensans-bold;
}

.leading-lines {
    margin-bottom: 4px;
}

.order-total-space {
    margin-top: 5px;
    margin-bottom: 1px;
}

.order-receipt-label,
.order-receipt-value {
    margin-bottom: 0;
}

.order-discount,
.shipping-discount {
    color: $black;
}

.grand-total {
    font-size: 14px;
}

.today-payment-title {
    @include media-breakpoint-up(sm) {
        width: 70%;
        float: left;
    }
}

.today-payment-value {
    @include media-breakpoint-up(sm) {
        width: 30%;
    }
}

.budgetpay-details {
    font-family: $opensans-light;
    color: $night-rider;
}

.est-saving-title {
    font-size: 12px;
    text-decoration: underline;
    color: $persian-red2 !important;
}

.est-saving-value {
    font-size: 12px;
    color: $persian-red2;
}

.item-attributes {
    .pdp-link,
    .line-item-id {
        line-height: 17px;
        color: $dark-gunmetal;
        text-align: left;
        margin-bottom: 5px;
        font-size: 13px;

        &:hover {
            text-decoration-line: none;
        }
    }

    .line-item-id {
        color: $smart-gray;
    }

    .personalize-msg {
        font-family: $opensans-bold;

        .personalize-msg-preview {
            color: $picton-blue;
        }

        .personalize-msg-text {
            font-family: $opensans-regular;
        }
    }

    .promotion-callout {
        &-not-applied-icon {
            color: $alert-red;
        }
    }
}

.store-credit-form {
    font-family: $opensans-regular;
    font-weight: normal;
    font-size: 14px;

    label {
        margin-bottom: 8px;
    }

    .store-credits-available {
        font-size: 13px;
        font-family: $opensans-bold;
    }

    .store-credits-available-value {
        font-family: $opensans-regular;
    }

    .store-credit-input {
        padding: 5px 10px;
        font-size: 14px;
        height: 30px;
        border-radius: 0;
    }

    .store-credit-btn {
        font-family: $opensans-bold;
        font-size: 14px;
        border-color: $lilac-purple;
        text-transform: uppercase;
        border-radius: 0;
        padding: 0 15px;
        height: 30px;

        &:active,
        &:focus {
            background-color: #868ce0 !important;
            box-shadow: none !important;
        }
    }

    .store-credit-note {
        margin: 5px 0;
        font-size: 11px;
        font-weight: bold;
        display: block;
    }
}

.cart-error-messaging.cart-error {
    position: static;

    @include media-breakpoint-down(md) {
        padding-top: 15px;
    }
}

.timer-wrapper {
    background-color: $pale-red-1;
    border: 1px solid $timer-red;
    border-radius: 3px;
    padding: 10px;
    font-size: 12px;

    .timer-text {
        font-weight: $font-wt-bold;
    }

    .timer-minutes,
    .timer-seconds {
        padding: 3px;
        background-color: $timer-red;
        color: $white;
        border-radius: 3px;
        font-size: 13px;
        font-weight: $font-wt-bold;
    }

    .timer-container {
        position: relative;
        width: 90%;
        height: 5px;
        background-color: $timer-red;
        border-radius: 4px;
        overflow: hidden;
        margin: 10px auto;

        @include media-breakpoint-up(sm) {
            width: 70%;
        }

        &-minicart {
            width: 96%;
            height: 3px;
        }

        .timer-bar {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: $pale-red-2;
        }
    }
}

.empty-cart-title {
    font-family: $opensans-regular;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.4;

    @include media-breakpoint-up(up) {
        font-size: 28px;
    }
}

.free-shipping-details-dialog {
    .modal-dialog {
        @include media-breakpoint-up(sm) {
            max-width: 450px;
        }
    }

    .modal-content {
        border-radius: 0;
        width: auto;
    }

    .modal-header {
        text-align: center;
        border: none;
        padding: 20px 25px 15px 25px;

        .header-title {
            color: $navy-blue;
            font-size: 18px;
        }

        .close {
            background-color: $picton-blue;
            color: $white;
            opacity: 1;
            font-size: 30px;
            margin: -20px -25px 0 0;
            padding: 0;

            span {
                display: block;
                height: 35px;
                width: 35px;
            }
        }
    }

    p.text {
        text-align: center;
        font-size: 13px;
        color: $night-rider;
    }
}

.shipping-item-description {
    display: block;
    font-size: 12px;
    padding-left: 24px;
}

#movedToWishlistSuccess {
    .modal-dialog {
        max-width: 260px;

        @include media-breakpoint-down(xs) {
            margin: 20px 30px;
        }

        .modal-content {
            padding: 14px;
            border-radius: 0;
            font-family: $opensans-regular;

            @include media-breakpoint-down(xs) {
                width: 95%;
            }

            .modal-header {
                border-bottom: none;
                padding: 0 8px;
            }

            .modal-body {
                padding: 5px 0 40px 0;
            }

            .wishlist-title {
                color: $picton-blue;
                font-size: 13px;
                line-height: 1.7;
                font-weight: 700;
                font-family: $opensans-regular;
                text-align: left;

                @include media-breakpoint-down(xs) {
                    margin-top: 16px;
                    text-align: center;
                }
            }

            .wishlist-wrapper {
                margin: auto;
                padding: 0 16px;
            }
        }
    }
}

.line-item-wrap {
    display: block;
    width: 60px;
    height: 55px;
    position: relative;
}

.qty-rbtn {
    color: $light-gray3;
    font-size: 12px;
    margin-left: 15px;
}

.valid-cart-error {
    min-height: auto;
}

/** --cart styles-- **/
.CRO_generic_color {
    .bp-logo-blue {
        display: none;
    }

    .bp-logo-black {
        display: block;
        height: 16px;
        width: auto;
    }

    .payment-options-out {
        .interest-fee-label {
            color: $primarybtn-color;
        }
    }

    .select-payment-options {
        background: $white url(../images/select-down-arrow-black.svg) no-repeat right top;
    }

    .regular-cart-header {
        color: $primarybtn-color !important;
    }

    .address-changed-modal-button {
        background: $primarybtn-color !important;
    }

    #movedToWishlistSuccess {
        .modal-dialog {
            .wishlist-title {
                color: $primarybtn-color;
            }
        }
    }

    .totals .danger,
    .totals .danger a {
        color: $primarybtn-color !important;
        text-decoration: none;
        font-weight: 600;
    }

    input[type="radio"]:checked {
        -webkit-appearance: none;
        border: 1px solid $primarybtn-color;
    }

    input[type="radio"]:checked::before {
        background: $primarybtn-color;
    }

    .save-for-later-wrapper {
        .save-for-later-icon {
            font-size: 12px;
            color: $primarybtn-color;
        }
    }

    .coupons-and-promos {
        .promo-code-btn {
            border-color: $primarybtn-color;

            &:active,
            &:focus {
                background-color: $primarybtn-color !important;
                box-shadow: none !important;
            }
        }
    }

    .free-shipping-details-dialog .modal-header .header-title {
        color: $primarybtn-color;
    }

    .free-shipping-details-dialog .modal-header .close {
        background: $primarybtn-color;
    }

    .checkout-continue {
        .checkout-btn {
            background: $primarybtn-color;
            border-color: $primarybtn-color;

            &.btn-green-big {
                background: $primarybtn-color;
            }

            &:focus,
            &:active {
                background-color: $primarybtn-color !important;
                box-shadow: none !important;
            }
        }

        .mobile-checkout-continue {
            .checkout-btn {
                background: $primarybtn-color;
                border-color: $primarybtn-color;
            }
        }
    }

    .store-credit-form {
        .store-credit-btn {
            border-color: $primarybtn-color;

            &:active,
            &:focus {
                background-color: $primarybtn-color !important;
                box-shadow: none !important;
            }
        }
    }

    .item-attributes {
        .pdp-link {
            text-decoration: none;

            &:hover {
                text-decoration-line: underline;
            }
        }
    }
}

#cart-paypal-button-container {
    @include media-breakpoint-down(sm) {
        margin-bottom: 0 !important;
    }
}

.checkout-footer {
    border-top: 2px solid $alabaster;
}

.protection-plan-checkbox-wrapper {
    font-size: 14px;
    font-weight: 700;
    margin-top: 15px;
    padding-left: 0;
    
    .checkbox {
        background-color: $solitude2;
        padding: 10px 5px;

        .checkox-label {
            margin-left: 5px;
        }
    }

    .warranty-info {
        color: $gray9;
        font-size: 16px;
    }
}