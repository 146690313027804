@import "../../../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.shopLink-icon-image {
    width: 20px;
    height: 15px;
}

.header-banner {
    .shopLink-layout {
        padding-top: 0;
        padding-bottom: 0;
        background: linear-gradient(to left, $red-gradient1, $red-gradient2 100%);

        @include media-breakpoint-up(sm) {
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
        }
    }
}

.shopLink-container {
    .shopLink-link {
        text-decoration: none;
        background: $white;
        display: inline-block;
        border-radius: 2em;
        margin: 15px 0 15px 0;
        padding: 10px 15px;
        font-size: 9px;
        color: $black !important;

        @include media-breakpoint-up(sm) {
            display: inline;
            font-size: 13px;
            margin: 15px 20px 15px 0;
            padding: 10px 25px;

            &:hover {
                text-decoration: none;
            }

            @include media-breakpoint-up(md) {
                font-size: 18px;
            }
        }
    }

    .shopLink-label {
        font-size: 14px;
    }
}
