@import "../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "../base/fonts";

.video-360-modal,
.video-360-modal-second {
    transition: none;

    &.modal.fade .modal-dialog {
        transition: none;
        transform: none;
    }

    &.modal.show .modal-dialog {
        transition: none;
        top: 50%;
        transform: translateY(-50%);
        max-width: 900px;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        background-color: rgba(68, 68, 68, 0.9);

        @include media-breakpoint-down(sm) {
            max-width: 380px;
            height: 320px;
        }
    }

    .pdpVideo360 {
        background-color: transparent !important;
    }

    .close {
        color: $white;
        font-size: 35px;
        font-family: $opensans-light;
        opacity: 1;
        position: absolute;
        top: -40px;
        right: 0;
        cursor: pointer;
        pointer-events: auto;
        font-weight: $font-wt-thin;

        &:hover {
            opacity: 1 !important;
        }
    }

    .modal-content {
        background: $night-rider;
        border-radius: 5px;
        padding: 5px;
    }

    .video-360 {
        max-width: 100%;
    }
}
