.activate-fast-buy-modal {
    .modal-title {
        font-weight: 600;
        font-size: 18px;
        text-transform: uppercase;
        color: $navy-blue;
    }

    .modal-dialog {
        max-width: 600px;
    }

    .fast-buy-modal__text,
    .fast-buy-modal__detail {
        font-size: 13px;
        margin-bottom: 20px;
    }

    .fast-buy-modal__button {
        font-size: 13px;
        color: $white;
        font-weight: 700;
        padding: 8px 15px;
        text-transform: uppercase;
        border-radius: 0;
        border: 0;
        text-decoration: none;
    }
}

.confirmation-fast-buy-modal {
    .fast-buy-title {
        font-size: 30px;
        line-height: 33px;
    }

    .fast-buy-subtitle {
        font-size: 13px;
    }

    .fast-buy-subtitle-email {
        margin: 25px 0;
    }
}

/** --CRO activefastbuyModal styles-- **/
.CRO_generic_color {
    .activate-fast-buy-modal {
        .modal-title {
            color: $primarybtn-color;
        }

        .fast-buy-modal__button {
            background: $primarybtn-color;

            &:focus,
            &:active {
                background: $primarybtn-color;
            }
        }
    }
}
