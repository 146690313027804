@import "../variables";

.live-tv-label {
    display: inline-block;
    width: 135px;
    background-color: $japanese-laurel;
    color: $white;
    font-family: $opensans-regular;
    font-size: 13px;
    text-align: center;
}
