.glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: $glyphicons;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
}

.glyphicon-upload {
    &::before {
        content: "\e027";
    }
}

.glyphicon-search {
    &::before {
        content: "\e003";
    }
}

.glyphicon-remove {
    &::before {
        content: "\e014";
    }
}

.glyphicon-plus {
    &::before {
        content: "\002b";
    }
}

.glyphicon-minus {
    &::before {
        content: "\2212";
    }
}

.glyphicon-bookmark {
    &::before {
        content: "\e044";
    }
}

.glyphicon-print {
    &::before {
        content: "\e045";
    }
}

.glyphicon-remove-sign {
    &::before {
        content: "\e083";
    }
}

.glyphicon-th {
    &::before {
        content: "\e011";
    }
}

.glyphicon-th-list {
    &::before {
        content: "\e012";
    }
}

.glyphicon-warning-sign {
    &::before {
        content: "\e107";
    }
}
