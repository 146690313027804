@import "~@sfra/scss/components/footer";

footer {
    background-color: $white;
    font-family: $opensans-regular;
}

.footer-block {
    border-top: 1px solid $mercury;
}

.support-container {
    padding: 40px 0;
}

.support-item {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
        border-right: 1px solid $mercury;
    }
}

.support-img-wrapper {
    margin-bottom: 0;
}

.support-title,
.support-title a {
    color: #1e1e2c;
    font-family: $opensans-bold;
    font-size: 17px;
    font-weight: $font-wt-bold;
    line-height: 25px;
    margin-bottom: 0;
    padding-left: 12px;
    display: inline-block;
}

.support-description {
    color: $night-rider;
    font-size: 14px;
    line-height: 1.5em;

    .link {
        color: $astral;

        &:hover {
            color: $black;
            text-decoration: none;
        }
    }
}

.copy-container {
    padding-top: 12px;

    @include media-breakpoint-only(md) {
        padding-top: 39px;
    }

    .copy-container-cards {
        @include media-breakpoint-up(lg) {
            padding-top: 24px;
            padding-bottom: 74px;
        }

        @include media-breakpoint-only(md) {
            padding-top: 16px;
            padding-bottom: 50px;
        }
    }

    .footer-copyright {
        padding-bottom: 11px;

        @include media-breakpoint-up(lg) {
            padding-bottom: 20px;
        }

        @include media-breakpoint-only(md) {
            padding-bottom: 4px;
        }

        .text--small {
            color: $Zambezi;
            font-family: $opensans-regular;
            font-style: normal;
            font-weight: 400;
            line-height: 21px;
            font-size: 13px;

            @include media-breakpoint-only(md) {
                font-size: 11px;
            }
        }
    }

    .footer-company-logo {
        .vglgrouplogo {
            color: $Zambezi;
            font-family: $opensans-regular;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px;

            .compantvglgroup {
                font-weight: 700;
            }
        }
    }

    .footer-allyantlogo {
        @include media-breakpoint-down(sm) {
            padding: 28px;
        }
    }
}

.footer-second-level {
    background: $light-grey-bg;

    @include media-breakpoint-up(lg) {
        padding-top: 25px;
        padding-bottom: 32px;
    }
}

.footer-links-container {

    @include media-breakpoint-up (md) {
        margin-bottom: 0;
    }
}

.footer-links-block {
    @include media-breakpoint-up (md) {
        border-bottom: 0;
    }

    .list {
        margin-bottom: 10px;

        @include media-breakpoint-up (md) {
            margin-bottom: 0;
        }
    }

    .footer-chat-link {
        font-weight: 700;
        font-size: 18px;
        text-decoration: underline;
        color: $picton-blue;
        cursor: pointer;
        line-height: 13px;
    }

    .footer-chat-icon {
        display: none;
    }

    .footer-chat {
        margin-bottom: 0;
    }
}

.footer-links-heading {
    color: $black;
    font-family: $opensans-regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    margin: 0 0 15px;
}

.footer-links-item {
    color: $Zambezi;
    font-family: $opensans-regular;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;

    @include media-breakpoint-up (md) {
        font-size: 13px;
    }

    .slc-footer-giftcard {
        margin-left: 5px;
    }
}

.email-signup-container {
    background: $grey-light-bg;
    border-radius: 10px;
    padding-top: 27px;
    padding-bottom: 27px;

    @include media-breakpoint-up(lg) {
        padding: 23px 41px 19px 72px;
    }

    @include media-breakpoint-only(md) {
        padding-top: 44px;
        padding-bottom: 25px;
    }

    .email-signup-title-description {
        line-height: 20px;
        color: $black;
        font-weight: 700;
        font-family: $opensans-regular;

        .email-signup-title-wrap {
            @include media-breakpoint-down(sm) {
                margin-bottom: 13px;
            }

            .email-signup-title {
                font-size: 22px;

                @include media-breakpoint-only(md) {
                    font-size: 30px;
                }
            }
        }

        .email-signup-description {
            font-size: 12px;
            font-weight: 400;
            color: $black;
            font-family: $opensans-regular;
            line-height: 20px;

            @include media-breakpoint-down(sm) {
                margin-bottom: 28px;
            }

            @include media-breakpoint-only(md) {
                margin-bottom: 21px;
            }

            @include media-breakpoint-up(lg) {
                margin-top: 9px;
            }
        }
    }

    .form-control {
        border-radius: 0;
        border-color: $black;

        &:focus {
            box-shadow: none;
        }
    }

    .email-signup-field,
    .sms-signup-field {
        margin-bottom: 18px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 9px;
        }

        input {
            font-family: $opensans-regular;
            color: $grey-input-placeholder-color;
            font-size: 13px;
        }
    }

    .hpCanadianResident-wrap {
        input {
            height: 16px;
            width: 16px;
        }

        .img-wrapper {
            img {
                margin: 0 3px 0 3px;
            }
        }

        .hpCanadianResident-checkbox {
            vertical-align: middle;
            position: relative;
            top: 2px;
        }

        .hpCanadianResident-text {
            color: $black;
            font-family: $opensans-regular;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 10px;
        }

        label {
            @include media-breakpoint-down(sm) {
                margin-bottom: 29px;
            }
        }
    }

    .or-text {
        color: #696969;
        font-size: 14px;
        line-height: 23px;
        font-style: italic;
        margin: 4px 20px 12px 20px;
    }
}

.back-to-top {
    position: fixed;
    right: 25px;
    bottom: 55px;
    width: 45px;
    height: 45px;
    z-index: 2;

    @include media-breakpoint-down (md) {
        width: 30px;
        height: 28px;
        margin-bottom: 90px !important;
    }

    .glyphicon-upload {
        font-size: 45px;
        color: $nobel-light;

        &:hover {
            color: $pigeon-post;
        }
    }
}

.red-footer-txt {
    color: $valencia;
}

.be-related-link-container {
    display: block;
    text-align: center;
    padding: 20px 0;

    .be-label {
        display: inline-block;
        color: $valencia;
        font-size: 14px;
        font-weight: normal;
        padding-right: 30px;
    }

    .be-list {
        display: inline-block;
        width: auto;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .be-list-item {
        display: inline-block;
        margin-right: 20px;
    }
}

.support-1 {
    .support-title {
        transform: translateX(-8px);
    }
}

.support-4 {
    .support-title {
        transform: translateX(-14px);
    }
}

.join-free {
    background-color: $black;
    color: white;

    &:hover {
        background-color: #3c3c3c;
    }
}

.acceptTermsLabel-wrap {
    .acceptTermsLabel {
        .acceptTermsLabelInput {
            height: 16px;
            width: 16px;
        }

        .accept-terms-conditions {
            color: $black;
            font-family: $opensans-regular;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;

            a {
                text-decoration: underline !important;
            }
        }

        .email-signup-checkbox {
            vertical-align: middle;
            position: relative;
            top: 2px;
        }
    }
}

/** --mini cart styles-- **/
.CRO_generic_color {
    .footer-links-block {
        .link--small {
            color: $primarybtn-color;
        }

        .footer-chat-link {
            color: $primarybtn-color;
        }
    }
}

.margin-top {
    margin-top: 176px !important;
    z-index: 99999999 !important;
}

.input-group-append {
    z-index: 0;

    .btn-secondary {
        border: 1px solid $black;
        background: $black;

        &.subscribe-email {
            padding: 4px 12.2px 4px 13px;

            @include media-breakpoint-only(md) {
                padding: 3px 12.2px 3px 13px;
                border: none;
            }
        }

        &.subscribe-sms {
            padding: 4px 15px 4px 16px;

            @include media-breakpoint-only(md) {
                padding: 3px 12.2px 3px 13px;
                border: none;
            }
        }
    }
}

.footer-signup-conatiner {
    padding-top: 12px;
    padding-bottom: 21px;

    @include media-breakpoint-only(md) {
        padding-top: 20px;
        padding-bottom: 11px;
    }

    @include media-breakpoint-up(lg) {
        padding-top: 20px;
        padding-bottom: 33px;

        .container-secondary {
            margin: 0 auto;
        }
    }
}

.footer-contact-details {
    @include media-breakpoint-down(sm) {
        margin: 30px auto 24px;
        padding-right: 0;
    }

    .footercontactdetails {
        border-radius: 30px;
        border: 0.5px solid $black;
        font-family: $opensans-regular;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;

        @include media-breakpoint-up(lg) {
            font-size: 30px;
            margin-top: 16px;
        }

        @include media-breakpoint-only(md) {
            margin-top: 4px;
        }

        @include media-breakpoint-only(md) {
            font-size: 22px;
        }

        .link {
            color: $Zambezi;
            text-decoration: none !important;
            vertical-align: middle;
            white-space: nowrap;

            @include media-breakpoint-up(lg) {
                margin-left: 20px;
            }
        }
    }
}

#footerLinks {
    .footer-links-details-block {
        @include media-breakpoint-only(md) {
            padding-top: 7px;
            padding-bottom: 26px;
        }

        @include media-breakpoint-down(sm) {
            padding-top: 17px;
            padding-bottom: 22px;
        }

        .footer-block-help-education-block {
            @include media-breakpoint-down(sm) {
                margin-top: 40px;
            }
        }
    }

    .footer-link-social-icons {
        @include media-breakpoint-down(md) {
            padding-top: 18px;
        }

        @include media-breakpoint-down(sm) {
            text-align: center;
        }

        @include media-breakpoint-down(md) {
            background: $white;
        }

        .footer-appicon-list {
            @include media-breakpoint-up(md) {
                display: inline-flex;
            }

            .app-icon {
                .footer-appicon-images {
                    @include media-breakpoint-up(md) {
                        width: 100%;
                    }
                }

                &.smarttv-image {
                    img {
                        @include media-breakpoint-down(sm) {
                            margin-top: 11px;
                        }
                    }
                }
            }

            .footer-appiconlist {
                @include media-breakpoint-down(sm) {
                    display: inline-flex;

                    .row {
                        margin: 0 auto;
                    }
                }
            }
        }

        .footer-sociallink-list {
            @include media-breakpoint-down(sm) {
                margin-top: 33px;
            }

            .social-icon {
                margin-right: 15px;

                &.twitter-icon {
                    margin-right: 0;
                }

                @include media-breakpoint-up(xl) {
                    margin-right: 25px;
                }

                @include media-breakpoint-only(lg) {
                    margin-right: 10px;
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

.footer-links-second-level-images {
    .secondlevelimage-footer {
        .row {
            display: inline-flex;
        }
        @include media-breakpoint-down(sm) {
            padding-right: 0;
            padding-left: 0;

            .trust-reviews-business {
                width: 100%;
            }
        }
    }
}

.social-link-footer-images {
    @include media-breakpoint-up(lg) {
        padding-bottom: 40px;
    }
}

.second-level-container {
    padding-bottom: 20px;
    @include media-breakpoint-only(md) {
        padding-bottom: 15px;
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: 34px;
    }
}
