@import "../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "../base/fonts";

.add-to-wishlist-messages {
    transform: translate(-50%, -50%);
    position: fixed;
    top: 15%;
    left: 50%;
}

.add-to-wishlist-alert {
    animation: fade 5s linear forwards;
    box-shadow: 1px 1px 5px grey;
    padding: 1em;

    @keyframes fade {
        0% {
            opacity: 0;
        }

        10% {
            opacity: 1;
        }

        90% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

    &.show {
        display: block;
    }
}

.wishlistTile {
    i {
        &.fa-heart-o {
            color: rgba(0, 0, 0, 0.7);
        }

        &.fa-circle {
            color: rgba(255, 255, 255, 0.7);
            text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        }
    }

    .fa-heart {
        color: black;
    }

    span {
        &.fa-stack {
            position: absolute;
            top: 8px;
            right: 16px;
        }
    }
}

.wishlistRed {
    color: $red !important;
}

.wishlist-modal {
    .modal-dialog {
        @include media-breakpoint-down(xs) {
            margin: 20px 30px;
        }

        .modal-content {
            padding: 8px;
            border-radius: 0;
            font-family: $opensans-regular;

            @include media-breakpoint-down(xs) {
                width: 95%;
            }

            .modal-header {
                border-bottom: none;
                padding: 0 8px;
            }

            .modal-body {
                padding: 0 16px 16px;
            }

            .wishlist-title {
                color: $picton-blue;
                font-size: 18px;
                font-weight: 700;
                font-family: $opensans-regular;
                text-align: left;
                @include media-breakpoint-down(xs) {
                    margin-top: 16px;
                    text-align: center;
                }
            }

            .wishlist-popup-image {
                img {
                    border: 2px solid $whisper;
                    max-width: 100%;
                }
            }

            .wishlist-wrapper {
                margin: auto;
                padding: 0 16px;

                .add-to-wish-list {
                    background: $picton-blue;
                    font-weight: bold;
                    font-size: 13px;
                    color: $white;
                    margin-top: 2rem;
                    border-radius: 0;
                    text-transform: uppercase;
                    @include media-breakpoint-down(xs) {
                        margin-top: 16px;
                        text-align: center;
                    }
                }

                .remove-wishlist-btn-group {
                    button.btn {
                        border-radius: 0;
                        padding-left: 24px;
                        padding-right: 24px;
                        margin-top: 12px;
                        border: 1px solid $grey3;
                        color: $grey5;
                        font-size: 14px;

                        &.remove-wishlist {
                            color: $red;
                            border: 1px solid $red;
                        }

                        &:hover {
                            background-color: $white;
                        }
                    }
                }
            }
        }
    }

    &.wishlist-modal-info {
        .modal-dialog {
            max-width: 350px !important;

            .wishlist-wrapper {
                margin: 1.5rem auto;

                .wishlist-title {
                    i.wl-ok-sign {
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}

.live-tv-pdp-section {
    .wishlistTile,
    .remove-wishlist-btn {
        padding: 0 5px;
        font-size: 18px;
        margin-left: 10px;
        border: 2px solid rgba(0, 0, 0, 0.3);
        border-radius: 20%;
    }

    .wishlistTile {
        opacity: 0.3;
    }

    .wishlist-disabled {
        pointer-events: none;
    }
}

/** --wishlistGlobal styles-- **/
.CRO_generic_color {
    .wishlist-modal {
        .modal-dialog {
            .modal-content {
                .wishlist-wrapper {
                    .add-to-wish-list {
                        background: $primarybtn-color;
                    }
                }
            }
        }
    }

    .wishlist-modal .modal-dialog .modal-content .wishlist-title {
        color: $primarybtn-color !important;
    }

    .max-quantity-modal__title {
        color: $primarybtn-color !important;
    }
}
