.category-breadcrumb,
.container,
.live-tv-page-breadcrumb {
    .breadcrumb {
        font-weight: 400;
        font-size: 11px;
        border-bottom: unset;
        font-family: $opensans-regular;

        .breadcrumb-item {
            text-transform: uppercase;
            display: flex;
            align-items: center;

            .breadcrumb-home {
                font-weight: bold;

                .shop-text {
                    color: $lilac-purple;
                }

                .lc-text {
                    color: $lc-red;
                }
            }

            a {
                color: $dark-gray3;
                font-weight: $font-wt-regular;
                font-size: 14px;

                &:hover {
                    text-decoration: none;
                }

                .breadcrumb-blue {
                    color: $astral;
                    font-weight: $font-wt-bold;
                    font-size: 14px;
                }

                .breadcrumb-red {
                    color: $persian-red2;
                    font-weight: $font-wt-bold;
                    font-size: 14px;
                }
            }

            span {
                color: $dark-gray3;
                font-weight: $font-wt-semibold;
                font-size: 14px;
            }
        }
    }

    .breadcrumb-item + .breadcrumb-item::before {
        padding: 0 5px;
        color: $dark-gray3;
        content: "|\00a0";
    }
}

.live-tv-page-breadcrumb .breadcrumb {
    padding: 20px 0 0;
    background: transparent;
    border: none;
    margin: 0 0 5px;

    @include media-breakpoint-down(sm) {
        padding: 10px 0;
        margin: 0 0 5px;
    }
}
