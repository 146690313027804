.category-tile {
    position: relative;

    h1,
    h2 {
        font-size: 1.75rem;
        position: absolute;
        bottom: 1.875rem;
        left: 1.875rem;
        color: $white;
    }

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to bottom, transparent 60%, rgba(0, 0, 0, 0.5) 100%);
    }
}
