// .container-secondary,
.container-orignal-LS,
[data-action="LiveTv-Show"] .container.container-livetv,
[data-action="Cart-Show"] #maincontent.container {
    width: 100% !important;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 15px;

    @include media-breakpoint-up(md) {
        max-width: map-get($grid-breakpoints, "md");
    }

    @include media-breakpoint-up(lg) {
        max-width: 1280px;
    }

    @include media-breakpoint-up(xl) {
        max-width: 1600px !important;
    }
}

nav {
    .container-orignal-LS {
        @include media-breakpoint-up(xl) {
            max-width: 1350px !important;
        }
    }
}

.container-xxl {
    @include media-breakpoint-up(md) {
        max-width: none;
    }

    @include media-breakpoint-up(lg) {
        width: 100% !important;
        max-width: 1440px;
        padding-right: 45px !important;
        padding-left: 45px !important;
    }
}

.spacing-sm {
    @include media-breakpoint-up(lg) {
        margin-bottom: 20px;
        padding-top: 20px;
    }
}

.container-original-lg {
    .container {
        max-width: 1200px;
        width: 100%;
    }

    .container-secondary {
        max-width: 1250px;
    }
}
