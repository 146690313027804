@import "../variables";

$width01: 320px;
$width0: 360px;
$width: 375px;
$width1: 769px;
$width2: 991.98px;
$width4: 1200px;

.brand-logo {
    width: 85px;

    @include media-breakpoint-up(lg) {
        width: 120px;
    }
}

#maincontent {
    @include media-breakpoint-down(sm) {
        padding-top: 0;
        z-index: auto;
    }
}

.page.page:not([data-action]) #maincontent {
    padding-top: 0;
}

[data-action="null"] #maincontent,
[data-action="Search-Show"] #maincontent,
[data-action="Product-RegularShow"] #maincontent {
    @include media-breakpoint-down(sm) {
        padding-top: 0;
    }
}

.header {
    // border-bottom: 1px solid $grey3;
    padding-bottom: 10px;
    padding-top: 20px;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
        padding: 20px 15px;
        border: 0;
    }
}

.modal-open {
    position: absolute;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;
}

.sticky-top {
    .sticky-scroll.is-sticky {
        top: 77px;
        z-index: 1031;

        @include media-breakpoint-down(sm) {
            top: 122px;
        }
    }

    .stickyHeader {
        position: fixed;
        top: 0;

        @include media-breakpoint-up(md) {
            box-shadow: 0 12px 20px -12px rgba(0, 0, 0, 0.31);
        }
    }

    #sg-navbar-collapse {
        @include media-breakpoint-up(md) {
            margin-top: 77px;
        }
    }

    #maincontent {
        @include media-breakpoint-down(sm) {
            padding-top: 61px;
        }
    }

    .page.page:not([data-action]) #maincontent {
        padding-top: 0;
    }

    [data-action="null"] #maincontent,
    [data-action="Search-Show"] #maincontent,
    [data-action="Product-RegularShow"] #maincontent {
        @include media-breakpoint-down(sm) {
            padding-top: 25px;
        }
    }

    [data-action="Auction-WatchList"] #maincontent,
    [data-action="Auction-BidHistory"] #maincontent,
    [data-action="AutoPay-Show"] #maincontent,
    [data-action="Auction-FavoriteList"] #maincontent,
    [data-action="AuctionProduct-ShowDetails"] #maincontent {
        @include media-breakpoint-down(sm) {
            padding-top: 0;
        }
    }

    .search-mobile {
        //position: fixed;
        left: 0;
        right: 0;
        top: 60px;
        width: 100%;
        box-shadow: 0 12px 20px -12px rgba(0, 0, 0, 0.31);
    }

    &.show-cart-popover {
        .stickyHeader {
            z-index: 1032;
        }

        .sticky-scroll.is-sticky {
            z-index: 100;
        }
    }

    .add-to-cart-sticky {
        @include media-breakpoint-up(md) {
            top: 77px !important;
            bottom: auto !important;
        }
    }
}

.top-banner-wrapper {
    overflow: hidden;

    .top-banner-holder {
        min-height: inherit;
        width: 100%;
    }
}

.stickyHeader {
    background-color: #fff;
    color: $dark-gunmetal;
    max-width: 100%;
    position: static;
    transition: all 0s ease;
}

[data-action="LiveTv-Show"] .stickyHeader {
    z-index: 10000;
}

.header-right-container {
    justify-content: space-between;
}

.cart-header {
    padding: 17px 15px;

    .return-link {
        display: inline-block;
        font-size: 9px;
        text-align: center;
        color: $lilac-purple;
        text-decoration: none;
        margin-top: 7px;
    }

    .fa-mail-reply {
        font-size: 20px;
        margin-bottom: 2px;
    }

    .sign-in-menu-container {
        @include media-breakpoint-down(xs) {
            width: 75%;
        }

        .dropdown-menu {
            @include media-breakpoint-down(xs) {
                left: auto;
                right: -15px;
            }

            @include media-breakpoint-up(lg) {
                top: 100%;
            }
        }
    }

    .header-right-container {
        @include media-breakpoint-down(sm) {
            justify-content: flex-end;
        }
    }

    .nav-item {
        display: flex;
        align-items: center;
    }

    .header-menu-icon {
        margin-right: 8px;
        display: none;

        &.cart-user-icon {
            display: block;

            @include media-breakpoint-down(sm) {
                margin: 0 auto;
            }
        }
    }

    .user-message {
        text-align: left;
    }

    .payment-icons-list {
        display: flex;
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;

        @include media-breakpoint-down(lg) {
            text-align: center;
        }
    }

    .payment-icon-link {
        display: inline-block;
        position: relative;
        max-width: 86px;
        font-size: 11px;
        line-height: 13px;
        padding: 7px 5px 7px 5px;
        margin-right: 5px;
        vertical-align: middle;
        text-transform: uppercase;
        color: $silver-chaice4;

        @include media-breakpoint-up(xl) {
            margin-right: 20px;
            padding-right: 20px;
            max-width: 96px;
        }

        &:hover {
            text-decoration: none;
        }

        &::after {
            content: "";
            position: absolute;
            height: 27px;
            top: 0;
            right: 0;
            bottom: 0;
            margin: auto;
        }
    }

    .payment-icon-item {
        &:not(:last-child) {
            .payment-icon-link::after {
                border-right: 1px solid $silver2;
            }
        }
    }

    .header-link {
        display: inline-block;
    }

    .mini-menu-link,
    .user-message {
        color: $black;

        &:hover {
            text-decoration: none;
        }
    }

    .dropdown-menu {
        .user-message {
            color: $white;
        }
    }

    .logo-home img {
        max-width: inherit;
        width: 105px;
        height: 30px;

        @include media-breakpoint-down(sm) {
            height: 27px;
            width: 95px;
        }
    }
}

.main-menu {
    background-color: $white;

    .navbar .close-menu button,
    .navbar .close-button button {
        background-color: transparent;
        border: 0;
        -webkit-appearance: none;
    }

    .navbar .close-menu button {
        position: relative;
        padding-left: 0;
    }
}

.menu-search-container {
    // margin-top: 8px;

    .site-search {
        width: 100%;
        height: auto;

        .simple-search-form {
            margin-bottom: 0;
        }

        .fa-search {
            padding: 0 15px;
            background: $blue-primary;
            right: 0;
            color: $white;
            font-size: 18px;
            top: 0;
            bottom: 0;
            border-radius: 0 5px 5px 0;
        }

        .reset-button {
            color: $dove-gray;
            top: 0.75em;
            right: 3.7em;
        }
    }

    .search-field,
    .auction-search-field {
        border-radius: 5px;
        height: 37px;
        padding-left: 20px;
        border: 0;
        border: 1px solid #dcdcdc;

        &::placeholder {
            color: #969696;
            font-style: italic;
            font-size: 13px;
            font-family: $opensans-regular;
        }

        &::-webkit-input-placeholder {
            color: #969696;
            font-style: italic;
            font-size: 13px;
            font-family: $opensans-regular;
        }

        &::-moz-placeholder {
            color: #969696;
            font-style: italic;
            font-size: 13px;
            font-family: $opensans-regular;
        }
    }
}

.sign-in-menu-container,
.inside-lc-menu-container {
    .dropdown-menu {
        @include media-breakpoint-up(lg) {
            top: 100%;
        }
    }

    .fa-sign-in {
        display: none;
    }
}

.sign-in-menu-container {
    .nav-item {
        &.user {
            display: flex;
            align-items: center;

            @include media-breakpoint-down(sm) {
                justify-content: center;
                flex-wrap: wrap;
            }
        }
    }

    .user {
        a {
            &:hover {
                text-decoration: none;
            }
        }
    }
}

.menu-dropdown-container {
    position: relative;

    .content-asset {
        font-size: 10px;
        display: inline;

        @include media-breakpoint-up(lg) {
            font-size: inherit;
        }
    }

    .dropdown-menu {
        font-size: 14px;
        text-align: left;
        list-style: none;
        background-color: $white;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.15);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        padding: 0;
        border-radius: 0;
    }

    .dropdown-item {
        border: 1px solid $grey8;
        border-top: 0;
        font-weight: 600;
        line-height: 3em;
        padding: 0 9px;
        color: $grey8;
        font-size: 12px;

        &:hover:not(:first-child) {
            background-color: $solitude2;
        }
    }

    .greeting-item {
        background: $grey8;
        color: $white;
    }

    .user-message {
        max-width: 159px;

        @include media-breakpoint-down(md) {
            max-width: 65px;
        }

        @include media-breakpoint-down(sm) {
            display: block !important;
            width: 100%;
        }
    }
}

.show-search-dropdown {
    .stickyHeader {
        z-index: 1039;
    }
}

.show-itemlist-dropdown {
    .stickyHeader {
        z-index: 1039;
    }
}

.header-nav {
    .dropdown-menu {
        .mini-menu-link,
        .user-message {
            color: $white !important;
        }
    }
}

.search-mobile {
    padding: 10px 15px 15px;
    background: $white;
    margin-top: 0;
    top: 0;
    position: static;
    z-index: 100;

    .search-field,
    .auction-search-field {
        height: 45px;
        border-radius: 5px 0 0 5px;
        box-shadow: none;
        font-size: 13px;
        color: $black;

        @include media-breakpoint-down(sm) {
            height: 37px;
        }

        &::placeholder {
            color: #969696;
            font-style: italic;
            font-size: 13px;
            font-family: $opensans-regular;
        }
    }

    .search-button {
        position: static;
        background: $light-steel-blue;
        border-left: 0;
        color: $white;
        padding: 0 16px;
        border-radius: 0 5px 5px 0;
        font-size: 18px;
    }

    .site-search {
        width: 100%;
        height: auto;

        .reset-button {
            padding: 0 15px;
            height: 100%;
            top: 0;
            right: 1px;
        }

        form {
            z-index: auto;
            margin-bottom: 0;
        }
    }
}

.suggestions {
    width: 100%;

    .item.has-image {
        .result-image {
            padding-right: 0;
            text-align: center;
        }

        .swatch-circle {
            margin: 0;
        }
    }

    a.search-result {
        &:hover {
            text-decoration: none;

            span.name {
                text-decoration: underline;
            }
        }
    }

    .container {
        .brand {
            display: none;
        }
    }
}

.minicart {
    .basket-icon {
        width: 20px;
        height: 20px;
        background: url("../images/Cart.svg") no-repeat left top;
        display: inline-block;

        &--active {
            background: url("../images/Cart-filled.svg") no-repeat left top;
        }
    }

    .minicart-link {
        position: relative;
        line-height: 1.1;
        display: inline-block;

        @include media-breakpoint-down(sm) {
            .mini-menu-link {
                display: none;
            }
        }
    }

    .minicart-quantity {
        position: absolute;
        top: -8px;
        left: 10px;
        background: #eb254b;
        color: $white;
        display: none;
        border-radius: 6px;
    }
}

.header-menu-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;

    @include media-breakpoint-down(sm) {
        margin-right: 0;
        margin-bottom: 4px;
    }

    &.cart-user-icon {
        display: none;
    }
}

.tab-active {
    .user-message {
        font-family: $opensans-bold;
        width: 56px;

        @include media-breakpoint-up(lg) {
            width: 146px;
        }
    }
}

.mini-menu-link,
.user-message {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    color: #1e1e2c;

    @include media-breakpoint-down(sm) {
        display: none !important;
    }

    @include media-breakpoint-up(lg) {
        font-size: 14px;
    }

    &--active {
        font-family: $opensans-bold;
    }

    &:hover {
        text-decoration: none;
    }
}

.wl-header > a {
    &:hover {
        text-decoration: none;
    }
}

.user-message {
    display: inline;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: auto;

    .show & {
        font-weight: bold;
    }

    .dropdown-menu {
        top: 100%;
    }
}

.important {
    color: $crimson !important;
}

.header-banner {
    display: none;
    background-color: $white;
    color: $black;
    font-family: $opensans-regular;
    font-size: 14px;
    line-height: 20px;

    @include media-breakpoint-up(md) {
        font-size: 15px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 18px;
    }

    @include media-breakpoint-up(xl) {
        font-size: 23px;
    }

    a {
        font-size: 16px;
        color: $black;
        text-decoration: underline;
        padding: 0 10px 0 10px;
    }

    .header-promotion-item {
        color: $venetian-red;
        font-weight: bold;
    }

    .header-promotion img {
        max-width: 150px;

        @include media-breakpoint-down(sm) {
            width: 90px;
        }
    }
}

nav[role="navigation"] {
    background: #fcfdff !important;

    .header-nav {
        @include media-breakpoint-up(xl) {
            .col-lg-1-5 {
                flex: 0 0 12.6666666667%;
                max-width: 12.6666666667%;
            }

            .col-lg-5-5 {
                flex: 0 0 45.6666666667%;
                max-width: 45.6666666667%;
            }
        }

        @include media-breakpoint-down(md) {
            margin: 0;

            .header-menu-icon {
                width: 15px;
                height: 15px;
            }
        }

        @include media-breakpoint-down(sm) {
            margin: 0 !important;

            .header-menu-icon {
                width: 20px;
                height: 20px;
            }
        }
    }

    .paginationfix {
        position: relative;
        top: -1px;
    }
}

div.minicart.text-center > div.minicart-total.hide-link-med > a > span.basket-icon {
    background: url(https://cdn.dynamicyield.com/api/8767986/images/f6556087bc81__cart.svg) no-repeat 0 0;
}

.nav-item .nav-link:focus,
.nav-item .nav-link:hover,
.nav-item.show .nav-link {
    background-color: #d1d1d1d1 !important;
}

@include media-breakpoint-up(md) {
    #sg-navbar-collapse {
        background: $white !important;
        border-color: #dcdcdc !important;

        .navbar.bg-inverse {
            padding: 0 15px;
        }
    }

    .multilevel-dropdown .dropdown-item .dropdown-link,
    .navbar.bg-inverse .navbar-nav .nav-link {
        color: $dark-gunmetal !important;
    }
}

@include media-breakpoint-down(sm) {
    .navbar-toggler {
        color: $dark-gunmetal;
    }
}

/** ---CRO Header styles--- **/
.CRO_generic_color {
    input[type=checkbox],
    input[type=radio],
    .form-group.custom-checkbox .custom-control-input {
        filter: grayscale(100%) !important;
        opacity: 1;
    }

    .cart-header {
        .return-link {
            color: $primarybtn-color;
        }
    }
}

.user [class*="notifyMeShow-false"] {
    .notify-me-sticky,
    .notifyme-attr,
    .check-mark-attr,
    .submit-notify-me,
    .out-of-stock-message {
        display: none !important;
    }
}
