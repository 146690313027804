@import "../../../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
$fonts-relative-path-prefix: "../../../";

@import "../../../base/fonts";

.carousel-layout {
    .heading-space {
        margin: 0;
    }

    .slick-dots {
        @include media-breakpoint-up(sm) {
            bottom: 45px;
        }
    }
}

.carousel-layout.main-hero-carousel .slick-dots {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}

.common-image-component {
    width: 100%;
    object-fit: cover;
    object-position: var(--focal-point-x) var(--focal-point-y);
    height: auto;
}

.type1 {
    margin: 0 0 14px;

    .title {
        padding: 5px 0 0;

        span {
            font-family: $opensans-bold;
            font-size: 20px;
            line-height: 28px;

            @include media-breakpoint-up(sm) {
                font-size: 24px;
                line-height: 34px;
            }
        }
    }

    .ITC-text-underneath {
        padding: 0;
        font-family: $opensans-regular;
        font-size: 13px;
        line-height: 19px;

        @include media-breakpoint-up(sm) {
            font-size: 14px;
            line-height: 20px;
        }

        span {
            text-align: left;
        }
    }
}

.image-component.type2 {
    margin: 0 0 46px;
    max-width: 357px;
    height: auto;

    @include media-breakpoint-up(sm) {
        margin: 0 0 35px;
    }

    @include media-breakpoint-up(md) {
        margin-bottom: 0;
    }

    .title {
        padding: 5px 0 0;

        span {
            font-family: $opensans-bold;
            font-size: 15px;
            line-height: normal;

            @include media-breakpoint-up(sm) {
                font-size: 24px;
                line-height: 34px;
            }
        }
    }

    .ITC-text-underneath {
        padding: 0;
        font-family: $opensans-regular;
        font-size: 13px;
        line-height: 19px;

        @include media-breakpoint-up(sm) {
            font-size: 14px;
            line-height: 20px;
        }

        span {
            text-align: left;
        }
    }

    @include media-breakpoint-down(sm) {
        &.col-left {
            transform: translateX(10px);
        }

        &.col-right {
            transform: translateX(-10px);
        }
    }
}

.modal-promo-container {
    p,
    a {
        display: inline;
        font-size: 16px;
        font-weight: $font-wt-bold;

        @include media-breakpoint-down(sm) {
            display: block;
        }
    }

    .banner-promo-link {
        margin-left: 10px;
        text-decoration: underline !important;
        text-underline-offset: 1px;
    }
}

.modal-promo-body {
    .header-title {
        text-align: center;

        p span {
            color: $lochmara !important;
        }
    }
}

.ITC-figure a:hover {
    text-decoration: none;
}

#modalBanner {
    .modal-content {
        width: 91%;
        margin: 0 auto;
    }

    .header-title {
        color: #0083df;
        width: 91%;
    }

    button.close {
        background-color: #3190e8;
        color: #fff;
        opacity: 1;
        width: 40px;
        height: 40px;
        line-height: 40px;
        padding: 0;
        position: relative;
        top: -1px;
        right: -1px;
    }

    .modal-body {
        color: #333;
        font-size: 13px;
        line-height: 20.8px;
        text-align: justify;
        padding-bottom: 35px;
    }
}
