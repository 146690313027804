@import "~@sfra/scss/components/pricing";
@import "../variables";

.price {
    .strike-through {
        color: $black;
        font-weight: bold;
    }

    .sales {
        display: block;
    }
}

.prices-add-to-cart-actions {
    .col {
        margin: 0;
        padding-left: 0;
    }

    @include media-breakpoint-up(sm) {
        position: static;
        padding: 0;
    }
}

.product-description-title {
    padding-bottom: 8px;
    font: bold 14px/19px $opensans-bold;
}

.product-detail {
    .prices .price {
        font-size: 13px;
        text-decoration: underline;
        text-align: left;

        .strike-through {
            font-weight: inherit;
        }

        span.red-text {
            font-weight: normal;
        }

        .strike-through::after {
            position: absolute;
            border-right: 1px solid $nobel;
            height: 12px;
            left: 25%;
            bottom: 28px;
            content: " ";
        }
    }

    .pdp-red {
        color: $persian-red;
    }
}

.price-container {
    .strike-through {
        color: $nobel;
        font-weight: normal;
    }
}

.bid-price-container {
    font-family: $opensans-bold;
    font-size: 13px;
    color: $night-rider;
}

.bid-price {
    margin-left: 10px;
    color: $la-palma2;
}

.promotional-star {
    color: $alert-red;
}

/** --CRO price styles-- **/
.CRO_generic_color {
    .product-description-title {
        color: $primarybtn-color !important;
    }
}
