@import "../base/fonts";

.budget-pay-container,
.specifications {
    position: relative;
    margin-bottom: 9px;

    .tooltip-popup {
        &.show {
            background: $white;
            border: 1px solid $silver;
            padding: 15px 10px 10px;
            width: 95%;
            box-shadow: 0 0 10px 0 $night-rider;
            position: absolute;
            top: 0;
            z-index: 1;

            @include media-breakpoint-up(sm) {
                top: -40px;
            }
        }

        p {
            margin-bottom: 0;
            font-size: 14px;
            font-family: $opensans-regular;
        }

        button.close {
            top: -13px;
            right: -15px;
            color: $black;
            font-weight: $font-wt-bold;
        }
    }

    .budget-pay {
        color: $light-green;
        font-size: 11px;
        font-weight: bold;
        margin-left: 10px;
        cursor: pointer;
        vertical-align: bottom;

        @include media-breakpoint-up(sm) {
            margin-left: 5px;
        }
        @include media-breakpoint-up(md) {
            margin-left: 5px;
        }
        @include media-breakpoint-down(lg) {
            display: inline-block;
        }
    }
}

.change-payment {
    .main-heading {
        border-bottom: 1px solid $grey3;
        margin-bottom: 20px;
    }

    .main-container {
        border: 4px solid $solitude;
        padding: 15px 0 8px 0;
        margin-bottom: 150px;
        @include media-breakpoint-down(xs) {
            border: none;
        }

        .change-payment-page {
            margin-left: 10px;
        }
    }

    .saved-cards {
        .card-headings {
            border-bottom: 1px solid $alto-secondary;
            padding-bottom: 6px;
            margin: 15px 0;
            @include media-breakpoint-down(xs) {
                display: none;
            }

            div {
                font-weight: $font-wt-semibold;
            }

            .name-card {
                margin-left: 17px;
                max-width: 31%;
            }
        }

        .entry-main {
            @include media-breakpoint-down(xs) {
                background: $solitude;
            }
        }

        .entries {
            margin: 5px 0;
            @include media-breakpoint-down(xs) {
                margin-top: 15px;
            }

            div {
                @include media-breakpoint-down(xs) {
                    max-width: 31%;
                }
            }

            .edit-remove {
                @include media-breakpoint-down(xs) {
                    display: flex;
                }

                .remove-card {
                    margin-right: 8px;
                    @include media-breakpoint-down(xs) {
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    .add-credit-card {
        .add-card {
            margin-top: 30px;
            margin-right: 10px;

            .add-new-card {
                margin-bottom: 15px;
            }

            .address-form-fields {
                padding: 18px;
                @include media-breakpoint-down(xs) {
                    border: none;
                    padding: 0;
                }

                input,
                select {
                    height: 35px;
                }

                .form-group {
                    margin-bottom: 16px;
                }

                .form-control-label {
                    font-weight: $font-wt-regular;
                }

                .expiry-date {
                    display: flex;

                    .custom-select {
                        width: 100px;
                        @include media-breakpoint-down(xs) {
                            width: 131px;
                        }
                    }

                    .expirationMonth {
                        margin-right: 15px;
                    }
                }

                .name-field {
                    width: 373px;
                    margin-right: 15px;
                    @include media-breakpoint-down(xs) {
                        width: 279px;
                    }
                }

                .col-padding-right {
                    @include media-breakpoint-down(xs) {
                        margin-left: 15px;
                    }
                }

                .cardSecurityCode {
                    width: 20%;
                    margin: 0 18px;
                    @include media-breakpoint-down(xs) {
                        width: 40%;
                    }
                }

                .save-actions {
                    display: flex;
                }

                .expiry-title {
                    padding-left: 0;
                }

                .cvv-title {
                    margin-left: 5px;
                }

                .cvv-btn {
                    color: $lilac-purple;

                    .what-cvv {
                        text-decoration: underline;
                        font-family: "Open Sans", sans-serif;
                    }
                }

                .cards {
                    margin: 0;
                    padding: 0;
                    list-style: none;

                    .image-cc {
                        float: left;
                        margin-right: 7px;

                        img {
                            opacity: 0.3;
                        }
                    }
                }

                .saved-for-future {
                    max-width: 45%;
                }
            }
        }
    }

    .remove-edit {
        .confirm-card-remove {
            width: 600px;
            @include media-breakpoint-down(xs) {
                width: 305px;
                margin-left: 3px;
            }

            .modal-header {
                .modal-title {
                    font-weight: $font-wt-semibold;
                    font-size: 18px;
                    color: $lilac-purple;
                    font-family: "Open Sans", sans-serif;
                }

                .fa-close {
                    font-size: 20px;
                    color: $black;
                    opacity: 0.3;

                    &:hover {
                        color: $black;
                        opacity: 0.5;
                    }
                }
            }

            .remove-payment {
                font-size: 13px;
                margin-bottom: 15px;
            }

            .btn-wrapper {
                .confirm-remove {
                    background: $lc-red;
                    border-radius: inherit;
                    color: $white;
                    font-size: 13px;
                    text-transform: uppercase;

                    &:hover {
                        background: $persian-dark;
                    }
                }

                .keep-card {
                    background: $picton-blue;
                    border-radius: inherit;
                    color: $white;
                    font-size: 13px;
                    font-weight: $font-wt-bold;
                    text-transform: uppercase;
                }
            }
        }

        .save-creditcard {
            width: 600px;
            @include media-breakpoint-down(xs) {
                width: 300px;
                height: 360px;
                margin-left: 6px;
            }

            .modal-header {
                .modal-title {
                    font-weight: $font-wt-semibold;
                    font-size: 18px;
                    color: $lilac-purple;
                    font-family: "Open Sans", sans-serif;
                }

                .fa-close {
                    font-size: 20px;
                    color: $black;
                    opacity: 0.3;

                    &:hover {
                        color: $black;
                        opacity: 0.5;
                    }
                }
            }

            .modal-footer {
                @include media-breakpoint-down(xs) {
                    margin-top: 45%;
                }
            }

            .save-cc-changes {
                height: 65px;

                label {
                    font-size: 13px;
                }

                input {
                    width: 130px;
                    height: 25px;
                    text-transform: uppercase;
                    background-color: $white;
                    font-size: 13px;
                    padding: 0 5px;
                    border-radius: 1px;
                }

                select {
                    width: 75px;
                    text-transform: uppercase;
                    background-color: $white;
                    font-size: 13px;
                    height: 27px;
                    padding: 0 5px;
                    display: inline;
                    border-radius: 1px;

                    &:focus {
                        box-shadow: none;
                        border-color: $very-light-grey;
                    }
                }

                .name,
                .details {
                    max-width: 27%;
                    @include media-breakpoint-down(xs) {
                        max-width: 100%;
                    }
                }

                .expiry {
                    min-width: 34%;

                    .expiration-Month {
                        margin-right: 10px;
                    }

                    select {
                        @include media-breakpoint-down(xs) {
                            display: block;
                        }
                    }
                }
            }

            .btn-wrapper {
                .confirm-update {
                    background: $lilac-purple;
                    border: 1px solid $lilac-purple;
                    border-radius: inherit;
                    color: $white;
                    font-size: 13px;
                    font-weight: $font-wt-bold;

                    &:hover {
                        background: $dark-blue;
                    }
                }

                .keep-card {
                    background: $light-green;
                    border: 1px solid $light-green;
                    border-radius: inherit;
                    color: $white;
                    font-size: 13px;
                    font-weight: $font-wt-bold;
                }
            }
        }

        .add-billing-address {
            @include media-breakpoint-down(xs) {
                width: 95%;
            }

            .modal-header {
                .modal-title {
                    font-weight: $font-wt-semibold;
                    font-size: 18px;
                    color: $lilac-purple;
                }

                .fa-close {
                    font-size: 20px;
                    color: $black;
                    opacity: 0.3;

                    &:hover {
                        color: $black;
                        opacity: 0.5;
                    }
                }
            }

            .modal-body {
                .address-form-fields {
                    border: none;
                    padding: 0;

                    .form-control {
                        height: 30px;
                    }

                    .custom-select {
                        height: 35px !important;
                    }

                    .row {
                        padding: 0;
                    }

                    .billing-hide-content {
                        display: none;
                    }
                }
            }

            .btn-wrapper {
                display: flex;
                width: 50%;
                @include media-breakpoint-down(xs) {
                    width: 80%;
                }

                .btn-save {
                    background: $picton-blue;
                    width: 75%;
                    height: 35px;
                    border-radius: inherit;
                    margin-top: 11px;
                    margin-right: 16px;
                    font-size: 13px;

                    &:hover {
                        border: none;
                        transition: none;
                    }
                }

                .btn-reset {
                    background: $alto-secondary;
                    font-weight: $font-wt-bold;
                    font-size: 13px;
                    margin-top: 11px;
                    width: 50%;
                    height: 35px;

                    &:hover {
                        background: $dark-gray2;
                        border: none;
                        color: $black;
                        transition: none;
                    }
                }
            }
        }
    }
}

.pay-installment {
    .modal-header {
        .modal-title {
            font-weight: $font-wt-semibold;
            font-size: 18px;
            color: $lilac-purple;
        }

        .fa-close {
            font-size: 20px;
            color: $black;
            opacity: 0.3;

            &:hover {
                color: $black;
                opacity: 0.5;
            }
        }
    }

    .modal-footer {
        border: none;

        .btn-wrapper {
            @include media-breakpoint-down(xs) {
                width: 87%;
            }

            .pay-now {
                background: $light-green;
                border: 1px solid $light-green;
                border-radius: inherit;
                color: $white;
                font-size: 13px;
                font-weight: $font-wt-bold;
            }
        }
    }
}

.global-availability {
    .specifications {
        position: relative;
        max-height: 200px;
        overflow-y: auto;
    }
}

/** --budgetPay styles-- **/
.CRO_generic_color {
    .budget-pay-container .budget-pay,
    .specifications .budget-pay {
        color: $primarybtn-color !important;
    }
}
