.what-is-fast-buy-modal {
    .modal-content {
        box-shadow: 0 5px 15px rgb(0 0 0 / 50%);

        .modal-header {
            display: block;
        }

        .close {
            position: absolute;
            top: 13px;
            right: 17px;
        }
    }

    .modal-dialog {
        max-width: 300px;
    }

    &--error {
        .modal-dialog {
            max-width: 600px;
        }
    }

    .modal-title {
        font-weight: 600;
        font-size: 18px;
        text-transform: uppercase;
        color: $navy-blue;
    }

    .fast-buy-modal__text,
    .fast-buy-modal__detail {
        font-size: 13px;
        margin-bottom: 20px;
    }
}

.what-is-fast-buy-modal--card-error {
    z-index: 1051;

    .modal-dialog {
        max-width: 450px;
    }

    .fast-buy-modal__text {
        padding-top: 25px;
    }
}

/* --whatisfastbuymodal-- */
.CRO_generic_color {
    .what-is-fast-buy-modal {
        .modal-title {
            color: $primarybtn-color !important;
        }
    }
}
