@import "~@sfra/scss/account/account";
@import "../variables";
@import "base/fonts";
@import "product/imagesCarousel";

.container {
    font-family: $opensans-regular;
}

.info-msg-fields-required {
    width: 500px;
    font-style: italic;
    font-size: 13px;
    font-family: $opensans-light;
}

.password-constraints {
    font-size: 13px;
    line-height: 1.6em;

    .password-guidelines-constraints {
        color: $orange;
    }
}

.fourth-heading {
    font-family: $opensans-regular;
    font-size: 18px;
}

.bottom-line {
    border-bottom: 1px solid $light-gray2;
}

.myaccount-toggle {
    .icon-bar {
        display: block;
        width: 22px;
        height: 2px;
        border-radius: 1px;
        background-color: $navy-blue;
        margin: 5px 0;
    }
}

.acc-payment {
    .acc-payment-body {
        font-size: 13px;
        border: 4px solid $athens-gray;
        padding-top: 15px;
        padding-bottom: 15px;

        .cards-details-header-inner {
            font-weight: $font-wt-semibold;
        }

        .strong-text {
            font-family: $opensans-bold;
        }

        .edit-btn,
        .remove-btn {
            color: #868ce0;
            font-size: 13px;
            position: unset;

            &:hover {
                color: #868ce0;
                text-decoration: underline;
            }
        }
    }

    .payment-card-body {
        .custom-control-input {
            width: 17px !important;
        }
    }
}

.acc-payment-from {
    .card-number-wrapper {
        @include media-breakpoint-down(md) {
            left: -4px;
        }

        @include media-breakpoint-down(sm) {
            left: 0;
        }

        &::after {
            display: none;
        }
    }

    .whatCVV {
        color: $navy-blue;
        font-size: 13px;
        text-decoration: underline;

        &:hover {
            color: $venice-blue;
            text-decoration: none;
        }

        &:hover .image-cvv {
            display: block;
        }

        .label-cvv {
            color: $lilac-purple;
            font-family: $opensans-light;
            cursor: pointer;
        }

        .image-cvv {
            position: absolute;
            top: -150px;
            display: none;
            width: 250px;
            max-width: 400px;
            height: 150px;
            right: 0;
        }
    }

    .make-default-payment {
        .custom-control-label {
            color: $picton-blue;
            font-size: 13px;
            font-family: $opensans-regular;
            font-weight: $font-wt-semibold;
            cursor: pointer;
        }
    }

    .image-cc {
        opacity: 0.3;
    }

    .image-cc.selected {
        opacity: 0.9;
    }
}

.remove-card,
.edit-card {
    cursor: pointer;
}

.info-label-fields {
    font-size: 13px;
    margin-top: 10px;
    display: inline-block;
}

.acc-navigation {
    background: $grey2;
    border: 1px solid $alto;
    padding-top: 21px;

    @include media-breakpoint-down(xs) {
        margin-bottom: 20px;

        .show-left-nav & {
            display: block;
        }
    }

    ul {
        padding: 0;
        list-style-type: none;
    }

    .acc-list {
        padding: 0 0 7px 25px;
        margin-bottom: 15px;
        border-bottom: 1px solid $white;

        @include media-breakpoint-down(md) {
            padding: 0 0 7px 9px;
        }

        &:last-child {
            border: 0;
            margin: 0;
        }
    }

    .acc-list-title {
        font-family: $opensans-bold;
        font-size: 14px;
        padding-bottom: 3px;
    }

    li a {
        font-family: $opensans-regular;
        font-size: 13px;
        color: $night-rider !important;
        margin: 5px;
        padding: 0.3rem 1rem;

        &.nav-link:hover {
            background: $white;
        }

        &.nav-link.active {
            background: $white;
        }
    }
}

.acc-card-header h2 {
    font-family: $opensans-light;
    font-size: 30px;
    color: $lc-red;
    font-weight: $font-wt-regular;

    @include media-breakpoint-down(xs) {
        font-size: 23px;
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
    }
}

.acc-card-body {
    .border-bottom {
        div {
            padding-top: 5px;
            font-size: 13px;
            font-weight: $font-wt-semibold;
        }
    }

    .view-profile-form {
        div {
            font-weight: $font-wt-regular;
        }

        .birthday {
            &::before {
                content: "";
            }
        }
    }

    .order-track-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .table-header {
            padding-top: 15px;
            font-size: 22px;
            font-weight: $font-wt-bold;
            color: $black;
        }

        .order-cancel {
            margin: 10px 0;

            .cancel-order {
                width: 120px;
                height: 40px;
                background: $btn-red;
                border: none;

                span {
                    font-size: 13px;
                    color: $white;
                    text-transform: uppercase;
                    font-weight: $font-wt-semibold;
                    vertical-align: text-bottom;
                }
            }

            .modal-content {
                width: 482px;
                height: 240px;
                background: $white;
                border: none;
                border-radius: unset;
                box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);

                @include media-breakpoint-down(xs) {
                    width: 300px;
                    height: 300px;
                    margin: 50px 30px;
                }

                .modal-header {
                    height: 50px;
                    padding: 10px 15px;
                    background: $night-rider;
                    border-radius: unset;

                    .modal-title {
                        font-size: 18px;
                        color: $white;
                        font-weight: $font-wt-bold;
                    }

                    .close {
                        color: $white;
                        text-shadow: none;
                        opacity: 1;
                    }
                }

                .modal-body {
                    #reasonTextLabel {
                        font-size: 13px;
                        font-weight: $font-wt-bold;
                        color: $night-rider;

                        i {
                            font-weight: $font-wt-regular;
                        }
                    }

                    .custom-select {
                        width: 354px;
                        height: 40px;
                        font-size: 13px;
                        font-weight: $font-wt-semibold;
                        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDE0IDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik03IDdMMTQgMEgwTDcgN1oiIGZpbGw9IiMzMzMzMzMiLz4KPC9zdmc+Cg==");
                        background-size: 15px 7px;
                        border: 1px solid $very-light-grey;
                        border-radius: unset;

                        @include media-breakpoint-down(xs) {
                            width: 254px;
                        }

                        option {
                            font-size: 13px;
                            font-weight: $font-wt-semibold;
                        }
                    }
                }

                .modal-footer {
                    width: 446px;
                    margin: auto;
                    padding: 10px 0;
                    border-top: 1px solid $very-light-grey;

                    @include media-breakpoint-down(xs) {
                        width: 254px;
                        padding: 15px 0 25px 0;
                    }

                    .btn-wrapper {
                        display: flex;
                        flex-direction: row;

                        @include media-breakpoint-down(xs) {
                            flex-direction: column;
                            margin: auto;
                        }

                        .btn-red {
                            width: 208px;
                            height: 40px;
                            font-size: 13px;
                            color: $white;
                            font-weight: $font-wt-semibold;
                            text-transform: uppercase;
                            background: $curious-blue;
                            border: none;
                        }

                        .btn-disabled {
                            background-color: #ccc;
                        }

                        .btn-black {
                            width: 208px;
                            height: 40px;
                            margin: 0 0 0 7px;
                            font-size: 13px;
                            color: $white;
                            font-weight: $font-wt-semibold;
                            text-transform: uppercase;
                            background: $night-rider;
                            border: none;
                            border-radius: unset;

                            @include media-breakpoint-down(xs) {
                                margin: 10px 0 0 0;
                            }
                        }
                    }
                }

                &.order-status {
                    .modal-body {
                        div {
                            padding: 15px 20px 0;
                            text-align: center;
                            font-size: 14px;
                            font-weight: $font-wt-semibold;
                        }
                    }

                    .modal-footer {
                        border-top: none;
                    }

                    .btn-wrapper {
                        margin: auto;
                    }

                    .btn-black {
                        margin: auto;
                    }
                }
            }
        }
    }

    .order-detail {
        .table.table-sm {
            thead {

                @include media-breakpoint-down(xs) {
                    display: none;
                }

                .row-head {
                    height: 40px;
                    background: $navy-blue;

                    th {
                        padding: 0 20px;
                        vertical-align: middle;
                        font-size: 13px;
                        font-weight: $font-wt-bold;
                    }
                }
            }

            tbody {
                background: $white-smoke;

                &.is-desktop {

                    @include media-breakpoint-down(xs) {
                        display: none;
                    }
                }

                &.is-mobile {
                    display: none;

                    @include media-breakpoint-down(xs) {
                        display: block;
                        border-top: 1px solid $very-light-grey;
                    }

                    th {
                        padding: 10px 0 5px 20px;
                        font-size: 13px;
                        font-weight: $font-wt-bold;
                        border-top: none;
                    }

                    tr {
                        display: flex;
                        flex-direction: column;
                    }

                    td {
                        padding: 0 20px 15px;
                    }
                }

                tr {
                    background: $white-smoke;
                }

                td {
                    padding: 10px 20px;
                    font-size: 13px;
                    font-weight: $font-wt-semibold;
                    border: none;
                }
            }
        }
    }
}

.timer-top {
    width: 18px;
    height: 18px;
    margin-top: -4px;
}

.timer-text {
    position: relative;
    left: -4px;
}

.bidwon {
    left: 150px;
    position: absolute;
}

.bidwon-desktop {
    left: 220px;
    position: absolute;
}

.bidvalue {
    left: 19px;
}

.view-profile-form {
    font-family: $opensans-regular;
    font-size: 13px;
}

.toggle-acc-menu {
    text-align: right;
    color: $picton-blue;
    font-size: 20px;
    padding: 10px 0;
    display: none;

    @include media-breakpoint-down(xs) {
        display: none;
    }
}

.creditValue {
    color: $night-rider;
    font-size: 30px;
    font-family: $opensans-bold;
}

.creditLabel {
    color: $night-rider;
    font-size: 18px;
    margin-bottom: 15px;
}

#confirmAddress .modal-dialog {
    max-width: 610px;

    .modal-header {
        padding: 10px 30px;

        .close {
            color: $grey3;
            opacity: 1;
        }
    }

    .header-title {
        font-size: 14px;
        font-family: $opensans-bold;
        line-height: 24px;
        text-transform: uppercase;
        color: $picton-blue;
    }

    .suggested-Address_detail {
        padding: 10px 30px 0 30px;

        .confirmAddress_des {
            padding-bottom: 10px;
        }

        .missging_field {
            font-size: 14px;
            font-weight: 700;
        }
    }

    .address-content {
        padding: 22px 30px 35px;
        display: flex;
        flex-wrap: wrap;
        color: $prussian-blue;
        font-size: 13px;
        font-family: $opensans-regular;

        @include media-breakpoint-up(sm) {
            flex-wrap: nowrap;
        }

        button {
            width: 100%;
            @include media-breakpoint-up(sm) {
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }
    }

    .use-suggested {
        flex-basis: 100%;
        position: relative;

        @include media-breakpoint-up(sm) {
            flex-basis: 50%;
            padding: 0 0 40px 0;
        }
    }

    .update-address {
        flex-basis: 100%;
        position: relative;

        @include media-breakpoint-up(sm) {
            flex-basis: 50%;
            padding: 0 0 40px 0;
        }
    }

    .use-original {
        flex-basis: 100%;
        position: relative;
        padding-top: 20px;

        @include media-breakpoint-up(sm) {
            flex-basis: 50%;
            margin-left: 15px;
            padding: 0 0 40px 0;
        }

        p {
            color: $suva-grey;
        }
    }

    .use-suggested,
    .update-address,
    .use-original {
        div {
            font-weight: 600;
            padding-bottom: 10px;
            color: $picton-blue;
        }

        p {
            padding-bottom: 10px;
        }
    }
}

.chevron-toggle {
    .fa-chevron-down::before {
        content: "\f078";
    }

    &.collapsed {
        .fa-chevron-down::before {
            content: "\f077";
        }
    }

    .account-logo {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;

        .account-label {
            font-size: 30px;

            @include media-breakpoint-down(xs) {
                font-size: 23px;
            }
        }

        .chevron-icon {
            font-weight: 200;
            padding-top: 10px;
        }
    }
}

#orderHistoryForm,
.payment-form,
.acc-payment,
.view-profile-form,
.add-credit-card,
.edit-profile-form,
.trackorder,
.address-form,
.change-password-form,
.reset-password-form,
.trackorder-container,
#confirmAddress {
    .form-group,
    .payment-card-body {
        align-items: center;

        .custom-control-input {
            width: auto;
            opacity: 1;
            z-index: unset;
        }

        .make-default-payment-card {
            left: 25px;
        }

        .custom-checkbox .custom-control-label::before {
            display: none;
        }

        .custom-control {
            padding-left: 30px;
        }

        .custom-control-label::before {
            display: none;
        }

        .custom-control-label::after {
            display: none;
        }

        .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%232780DC' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
        }

        .custom-select {
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDE0IDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik03IDdMMTQgMEgwTDcgN1oiIGZpbGw9IiMzMzMzMzMiLz4KPC9zdmc+Cg==");
            background-size: 15px 7px;
        }

        &.required .form-control-label::before {
            display: none;
        }
    }

    .btn.btn-primary {
        font-weight: 800;
        font-size: 13px;
        line-height: 40px;
        width: max-content;
        text-transform: uppercase;
        background-color: $picton-blue;
        border-radius: 0;
        border: 0;
        padding: 0 21px;
        font-family: $opensans-regular;
    }

    .btn.btn-secondary {
        font-weight: 600;
        font-size: 13px;
        line-height: 40px;
        text-transform: uppercase;
        color: $night-rider;
        background-color: $alto;
        border-radius: 0;
        border: 0;
        padding: 0 21px;
        font-family: $opensans-regular;
    }

    .row .form-control {
        padding: 7px 14px;
        height: 40px;
        border: 1px solid $very-light-grey;
        border-radius: 0;
        font-size: 13px;
        font-family: $opensans-regular;

        &:focus {
            color: $navy-blue;
            box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
        }
    }

    .row {
        .col-form-label,
        label,
        .custom-control-label {
            font-size: 13px;
            font-family: $opensans-regular;
            font-weight: $font-wt-semibold;
        }
    }
}

.shipping-heading,
.billing-heading {
    color: $black !important;
}

.address-form {
    .nav-tabs {
        border: none;

        li {
            @include media-breakpoint-up(sm) {
                margin: 0;
            }

            a {
                padding: 0 15px;
                font-weight: bold;
                font-size: 14px;
                font-family: $opensans-regular;
                line-height: 40px;
                display: block;
                text-decoration: none;
                outline: none;

                &.active {
                    background: $solitude;
                    box-shadow: 0 -2px 0 $navy-blue;
                }
            }
        }
    }

    .address-container {
        border: 4px solid $solitude;
        margin-bottom: 20px;
        padding: 0 12px 15px;

        @include media-breakpoint-up(sm) {
            padding: 0 25px 25px;
        }
    }

    .address-form-fields {
        .form-control-label {
            transform: none !important;
        }
    }
}

.edit-profile-form,
.change-password-form,
.address-form,
.payment-form {
    .row {
        .col-form-label,
        .custom-control-label,
        .custom-label,
        .form-control-label {
            font-weight: $font-wt-regular;
        }
    }
}

.address-form-fields {
    border: 4px solid $solitude;
    padding: 24px 12px;

    @include media-breakpoint-up(sm) {
        padding: 43px 25px;
    }

    .billing-main {
        min-width: 81%;
    }

    .label-right {
        max-width: 60%;
    }
}

.address-suggest {
    position: relative;
}

ul.address-suggestion {
    display: none;
    position: absolute;
    left: 15px;
    top: 40px;
    width: calc(100% - 30px);
    max-height: 227px;
    overflow: auto;
    z-index: 1;
    background: $white;
    list-style: none;
    padding: 0;
    margin: auto;
    border: 1px solid $very-light-grey;
}

input:focus ~ ul.address-suggestion,
ul.address-suggestion:hover {
    display: block;
}

li.suggestion {
    font-size: 13px;
    padding: 10px;
    border-bottom: 1px solid $very-light-grey;
}

.saved-address {
    font-size: 13px;
}

.ttl-addrs-title {
    font-weight: bold;
}

.nickname {
    .primary-heading {
        margin-bottom: 25px;
        color: $persian-red2;
        line-height: 26px;
        font-size: 30px;
        text-transform: uppercase;
        font-weight: $font-wt-regular;

        @include media-breakpoint-down(xs) {
            margin-top: 20px;
            font-size: 23px;
        }
    }

    .nickname-para-lc {
        margin-bottom: 20px;
        font-size: 13px;
        line-height: 1.6em;
        text-align: justify;
        color: $Zambezi;
    }

    .form-group {
        margin-bottom: 0;

        .nickname-label {
            margin-top: 15px;
            font-size: 13px;
            font-weight: $font-wt-regular;
            color: $night-rider;
        }

        .red-imp {
            color: $persian-red2;
        }

        .text-input {
            display: block;
            width: 27%;
            height: 34px;
            padding: 6px 12px;
            font-size: 14px;
            line-height: 1.42857143;
            color: $emperor;
            background-color: $white;
            background-image: none;
            border: 1px solid $light-gray;

            @include media-breakpoint-down(xs) {
                width: 100%;
            }
        }

        .invalid-feedback,
        .nickname-blacklisted,
        .nickname-registration-error {
            font-size: 11px;
            color: $persian-red2;
        }

        .error-message {
            width: 27%;
            margin-top: 0;
            margin-bottom: 0;
            padding: 2px 0;

            @include media-breakpoint-down(xs) {
                width: 100%;
            }
        }

        .checkbox {
            .btn-checkbox {
                display: inline-block;
                width: auto;
                height: auto;
                margin: 10px 5px 0 0;
            }

            .nickname-acceptance {
                font-size: 13px;

                .guidelines-link {
                    color: $navy-blue;

                    &:hover {
                        color: $venice-blue;
                        text-decoration: none;
                    }
                }
            }
        }

        &.footer {
            margin-top: 10px;

            .nickname-btn {
                width: 120px;
                padding: 6px 12px;
                letter-spacing: 1px;
                font-size: 14px;
                font-weight: $font-wt-regular;
                color: $white;
                text-transform: uppercase;
                background: $picton-blue;
                border: 1px solid $picton-blue;
                border-radius: 0;

                &:hover {
                    background: $light-blue;
                }
            }

            .cancel-nickname {
                width: 120px;
                margin-left: 15px;
                padding: 6px 12px;
                letter-spacing: 1px;
                font-size: 14px;
                font-weight: $font-wt-regular;
                color: $picton-blue;
                text-transform: uppercase;
                background: none;
                border: 1px solid $picton-blue;
                border-radius: 0;

                &:hover {
                    color: $light-blue;
                    border: 1px solid $light-blue;
                }
            }
        }
    }
}

.community-guide {
    @include media-breakpoint-down(xs) {
        margin-top: 30px;
    }
}

.history-heading {

    @include media-breakpoint-down(xs) {
        margin-left: 0;
    }

    .budgetpay-title {
        font-size: 30px;
        font-weight: $font-wt-regular;
        color: $lc-red;

        @include media-breakpoint-down(xs) {
            font-size: 23px;
        }
    }
}

.birthday::before {
    font-family: "FontAwesome", arial, sans-serif;
    content: "\f073";
    font-size: 16px;
    position: absolute;
    top: 8px;
    right: 31px;
    color: $black;
}

.birthday::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
}

.order-list-container {
    padding: 0;

    .filter {
        .success-error-message {
            .error-msg {
                position: absolute;
                width: 98%;
                height: 50px;
                top: 50px;
                left: 0;
                padding: 11px;
                font-size: 16px;
                color: $roof-terracotta;
                background: linear-gradient(to bottom, $soft-peach 0%, $reddish-white 100%);
                background-repeat: repeat-x;
                border: 1px solid $pale-chestnut;
                border-radius: 4px;

                @include media-breakpoint-down(xs) {
                    width: 92%;
                    left: unset;
                    padding: 13px;
                    font-size: 13px;
                }

                .closeBtn,
                .fa-times {
                    position: absolute;
                    top: 15px;
                    right: 10px;
                    opacity: 0.2;
                    cursor: pointer;

                    &:hover {
                        opacity: 1;
                    }

                    @include media-breakpoint-down(xs) {
                        top: 16px;
                    }
                }
            }
        }

        #no-order-msg {
            top: 60px;
        }

        .sort-details {
            margin: 15px 0;
            align-items: center;

            @include media-breakpoint-down(xs) {
                display: block;

                .col-6 {
                    display: inline-block;
                    padding: 0;
                }
            }

            .record-count {
                float: right;
                font-size: 13px;
                color: $night-rider;

                @include media-breakpoint-down(xs) {
                    float: left;
                }
            }

            .item-count {
                display: flex;
                align-items: baseline;
                justify-content: space-evenly;
                padding: 0;
                border-left: 1px solid $very-light-grey;
                border-right: 1px solid $very-light-grey;

                @include media-breakpoint-down(xs) {
                    display: inline-block;
                    justify-content: left;
                    max-width: 50%;
                    top: 5px;
                    border-left: none;
                }

                label {
                    font-size: 13px;
                    color: $night-rider;
                }

                .custom-select {
                    width: 70px;
                    height: 40px;
                    font-size: 13px;
                    color: $night-rider;
                    border: 1px solid $very-light-grey;
                    border-radius: 0;

                    @include media-breakpoint-down(xs) {
                        width: 60px;
                        height: 35px;
                    }
                }
            }

            .sort-order {
                @include media-breakpoint-down(xs) {
                    display: inline-block;
                    top: 5px;
                }

                .custom-select {
                    width: 160px;
                    height: 40px;
                    font-size: 13px;
                    color: $night-rider;
                    border: 1px solid $very-light-grey;
                    border-radius: 0;

                    @include media-breakpoint-down(xs) {
                        width: 145px;
                        height: 35px;
                        font-size: 12px;
                    }
                }
            }
        }

        .filter-content {
            height: 60px;
            align-items: center;
            margin-right: 0;
            background: $alto-secondary;

            @include media-breakpoint-down(xs) {
                display: block;
                height: auto;
                margin: auto;
                padding-top: 10px;
            }

            .col-2 {
                font-size: 13px;
                font-weight: $font-wt-bold;
                color: $night-rider;
                margin-right: -20px;

                @include media-breakpoint-down(xs) {
                    max-width: 100%;
                }

                .filter-order {
                    width: 77px;
                    height: 40px;
                    border: none;
                    background: $picton-blue;
                    color: $white;
                    font-size: 13px;

                    @include media-breakpoint-down(xs) {
                        width: 70px;
                        height: 35px;
                        margin-bottom: 15px;
                    }
                }
            }

            .col-4 {
                display: flex;
                justify-content: space-between;
                align-items: center;

                @include media-breakpoint-down(xs) {
                    display: inline-block;
                    margin: 5px 50px 15px 0;

                    &.end-date {
                        margin-left: 40px;
                    }
                }

                .form-control-label {
                    font-size: 12px;
                    font-weight: $font-wt-regular;
                    margin-right: 3px;

                    @include media-breakpoint-down(xs) {
                        margin-bottom: 5px;
                    }
                }

                .form-control {
                    width: 185px;
                    height: 40px;
                    font-size: 13px;
                    font-weight: $font-wt-regular;
                    text-transform: uppercase;
                    border: 1px solid $very-light-grey;
                    border-radius: 0;

                    @include media-breakpoint-down(xs) {
                        width: 135px;
                        height: 35px;
                    }

                    &::-webkit-calendar-picker-indicator {
                        color: transparent;
                        background: none;
                        z-index: 1;
                    }

                    &::before {
                        position: absolute;
                        display: block;
                        width: 15px;
                        height: 20px;
                        top: 3px;
                        right: 26px;
                        font-family: "FontAwesome", arial, sans-serif;
                        content: "\f073";
                        color: $astral;
                        font-size: 22px;

                        @include media-breakpoint-down(xs) {
                            top: 28px;
                            left: 115px;
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }

    .order-history-heading {
        padding: 20px 0 10px 0;
        font-size: 13px;
        color: $night-rider;
        border-bottom: 1px solid $very-light-grey;

        @include media-breakpoint-down(xs) {
            display: none;
        }
    }

    .budgetpay-order-details {
        min-height: 125px;
        padding: 10px 0;
        border-bottom: 1px solid $very-light-grey;

        @include media-breakpoint-down(xs) {
            display: inline-block;
            height: 210px;
            width: 100%;

            .col-6 {
                max-width: 100%;
            }
        }

        .emi-details,
        .upcoming-payments,
        .order-number-text {
            font-size: 13px;
            font-weight: $font-wt-regular;
        }

        .order-details {
            font-size: 13px;
            font-weight: $font-wt-semibold;
            color: $picton-blue;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        .payment-label,
        .order-total {
            font-size: 13px;
            font-weight: $font-wt-bold;
        }

        .paid-details {
            margin-top: 5px;

            .already-paid {
                margin-bottom: 0;
                font-size: 13px;
                font-weight: $font-wt-bold;
            }

            .order-date {
                font-size: 13px;
                font-weight: $font-wt-regular;
            }
        }

        .payment-heading-mobile {
            display: none;

            @include media-breakpoint-down(xs) {
                display: block;
                margin-bottom: 0;
                font-size: 13px;
            }
        }

        .order-paid-full {
            margin-bottom: 5px;
            font-size: 18px;
            font-weight: $font-wt-bold;
            color: $picton-blue;

            @include media-breakpoint-down(xs) {
                font-size: 16px;
            }
        }

        .payments {
            .change-payment,
            .see-less,
            .see-more {
                font-size: 13px;
                font-weight: $font-wt-regular;
                color: $picton-blue;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }

            .inactive-msg {
                color: $persian-red;
                margin-bottom: 5px;
                font-size: 18px;
                font-weight: $font-wt-bold;
                font-family: $opensans-regular;
            }
        }

        .emi-details {
            &.fade {
                display: none;
            }
        }

        .upcoming-payments {
            border-top: 1px solid $very-light-grey;
            margin: 6px 0;
            padding: 6px 0;

            &.last-row {
                border-bottom: 1px solid $very-light-grey;
            }

            .pay-btn {
                display: flex;
                position: relative;
                align-items: center;
                flex-direction: row-reverse;

                .why-cant-pay {
                    color: $sky-blue;
                    font-size: 9px;
                    font-family: "Open Sans", sans-serif;
                    font-style: italic;
                    position: absolute;
                    margin-top: 23px;
                }

                .pay-all,
                .pay-now {
                    float: right;
                    font-family: $opensans-bold;
                    color: $white;
                    font-size: 13px;
                    padding: 10px;
                    text-transform: uppercase;
                    border: unset;
                    min-width: 90px;

                    &.pay-all {
                        background-color: $light-green;
                    }

                    &.pay-now {
                        background-color: $picton-blue;
                    }

                    &:disabled {
                        opacity: 0.65;
                        pointer-events: none;
                    }
                }
            }

            .payment-date {
                font-size: 12px;
                color: $sonic-silver-gray;
            }
        }
    }

    .pagination {
        margin-top: 10px;
        justify-content: center;

        .pagination-page {
            font-weight: $font-wt-bold;
            font-size: 14px;
            color: $night-rider;
        }

        .clearfix {
            display: flex;
            padding-left: 10px;
            list-style: none;

            li {
                a {
                    padding: 1px 6px;
                    color: $black;

                    &:hover {
                        background: $picton-blue;
                        color: $white;
                        text-decoration: none;
                    }

                    &.page-next,
                    &.page-previous {
                        color: $picton-blue;

                        &:hover {
                            background: none;
                            text-decoration: none;
                        }
                    }
                }

                &.current-page {
                    padding: 0 6px;
                    background: $picton-blue;
                    color: $white;
                }
            }
        }
    }
}

.paynow-tooltip .tooltip-text {
    visibility: hidden;
    background-color: $white;
    color: $black;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    bottom: 55%;
    left: 50%;
    margin-left: -60px;
    transition: all 200ms ease;
    box-shadow: 0 0 10px $border-gray;
    width: 210px;

    .tooltip-title {
        font-weight: bold;
        margin-top: 10px;
        font-size: 18px;
    }

    .tooltip-content {
        text-align: left;
        margin: 10px;
        line-height: 25px;
    }
}

.paynow-tooltip:hover {
    visibility: visible;
    cursor: pointer;
}

.paynow-tooltip:hover .tooltip-text {
    visibility: visible;
}

.change-payment-page {
    font-size: 13px;
    font-weight: $font-wt-regular;

    .billing-address-label,
    .curr-payment,
    .add-new-card {
        margin-bottom: 5px;
        font-size: 18px;
        font-weight: $font-wt-bold;
        font-family: $opensans-regular;
        color: $lilac-purple;

        @include media-breakpoint-down(xs) {
            font-size: 16px;
        }
    }

    .remove-card,
    .edit-card {
        color: $lilac-purple;
    }

    .add-address,
    .save-card {
        font-family: $opensans-bold;
        color: $white;
        font-size: 13px;
        padding: 10px;
        text-transform: uppercase;
        border: unset;

        &.save-card {
            background-color: $la-palma;
        }

        &.add-address {
            background-color: $picton-blue;
        }
    }

    .billing-address {
        margin-top: 30px;
    }

    .add-credit-card {
        .address-form-fields {
            .form-group {
                display: block;
            }
        }
    }
}

.on-air-top {
    margin-top: 20px;

    @include media-breakpoint-down(sm) {
        .for-mobile {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .headingWrapper {
        @include media-breakpoint-down(sm) {
            margin-top: 5px;
        }

        .topHeading {
            font-size: 20px;
            font-weight: $font-wt-semibold;
            color: $night-rider;
        }
    }

    .timeZone {
        display: flex;
        flex-direction: row;
        align-items: center;

        @include media-breakpoint-down(sm) {
            align-items: baseline;
        }

        .otherwise {
            label {
                display: none;
            }
        }

        .time-txt {
            padding-right: 5px;
            font-size: 14px;
        }

        .skip {
            display: none;
        }

        .form-control.timezone-select {
            height: 33px;
            margin-top: 20px;
            padding: 6px 20px 5px 8px;
            line-height: 1.3;
            font-size: 16px;
            color: $grey6;
            border: 1px solid $dove-gray;
            border-radius: 0;
            background-image: url(../images/custom-icons/custom-select-up-down-arrows.svg);
            background-repeat: no-repeat, repeat;
            background-position: right 0.4em top 50%, 0 0;
            background-size: 0.6em auto, 100%;
        }
    }

    .liveTvLinks {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;

        @include media-breakpoint-down(sm) {
            padding: 0;
        }

        .rightIcons {
            width: 60px;
            height: 60px;
            float: left;
            margin-top: 10px;
            margin-left: 10px;
            text-align: center;
            list-style-type: none;

            .onAir {
                position: relative;
                display: block;
                width: 30px;
                height: 31px;
                font-size: 13px;
                line-height: 1;

                &::before {
                    position: absolute;
                    content: "ON AIR";
                    padding: 7px 8px 6px 8px;
                    font-size: 9px;
                    background: $picton-blue;
                    color: $white;
                    border-radius: 50%;
                }
            }
        }

        .fa {
            padding: 8px;
            font-size: 14px;
            background: $picton-blue;
            color: $white;
            border-radius: 50%;
        }

        .linkPara {
            display: inline-block;
            margin: 5px auto;
            font-size: 11px;
            color: $night-rider;
            line-height: 1.3;
        }
    }
}

.on-air {
    margin-top: 10px;

    ul.dateTabs {
        display: block;
        padding: 0;

        @include media-breakpoint-down(sm) {
            display: none;
        }

        li {
            display: table-cell;
            width: 1%;
            border: 1px solid $picton-blue;
            border-right: 0;
            cursor: pointer;

            &:last-of-type {
                border-right: 1px solid $picton-blue;
            }

            a {
                display: block;
                padding: 10px 15px;
                color: $night-rider;
                text-align: center;

                &.active {
                    background-color: $picton-blue;
                    border-radius: 0;
                    color: $white !important;
                }

                &:hover {
                    background-color: $picton-blue;
                    border-radius: 0;
                    color: $white !important;
                }
            }
        }
    }

    select.dateTabs {
        display: none;

        @include media-breakpoint-down(sm) {
            display: block;
            width: 100%;
            max-width: 100%;
            margin-bottom: 10px;
            padding: 9px 15px 8px 16px;
            font-size: 16px;
            font-weight: $font-wt-bold;
            color: $white;
            line-height: 1.3;
            box-sizing: border-box;
            border: none;
            border-radius: 0;
            box-shadow: 0 1px 0 1px rgb(0 0 0 / 4%);
            appearance: none;
            background-color: $white;
            background-image: url(../images/custom-icons/custom-select-up-down-arrows-white.svg), linear-gradient(to bottom, $picton-blue 0%, $picton-blue 100%);
            background-repeat: no-repeat, repeat;
            background-position: right 0.7em top 50%, 0 0;
            background-size: 0.65em auto, 100%;
        }
    }

    .hourTabs {
        display: flex;
        padding: 0 0 15px 0;
        border-bottom: 1px solid $dark-gray3;

        li {
            display: inline-block;
            margin-right: 10px;
            list-style-type: none;
            border: 1px solid $alabaster;
            text-align: center;
            cursor: pointer;

            @include media-breakpoint-down(sm) {
                width: 100%;
            }

            .changeHour {
                width: 140px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                @include media-breakpoint-down(sm) {
                    width: 100%;
                }

                &.active {
                    .title {
                        background: $denim;
                        color: $white;
                        border-radius: 5px 5px 0 0;
                    }

                    .body {
                        background: $picton-blue;
                        color: $white;
                        border-radius: 0 0 5px 5px;
                        height: 90px;
                    }
                }

                &:hover {
                    .title {
                        background: $denim;
                        color: $white;
                        border-radius: 5px 5px 0 0;
                    }

                    .body {
                        background: $picton-blue;
                        color: $white;
                        border-radius: 0 0 5px 5px;
                    }
                }

                .title {
                    background: $alabaster;
                    margin: 0;
                    padding: 6px 21px 6px 21px;
                    color: $night-rider;
                    font-weight: $font-wt-bold;
                }

                .body {
                    font-size: 14px;
                    font-weight: $font-wt-bold;
                    padding: 0 20px;
                    text-align: center;
                    color: $night-rider;
                    height: 90px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:hover {
                        background: $picton-blue;
                        color: $white;
                        border-radius: 0 0 5px 5px;
                    }
                }
            }

            .disabled {
                width: 140px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                cursor: not-allowed;
                opacity: 0.5;

                @include media-breakpoint-down(sm) {
                    width: 100%;
                }

                &:hover {
                    cursor: not-allowed;
                    background: none;
                    text-decoration: none;
                    opacity: 0.5;
                }

                .title {
                    background: $alabaster;
                    color: $dove-gray;
                    margin: 0;
                    padding: 6px 21px 6px 21px;
                    font-weight: $font-wt-bold;

                    &:hover {
                        color: $black;
                        background: $alabaster;
                    }
                }

                .body {
                    color: $dove-gray;
                    font-size: 14px;
                    font-weight: $font-wt-bold;
                    padding: 0 20px;
                    text-align: center;
                    height: 90px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:hover {
                        color: $black;
                        background: none;
                    }
                }
            }
        }

        .onair-carousel {
            text-align: center;

            .owl-nav {
                display: block;

                @include media-breakpoint-down(sm) {
                    display: none;
                }

                button.owl-prev,
                button.owl-next {
                    background: rgba(0, 0, 0, 0.6);
                    height: 36px;
                    width: 36px;
                    border-radius: 50%;
                    color: transparent;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);

                    i {
                        font-size: 25px;
                        font-weight: $font-wt-bold;
                        color: $white !important;
                    }
                }

                button.owl-prev {
                    left: 0;

                    &.disabled {
                        opacity: 0.5;
                    }
                }

                button.owl-next {
                    right: 0;

                    &.disabled {
                        opacity: 0.5;
                    }
                }
            }
        }
    }

    .products {
        padding: 0 0 0 10px;

        li {
            list-style-type: none;

            .product-tile {
                padding: 5px 10px 10px;
                margin-bottom: 10px;
                min-height: 500px;

                &:hover {
                    box-shadow: 0 0 10px $dark-gray3;
                }

                .wishlist-air-container {
                    height: 25px;

                    .btn {
                        float: right;
                        padding: 0;

                        .wishlist-icon .fa {
                            color: $grey3;
                        }
                    }
                }

                .image-container {
                    padding-left: 0 !important;
                    text-align: center;

                    img {
                        max-width: 240px;
                    }
                }

                .tile-body {
                    position: relative;

                    .actions {
                        display: none;
                        position: absolute;
                        width: 97%;
                        margin: 0 4px;
                        top: -80px;

                        .quickview {
                            float: left;
                            width: 100%;
                            font-size: 15px;
                            font-weight: $font-wt-bold;
                            padding: 5px 0;
                            background: $gray10;
                            text-align: center;
                            text-transform: uppercase;
                            cursor: pointer;

                            &:hover {
                                text-decoration: none;
                            }

                            .tile-quick-view {
                                color: $white !important;
                            }
                        }

                        .cart-wrapper {
                            float: left;
                            width: 37%;
                            font-size: 13px;
                            font-weight: $font-wt-bold;
                            cursor: pointer;
                            padding: 0 !important;

                            a.link {
                                text-decoration: none;
                            }

                            .add-to-cart {
                                color: $white !important;
                            }
                        }

                        .fastbuy-wrapper {
                            float: left;
                            width: 50%;
                            font-size: 13px;
                            font-weight: $font-wt-bold;
                            padding: 0;

                            a.link {
                                text-decoration: none;
                            }

                            .link {
                                color: $white !important;
                            }
                        }
                    }

                    .promotion-msg {
                        width: 100%;
                        margin-top: 90px;
                        font-size: 13px;
                        color: $night-rider;
                        line-height: normal;
                    }

                    .product-name {
                        height: 40px;
                        margin-top: 0;
                        margin-bottom: 45px;

                        .link {
                            color: $night-rider !important;
                        }
                    }
                }
            }
        }

        .price {
            .price-container {
                .strike-through {
                    .value {
                        color: $black;
                        font-size: 11px;
                    }
                }
            }
        }

        .wishlist-air-container {
            text-align: center;

            .on-air {
                font-weight: $font-wt-bold;
                font-size: 10px;
                color: $white;
                background: linear-gradient(90deg, $picton-blue 0%, $picton-blue-gradient 100%);
                padding: 5px 8px;
            }
        }
    }
}

.bid-history-buynow-badge {
    position: relative;
    width: fit-content;
    background-color: #f9d84c;
    font-size: 12px;
    color: #333;
    font-weight: 600;
    font-family: $opensans-regular;
    padding: 1px 6px;

    &::after {
        position: absolute;
        content: "";
        right: -10%;
        top: 0;
        height: 100%;
        width: 12px;
        background-color: inherit;
        -webkit-transform: skewX(-21deg);
        -moz-transform: skewX(-21deg);
        -ms-transform: skewX(-21deg);
        transform: skewX(-21deg);
    }
}

.bid-history-container {
    .btn-success {
        background-color: $light-green !important;
        border-color: $light-green !important;
    }

    .acc-card-header {
        h2 {
            flex-basis: 100%;
        }
    }

    .acc-card-top {
        justify-content: space-between;

        .auctions-pagination {
            align-items: center;
        }
    }

    .acc-card-bottom {
        justify-content: space-between;

        @include media-breakpoint-down(xs) {
            .auctions-buttons {
                flex-basis: 100%;
            }

            .auctions-pagination {
                justify-content: center;
                flex-basis: 100%;
            }
        }
    }

    .no-bids-text {
        font-size: 13px;
        text-align: center;

        .bid-link {
            font-weight: bold;
            text-decoration: underline;
            color: #868ce0;
        }
    }

    .auctions-buttons {
        button {
            text-transform: uppercase;
            border-radius: 0;
            font-size: 14px;
            font-weight: bold;
            border: none;
            margin-bottom: 10px;

            @include media-breakpoint-down(xs) {
                width: 100%;
            }

            &.purchase-all {
                font-size: 14px;
                font-family: $opensans-bold;
                background: $la-palma;
                color: $white;

                &.disabled,
                &:disabled {
                    cursor: default;
                    pointer-events: none;
                    opacity: 1;
                    background: $alto;
                    color: $dove-gray;
                }
            }

            &.purchase-selected {
                cursor: default;
                pointer-events: none;
                margin-left: 10px;
                text-transform: uppercase;
                background: $alto;
                color: $dove-gray;
                font-size: 14px;
                font-family: $opensans-bold;
                border-radius: 0;

                @include media-breakpoint-down(xs) {
                    margin-left: 0;
                }

                .fa {
                    display: none;
                }

                &[data-selected=true] {
                    cursor: pointer;
                    pointer-events: auto;
                    background: $picton-blue;
                    color: $white;

                    .fa {
                        display: inline;
                    }
                }
            }
        }
    }

    .auctions-pagination-details {
        align-items: center;

        @include media-breakpoint-down(xs) {
            flex-basis: 100%;
            justify-content: start;
            padding: 0 !important;
        }

        .showing-actual-auctions {
            padding: 0 10px 0 0;
            border-right: 1px solid $dusty-gray;
            font-size: 12px;
            color: $night-rider;

            @include media-breakpoint-down(sm) {
                border-right: none;
            }

            @include media-breakpoint-down(xs) {
                padding: 0 10px 10px;
            }
        }

        .auctions-per-page-wrap {
            padding: 0 0 0 10px;
            font-size: 13px;
            color: $night-rider;

            @include media-breakpoint-down(xs) {
                padding: 0 10px 10px;
            }
        }

        p,
        label {
            margin: 0;
            padding: 5px 0;
        }
    }

    .divider {
        width: 1px;
        height: 100%;
        background: $dusty-gray;
        margin: 0 15px;
    }

    .auctions-per-page-wrap {
        label {
            margin-right: 10px;
        }

        select.auctions-per-page {
            display: inline-block;
            border: 1px solid $dusty-gray;
            width: auto;
            border-radius: 0;
            font-size: 12px;
            color: $emperor;
            padding: 5px 25px 5px 10px;
        }
    }

    .auctions-pagination {
        justify-content: flex-end;

        @include media-breakpoint-down(xs) {
            flex-basis: 100%;
            justify-content: start;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
        }

        li {
            display: block;
            float: left;
            font-size: 13px;

            &.current-page,
            a {
                line-height: 30px;
                text-align: center;
            }

            &.current-page {
                background: #868ce0;
                color: $white;
                width: 30px;
            }

            a {
                display: block;
                background: $white;
                color: #868ce0;
                padding: 0 5px;

                &:hover {
                    background: $dusty-gray;
                    text-decoration: none;
                }
            }

            &.first-last {
                @include media-breakpoint-down(xs) {
                    display: block;
                }
            }
        }
    }

    .bid-table-header {
        border-top: 1px solid $dusty-gray;
        color: $night-rider;

        .header-label {
            display: block;
            width: 100%;
            line-height: 30px;
            font-size: 12px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        .sort-trigger {
            background-image: url("../../images/custom-icons/custom-select-up-down-arrows.svg");
            background-position: right 0.4em top 50%, 0 0;
            background-size: 0.6em 60%;
            background-repeat: no-repeat;
            padding: 0 1.4em 0 0;
            color: $night-rider !important;

            &[data-sort-order] {
                background-position: calc(100% - 10px) 50%, calc(100% - 5px) 50%;
                background-size: 6px 7px, 6px 7px;
            }

            &[data-sort-order=asc] {
                background-image: linear-gradient(60deg, transparent 50%, $black 50%), linear-gradient(120deg, $black 50%, transparent 50%);
            }

            &[data-sort-order=desc] {
                background-image: linear-gradient(120deg, transparent 50%, $black 50%), linear-gradient(60deg, $black 50%, transparent 50%);
            }

            &:hover {
                text-decoration: none;
            }
        }

        .bid-status-sort-trigger {
            cursor: pointer;
            height: 30px;
            padding: 0 1.4em 0 15px;
            border: none;
            background-image: linear-gradient(60deg, transparent 50%, $black 50%), linear-gradient(120deg, $black 50%, transparent 50%);
            background-position: calc(100% - 10px) 50%, calc(100% - 5px) 50%;
            background-size: 6px 7px, 6px 7px;
        }
    }

    .bid-table-body {
        border-top: 1px solid $dusty-gray;
    }

    .bid-table-item {
        border-bottom: 1px solid $dusty-gray;
        padding: 10px 0;
        font-size: 14px;
        font-weight: bold;

        @include media-breakpoint-down(sm) {
            .sm-top {
                padding: 0;
            }

            .sm-bottom {
                margin: 10px 0;
            }
        }

        .product-image {
            a {
                display: block;
            }

            .item-image {
                height: auto;
                width: auto;
                margin-right: 0;
            }

            img.product-image {
                max-height: none;
            }
        }

        .pdp-link {
            font-family: $opensans-bold;
            font-size: 14px;
            color: $black !important;
            text-decoration: underline;
        }

        .product-id {
            display: block;
            color: $dusty-gray;
        }

        .bid-end-date {
            display: block;
            color: $dusty-gray;
            word-break: break-all;
        }

        .item-timer {
            margin: 15px 0 0;
            color: $alert-red;
        }

        .item-your-bid {
            text-align: left;
        }

        .item-bid-status {

            @include media-breakpoint-up(sm) {
                text-align: center;
            }

            text-align: left;

            &.won {
                color: $bidstatus-green;
            }

            &.lost {
                color: $bidstatus-red;
            }

            .bid-status-value {
                text-transform: capitalize;
                font-weight: bold;
            }
        }

        .item-order-status {
            text-align: center;
        }

        .select-auction {
            text-transform: uppercase;
            background: $alto;
            color: $dove-gray;
            font-size: 14px;
            font-weight: bold;
            width: 100%;
            border-radius: 0;

            .fa {
                display: none;
            }

            &[data-selected=true] {
                background: $picton-blue;
                color: $white;

                .fa {
                    display: inline;
                }
            }
        }
    }

    .bid-mobile-view {
        display: none;

        @include media-breakpoint-down(sm) {
            display: flex;
            margin-top: 10px;
        }
    }

    .order-status-mobile {
        display: none;

        @include media-breakpoint-down(sm) {
            display: block;
            margin-top: 10px;
        }
    }

    .bid-desktop-view {
        display: block;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .auction-cart-subtotal {
        text-align: right;
        font-size: 14px;

        h3 {
            font-weight: bold;
        }

        p {
            font-family: $opensans-bold;
        }
    }
}

header ~ #maincontent {
    .container {
        .acc-card {
            .bid-link {
                color: $navy-blue;
            }
        }
    }
}

// Welcome Page
.welcome-page {
    .welcome-page-block {
        @include media-breakpoint-up(lg) {
            max-width: 1040px;
            margin: auto;
            width: 100%;
        }
    }

    .post-registration-block {
        .watch-on-tv {
            border: 1px solid $light-gray;

            .welcome-page-primary-head {
                font-size: 20px;
                font-weight: $font-wt-bold;
                background: $welcome-gray;
                font-family: $opensans-bold;
            }

            .welcome-page-primary-desc {
                font-size: 14px;
                margin: 0 20px;
                border-bottom: 1px solid $dove-gray;
                padding: 10px 0;
            }

            .post-registration-form {
                .form-group {
                    .form-control-label {
                        font-size: 13px;
                        font-weight: $font-wt-regular;

                        i {
                            font-style: italic;
                            font-family: $opensans-light;
                            font-size: 11px;
                            padding-left: 2px;
                        }
                    }

                    input.form-control {
                        width: 100%;
                        height: 34px;
                        padding: 6px 12px;
                        font-size: 14px;
                        border: 1px solid $dove-gray;
                        box-shadow: none;
                        border-radius: 0;
                    }

                    .custom-control-label::before {
                        height: 15px;
                        width: 14px;
                    }

                    &.required .form-control-label::after {
                        content: "*";
                        color: $red;
                    }

                    &.required .form-control-label::before {
                        display: none;
                    }
                }

                .create-reg-button {
                    font-size: 13px;
                    max-width: 210px;
                    border-radius: 0;
                    font-family: $opensans-bold;
                    background: $picton-blue;
                    font-weight: $font-wt-bold;
                    padding: 8px 12px;
                }
            }
        }
    }

    .download-app-banner {
        position: relative;

        .content-block {
            position: absolute;
            color: white;
            font-family: $font-family-fontawesome;
            font-weight: $font-wt-bold;

            .app-head {
                font-family: $font-family-fontawesome;
                font-weight: $font-wt-bold;
            }

            .app-text {
                font-size: 16px;
            }

            .app-btn {
                justify-content: center;
            }
        }

        @include media-breakpoint-up(lg) {
            .content-block {
                top: 35%;
                left: 15%;

                .app-head {
                    font-size: 40px;
                }
            }

            .app-img {
                width: 180px;
            }

            .app-btn {
                justify-content: center;
                margin-top: 15px;
            }
        }

        @include media-breakpoint-down(lg) {
            .content-block {
                top: 25%;
                left: 15%;
            }

            .app-btn {
                margin-top: 15px;
            }
        }

        @include media-breakpoint-down(md) {
            .content-block {
                top: 20%;
            }

            .app-head {
                font-size: 31px;
            }

            .app-img {
                width: 140px;
            }
        }

        @include media-breakpoint-down(sm) {
            .content-block {
                top: 14%;
                left: 10%;

                .app-head {
                    font-size: 24px;
                    margin-bottom: 5px;
                }

                .app-text {
                    font-size: 12px;
                }
            }

            .app-img {
                width: 100px;
            }

            .app-btn {
                margin-top: 10px;
            }
        }

        @include media-breakpoint-down(xs) {
            .content-block {
                padding: 0;

                .app-head {
                    font-size: 18px;
                    margin-bottom: 0;
                }

                .app-text {
                    font-size: 10px;
                }
            }

            .app-img {
                width: 90px;
            }

            .app-btn {
                margin-top: 5px;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .container.forgot-container-password {
        margin-left: 16%;
    }

    .container-footer-width .support-img-wrapper {
        margin-top: 0;
    }

    .container-footer-width-two .support-img-wrapper {
        margin-top: -8px;
    }

    .container-style_squad_two .col-12 {
        padding-right: 0;
    }

    .container-style_squad .col-12 {
        padding-left: 0;
        margin-left: -1px;
        margin-top: -0.5px;
    }
}

.remove-line-item {
    padding-top: 20px;
}

.request-password-body {
    .reset-password-email {
        height: 35px;
    }

    .reset-form-control-label {
        font-weight: $font-wt-regular !important;
    }

    #reset-password-email {
        height: 35px;
    }

    #submitEmailButton {
        width: 100%;
        font-weight: $font-wt-regular;
        background: $navy-blue;
        margin-left: 10px;
        padding: 6px 12px;
        line-height: 1.42857143;

        &:hover {
            background-color: $dark-blue2;
        }
    }
}

.live-tv-buttons-wrap {
    .live-tv-btn {
        flex-basis: 18%;
    }
}

.fast-buy-livetv {
    background: #339f5c !important;
}

.support-description {
    font-family: "Open Sans", sans-serif !important;
}

.live-tv-what-is-fast-buy__text {
    font-size: 13px;
    font-weight: 600;
    line-height: 1.42857143 !important;
    word-wrap: break-word;
    word-spacing: 4.5px;
    padding-top: 12px;
}

.live-tv-what-is-fast-buy-info.js-live-tv-what-is-fast-buy {
    padding: 10px;
}

#productSpecificationDiv .border-bottom {
    border-bottom: transparent !important;
}

#productSpecificationDiv tbody {
    border: none !important;
}

#productSpecificationDiv table {
    border: none !important;
}

#productSpecificationDiv td {
    border: none !important;
}

@include media-breakpoint-down(sm) {
    .has-submenu {
        padding-left: 10px !important;
    }

    .align-mobile-sjoplc {
        display: flex;
        flex-direction: column-reverse;
    }
}

.filled + .form-control-label {
    opacity: 0;
}

.labels-container {
    width: 100%;
}

#freeshipping-box {
    height: 32px;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    padding: 5px;
    display: flex;
    font-weight: bold;
    border-radius: 5px;
    color: $black;
    background: #e2d6fd;
    font-size: 13px;
    width: 100%;
    border-left: 5px solid #1e1e2c;
}

.final-price-box {
    height: 32px;
    width: 100%;
    border-radius: 5px;
    background: #e8eafe;
    border: 1px solid #e8eafe;
    border-left: 5px solid $yellow;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    .dollar-icon {
        text-align: center;

        .fa-circle.fa-stack-1x.circle-symbol {
            font-size: 20px;
            color: $yellow;
            transform: translateY(0.5px);
        }

        .dollar-symbol {
            font-size: 16px;
            color: $white;
        }
    }

    .final-price-label {
        font-size: 13px;
        padding-left: 2px;
        font-weight: bold;
        color: $black;
        display: flex;
        align-items: center;
    }
}

@include media-breakpoint-down(lg) {
    .product-breadcrumb {
        margin-left: 20px;
    }
}

@include media-breakpoint-down(md) {
    .tile-badges-image {
        width: 302px;
        height: 226px;
    }

    .tile-badges-image img {
        width: 100%;
    }

    #sf-pdp-custom-slider2 .bx-viewport {
        display: contents;
        overflow: initial !important;
    }

    #sf-pdp-custom-slider3 .bx-viewport {
        display: contents;
        overflow: initial !important;
    }
}

.non-input-label {
    padding-top: 5px;
}

.product-specification a {
    line-height: 0;
}

.product-details-container table {
    border: transparent !important;
}

.menu-group .nav-item .nav-link:hover {
    background-color: transparent !important;
}

.menu-group .nav-item.show .nav-link {
    background-color: transparent !important;
}

.wishlist-widget .wishlist-widget-left .custom-aire-cta {
    @include media-breakpoint-down(md) {
        margin: 0 auto !important;
    }
}

.wishlist-icon .fa.fa-heart {
    @include media-breakpoint-down(md) {
        font-size: 14px;
        margin-top: 0;
        margin-bottom: 0;
    }
}

.js-pdp-nav-carousel-zoom {
    .nav-item {
        .img {
            border: none !important;
        }
    }
}

.link.add-to-wish-list.d-block:hover {
    cursor: pointer;
}

.experience-component.experience-commerce_assets-wishlistTile .wishlist-widget .wishlist-widget-right .wishlist-carousel .owl-stage-outer .owl-stage {
    width: 6060px !important;
}

.mobile-nav-expanded {
    .primary-images-container {
        z-index: 0;
    }
}

.fastbuy-wrapper .fast-buy-button .fast-buy-button-inner {
    font-size: 16px !important;
}

.edit-profile-form .form-group .custom-control-input {
    margin-top: 0;
}

.custom-checkbox .custom-control-input {
    margin-top: 0;
    filter: hue-rotate(33deg);
    opacity: 0.73 !important;
}

.justify-content-evenly {
    justify-content: space-evenly !important;
}

.now-shop-ul li {
    list-style: none;
    font-size: 14px !important;
    margin-bottom: 0 !important;
    font-weight: bold !important;
    display: inline-flex !important;
}

.now-shop-ul {
    padding-left: 15px !important;
}

.now-shop-ul del {
    color: red !important;
}

.now-shop-ul .price-save-additional {
    margin-left: 5px !important;
    color: white !important;
    background: red !important;
    font-size: 11px !important;
    padding: 4px 5px !important;
    font-weight: 600;
}

.price-label.plp-price-now-parent {
    display: none;
}

#quickViewModal .plp-price-now {
    font-size: 28px !important;
    display: block !important;
    font-weight: bold !important;
    margin-top: 5px !important;
    color: black !important;
}

.tile-body .now-shop-ul {
    display: none;
}

.product-quickview .nowclassdivonly {
    display: none;
}

#quickViewModal .js-formatted-price.promo-plp.plp-price-now {
    margin-top: -10px !important;
}

#quickViewModal .plp-price-was.plp-price-was-quickview-hidden {
    display: none;
}

.warranty-heading h4 {
    font-size: 35px;
    font-weight: bold !important;
}

.warranty-content.justify-content-center .text-center p:first-child {
    font-size: 20px;
    font-weight: 300;
    margin: 20px 0;
    margin-bottom: 20px !important;
}

.warranty-content.justify-content-center .text-center p:last-child {
    font-size: 14px;
    color: #3a3231;
}

.enroll-form-section .custom-control-label::after,
.enroll-form-section .custom-control-label::before {
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    background: transparent !important;
}

.enroll-form-section .btn.btn-block.btn-primary.warranty-submit.w-50.m-auto {
    border-radius: 0;
}

.warranty-success p:first-child {
    text-align: center;
    display: block;
    margin: 20px 0;
    font-size: 23px;
    font-weight: bold;
}

.warranty-success p:nth-child(2) {
    margin: 10px 0;
    display: block;
    font-size: 14px;
    margin-bottom: 20px !important;
}

.warranty-success .btn.btn-block.btn-primary a {
    color: white;
}

.warranty-success .btn.btn-block.btn-primary {
    border-radius: 0;
    width: 50%;
    margin: 0 auto;
}

.warranty-content.justify-content-center .error-message {
    background: #e7ccc6;
    color: darkred;
    padding: 20px;
    border-radius: 10px;
    width: 194%;
    margin-left: -44%;
    font-weight: bold;
}

.warranty-submit.w-50.m-auto:focus {
    background-color: #868ce0 !important;
    border-color: transparent !important;
    box-shadow: none !important;
}

.vjs-big-play-button {
    position: absolute !important;
    top: 50% !important;
    margin-left: auto;
    margin-right: auto;
    left: 50% !important;
    right: 0;
    text-align: center;
    border-radius: 60px !important;
    height: 60px !important;
    width: 60px !important;
    border: 4px solid !important;
    background: #00000073 !important;
}

.smv-sirv-video .vjs-big-play-button {
    line-height: 0.2em !important;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder::before {
    padding-top: 22px;
    content: "\f101" !important;
    font-size: 50px;
}

.vjs-paused .vjs-big-play-button {
    display: block !important;
}

.video-section-wrapper {
    margin-left: 30px;
    width: 42.8%;
    border-top: 1px solid #ccc;
    z-index: 999;
}

.video-section-wrapper .my_video_1-dimensions {
    width: 649px !important;
    height: 365px !important;
}

.video-section-wrapper i {
    float: right;
}

.video-icon-plus {
    display: none;
}

.video-section-wrapper .video-heading {
    font-size: 20px;
    font-weight: bold;
    padding-top: 7px;
    margin-bottom: -11px;
}

.video-icon-minus,
.video-icon-plus {
    margin-top: -7px;
}

.warranty-success .btn.btn-block.btn-primary:focus {
    box-shadow: none !important;
    text-decoration: none;
    background-color: #868ce0 !important;
}

.browse-more:hover {
    text-decoration: none !important;
}

.warranty-success button:focus {
    background-color: #868ce0 !important;
    border-color: transparent !important;
    box-shadow: none !important;
}

.bid-table-item .item-attributes.col-lg-8.col-7.p-0.pl-1 {
    padding-left: 20px !important;
}

@include media-breakpoint-down(md) {
    .container {
        .on-air {
            .products li {
                .product-tile {
                    .image-container {
                        a img.tile-image {
                            width: 100% !important;
                        }
                    }
                }
            }
        }
    }
}

/** --CRO Account styles-- **/
.CRO_generic_color {
    .blue-text-label {
        color: $primarybtn-color;

        &.print-receipt {
            color: $primarybtn-color;
        }
    }

    .myaccount-toggle {
        .icon-bar {
            background-color: $primarybtn-color;
        }
    }

    .acc-payment {
        .acc-payment-body {
            .edit-btn,
            .remove-btn {
                color: $primarybtn-color;

                &:hover {
                    color: $primarybtn-color;
                    text-decoration: underline;
                }
            }
        }
    }

    .acc-card-header h2 {
        color: $primarybtn-color;
    }

    #orderHistoryForm,
    .payment-form,
    .acc-payment,
    .view-profile-form,
    .add-credit-card,
    .edit-profile-form,
    .trackorder,
    .address-form,
    .change-password-form,
    .reset-password-form,
    .trackorder-container,
    #confirmAddress {
        .btn.btn-primary {
            background-color: $primarybtn-color;
        }

        .btn.btn-secondary {
            color: $primarybtn-color;
        }
    }

    .shipping-heading,
    .billing-heading {
        color: $primarybtn-color !important;
    }

    .nickname {
        .primary-heading {
            color: $primarybtn-color;
        }

        .form-group {
            .checkbox {
                .nickname-acceptance {
                    .guidelines-link {
                        color: $primarybtn-color;

                        &:hover {
                            color: rgba(51, 51, 51, 0.25);
                        }
                    }
                }
            }

            &.footer {
                .nickname-btn {
                    background: $primarybtn-color;
                    border: 1px solid $primarybtn-color;

                    &:hover {
                        background: $primarybtn-color;
                    }
                }

                .cancel-nickname {
                    color: $primarybtn-color;
                    border: 1px solid $primarybtn-color;

                    &:hover {
                        color: $primarybtn-color;
                        border: 1px solid $primarybtn-color;
                    }
                }
            }
        }
    }

    .on-air-top {
        .liveTvLinks {
            .rightIcons {
                .onAir {
                    &::before {
                        background: $primarybtn-color;
                        color: $white;
                    }
                }
            }

            .fa {
                background: $primarybtn-color;
                color: $white;
            }

            .linkPara {
                color: $primarybtn-color;
            }
        }
    }

    .on-air {
        ul.dateTabs {
            li {
                border: 1px solid $primarybtn-color;

                &:last-of-type {
                    border-right: 1px solid $primarybtn-color;
                }

                a {
                    &.active {
                        background-color: $primarybtn-color;
                        border-radius: 0;
                        color: $white !important;
                    }

                    &:hover {
                        background-color: $primarybtn-color;
                        border-radius: 0;
                        color: $white !important;
                    }
                }
            }
        }

        .hourTabs {
            li {
                .changeHour {
                    &.active {
                        .title {
                            background: $primarybtn-color;
                        }

                        .body {
                            background: $primarybtn-color;
                            color: $white;
                            opacity: 0.7;
                        }
                    }

                    &:hover {
                        .title {
                            background: $primarybtn-color;
                            color: $white;
                        }

                        .body {
                            background: $primarybtn-color;
                            color: $white;
                            opacity: 0.7;
                        }
                    }

                    .body {
                        &:hover {
                            background: $primarybtn-color;
                            color: $white;
                            opacity: 0.7;
                        }
                    }
                }
            }
        }
    }

    .bid-history-container {
        .no-bids-text {
            .bid-link {
                color: $primarybtn-color;
            }
        }

        .auctions-buttons {
            button {
                &.purchase-all {
                    background: $primarybtn-color;
                    color: $white;
                }

                &.purchase-selected {
                    &[data-selected=true] {
                        background: $primarybtn-color;
                        color: $white;
                    }
                }
            }
        }

        .auctions-pagination {
            li {
                &.current-page {
                    background: $primarybtn-color;
                }

                a {
                    background: $white;
                    color: $primarybtn-color;

                    &:hover {
                        background: $primarybtn-color;
                    }
                }
            }
        }

        .bid-table-header {
            color: $primarybtn-color;
        }

        .bid-table-item {
            .select-auction {
                &[data-selected=true] {
                    background: $primarybtn-color;
                    color: $white;
                }
            }
        }
    }

    .fast-buy-livetv {
        background: $primarybtn-color !important;
    }

    #freeshipping-box {
        border: 1px solid $primarybtn-color;
        color: $primarybtn-color;
        background: transparent;
        padding: 10px;
        border-radius: 0;
    }

    .on-air select.dateTabs {
        @include media-breakpoint-down(sm) {
            background-color: $primarybtn-color !important;
            background-image: url(../images/custom-icons/custom-select-up-down-arrows-white.svg), linear-gradient(to bottom, #333 0%, #333 100%);
        }
    }

    .product-grid .product-tile-wrapper .product-tile .wishlist-air-container .on-air {
        background: linear-gradient(90deg, #b7b5b5 0%, #333 100%);
    }

    .now-shop-ul {
        del {
            color: $primarybtn-color !important;
        }

        .price-save-additional {
            background: $primarybtn-color !important;
        }
    }

    .acc-payment-from {
        .make-default-payment .custom-control-label,
        .whatCVV .label-cvv {
            color: $primarybtn-color;
        }
    }

    .acc-payment .acc-payment-body .edit-btn,
    .acc-address-tile .acc-card-body a,
    .manageRemindersLink .reminder-text,
    .fast-buy-settings .show-address-form,
    .fast-buy-settings__faq-link,
    .fast-buy-settings .fast-buy-settings__shipping-form-wrapper .fast-buy-settings__shipping-form-label,
    .removeWishlistItems .modal-head h2,
    .acc-card .acc-card-body .product-link,
    .acc-card .acc-card-body .product-link,
    .acc-card .acc-card-body .track-order,
    .acc-card .acc-card-body .order-link,
    .cart-empty-container .link,
    .custom-checkbox-container span {
        color: $primarybtn-color !important;
    }

    .address-form .nav-tabs li a.active {
        box-shadow: 0 -2px 0 $primarybtn-color;
    }

    .fast-buy-settings__nav-tabs .address-nav-link.active {
        border-top-color: $primarybtn-color;
    }

    .acc-card-body .link {
        color: $primarybtn-color;
    }

    .fast-buy-settings__action {
        background: $primarybtn-color;
    }

    .acc-card .acc-card-body .order-list-container .card.order-details-card .order-table thead {
        background: $primarybtn-color !important;
    }

    .status-bar .cart-switcher-btn.btn-blue-disabled {
        opacity: 0.5;
        background: $primarybtn-color;
    }

    .custom-checkbox-container input:checked ~ span::before,
    .custom-checkbox-container input:checked ~ span::after,
    .custom-checkbox-container span::before,
    .custom-checkbox-container span::after {
        display: none !important;
    }

    .acc-card .acc-card-body .order-list-container .card .order-status {
        color: $primarybtn-color;
    }

    .acc-card-body .order-track-top .order-cancel .modal-content .modal-footer .btn-wrapper .btn-red {
        background: $primarybtn-color;
    }

    .acc-card .acc-card-body .order-list-container .card .card-custom-footer .cancel-order {
        background: $primarybtn-color;

        &:hover {
            background: $primarybtn-color;
        }
    }

    .order-list-container .filter .filter-content .col-2 .filter-order {
        background: $primarybtn-color;
    }

    .order-list-container .budgetpay-order-details .payments .change-payment,
    .order-list-container .budgetpay-order-details .upcoming-payments .pay-btn .pay-all.pay-all {
        color: $primarybtn-color;
    }

    .order-list-container .budgetpay-order-details .upcoming-payments .pay-btn .why-cant-pay,
    .order-list-container .budgetpay-order-details .payments .change-payment,
    .order-list-container .budgetpay-order-details .payments .see-less,
    .order-list-container .budgetpay-order-details .payments .see-more,
    .auto-pay-settings .auto-pay-header .auto-pay-automatically .auto-pay-automatically-link,
    .auto-pay-settings .auto-pay-header .auto-pay-automatically .auto-pay-automatically-link:hover,
    .auto-pay-settings .auto-pay-settings__shipping-form-wrapper .info-icon {
        color: $primarybtn-color;
    }

    .auto-pay-settings .submit-new-address {
        background: $primarybtn-color;
    }

    .change-payment-page .billing-address-label,
    .change-payment-page .curr-payment,
    .change-payment-page .add-new-card,
    .change-payment .add-credit-card .add-card .address-form-fields .cvv-btn {
        color: $primarybtn-color !important;
    }

    .change-payment-page .add-address.add-address,
    .change-payment-page .save-card.add-address,
    .change-payment-page .add-address.save-card,
    .change-payment-page .save-card.save-card {
        background: $primarybtn-color;
        margin-right: 10px;
    }

    .modal-disable-remove-payment-button,
    .modal-keep-remove-payment-button {
        background: $primarybtn-color !important;
    }

    .change-payment .remove-edit .save-creditcard .modal-header .modal-title,
    .change-payment .remove-edit .confirm-card-remove .modal-header .modal-title,
    .change-payment-page .edit-card {
        color: $primarybtn-color;
    }

    .custom-control-input:not(:disabled):active ~ .custom-control-label::before,
    .custom-control-input:checked ~ .custom-control-label::before {
        color: #fff;
        background-color: $primarybtn-color;
        border-color: $primarybtn-color;
    }

    .custom-control-input:focus ~ .custom-control-label::before {
        box-shadow: 0 0 0 0;
    }
}

.email-notifications {
    margin-top: 16px;

    @include media-breakpoint-up(md) {
        .label-email-notifications {
            margin-bottom: 5rem;
        }
    }
}
