@import "bootstrap/scss/mixins/breakpoints";
@import "../variables";
@import "../base/fonts";

.status-bar {
    margin-top: 20px;

    .regular-cart & {
        margin-top: 0;
    }

    &.stage-bar {
        margin-bottom: 25px;
        justify-content: space-between;
        margin-top: 15px;
    }

    .cart-switcher {
        padding: 10px  0 0;
        background-color: $alabaster;
        margin-bottom: 5px;
        display: flex;

        @include media-breakpoint-up(sm) {
            flex: 1;
            justify-content: flex-end;
            align-items: center;
            margin-bottom: 0;
            padding-top: 0;
        }

        &--regular {
            background-color: $white;

            .status-bar-item {
                @include media-breakpoint-up(sm) {
                    text-decoration: none;
                }

                &.auction-cart-btn {
                    text-decoration: underline;
                }
            }
        }
    }

    .cart-switcher-btn {
        display: inline-block;
        font-family: $opensans-regular;
        font-weight: 400;
        text-decoration: underline;
        color: $gray9;
        background-color: transparent;
        order: 2;

        .fa-shopping-cart {
            margin-right: 6px;
        }

        @include media-breakpoint-up(sm) {
            font-size: 14px;
            padding: 6px 10px;
        }

        @include media-breakpoint-up(md) {
            padding: 0 25px;
            line-height: 40px;
        }

        &.btn-blue-disabled {
            color: $picton-blue;
            text-decoration: none;
            order: 1;
            margin-right: 15px;

            img {
                width: 12px;
                height: auto;
                filter: brightness(0) invert(1);
                transform: translate(-2px, -1px);
            }

            @include media-breakpoint-up(sm) {
                margin-right: 0;
                color: $white !important;
                background-color: $lilac-purple;
            }
        }
    }
}

.status-bar-item {
    text-transform: uppercase;

    @include media-breakpoint-up(xl) {
        max-width: none;
        flex-basis: auto;
    }
}

.status-bar-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-right: 3px;
    text-transform: uppercase;
    font-size: 14px;

    @include media-breakpoint-down(md) {
        font-size: 12px;
    }

    .status-bar-tab {
        background-color: #b0b0c3;
        font-family: $opensans-light;
        color: $gray-status-bar;
        font-size: 14px;
        font-weight: bold;
        width: 100% !important;
        cursor: default;
        display: flex !important;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        padding: 0 !important;
        height: 6px;
        align-self: center;

        .stage-number {
            display: inline-block;
            width: 22px;
            height: 22px;
            background-color: #b0b0c3;
            border-radius: 50%;
            text-align: center;
            line-height: 21px;
            color: $white;
            margin-right: 3px;
        }

        @include media-breakpoint-down(xs) {
            font-family: $opensans-regular;

            &:nth-child(-n+2) {
                width: 32%;
            }

            &:nth-child(3) {
                font-size: 13px;
                width: 32%;
                padding: 4px 0;

                .stage-number {
                    width: 32px;
                    font-size: 14px;
                    margin-left: 10px;
                    margin-right: 0;
                }
            }
        }

        &.active {
            background-color: #868ce0;

            .stage-number {
                background-color: $lilac-purple;
            }
        }
    }

    .status-bar-text {
        color: #b0b0c3;
        font-size: 16px;

        @include media-breakpoint-down(sm) {
            font-size: 12px;
        }

        &.active {
            color: black;
            font-weight: 600;
        }
    }
}

.status-bar-btn {
    font-family: $opensans-bold;
    font-size: 16px;

    @include media-breakpoint-up(sm) {
        font-family: $opensans-light;
        font-size: 14px;
    }
}

/** --CRO StatusBar styles-- **/
.CRO_generic_color {
    .status-bar-item {
        &.active {
            background-color: $primarybtn-color;
        }
    }
}
