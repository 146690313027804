@import "../../../variables";
@import "bootstrap/scss/mixins/breakpoints";
$fonts-relative-path-prefix: "../../../";

.CRO_generic_color {
    .purchase-feed-banner {
        .feed-text-count {
            color: #333333 !important;
        }
    }
}
.purchase-feed-banner {
    margin: 22px auto;

    @include media-breakpoint-up(sm) {
        margin: 70px auto;
    }

    .feed-left-col {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .feed-text-svg {
        max-width: 272px;
        height: auto;
        margin-bottom: 10px;

        @include media-breakpoint-down(sm) {
            margin-top: 22px;
        }

        @include media-breakpoint-up(sm) {
            max-width: 435px;
        }
    }

    p {
        font-weight: bold;

        @include media-breakpoint-down(sm) {
            font-size: 14px;
        }
    }

    .feed-text-over {
        margin-top: 10px;
        margin-bottom: 5px;
    }
    .feed-text-over-shoplc {
        margin-bottom: 24px;
        @include media-breakpoint-down(sm) {
        margin-bottom: 0;

        }
    }

    .feed-text-count {
        color: #D93C50;
        font-size: 32px;
        line-height: 48px;

        @include media-breakpoint-up(sm) {
            font-size: 45px;
            line-height: 53px;
        }
    }

    .feed-img-mobile {
        margin-top: 12px;

        @include media-breakpoint-down(sm) {
            padding-left: 11px;
            padding-right: 11px;
        }
    }

    .feed-link {
        font-size: 16px;
        background-color: #1e1e2b;
        color: #fff;
        display: inline-block;
        width: 132px;
        height: 38px;
        line-height: 38px;
        margin-top: 17px;
        text-decoration: none;
        border-radius: 5px;

        @include media-breakpoint-up(sm) {
            width: 271px;
            height: 50px;
            line-height: 50px;
            margin-top: 35px;
        }
    }
}
