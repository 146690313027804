@import "../variables";

$tra-black: rgb(0 0 0 / 40%);

.modal {
    z-index: 10002 !important;

    .header-title {
        color: $primarybtn-color;
        font-weight: 600;
        font-size: 18px;
        text-transform: uppercase;
    }

    .modal-body {
        font-family: $opensans-regular;
        font-size: 14px;
    }
}

.modal-absolute {
    position: absolute;
    height: auto;

    .close {
        position: absolute;
        top: 6px;
        right: 10px;
        font-size: 14px;
        z-index: 11;
    }
}

.modal-lg {
    max-width: 900px;
}

.asset-modal {
    .modal-content {
        width: auto;
        box-shadow: 0 5px 15px rgb(0 0 0 / 50%);

        @include media-breakpoint-up(md) {
            width: 600px;
        }
    }

    .header-title {
        font-family: $opensans-regular;
    }
}

.message-modal {
    .modal-dialog {
        text-align: left;
        vertical-align: middle;
        position: relative;
        width: auto;
        max-width: 100%;
        margin: 10px;

        @include media-breakpoint-up(lg) {
            max-width: 600px;
            margin: 30px auto;
        }

        .modal-content {
            border-radius: 0;
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
            border: 1px solid rgba(0, 0, 0, 0.2);
        }

        .modal-header {
            background: $jaffa-light;
            border-color: $jaffa-light;
            padding: 15px;

            .header-title {
                font-family: $opensans-bold;
                font-weight: 600;
                color: $white;
                font-size: 20px;
                line-height: 27px;
            }

            button.close {
                font-family: $opensans-bold;
                font-size: 30px;
                color: $white;
                opacity: 1;

                &:hover {
                    opacity: 1;
                }
            }
        }

        .modal-body {
            padding-top: 50px;
            padding-bottom: 40px;
            position: relative;
            text-align: center;
            font-size: 16px;
            line-height: 22px;

            button.close {
                background: $night-rider;
                color: $white;
                font-weight: bold;
                font-size: 16px;
                min-width: 200px;
                border-radius: 0;
                text-decoration: none !important;
                padding: 10px 10px;
                float: none;
                margin: 20px 0;
                opacity: 1;

                &:hover {
                    opacity: 1;
                }
            }

            .subtitle-error {
                font-size: 11pt;
                font-family: $opensans-bold;
                padding-bottom: 5px;
            }

            p {
                font-family: $opensans-regular;
                font-size: 13px;
                line-height: 1em;
            }
        }
    }
}

// Personalize Modal
.c-modal {
    .modal-lg {
        padding: 0;

        @include media-breakpoint-down(md) {
            width: 97.5%;
        }

        @include media-breakpoint-down(md) {
            width: 80%;
        }
    }

    .modal-header {
        .modal-title {
            font-size: 18px;
            font-family: inherit;
            font-weight: $font-wt-semibold;
            color: $primarybtn-color !important;
        }

        .close {
            font-size: 35px;
            padding: 5px;
            font-weight: $font-wt-semiregular;
            opacity: 1;
        }
    }

    .modal-body {
        #ciframe {
            height: 100%;
            min-height: 550px;
        }

        #pre-img {
            width: auto;
            max-width: -webkit-fill-available;
        }

        .c-footer {
            .c-btn {
                border: 2px solid;
                color: $night-rider;
                font-weight: $font-wt-semibold;
                border-radius: 25px;
                max-width: 250px;
                font-size: 14px;
            }
        }
    }

    .modal-dialog {
        .c-confirm {
            font-size: 15px;
        }

        .c-c-btn {
            background-color: $purple-personalize;
            border-color: $purple-personalize;
            border-radius: 3px;
            font-weight: $font-wt-bold;
            padding: 8px 12px;
            color: $white;

            &:hover {
                background-color: $purple-dark-personalize;
                border-color: $purple-dark-personalize;
            }
        }
    }
}

#c-success .modal-dialog {
    max-width: 300px;
}

#jewelleryPersonalizationPopup {
    @include media-breakpoint-up(md) {
        padding-left: 0;
    }

    .jewellery-personalised-popup {
        max-width: 600px;
        width: 100%;
        margin: 30px auto;

        .modal-title {
            font-weight: $font-wt-semibold;
            font-family: $opensans-regular;
            font-size: 18px;
            color: $navy-blue;
        }

        .modal-body {
            width: 75%;
            margin: auto;

            @include media-breakpoint-down(md) {
                width: 100%;
            }

            .btn {
                font-size: 13px;
                border-radius: 0;
            }

            #jpButtonContinue {
                background: $navy-blue;
                border: 1px solid $navy-blue;

                &:hover {
                    background: $dark-blue2;
                    border: 1px solid $dark-blue2;
                }
            }

            #jpButtonCancel {
                border: 1.5px solid $navy-blue;
                color: $navy-blue;

                &:hover {
                    background: $white;
                }
            }
        }
    }
}

// Personalized Jewelry How it works
.dy-brandpage-popup {
    .modal-dialog {
        max-width: 1040px;
        margin-top: 2%;
        width: 90%;

        .plp-modal-title1,
        .plp-modal-title2 {
            color: $gold-popup-personalize;
            margin: auto 1%;
            font-size: 35px;
            display: block;
            font-family: $opensans-regular;
            font-weight: $font-wt-bold;
        }

        .plp-modal-title2 {
            color: $black;
        }

        .plp-modal-tile {
            .well {
                min-height: 230px;
                padding: 19px;
                margin-bottom: 20px;
                background-color: $wild-sand;
                border: 1px solid $incredible-gray;
                border-radius: 4px;
                box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);

                @include media-breakpoint-up(md) {
                    width: auto;
                }

                .plp-modal-tile-head {
                    font-size: 30px;
                    margin-bottom: 5px;
                    color: #d4ca9b;
                }

                .plp-modal-tile-content {
                    font-size: 16px;
                    font-weight: $font-wt-bold;
                    font-family: $opensans-bold;
                }
            }
        }

        .plp-modal-tiles-img {
            text-align: center;
            margin: 10px auto;

            img {
                width: 300px;
                box-shadow: 0 2px 15px $light-gray;
            }
        }
    }
}

.auction-alert-modal {
    @include media-breakpoint-down(sm) {
        padding-right: 0 !important;
    }

    .modal-dialog {
        max-width: 673px;

        @include media-breakpoint-down(sm) {
            max-width: 348px;
            margin: 0 auto;
        }
    }

    .modal-content {
        padding: 38px 30px;
        position: relative;

        @include media-breakpoint-down(sm) {
            padding: 28px 12px;
        }

        .modal-close-btn {
            position: absolute;
            right: 12px;
            top: 4px;
            font-weight: 600;
            height: 30px;
            line-height: 1;
            display: block;
            align-items: center;
            cursor: pointer;

            @include media-breakpoint-down(sm) {
                top: -43px;
                right: 0;
                color: $white;
                font-weight: 300;
            }

            .cross-icon {
                display: block;

                &::after {
                    display: inline-block;
                    content: "\00d7";
                    font-size: 40px;
                    line-height: 1;
                }
            }
        }

        .modal-info-block {
            background: #fff3cd;
            border: 1px solid #e5d193;
            border-radius: 3px;
            display: flex;
            align-items: flex-start;
            padding: 10px 15px;
            max-width: 566px;
            margin: 0 auto 26px;

            @include media-breakpoint-down(sm) {
                margin-bottom: 21px;
                max-width: 100%;
                padding: 10px;
            }

            p {
                font-size: 15px;
                line-height: 25px;
                font-weight: 600;
                color: #333;
                text-align: center;

                @include media-breakpoint-down(sm) {
                    text-align: left;
                    font-size: 14px;
                }
            }

            .fa {
                color: #ffb400;
                margin-right: 7px;
                line-height: 1.5;
            }
        }
    }

    .modal-footer {
        padding: 0;
        border: none;
        justify-content: center;

        .btn {
            max-width: 236px;
            font-size: 16px;
            width: 100%;
            height: 43px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 12px 10px;
            border: none;
            margin: 0 15px;

            @include media-breakpoint-down(sm) {
                margin: 0;
                max-width: 280px;
            }

            &.btn-cancel {
                background: #d9d9d9;
                color: #333;

                @include media-breakpoint-down(sm) {
                    order: 1;
                }
            }

            &.btn-paynow {
                background: #339f5d;
                color: $white;

                @include media-breakpoint-down(sm) {
                    order: 0;
                    margin-bottom: 18px;
                }
            }
        }
    }
}

/** --CRO Modal styles-- **/
.CRO_generic_color {
    .modal {
        // .modal-header {
        //     .close,
        //     .fa-close {
        //         opacity: 1 !important;
        //         color: $white !important;
        //     }

        //     .modal-title {
        //         color: $white !important;
        //     }
        // }

        // .header-title {
        //     color: $white;
        // }

        .btn-navy-blue {
            background: #707070 !important;
        }
    }

    .c-modal {
        .modal-header {
            .modal-title {
                color: $primarybtn-color !important;
            }
        }
    }

    .auction-modal .btn-bid {
        background: $primarybtn-color !important;
        border-color: $primarybtn-color !important;
    }

    .removeWishlistItems .modal-footer .btn.keep-allitems-wishlist-btn,
    .removeWishlistItems .modal-footer .btn.remove-allfrom-wishlist-btn {
        color: $white;
        border-color: $primarybtn-color;
        background: $primarybtn-color;
    }

    .confirmation-modal .close-btn {
        background: $primarybtn-color !important;
    }

    .empty_wishlist .btn {
        border-color: $primarybtn-color;
        color: $primarybtn-color;
    }

    .acc-card-body .empty-wishlist-header .wishlist-owner {
        color: $primarybtn-color;
    }
}

#variantSelectionErrorModal,
#wishlistModal,
#add-to-wishlist-popup,
#add-to-wishlist-success-popup,
#remove-wishlist-popup,
#removeWishlistItem,
#removeWishlistItems,
#wishlist-removed-popup,
#fastBuyLoginModal,
#quickViewModal,
#loginModal,
#activateFastBuyModal,
#failedFastBuyModal,
#formModal,
#confirmAddress,
#confirmationFastBuyModal,
#cancelOrderModal,
#cancelOrderStatusModal,
#maximum-quantity-modal,
#SoldoutWishlistInfo,
#stockErrorModal,
#bidModal,
#movedToWishlistSuccess {
    @include media-breakpoint-down (sm) {
        background-color: $tra-black;
    }

    .modal-dialog {
        @include media-breakpoint-down(sm) {
            top: 60px;
        }
    }

    .livetv360video {
        @include media-breakpoint-down(sm) {
            top: 500px;
            max-width: 100%;
        }
        @include media-breakpoint-down(xs) {
            top: 50%;
        }
    }

    .modal-dialog.quick-view-dialog {
        @include media-breakpoint-down(sm) {
            top: 0;
        }

        .modal-header {
            @include media-breakpoint-down(sm) {
                display: block !important;
            }
        }
    }
}

.remove-wishlist-css {
    @include media-breakpoint-down (sm) {
        position: relative;
        z-index: -1;
    }
}

.tracking-consent-model {
    max-width: 100%;
    padding: 0 !important;
    margin: 0;
    bottom: 40px;
    z-index: 2147483641;

    @include media-breakpoint-up (md) {
        max-width: 90%;
        left: 5%;
    }

    &.bottom-consent {
        bottom: 40px !important;
    }

    .tracking-consent-body {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 20px 5px 30px;
        font-size: 16px;
        color: black;

        @include media-breakpoint-down(xs) {
            padding: 13px 42px 0 43px;
            font-size: 14px;
        }

        @include media-breakpoint-up(md) {
            justify-content: end;
            padding: 0 10px 0 30px;
        }
    }

    .button-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .tracking-consent-content {
        background-color: #eff0fc;
        display: inline-flex;
        opacity: 0.9;
    }

    .continue-button {
        background-color: #868dda;
        border-color: #868dda !important;
        transition: none;
        max-height: 40px;

        @include media-breakpoint-down(xs) {
            margin-left: 28px !important;
        }

        &:focus {
            border: none !important;
            background-color: #868dda;
            outline: none;
            transition: none !important;
        }
    }

    .close-button {
        background-color: #eff0fc;
        color: #707070;
        border: none;
        font-size: 40px;
        font-weight: 400;
        margin: 0 15px 10px 10px !important;
    }
}

.modal-center {
    text-align: center;
    padding: 0 !important;

    .modal-dialog {
        display: inline-block;
        text-align: left;
        vertical-align: middle;
    }

    &::before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        margin-right: -4px;
    }
}

#pageExitModal {
    .modal-dialog {
        max-width: 300px;

        @include media-breakpoint-up(sm) {
            max-width: 357px;
        }
    }

    .modal-content {
        border-radius: 10px;
    }

    .modal-header {
        padding-top: 9px;
        padding-bottom: 9px;
        border-bottom: 0;
    }

    .modal-body {
        padding-top: 3px;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-size: 32px;
            font-weight: 600;
        }

        p {
            font-size: 16px;
        }
    }

    .modal-footer {
        padding-bottom: 42px;
        border-top: 0;

        button,
        a {
            width: 100%;
            flex: 100%;
        }
    }

    .modal-body,
    .modal-footer {
        padding-left: 43px;
        padding-right: 55px;
    }
}
