#two-factor-modal {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10003 !important;

  .modal-content {
    border-radius: 15px;
    font-family: $opensans-regular;

    #two-factor-submit {
      font-family: $opensans-bold;
      background-color: $lilac-purple;
      border-radius: 5px;
      color: $white;
    }

    .two-factor-input {
      border: 1px solid $very-light-grey;
      border-radius: 5px;
      width: 40px;
      height: 40px;
      font-size: 30px;
      padding: 0 12px;
    }

    .two-factor-resend-link{
      text-decoration: underline;
    }

    .two-factor-error {
      color: $red;
    }
  }

  .modal-title {
    font-family: $opensans-bold;
  }

  .modal-header {
    border-bottom: none;
  }

  .modal-footer {
    border-top: none;
  }
}
