@import "../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "../base/fonts";

@mixin auction-product-details() {
    .play-video-btn {
        text-transform: uppercase;

        a {
            color: $navy-blue;
            font-size: 18px;
        }

        .video-icon {
            width: 20px;
            height: 20px;
            line-height: 1em;
        }

        .video-play {
            color: $white;
            font-size: 0.5em;
            margin: -1px 0 0 1px;
        }

        .video-label {
            color: $navy-blue;
            font-size: 14px;
        }
    }

    .main-info-container-left {
        margin: 0;
        // margin-left: 57px !important;
        // width: 650px;
        // @include media-breakpoint-down(sm){
        //     margin-left: 0 !important;
        // }
    }

    .product-name {
        margin-bottom: 0;
        color: $inline-gray;
        font-size: 20px;
        text-align: left;
        font-family: $opensans-bold;
        letter-spacing: 0;
        opacity: 1;

        @include media-breakpoint-down(sm) {
            font-weight: 800;
        }
    }

    .product-id {
        color: $black;
        font-size: 14px;
        text-align: left;
        font-family: $opensans-regular;
        letter-spacing: 0;
        opacity: 1;

        @include media-breakpoint-down(sm) {
            margin-bottom: 0;
            font-weight: 800;
        }

        &.id-qv {
            @include media-breakpoint-down(sm) {
                margin-left: 15px;
            }
        }
    }

    .product-size {
        font-size: 14px;
        margin: 10px 0;
        font-weight: 600;
    }

    .pdp-badge {
        clip-path: polygon(0 0, 100% 0, calc(100% - 7px) 50%, 100% 100%, 0 100%);

        .pdp-badge-name {
            color: #fff;
            font-family: OpenSans-Bold, arial, sans-serif;
            font-size: 12px;
            letter-spacing: 0.13px;
            opacity: 1;
            padding: 4px 18px 4px 10px;
        }
    }

    .owl-carousel .owl-nav--secondary {
        .owl-prev,
        .owl-next {
            &:hover {
                color: $picton-blue;
            }
        }

        .owl-prev {
            left: -20px;
        }

        .owl-next {
            right: -20px;
        }
    }

    .bx-wrapper .bx-controls-direction a {
        color: $dusty-gray;

        &:hover {
            color: $picton-blue;
        }
    }

    .auction-details {
        margin: 3px 0;
        border-top: none;

        @include media-breakpoint-up(sm) {
            margin: 3px 0;
            border-bottom: none;
        }
    }

    .auction-details-left {
        position: relative;
        padding: 18px 0 19px 23px !important;
        background: $white;
        box-shadow: 2px 4px 9px #0000001a;
        border: 1px solid #d6d6d6;
        border-radius: 4px;
        opacity: 1;
        width: 310px;
        height: max-content;

        @include media-breakpoint-down(sm) {
            border-right: 1px solid $solitude;
            margin-left: 1px;
        }
    }

    .auction-card-height {
        height: 135px;
    }

    .auction-quickview .auction-details-left {
        padding: 10px 0 19px 23px !important;
        width: 300px;
        height: 110px;
    }

    .current-bid-label,
    .highest-bidder-label,
    .winner-auction-label,
    .order-status-label {
        display: inline-block;
        vertical-align: middle;
        text-align: left;
        font-family: $opensans-bold;
        font-size: 16px;
        letter-spacing: 0;
        color: $black;
        opacity: 1;

        @include media-breakpoint-up(sm) {
            font-size: 17px;
        }
    }

    span.current-bid-amount {
        margin-left: -5px;
    }

    .current-bid-value,
    .highest-bidder-name,
    .order-status-name {
        display: inline-block !important;
        vertical-align: middle;
        text-align: left;
        font-family: $opensans-regular;
        letter-spacing: 0;
        color: $night-rider;
        opacity: 1;
        font-size: 17px;

        @include media-breakpoint-up(sm) {
            display: inline;
            font-size: 17px;
        }
    }

    .order-status-expired {
        display: inline-block !important;
        vertical-align: middle;
        text-align: left;
        font-family: $opensans-regular;
        letter-spacing: 0;
        color: $lc-red;
        opacity: 1;
        font-size: 19px;

        @include media-breakpoint-down(sm) {
            display: inline;
            font-size: 17px;
            font-weight: $font-wt-semibold;
        }
    }

    .current-highest-bid {
        margin-bottom: 15px;

        @include media-breakpoint-up(sm) {
            margin-bottom: 0;
            padding-right: 15px;
        }
    }

    .highest-bidder,
    .order-status {
        margin-top: 16px;
    }

    .auction-save-est-values {
        flex-flow: row;
        flex-basis: 100%;
        align-items: flex-end;
        border-right: none !important;
        border: none !important;
        margin-top: 18px;
        margin-bottom: 28px;
        // height: 22px;

        @include media-breakpoint-up(sm) {
            flex-flow: column;
            flex-basis: auto;
            border: 1px solid $solitude;
            border-right: none;
            align-items: center;
        }

        @include media-breakpoint-down(sm) {
            flex-basis: 100%;
            flex-wrap: wrap;
            margin-left: 10px;

            .estimated-values,
            .save-value {
                flex-basis: 100%;
                margin-left: -9px;
            }
        }
    }

    .save-value {
        order: 2;
        flex-basis: 50%;
        display: flex;
        width: 150%;
        margin: 0 0 10px 11px;

        @include media-breakpoint-up(sm) {
            order: 1;
            flex-basis: auto;
            text-align: left;
        }
    }

    .save-value-label,
    .estimated-price-label {
        display: inline-block;
        vertical-align: middle;
        font-size: 16px;
        font-family: $opensans-regular;
        font-weight: 600;
        color: $night-rider;

        @include media-breakpoint-down(sm) {
            display: block;
            font-size: 14px !important;
            padding-right: 4px;
        }
    }

    .save-value-value {
        display: inline-block;
        vertical-align: middle;
        font-size: 16px;
        font-weight: 600;
        color: $lc-red !important;
        margin-left: 4px;

        @include media-breakpoint-down(sm) {
            display: block;
            font-size: 15px;
        }
    }

    .estimated-values {
        order: 1;
        flex-basis: 50%;
        // padding-right: 15px;

        @include media-breakpoint-up(sm) {
            flex-basis: auto;
            text-align: center;
            // padding-right: 0;
        }
    }

    .estimated-price-value {
        display: inline-block;
        font-size: 16px;
        color: $night-rider;
        text-decoration: none;
        margin-left: 4px;
        font-weight: 600;

        @include media-breakpoint-down(sm) {
            font-size: 15px;
        }
    }

    .estimated-price {
        display: flex;
    }

    .auction-expired-badge {
        .sold-out-badge {
            display: inline-block;
            font-family: $opensans-regular;
            color: $white;
            background-color: $lc-red;
            font-size: 16px;
            padding: 0 10px;
            line-height: 2;
            font-weight: 600;
        }

        &.desktop {
            padding: 0 10px;

            .sold-out-badge {
                line-height: 75px;
                padding: 0;
                width: 73px;
                height: 73px;
                text-align: center;
            }
        }

        &.mobile {
            .sold-out-badge {
                position: absolute;
                top: 0;
                right: 0;
                border-radius: 13px;
            }
        }

        .auction-expired-msg {
            font-size: 13px;
            margin: 0;
            color: $lc-red;
            margin-top: 18px;
            font-family: $opensans-regular;
            font-weight: 600;
        }
    }

    .auction-expired-hidden {
        display: block;
    }

    .auction-expired-visible {
        display: none;
    }

    &.auction-expired {
        .auction-expired-visible {
            display: block;
        }

        .auction-expired-visible.desktop {

            @include media-breakpoint-up(sm) {
                display: block;
            }

            .estimated-values {
                order: 1;
                flex-basis: 50%;
                // padding-right: 15px;

                @include media-breakpoint-up(sm) {
                    flex-basis: auto;
                    text-align: left !important;
                    // padding-right: 0;
                }
            }

            .save-value {
                order: 2;
                flex-basis: 50%;
                display: flex;
                width: 150%;
                margin: 0 !important;

                @include media-breakpoint-up(sm) {
                    order: 1;
                    flex-basis: auto;
                    text-align: left;
                }
            }
        }

        .auction-expired-visible.mobile {
            display: block;

            @include media-breakpoint-up(sm) {
                display: none;
            }
        }

        .auction-expired-hidden {
            display: none;
        }

        .auction-save-est-values {
            @include media-breakpoint-down(sm) {
                flex-basis: 50% !important;
            }
        }
    }

    .item-timer {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        margin: 13px 0 30px 0;
        color: $lc-red;
        width: 220px !important;
        height: 47px;

        @include media-breakpoint-down(sm) {
            margin: 13px 0 30px 0;
        }

        span {
            display: block;
            background: #f3f3f3;
            height: 45px;
            border-radius: 4px;
            width: 220px;
        }
    }

    .order-item-timer {
        text-align: left;
        font-size: 13px;
        color: $lc-red;
        width: 225px !important;
        height: 47px;
        font-family: $opensans-bold !important;
        margin-top: 13px;

        .bid-history-hours,
        .bid-history-minutes {
            text-align: left;
            font-family: $opensans-bold;
            font-size: 13px;
            letter-spacing: 0;
            color: #ea244c;
            opacity: 1;
            margin: 0 4px 0 0;
        }
    }

    .auction-end-date {
        text-align: left;
        font-size: 12px;
        font-weight: 600;

        @include media-breakpoint-down(sm) {
            text-align: left;
            font-size: 14px;
        }
    }

    .auction-end-date-label {
        display: block;
        text-align: left;
    }

    // .bid-history-minutes{
    //     padding: 5px 0 5px 0;
    // }

    .auction-user-innteraction {
        .btn {
            font-size: 13px;
            border-radius: 0;
            text-transform: uppercase;
        }

        .bid-now-pdp {
            flex-grow: 1;
            font-family: $opensans-bold;
            height: 48px;
            min-width: 170px;

            &.btn-qv {
                min-width: auto;
            }
        }

        .btn-max-bid {
            border: 0 solid $blue-primary;
            color: $white;
            line-height: 10px;
            background: $blue-primary;
            border-radius: 3px;
            opacity: 1;
            height: 48px;
            min-width: 170px;
            margin-right: 10px;

            &.btn-qv {
                min-width: auto;
                margin-right: 10px;
            }

            &:hover {
                background: $picton-blue-dark;
            }

            span {
                line-height: 1;
            }

            span > span {
                display: block;
                font-size: 12px;
                margin-top: 5px;
            }
        }

        .btn-bid-now {
            border: 0 solid $light-green;
            background: $light-green;
            border-radius: 3px;
            opacity: 1;
            color: $white;
            height: 48px;

            &:hover {
                background: $light-green;
            }
        }

        .login-to-bid {
            width: 415px;
            height: 48px;
            background: #fff;
            border: 1px solid #868ce0;
            color: #868ce0;
            margin: 0 0 10px;
            font-weight: 600;
            display: flex;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;

            @include media-breakpoint-down(sm) {
                width: auto;
                margin-top: 25px !important;
            }

            &:hover {
                background: $picton-blue;
                color: $white;
            }
        }

        .login-to-qv-buynow {
            background-color: #f9d84c;
            width: 415px;
            height: 48px;
            margin: 0 0 10px;
            font-weight: 600;
            display: flex;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            border-radius: 4px;

            @include media-breakpoint-down(sm) {
                width: 90% !important;
                margin: 25px 15px 0 15px;
            }

            @include media-breakpoint-only(sm) {
                width: 365px !important;
                margin: 25px 0 0 0;
            }
        }

        .buy-now-pdp-guest {
            background-color: #f9d84c;
            width: 415px;
            height: 48px;
            margin: 20px 0 2px;
            font-weight: 700;
            font-family: $opensans-regular;
            font-size: 16px;
            display: flex;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            padding: 0;
            color: #212529; 

            @include media-breakpoint-down(sm) {
                width: auto;
                font-size: 14px;
            }
        }

        .buy-it-now-pdp {
            background-color: #f9d84c;
            width: 415px;
            height: 48px;
            font-weight: 700;
            font-family: $opensans-bold;
            font-size: 13px;
            display: flex;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            padding: 0;
            text-decoration: none;
            border: none;
            color: #212529;

            @include media-breakpoint-down(sm) {
                width: 100%;
                margin-top: 25px;
            }
        }

        .bid-buttons-wrap {
            margin-bottom: 10px;
            margin-top: 15px;
            position: relative;

            @include media-breakpoint-down(sm) {
                display: block !important;
                text-align: center;
            }

            .spinner {
                width: 40px;
                height: 40px;
                top: 0;
                margin-top: 0;
            }
        }
    }

    .product-short-desc {
        font-size: 13px;
    }

    &.auction-expired .product-short-desc {
        opacity: 0.8;
    }

    input.amountInput {
        background: $white;
        border: 0.8000000119px solid #c3c3c3;
        border-radius: 4px;
        width: 82px;
        height: 47px;
        text-align: center;
        font-family: "OpenSans-Bold", arial, sans-serif;
        font-size: 22px;
        color: #ea244c;
        padding: 0;
        margin-left: 4px;
    }

    .bid-amount-wrap {
        position: relative;
        margin: 0 0 10px;

        .currency-symbol {
            position: absolute;
            left: 0;
            font-size: 18px;
            line-height: 33px;
            margin-left: 5px;
        }

        input.bid-amount {
            width: 100%;
            border-radius: 0;
            border: 1px solid #ddd;
            padding: 5px 10px 5px 15px;
            text-align: center;
            font-size: 14px;

            &[type=number] {
                text-align: center;
                -moz-appearance: textfield;

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }
        }
    }

    .question_margin {
        margin-top: 20px;
    }

    .question {
        button {
            width: 415px;
            height: 48px;
            background: $white;
            border: 1px solid $dark-gray3;
            color: $night-rider;
            margin: 26px 0 10px;
            font-family: $opensans-bold;
            text-align: center;
            letter-spacing: 0;
            opacity: 1;

            @include media-breakpoint-down(sm) {
                width: -webkit-fill-available;
            }

            &:hover {
                background: $night-rider;
                color: $white;
            }
        }

        .form-check-input {
            margin: 0;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            filter: hue-rotate(250deg);
        }

        label {
            text-align: left;
            font-family: $opensans-bold;
            letter-spacing: 0;
            color: $night-rider;
            opacity: 1;
            font-size: 16px;
        }
    }

    .product-content-section {
        margin: 20px 0 0;

        @include media-breakpoint-only(sm) {
            margin-top: 45px;
        }

        @include media-breakpoint-down(xs) {
            margin: 0;
        }

        h2.section-header {
            font-size: 20px;
            border-bottom: 3px solid $picton-blue;
            margin-bottom: 20px;
            display: inline-block;
            line-height: 2;
            letter-spacing: 0.5px;
        }

        .product-video {
            video {
                width: 100%;
            }

            @include media-breakpoint-down(xs) {
                padding: 0;
            }
        }

        .video-note {
            margin: 10px 0;
            font-size: 12px;
        }

        .product-highlights {
            margin-bottom: 20px;
        }

        .qv-collapsible-header {
            position: relative;
            display: flex;
            justify-content: space-between;
            padding: 10px 20px;
            background-color: #f5f5f5;
            margin-top: 15px;
            cursor: pointer;

            .btn:focus {
                box-shadow: none;
            }

            &.chevron-toggle {
                &[aria-expanded=true] {
                    .fa-chevron-down::before {
                        content: "\f077";
                    }
                }
            }

            &.chevron-toggle.collapsed {
                &[aria-expanded=false] {
                    .fa-chevron-down::before {
                        content: "\f078";
                    }
                }
            }

            .desc-spec-chevron-icon {
                position: absolute;
                right: 0;
                padding: 10px 20px 0 0;
            }

            .panel-title {
                font-size: 16px;
                font-family: "OpenSans-Bold", arial, sans-serif;
            }
        }

        .qv-description-holder {
            .gemstone-details-container {
                width: 100%;
            }
        }

        h3.product-highlights-header {
            font-size: 18px;
            border-bottom: 3px solid $picton-blue;
            margin-bottom: 20px;
            display: inline-block;
            line-height: 2;
        }
    }

    .bid-history-product-details-wrap {
        // margin: 20px 0 0;

        .pdp-tabs-wrapper {
            padding: 0;

            @include media-breakpoint-up(sm) {
                padding: 0 15px;
            }
        }

        .nav-tabs {
            border: none;
            width: 100%;

            @include media-breakpoint-up(sm) {
                width: auto;
            }

            .nav-item {
                width: 100%;
                text-align: left;
                text-transform: uppercase;

                @include media-breakpoint-up(sm) {
                    width: auto;
                    text-align: center;
                    text-transform: none;
                }
            }

            .nav-link {
                font-size: 15px;
                color: $night-rider;
                border: 1px solid $solitude;
                padding: 15px 20px;

                @include media-breakpoint-up(sm) {
                    margin-right: 2px;
                    padding: 10px 15px;
                    border: none;
                    border-top: 2px solid transparent;
                    font-family: $opensans-bold;
                }

                &:hover,
                &.active,
                &:focus {
                    color: $white;
                    background: $navy-blue !important;
                    border: none;

                    @include media-breakpoint-up(sm) {
                        color: $emperor;
                        background: $solitude !important;
                        border-top: 2px solid #868ce0;
                    }
                }

                &#product-details-tab-mobile {
                    border-top: none;

                    &.active {
                        display: none;
                    }
                }
            }
        }

        .pdp-panels-wrapper {
            font-size: 14px;
            border: 1px solid $solitude;
            border-top: none;
            padding: 20px 0;

            @include media-breakpoint-up(sm) {
                border: 2px solid $solitude;
            }
        }

        .bid-history-table-header,
        .bid-history-item {
            padding: 10px 0;
            border-bottom: 1px solid $alto;
        }

        .pdp-bidhistory {
            padding: 0;
        }

        #viewMore {
            text-align: left;
            letter-spacing: 0;
            color: $lilac-purple;
            opacity: 1;
            cursor: pointer;
            font-size: 17px;
            font-weight: 600;
            margin: 10px;

            .arrow-down {
                width: 17px;
                height: 17px;
            }

            .arrow-up {
                width: 17px;
                height: 17px;
                rotate: 180deg;
            }

            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }
    }

    #productBidhistory {
        border-bottom: 0;
    }

    .bid-history-drop-down {
        &:hover {
            text-decoration: none;
        }
    }

    .primary-images {
        .out-of-stock-badge {
            display: none;
        }
    }

    .pdp-pay-now-cta {
        a {
            &.pay-now.registered.green {
                display: block;
                padding: 0;
                background: $night-rider;
                line-height: 45px;
                color: $white;

                @include media-breakpoint-down(sm) {
                    line-height: 42px;
                }
            }
        }

        &.pdp-paynow-btn {
            margin-top: 13px;

            @include media-breakpoint-down(sm) {
                display: flex;
                margin-top: 20px;
                padding: 0;
            }

            a {
                &.pay-now {
                    background: $night-rider;
                    color: $solitude2;
                    border-radius: 3px;
                    max-width: 199px;
                    text-align: center;
                    height: 48px;
                    text-decoration: none;
                    font-family: $opensans-bold;
                    font-size: 18px;
                    text-transform: uppercase;
                    display: block;

                    @include media-breakpoint-down(sm) {
                        background: $night-rider;
                        color: $solitude2;
                        border-radius: 3px;
                        width: 165px;
                        text-align: center;
                        height: 40px;
                        text-decoration: none;
                        font-family: $opensans-bold;
                        text-transform: uppercase;
                        display: block;
                        font-size: 14px;
                    }
                }

                &.disabled {
                    background: white;
                    color: #ccc;
                    border-radius: 3px;
                    max-width: 199px;
                    text-align: center;
                    height: 48px;
                    line-height: 36px !important;
                    text-decoration: none;
                    font-family: $opensans-bold;
                    text-transform: uppercase;
                    display: block;
                    border: 1px solid #ccc;
                    cursor: default;
                    font-size: 18px;

                    @include media-breakpoint-down(sm) {
                        background: white;
                        color: #ccc;
                        border-radius: 3px;
                        width: 165px;
                        text-align: center;
                        height: 40px;
                        line-height: 30px !important;
                        text-decoration: none;
                        font-family: $opensans-bold;
                        text-transform: uppercase;
                        display: block;
                        border: 1px solid #ccc;
                        cursor: default;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .paynow-for-desktop {
        display: block;
    }

    .paynow-for-mobile {
        display: none;
    }
}

@include media-breakpoint-down(sm) {
    .paynow-for-desktop {
        display: none;
    }

    .paynow-for-mobile .pdp-pay-now-cta {
        display: block;
        flex-basis: 50%;
    }

    .pdp-pay-now-cta {
        height: 8%;
        bottom: 0;
        background: white;
        width: -webkit-fill-available;
        left: 0;
    }

    .paynow-for-mobile .pdp-paynow-btn {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        margin: 10px 0 5px 0 !important;
        align-content: space-around;
    }
}

.auction-modal {
    .header-title {
        font-family: $opensans-regular;
    }

    .modal-body p {
        margin: 0;
    }

    .bid-step-limit {
        font-weight: bold;
        color: $navy-blue;
    }

    .btn {
        font-size: 13px;
        font-weight: bold;
        border-radius: 0;
        text-transform: uppercase;
        line-height: 30px;
        padding: 0 1em;
        margin: 0;
        border: 1px solid transparent;
    }

    .btn-bid {
        background: $navy-blue;
        color: $white;
        border: 1px solid $navy-blue;
    }

    .btn-cancel {
        background: $grey1;
        background: linear-gradient($grey1, $grey3);
        color: $danger;
        border: 1px solid $danger;
        margin-left: 15px;
    }

    .btn-close,
    .btn-ok {
        background: $grey1;
        background: linear-gradient($grey1, $grey3);
        color: $night-rider;
        border: 1px solid $dusty-gray;
    }

    .btn-paynow {
        color: $navy-blue;
    }

    .bid-amount-wrap {
        position: relative;
        margin: 0;

        .currency-symbol {
            position: absolute;
            left: 0;
            font-size: 14px;
            line-height: 33px;
            margin-left: 5px;
        }

        input.bid-amount {
            margin: 0;
            padding: 0 10px 0 20px;
        }
    }

    input.bid-amount {
        line-height: 30px;
        width: 70px;
        border-radius: 0;
        border: 1px solid $dusty-gray;
        padding: 0;
        text-align: center;
        font-size: 14px;
        margin: 0 10px;

        &[type=number] {
            -moz-appearance: textfield;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }

        &[disabled] {
            border-color: $very-light-grey;
            background: $very-light-grey;
        }
    }

    &#maxBidModal {
        .modal-header .close {
            margin-right: 5px;
            margin-top: 5px;
            padding-top: 3px;
            padding-right: 5px;
            width: 38px;
            font-size: 25px;
        }

        @include media-breakpoint-down(md) {
            .modal-dialog {
                margin: 1.75rem auto;
                transform: none;
            }
        }

        .modal-body {
            text-align: center;
        }

        input.bid-amount {
            text-align: left;
        }
    }
}

.bid-watchlist-table {
    .bid-table-item {
        display: flex;
        text-align: left;

        .bid-status {
            margin-top: 10px;
        }

        .won {
            color: $bidstatus-green;
        }

        .sold {
            color: $bidstatus-red;
        }
    }

    .watchlist-bid-label {
        text-align: center;
        font-size: 12px;
        display: block;

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    .time-left-auction {
        color: $black;
    }

    .product-id-ra-badgename-watchlist {
        margin-top: 5px;

        .product-id {
            padding-top: 3px;
        }

        .ra-badge-container-watchlist {
            margin-left: 10px;
            margin-top: 0;

            .pdpbadge-name {
                padding: 2px 18px 2px 8px !important;
            }
        }
    }

    .auctionTimer {
        font-weight: bold;
        font-size: 14px;
        color: $btn-red;
        white-space: nowrap;
        background: #f3f3f3;
        height: 35px;
        border-radius: 4px;

        @include media-breakpoint-up(lg) {
            font-size: 12px;
        }
    }

    .bidTimer {
        font-family: $opensans-bold;
    }

    .item-name {
        &:hover {
            color: $navy-blue;
            text-decoration: underline;
        }
    }

    .item-name,
    .product-id {
        font-size: 13px;

        @include media-breakpoint-up(lg) {
            font-size: 11px;
        }
    }

    .quickview::after {
        height: 60%;
    }

    .imgUpperTxt-quickview {
        background: $black none repeat scroll 0 0;
        opacity: 0.5;
        font-size: 12px;
        color: $white;
        left: -5px;
        padding: 4px 0;
        position: absolute;
        text-align: center;
        text-transform: uppercase;
        top: 40%;
        width: 100%;
        line-height: 18px;
        display: none;
    }

    .product-image {
        &:hover {
            .imgUpperTxt-quickview {
                @include media-breakpoint-up(lg) {
                    display: block;
                }
            }
        }
    }

    .bid-buttons-wrap {
        .bid-buttons-value {
            display: flex;
            justify-content: space-between;
            margin: 10px 0;
        }

        button.send-bid {
            width: 100%;
            border-radius: 4px;
            border: 0;

            @include media-breakpoint-down(md) {
                height: 39px;
            }
        }

        [class^=send-1-bid-],
        [class^=send-2-bid-],
        [class^=send-5-bid-] {
            width: 30%;
            padding: 6px 0;
            font-size: 14px;
            border-color: $alto-secondary;
            border-radius: 4px;
            background: $pg-lightgray;
            box-shadow: none;
            text-shadow: none;

            &:hover {
                background-color: $grey3;
                border-color: $grey4;
            }

            &.selected {
                background-color: $grey3;
                border-color: $grey4;
            }
        }

        .max-bid-button {
            margin-right: 10px;
            text-transform: uppercase;
            font-size: 13px;
            border-radius: 0;

            &:disabled {
                background-color: $lilac-purple;
            }
        }

        .bid-now-button {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 13px;
            border-radius: 0;

            &:hover {
                background: $green;
            }
        }
    }

    .bid-amount-wrap {
        background-color: $concrete;
        border-radius: 4px;

        .bid-amount {
            text-align: center;
            display: block;
            width: 100%;
            height: 38px;
            padding: 6px 12px;
            font-size: 16px;
            line-height: 1.42857143;
            color: $emperor;
            background-color: $white;
            border: 1px solid $alto-secondary;

            @include media-breakpoint-up(lg) {
                font-size: 14px;
                max-width: 102px;
            }
        }

        .btn-minus,
        .btn-plus {
            width: 64px;
            background: transparent;
            font-weight: 500;
            border: none;
            padding: 5px 10px;
            cursor: pointer;

            @include media-breakpoint-up(md) {
                width: 44px;
            }
        }

        @include media-breakpoint-down(md) {
            .btn-plus {
                font-size: 20px;
            }

            .btn-minus {
                font-size: 25px;
                line-height: 19px;
            }
        }

        .bid-amount::-webkit-outer-spin-button,
        .bid-amount::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    .auction-won {
        font-size: 14px;
        font-weight: 600;

        .you-won-text {
            font-size: 14px;
            font-weight: 700;
            color: $lilac-purple;
        }

        .btn.pay-now {
            margin-top: 10px;
            border-radius: 4px;
            color: $white;
            background-color: $green-light-1;
            text-transform: uppercase;
        }
    }

    .bid-status-ended {
        text-align: center;
    }

    .auction-sold {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 165px;
        height: 45px;
        border-radius: 4px;
        margin: 46px auto;
        color: $light-blue2;
        font-weight: 700;
        border: 1px solid $light-gray2;
    }

    .bidder-labels {
        width: 100%;

        [class^=highest-bidder-label-] {
            font-size: 13px;
            font-weight: bold;
            padding: 3px 5px;
            color: $night-rider;
            background-color: $label-yellow;
            border: 1px solid $label-yellow;
            text-transform: uppercase;

            @include media-breakpoint-up(lg) {
                font-size: 11px;
            }
        }

        [class^=outbid-bidder-label-] {
            font-size: 11px;
            font-weight: bold;
            padding: 3px 5px;
            color: $btn-red;
            border: 2px solid $btn-red;
            text-transform: uppercase;

            @include media-breakpoint-up(lg) {
                font-size: 11px;
            }
        }
    }

    .remove-from-watchlist {
        align-self: center;
        background: none;
        border: none;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        font-size: 20px;
        color: $grey4;
        font-weight: bold;

        @include media-breakpoint-up(lg) {
            height: 30px;
            width: 30px;
        }

        &:hover {
            @include media-breakpoint-up(lg) {
                background-color: $btn-red;
                color: $white;
            }
        }
    }

    [class^=current-bid-with-currency-],
    [class^=current-bid-quantity-] {
        display: block;
        width: 100%;
        color: $btn-red;
    }

    [class^=your-bid-with-currency-],
    [class^=current-bid-with-currency-],
    [class^=your-max-bid-with-currency-],
    [class^=current-bid-quantity-] {
        font-size: 16px;
        display: flex;
        justify-content: center;
        font-weight: bold;

        @include media-breakpoint-up(lg) {
            display: block;
        }
    }

    .item-your-bid {
        text-align: left;
    }
}

.watchlist-page-previous,
.watchlist-page-last {
    min-width: 50px;

    @include media-breakpoint-up(lg) {
        border: none;
    }
}

.bid-history-container {
    .auctions-pagination {
        .pagination {
            li {
                a {
                    &:hover {
                        background: $picton-blue;
                        color: $white;
                        text-decoration: none;
                        border-radius: 0;
                    }
                }
            }
        }
    }

    .watchlist-paging-top {
        display: flex;
        display: nowrap;

        .auctions-pagination {
            align-items: flex-end;
            justify-content: flex-end;

            @include media-breakpoint-up(lg) {
                align-items: center;
                justify-content: flex-start;
            }
        }

        .auctions-pagination-details {
            align-items: flex-start;

            @include media-breakpoint-up(lg) {
                align-items: center;
            }

            .showing-actual-auctions {
                padding: 0;

                @include media-breakpoint-up(lg) {
                    padding: 0 10px 0 0;
                }
            }

            .auctions-per-page-wrap {
                padding: 0;
                margin-top: 40px;

                @include media-breakpoint-up(lg) {
                    padding: 0 0 0 10px;
                    margin: 0;
                }
            }
        }
    }
}

.maxBid {
    text-align: left;
    text-decoration: underline;
    font-family: $opensans-bold;
    font-size: 11px;
    letter-spacing: 0;
    color: $gray10;
    opacity: 1;
    margin: 0 0 25px 40px;
    display: flex;
    height: 35px;
    width: 95px;

    @include media-breakpoint-down(sm) {
        margin: 0 0 25px 40px;
    }
}

.maxBid:hover .maxBidTooltiptext {
    visibility: visible;
}

.maxBid .maxBidTooltiptext {
    visibility: hidden;
    background-color: $white;
    color: $night-rider;
    box-shadow: 0 3px 6px #00000050;
    border: 1px solid $light-gray2;
    opacity: 1;
    text-align: center;
    border-radius: 3px;
    padding: 14px 16px 0 15px;
    position: absolute;
    z-index: 99;
    top: 95%;
    margin-left: -105px;
    font-size: 16px;
    height: 200px;
    width: 316px;
    font-family: "OpenSans-Bold", arial, sans-serif;

    @include media-breakpoint-down(sm) {
        left: 60%;
        width: 240px;
        height: auto;
    }
}

.maxBidRAtooltip {
    width: 20px;
    background-color: $white;
    display: block;
    margin-left: 128px;
    height: 18.4px;
    color: black;
    z-index: 1;
    position: absolute;
    opacity: 1 !important;
    rotate: 43deg;
    top: -10px;
    border: 1px solid $light-gray2;
    border-bottom-width: 0;
    border-right-width: 0;
}

/** --auctionProduct styles-- **/
.CRO_generic_color {
    @mixin auction-product-details() {
        .play-video-btn {
            a {
                color: $primarybtn-color;
            }

            .video-label {
                color: $primarybtn-color;
            }
        }

        .owl-carousel .owl-nav--secondary {
            .owl-prev,
            .owl-next {
                &:hover {
                    color: $primarybtn-color;
                }
            }
        }

        .bx-wrapper .bx-controls-direction a {
            &:hover {
                color: $primarybtn-color;
            }
        }

        .save-value-value {
            color: $primarybtn-color !important;
        }

        .auction-user-innteraction {
            .btn-max-bid {
                color: $white;
                line-height: 10px;
                border-radius: 3px;
                opacity: 1;
                background: $primarybtn-color;
                border: 1px solid $primarybtn-color;

                &:hover {
                    background: $primarybtn-color;
                }
            }

            .btn-bid-now {
                margin-left: 25px;
                border-radius: 3px;
                opacity: 1;
                background: $primarybtn-color;
                border: 1px solid $primarybtn-color;
                color: $white;

                &:hover {
                    background: $primarybtn-color;
                }
            }

            .login-to-bid {
                border: 1px solid $primarybtn-color;
                color: $primarybtn-color;

                &:hover {
                    background: $primarybtn-color;
                    color: $white;
                }
            }
        }

        .question {
            button {
                border: 1px solid $primarybtn-color;

                &:hover {
                    background: $primarybtn-color;
                    color: $white;
                }
            }
        }
    }

    .pdp.auction-pdp input.amountInput:focus-visible {
        outline: 2px solid black;
    }

    #quickViewModal .auction-quickview .auction-user-innteraction .login-to-bid,
    #quickViewModal .auction-quickview .question button {
        border-color: $primarybtn-color;
        color: $primarybtn-color;

        &:hover,
        &:focus,
        &:active {
            background: $primarybtn-color;
            color: $white !important;
        }
    }

    #quickViewModal .auction-quickview .bid-history-product-details-wrap .nav-tabs .nav-link:hover,
    #quickViewModal .auction-quickview .bid-history-product-details-wrap .nav-tabs .nav-link.active {
        border-top-color: $primarybtn-color !important;
    }

    .pdp.auction-pdp .current-bid-value,
    .pdp.auction-pdp .highest-bidder-name,
    .pdp.auction-pdp .order-status-name,
    .pdp.auction-pdp .save-value-value,
    .pdp.auction-pdp .ra-warn-msg {
        color: $primarybtn-color;
    }

    .pdp.auction-pdp .save-value-label,
    .pdp.auction-pdp .estimated-price-label {
        font-weight: 600;
        text-decoration: none;
        color: $primarybtn-color;
        font-size: 16px;
    }

    .pdp.auction-pdp .product-content-section h2.section-header,
    .pdp.auction-pdp .product-content-section h3.product-highlights-header {
        border-bottom-color: $primarybtn-color;
    }

    .pdp.auction-pdp .question button,
    .pdp.auction-pdp .auction-user-innteraction .login-to-bid {
        border-color: $primarybtn-color;
        color: $primarybtn-color;

        &:hover,
        &:focus,
        &:active {
            background: $primarybtn-color;
            color: $white;
        }
    }

    .pdp.auction-pdp .auction-user-innteraction .btn-bid-now {
        border-color: $primarybtn-color;
        color: $primarybtn-color;

        &:hover,
        &:focus,
        &:active {
            background: $primarybtn-color;
            color: $white;
        }
    }

    .pdp.auction-pdp .bid-history-product-details-wrap .nav-tabs .nav-link:hover,
    .pdp.auction-pdp .bid-history-product-details-wrap .nav-tabs .nav-link.active {
        border-top-color: $primarybtn-color;

        @include media-breakpoint-down(sm) {
            background: $primarybtn-color !important;
        }
    }

    .pdp.auction-pdp .auction-user-innteraction .btn-max-bid,
    .pdp.auction-pdp .auction-user-innteraction .btn-bid-now {
        background: $primarybtn-color;
        border-color: $primarybtn-color !important;
        color: $white;
        height: 48px;
        font-size: 18px;
    }

    #quickViewModal .auction-quickview .play-video-btn .video-icon {
        color: $primarybtn-color;
    }

    .auction-pdp .product-number-rating {
        @include media-breakpoint-down(sm) {
            top: 0 !important;
        }
    }

    #playPauseBtn {
        color: #ffca01 !important;
        background: none !important;
        border-color: #ffca01 !important;
    }

    .bid-watchlist-table {
        [class^=current-bid-with-currency-],
        [class^=current-bid-quantity-] {
            color: $primarybtn-color !important;
        }

        .bidder-labels {
            [class^=highest-bidder-label-] {
                background: $gray10 !important;
                opacity: 0.7 !important;
                border-color: $primarybtn-color !important;
                color: $white;
            }
        }

        .bid-buttons-wrap {
            .bid-now-button {
                background: $primarybtn-color !important;
                border-color: $primarybtn-color !important;

                &:hover,
                &:active {
                    border-color: $primarybtn-color !important;
                }
            }
        }

        .item-name {
            &:hover {
                color: $primarybtn-color !important;
                text-decoration: underline !important;
            }
        }
    }

    .bid-history-container .bid-table-item .item-bid-status.won {
        color: $primarybtn-color;
    }

    .bid-history-container .bid-table-item .item-your-bid {
        text-align: left;
    }

    .check-question {
        color: $primarybtn-color;
    }
}

.end-date-setup {
    text-align: left;
    font-size: 13px;
    font-family: $opensans-regular;
    letter-spacing: 0;
    color: $night-rider;
    padding: 14px 0 0 0;
}

.check-question {
    color: $blue-primary;
}

.check-question .tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: $white;
    color: $night-rider;
    box-shadow: 0 3px 6px #00000050;
    border: 1px solid $light-gray2;
    opacity: 1;
    text-align: center;
    border-radius: 3px;
    padding: 14px 0;
    position: absolute;
    z-index: 1;
    top: 150%;
    left: 60%;
    margin-left: -60px;
    font-size: 16px;
    height: 48px;
    font-family: "OpenSans-Bold", arial, sans-serif;

    @include media-breakpoint-down(sm) {
        left: 60%;
        width: 240px;
        height: auto;
    }
}

.RAtooltip {
    width: 20px;
    background-color: $white;
    display: block;
    margin-left: 152px;
    height: 18.4px;
    color: black;
    z-index: 1;
    position: absolute;
    opacity: 1 !important;
    rotate: 43deg;
    top: -10px;
    border: 1px solid $light-gray2;
    border-bottom-width: 0;
    border-right-width: 0;
}

.check-question .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 108%;
    left: 50%;
    margin-left: -12px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent $white transparent;
}

.check-question:hover .tooltiptext {
    visibility: visible;
}

.help-question-mark {
    margin-right: 3px;
}

.auction-bid-days {
    font-size: 15px;
    text-align: center;
    font-family: $opensans-regular;
    letter-spacing: 0;
    color: #6f6f6f;
    opacity: 1;
    margin-top: 15px;
    margin-right: 15px;
    font-weight: normal;
}

.auction-dynamic-inforamtion {
    margin-top: 15px;

    @include media-breakpoint-down(xs) {
        margin: 15px 0;
    }
}

.product {
    margin-bottom: 15px;
}

.end-date-setup-mobile {
    display: none;

    @include media-breakpoint-down(sm) {
        display: block;
        margin-top: 5px;
    }
}

.sticky {
    @include media-breakpoint-down(sm) {
        display: none !important;
    }
}

.est_mobile_view {
    @include media-breakpoint-down(sm) {
        padding: 12px 35px 12px 35px !important;
    }
}

.mobile-view {
    display: none;

    @include media-breakpoint-down(sm) {
        display: block !important;
    }
}

.non-mobile-view {
    @include media-breakpoint-down(sm) {
        display: none !important;
    }
}

.sticky-bottom-cta {
    padding: 0 15px;
    width: 415px;

    @include media-breakpoint-down(xs) {
        position: fixed;
        bottom: 0;
        z-index: 1;
        background-color: white;
        border-radius: 20px 20px 0 0;
        left: 0;
        width: -webkit-fill-available;
        padding: 15px 10px 0 10px;
        box-shadow: 0 -2px 3px rgb(50 50 50 / 12%);
        height: 110px;
    }

    &.quickview-cta {
        @include media-breakpoint-down(sm) {
            height: 125px;
            margin-top: 0 !important;
            padding: 0 25px;
        }
    }

    .bid-amount-wrap {
        background-color: $concrete;
        border-radius: 4px;

        .bid-amount {
            text-align: center;
            display: block;
            width: 100%;
            height: 38px;
            padding: 6px 12px;
            font-size: 16px;
            line-height: 1.42857143;
            color: $emperor;
            background-color: $white;
        }

        .btn-minus,
        .btn-plus {
            width: 64px;
            background: transparent;
            font-weight: 500;
            border: none;
            padding: 5px 10px;
            cursor: pointer;

            @include media-breakpoint-up(md) {
                width: 44px;
            }
        }

        @include media-breakpoint-down(md) {
            .btn-plus {
                font-size: 20px;
            }

            .btn-minus {
                font-size: 25px;
                line-height: 19px;
            }
        }
    }
}

.bid-now {
    @include media-breakpoint-down(sm) {
        position: relative;
        max-width: -webkit-fill-available;
        padding-top: 30px !important;
        width: -webkit-fill-available;
        // padding-bottom: 20px !important;
    }

    @include media-breakpoint-up(sm) {
        margin-top: 30px;
    }
}

// .bid-currency{
// @include media-breakpoint-down(sm){
//     position: absolute;
//     top: 40px;
//     left: 10px;
// }
// }

.form-check {
    width: fit-content;

    @include media-breakpoint-down(sm) {
        margin-top: 35px;
    }
}

.noscroll {
    width: 30px;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 5px;
    border-radius: 0;
    border: 1px solid $dusty-gray;

    &[type=number] {
        color: $dark-gunmetal;
        font-family: $opensans-bold;
        text-align: center;
        -moz-appearance: textfield;

        &:disabled {
            color: $night-rider;
            margin: 0;
            border: none;
            background: transparent;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}

.retail_saving {
    width: 45%;
}

.retail_saving_val {
    width: 55%;
}

.max-bid {
    background: #fff 0% 0% no-repeat padding-box;
    box-shadow: 0 3px 6px #00000050;
    border: 1px solid #d5d5d5;
    opacity: 1;
    width: 316px;
    height: 211px;
}

.more-details {
    border-radius: 3px;
    opacity: 1;
    color: #fff;
    height: 36px;
    width: 212px;
    margin: 22px 10px 10px 10px;
    border: 0 solid #333;
    background: #333;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: $opensans-bold;
    text-align: center;
}

.congrats-top {
    height: 25px;
    width: 25px;

    @include media-breakpoint-down(sm) {
        margin-top: 10px;
    }
}

.congratulations-msg {
    margin-top: 23px;

    @include media-breakpoint-down(sm) {
        margin-top: 20px;
    }
}

.congrats_msg {
    font-family: $opensans-bold;
    font-size: 16px;

    @include media-breakpoint-up(sm) {
        margin-left: 7px;
    }
}

.auctoin_won {
    font-family: $opensans-regular;
    font-size: 16px;
}

.divWidth {
    max-width: 55px;
}

.sold_out_margin {
    margin-top: 30px;
}

#bidModal {
    @include media-breakpoint-down(xs) {
        z-index: 112400 !important;
        margin-top: 90px;
    }
}

.sticky-top .sticky-scroll.is-sticky {
    @include media-breakpoint-down(sm) {
        top: 61px !important;
    }
}

.increment-message {
    font-size: 14px;
    color: $gray10;
}

.bid-history-table-header,
.bid-history-item {
    padding: 10px 0;
    border-bottom: 1px solid $alto;
}

#auctionVideo {
    width: 100%;
}

#video-on-load {
    display: block;
}

#maxBidModal {
    .modal-header {
        padding: 0;
        border-bottom: 0;
    }

    .error-msg {
        font-size: 16px;
        font-weight: 500;
    }

    .place-bid-buttons-container,
    .place-max-bid-buttons-container {
        gap: 10px;
    }

    .current-bid {
        background-color: $light-gray2;
        border-radius: 25px;
        color: $black;
        padding: 8px 0;
        width: 144px;
        height: 38px;
        margin: auto;
        font-size: 15px;
        font-weight: 500;
        white-space: nowrap;
    }

    .bid-button {
        background-color: #619e54;
    }

    .max-bid-button {
        background-color: $lilac-purple;
    }

    .bid-now-pdp {
        font-size: 16px;
        text-transform: uppercase;
        width: 265px;
        height: 40px;
        border-radius: 5px;
        border: 0;
        color: $white;
        cursor: pointer;
    }

    .place-bid-text {
        font-size: 16px;
        font-weight: 700;
    }

    .or-component {
        display: flex;
        align-items: center;
        margin: 20px 0;

        .line {
            flex-grow: 1;
            height: 1px;
            background-color: $light-gray2;
            margin: 0 10px;
        }

        .or {
            font-size: 14px;
            font-weight: 500;
        }
    }
}

@include media-breakpoint-down(sm) {
    .ra-filter-sort-mobile-wrap-sticky-header {
        .ra-filter-sort-mobile-wrap {
            margin: 0 !important;

            .ra-filter-sort-mobile {
                margin: 0 !important;
            }
        }

        .filter-sort-popup-open {
            width: auto !important;
            border: 1px solid $alto !important;

            &.rafilter-stickyheader {
                padding: 4px 6px;
                border-radius: 4px;

                img {
                    width: 27px;
                    height: 27px;
                }
            }
        }
    }

    .ra-filter-sort-mobile {
        .sticky-buttons {
            &.is-sticky-buttons {
                display: none !important;
            }
        }
    }
}

.max-bid-modal-plp {
    .bid-amount-wrap {
        background-color: $concrete;
        border-radius: 4px;
        .currency-symbol {
            position: absolute;
            left: 0;
            font-size: 18px;
            line-height: 33px;
            margin-left: 5px;
        }
        input.bid-amount {
            width: 100%;
            border-radius: 0;
            border: 1px solid $alto-secondary;
            text-align: center !important;
            font-size: 14px;
            &[type=number] {
                text-align: center;
                -moz-appearance: textfield;
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }
        }
        .btn-minus,
        .btn-plus {
            background: transparent;
            font-weight: 500;
            border: none;
            padding: 5px 18px;
            cursor: pointer;
        }
        @include media-breakpoint-down(md) {
            .btn-plus {
                font-size: 20px;
            }
            .btn-minus {
                font-size: 25px;
                line-height: 19px;
            }
        }
    }
}
