@import "~@sfra/scss/homePage";
@import "variables";
@import "components/carouselProduct";
@import "base/fonts";
@import "components/bx-slider";
@import "product/attributes";

#liveTvVideoSection {
    .fa-circle {
        font-size: 10px;
        color: $live-tv-red;
    }
}

.live-tv-pdp-top {
    .right {
        @include media-breakpoint-only(xs) {
            padding: 0;
        }

        .yotpo-sr-bottom-line-summary {
            align-items: center !important;
            @include media-breakpoint-up(md) {
                flex-direction: column !important;
                align-items: flex-start !important;
            }
        }

        .yotpo-reviews-star-ratings-widget {
            @include media-breakpoint-down(xs) {
                justify-content: center !important;
            }
        }

        .star-container {
            height: 16px !important;
            width: 12px !important;
        }

        .yotpo-sr-vertical-border {
            display: none !important;
        }

        .yotpo-sr-bottom-line-score {
            font-weight: 600 !important;
            font-size: 12px !important;
            line-height: 16px !important;
        }

        .yotpo-sr-bottom-line-right-panel::before {
            content: "\0028";
        }

        .yotpo-sr-bottom-line-right-panel::after {
            content: "\0029";
        }

        .yotpo-sr-bottom-line-right-panel {
            font-size: 12px !important;
            margin-bottom: 5px !important;
            font-weight: 700 !important;

            @include media-breakpoint-down(sm) {
                margin-left: 18px !important;
            }
        }

        .yotpo-sr-bottom-line-text {
            font-size: 12px !important;
            line-height: 17px !important;
            font-weight: 700 !important;
        }

        .yotpo-sr-bottom-line-new-review {
            display: none;
        }

        .livetv-topsection {
            display: flex;
            align-items: flex-start;

            .livetv-ratings {
                @include media-breakpoint-down(sm) {
                    justify-items: center;
                }
            }

            @include media-breakpoint-down(xs) {
                align-items: center;
                flex-direction: column;
            }

            .sold-so-far-section {
                justify-items: center;

                @include media-breakpoint-down(sm) {
                    display: flex;
                    display: -webkit-flex;
                    justify-content: center;
                    -webkit-justify-content: center;
                }

                .sold-so-far-box {
                    border: 1px solid #eb254b;
                    border-radius: 2px;
                    display: flex;
                    justify-content: center;
                    width: 210px;
                    
                    @include media-breakpoint-up(md) {
                        margin-bottom: 10px;
                        width: 100px;
                    }

                    @include media-breakpoint-up(xl) {
                        width: 125px;
                    }

                    img {
                        padding: 3px 2px 3px 8px;

                        @include media-breakpoint-down(sm) {
                            padding-left: 42px;
                        }
                    }

                    .sold-so-far-text {
                        padding: 2px 8px 2px 0;
                        font-size: 11px;

                        @include media-breakpoint-down(sm) {
                            padding-right: 42px;
                        }
                    }
                }
            }
        }
    }
}

.live-tv-pdp-section {
    .product-buttons {
        .fastbuy-wrapper {
            .fast-buy-button {
                .fast-buy-button-inner {
                    font-size: 13px !important;
                    font-weight: $font-wt-bold;
                }
            }
        }

        .livetv-add-to-cart {
            font-size: 13px !important;
            font-weight: $font-wt-bold;
            line-height: 2.4 !important;
            text-align: center;
            border-radius: 3px !important;
            cursor: pointer;
        }
    }

    .product-options {
        .product-quantity {
            flex-direction: row;
        }
    }

    .live-tv-what-is-fast-buy {
        margin-bottom: 7px;
        margin-top: -2px;
        font-weight: bold;
    }
}

.fastbuy-wrapper {
    .fast-buy-button {
        .fast-buy-button-inner {
            font-size: 12px !important;
        }
    }
}

#remove-from-wishlist-popup {
    .wishlist-popup-image {
        img {
            max-width: 100%;
        }
    }
}

.wishlist-widget-right a:not(.btn-primary, .btn-outline-primary) {
    color: $night-rider !important;
}

#homepage-SHOP_LC {
    .full-width-banner {
        &.livetv-wrap {
            background: $light-green1;
            padding: 28px 15px;

            .container {
                max-width: 100%;
            }

            .experience-component.experience-commerce_assets-liveTV {
                background: $light-green1 !important;

                #liveTvAuctionSection {
                    @include media-breakpoint-down(sm) {
                        background: $light-green1;
                        padding: 25px 15px;
                    }

                    #liveTvPDPSection.liveTvPDPSectionHeight {
                        min-height: 540px !important;
                    }
                }
            }
        }
    }

    .slick-prev,
    .slick-next {
        top: 110px;
        width: 20px;
        height: 40px;
        line-height: 22px;
        background: rgba(0, 0, 0, 0.1);
        color: $black;

        .fa {
            font-size: 26px;
            line-height: 1.5;
        }
    }

    .slick-prev {
        left: -10px;
        border-radius: 5px 0 0 5px;

        @include media-breakpoint-down(sm) {
            left: 15px;
        }
    }

    .slick-next {
        right: -10px;
        border-radius: 0 5px 5px 0;

        @include media-breakpoint-down(sm) {
            right: 15px;
        }
    }

    .slick-list {
        padding-left: 0 !important;
    }

    .live-tv-shop-recent-section .tile-badges-image .wishlist-air-container .on-air {
        display: none !important;
    }

    .live-tv-shop-recent-section .slick-recent-items-instance .product-grid.shop-last-grid + .product-grid.shop-last-grid {
        display: none !important;
    }

    .live-tv-shop-recent-section {
        .recentLiveTvProductTitle {
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 100%;
            margin: 0 auto;
            padding: 15px;

            @include media-breakpoint-up(sm) {
                padding: 25px 15px;
            }

            .left-content {
                .title-label {
                    font-family: $opensans-bold;
                    font-weight: 700;
                    font-size: 20px;
                    text-transform: uppercase;

                    @include media-breakpoint-up(sm) {
                        font-size: 24px;
                    }
                }
            }

            .live-tv-btn {
                display: block;
                padding: 0;
                margin: 0;
                background-color: transparent;
                color: $black;
                font-size: 14px;
                font-weight: 700;
                text-decoration: none;

                i {
                    height: auto;
                    width: auto;
                    background-color: transparent;
                    border-radius: 0;
                    margin-bottom: 0;
                    margin-left: 5px;
                    color: $black;
                    display: inline-flex;
                    flex-direction: row;
                }

                .btn-text {
                    font-size: 18px;
                }

                @include media-breakpoint-up(sm) {
                    min-width: 120px;
                    background: transparent;
                    color: $black;
                    padding: 0;
                    font-size: 18px;
                    border: none;
                    text-align: right;
                }
            }
        }

        .slick-recent-items-instance {
            padding: 0 7px;
        }

        .recently-on-air-products-height {
            min-height: 520px;

            @include media-breakpoint-down(md) {
                min-height: 500px;
            }
        }

        .actions:not(.homepageShopLast) {
            display: none !important;
        }

        .auction-timer-section {
            @include media-breakpoint-up(md) {
                font-size: 11px;
            }
        }
    }

    .product-grid .product-tile-wrapper {
        @include media-breakpoint-down(sm) {
            padding: 0 10px;
        }
    }

    .product-grid .product-tile-wrapper .product-tile {
        .wishlist-air-container .wl-toggle {
            right: 18px;
        }

        .tile-badges-image {
            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }

        .image-container {
            a .tile-image {
                margin: 10px auto 90px auto;
                max-width: 100%;

                @include media-breakpoint-down(sm) {
                    margin: 0 auto;
                    width: 100%;
                    max-width: 200px;
                }
            }

            .product-image-url {
                min-height: 220px;
            }
        }

        .select-item-container {
            width: 100%;
        }
    }

    .product-grid.shop-last-grid .shop-last-tile .product-tile {
        background-color: white;
        height: 100%;
        display: block !important;

        .tile-badges-image {
            .wishlistTile,
            .remove-wishlist-btn {
                top: 2px;
            }
        }

        .tile-details {
            margin-top: 18%;
            padding: 0 15px;
        }

        .tile-body {
            flex-direction: column;
            justify-content: space-between;
            min-height: 202px;
            @include media-breakpoint-down(sm) {
                min-height: 242px;
            }

            .select-item-wrapper {
                padding: 0 15px;

                .select-holder {
                    border: 1px solid #aaa;
                    border-radius: 2px;
                    margin: 0 0 12px 0;
                    height: 32px;
                    text-transform: uppercase;
                    padding: 0 25px 0 10px;
                    width: 100%;
                    font-size: 12px;
                    appearance: none;
                    background-image: linear-gradient(45deg, transparent 50%, #808080 50%), linear-gradient(135deg, #808080 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
                    background-position: calc(100% - 12px) calc(1em + 2px), calc(100% - 7px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
                    background-size: 5px 5px, 5px 5px, 0 1.5em;
                    background-repeat: no-repeat;

                    &:focus {
                        background-color: #fff;
                        border-color: #61d2ff;
                        outline: 0;
                        box-shadow: 0 0 0 0.2rem rgba(0, 161, 224, 0.25);
                    }
                }
            }
        }
    }

    .product-grid.shop-last-grid .shop-last-tile .product-tile .actions .quickview {
        background: rgba(0, 0, 0, 0.7);
        color: white !important;
    }

    .product-grid.shop-last-grid .shop-last-tile .product-tile .actions {
        top: -25px;
        bottom: unset;
        background: transparent;
        display: block;

        @include media-breakpoint-down(sm) {
            display: flex;
            position: static;
            order: 0;
            flex-direction: row !important;
            width: 100%;
        }

        .actions-row {
            margin: 0 0 6px !important;
            width: 100%;
            height: 27px;
            overflow: hidden;
            flex-basis: 50%;
            padding: 0 14px;

            @include media-breakpoint-down(sm) {
                min-width: 100%;

                &:first-child {
                    display: none !important;
                }
            }

            .quickview {
                line-height: 20px;
                height: 27px;
                font-size: 12px;
                margin: 0;
            }
        }

        .actions-row .cart-wrapper {
            line-height: 27px;
            height: 27px !important;
            margin-right: 3px;
            margin-left: 0;

            a {
                padding: 0;
                line-height: 27px;
                display: block;
                font-size: 12px;
                height: 27px;
            }
        }

        .actions-row .fastbuy-wrapper.fastbuy-wrapper {
            width: 100%;
            padding: 0;

            .fast-buy-button {
                margin-top: 0;
                margin-left: 0;
                height: 27px;
                line-height: 27px;

                .fast-buy-button-inner {
                    font-size: 12px;
                }
            }
        }

        &.homepageShopLast {
            @include media-breakpoint-down(sm) {
                display: flex;
            }
        }
    }

    .product-grid.shop-last-grid .shop-last-tile .product-tile:hover .homepageShopLast {
        display: flex;
    }
}

/** --CRO Home styles-- **/
.CRO_generic_color {
    .wishlist-widget {
        .wishlist-widget-right {
            .wishlist-carousel {
                .owl-stage-outer {
                    .owl-item {
                        .product-info {
                            .row {
                                .hover-section {
                                    background: rgba(207, 207, 207, 0.7);

                                    .buy-option {
                                        .buy-now {
                                            .add-to-cart-wishList {
                                                background: $primarybtn-color;
                                            }
                                        }

                                        .fast-buy {
                                            background: $primarybtn-color;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#homepage-SHOP_LC .live-tv.container {
    display: none;
}

img#selectedProductImage {
    cursor: pointer;
}
