html,
body {
    margin: 0;
    padding: 0;
    line-height: normal;
    display: initial;
}

body {
    overflow-x: hidden;
    overflow-y: scroll;
}

p {
    margin: 0;
}

.page-container {
    max-width: 1200px;
}

.region {
    position: relative;
    //margin-bottom: 0.75rem;
}

.region::before {
    display: block;
    content: "";
    width: 100%;
}

/*
    padding-top in percentages refers to parent width
    this allows us to specify an aspect-ratio
*/
.region_landscape-large::before {
    padding-top: calc(400 / 1024 * 100%);
}

.region_square::before {
    padding-top: calc(1 / 1 * 100%);
}

.region_landscape-small::before {
    padding-top: calc(1 / 2 * 100%);
}

.region_portrait-small::before {
    padding-top: calc(2 / 1 * 100%);
}

div.sf-interactions-proxyHoverContainer a {
    overflow: visible;
}

// .five9-chat {
//     padding: 5px 0;
//     font-size: 18px;
//     font-weight: 700;
//     color: #1e1e2c;
//     text-align: center;

//     span {
//         text-decoration: underline;
//         text-transform: uppercase;
//         cursor: pointer;
//     }

//     i {
//         display: none;
//         padding-right: 5px;

//         &::before {
//             content: "\f086";
//         }
//     }
// }

.experience-commerce_layout-purchaseFeedBanner {
    padding-top: 0 !important;
    margin-top: -35px !important;
}

footer {
    .five9-chat {
        text-align: left !important;
    }
}
