@import "../../../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.promotion-banner {
    .promotion-slide {
        padding-top: 4px;
        padding-bottom: 4px;
        font-weight: bold;
        @include media-breakpoint-down(sm) {
            font-size: 13px;
            padding-left: 10%;
            padding-right: 10%;
        }
    }

    .promotion-timer {
        color: red;
        padding-left: 20px;
        @include media-breakpoint-down(sm) {
            display: flex;
            padding-left: 0;
            justify-content: center;
        }
    }

    .promotion-link {
        text-decoration: underline;
        padding-left: 20px;
        @include media-breakpoint-down(sm) {
            display: flex;
            padding-left: 0;
            justify-content: center;
        }
    }
}
