@import "../base/fonts";
@import "components/formField";

.login-modal {
    padding-right: 0 !important;

    .modal-dialog {
        margin: 10px;
        max-width: 100%;
        @include media-breakpoint-up(md) {
            max-width: 918px;
            margin: 30px auto;
        }
        @include media-breakpoint-down(lg) {
            margin: 30px 30px;
        }
        @include media-breakpoint-up(lg) {
            margin: 30px auto;
        }
    }

    .modal-content {
        @include media-breakpoint-up(sm) {
            padding: 15px 25px;
        }
    }

    .modal-header {
        border-bottom: 1px solid $dove-gray;
        padding: 10px;
        padding-top: 10px;
        @include media-breakpoint-up(md) {
            padding-top: 0;
        }
    }

    .modal-body {
        padding: 15px;

        @include media-breakpoint-up(sm) {
            padding-top: 25px;
        }
    }

    .bottom-space-40 {
        margin-bottom: 40px;
    }

    .bottom-space-30 {
        margin-bottom: 30px;
    }

    .header-title {
        width: 100%;
        color: $dove-gray;
        font-family: $opensans-regular;
        font-size: 24px;
        font-weight: 400;
        line-height: 33px;
        padding: 7px 0 15px;
        margin-bottom: 0;
    }

    .close {
        position: absolute;
        right: 15px;
        font-family: $opensans-bold;
        font-size: 30px;
        line-height: 1;
        color: $dove-gray;
        opacity: 1;
        padding: 0 15px 0 0;
        margin-top: 0;

        @include media-breakpoint-up(sm) {
            margin-top: 5px;
            right: 40px;
        }
    }

    .card {
        border: none;

        @include media-breakpoint-up(sm) {
            padding: 0 0;
            margin-bottom: 0;
        }
    }

    .card-title {
        font-family: $opensans-bold;
        font-size: 18px;
        line-height: 27px;
        text-transform: uppercase;
        color: $dark-gunmetal;
        margin-bottom: 15px;
    }

    .card-text {
        font-size: 13px;
        line-height: 21px;
        margin-bottom: 10px;

        @include media-breakpoint-up(sm) {
            margin-bottom: 49px;
        }
    }

    .card-separator {
        border-bottom: 1px solid $dove-gray;

        @include media-breakpoint-up(sm) {
            &.card-form {
                border: none;
            }
        }
    }

    .top-space-30-sm {
        @include media-breakpoint-up(sm) {
            margin-top: 30px;
        }
    }

    .border-right-sm {
        @include media-breakpoint-up(sm) {
            border-right: 1px solid $dove-gray;
        }
    }

    .text-right-sm {
        @include media-breakpoint-up(sm) {
            text-align: right;
        }
    }

    .bottom-space-40-sm {
        @include media-breakpoint-up(sm) {
            margin-bottom: 40px;
        }
    }

    .bottom-space-30-xs {
        @include media-breakpoint-down(xs) {
            margin-bottom: 30px;
        }
    }

    .space-right-lg {
        @include media-breakpoint-up(lg) {
            padding-right: 45px;
        }
    }

    .space-left-lg {
        @include media-breakpoint-up(lg) {
            padding-left: 45px;
        }
    }

    .tooltip-text {
        position: absolute;
        z-index: 2;
        bottom: 100%;
        right: 15px;
        width: calc(100% - 30px);
        padding: 8px;
        font-family: $opensans-bold;
        font-size: 11px;
        background-color: $white;
        color: $gray;
        border-radius: 8px;
        border: 1px solid $gray;
        opacity: 0;
        visibility: hidden;
        transition: opacity 1s;

        &::before {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            border-top: 10px solid $gray;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            margin-left: 10px;

            @include media-breakpoint-up(sm) {
                left: auto;
                margin-left: 0;
            }

            @include media-breakpoint-up(lg) {
                margin-left: -10px;
            }
        }

        @include media-breakpoint-up(sm) {
            width: 350px;
            right: -15px;
        }

        @include media-breakpoint-up(md) {
            right: -30px;
        }

        @include media-breakpoint-up(lg) {
            right: -45px;
        }

        .tooltip-paragraph {
            margin-bottom: 5px;
        }
    }

    .nav-tabs {
        border-bottom: 0;
        margin-left: 15%;
        @include media-breakpoint-up(md) {
            margin-left: 37%;
        }

        .nav-link {
            padding: 0;

            &.active {
                border-bottom: 3px solid $lilac-purple;
            }
        }
    }

    .tab-content {
        width: 100%;

        > .tab-pane {
            display: none;
        }

        > .active {
            display: block;
            @include media-breakpoint-up(md) {
                display: flex;
            }
        }
    }

    form.registration {
        width: 100%;
    }

    .nav-link {
        border-bottom: none;
    }

    .registration .form-control-label {
        left: 22px;
    }

    .form-control {
        border-color: $dove-gray;
        height: 43px;
        border-radius: 0;
    }

    ._msg {
        p {
            font-size: 10px;
            color: black;
        }
    }
}

.registered-by-phone {
    &:hover {
        ~ .tooltip-text {
            visibility: visible;
            opacity: 1;
        }
    }
}

.login-modal-link {
    font-family: $opensans-bold;
    font-size: 14px;
    color: $lilac-purple;
    white-space: nowrap;
    font-weight: normal;
    text-decoration: underline;

    @include media-breakpoint-up(sm) {
        display: block;
        margin: 0 auto;
    }

    &:hover {
        color: $lilac-purple;
        text-decoration: none;
    }

    &:first-child {
        @include media-breakpoint-down(xs) {
            margin-bottom: 10px;
        }
    }
}

.login-modal-text {
    font-family: $opensans-light;
    font-size: 14px;
    color: $dove-gray;
}

.border-title {
    font-family: $opensans-regular;
    font-size: 20px;
    color: $dove-gray;
    position: relative;
    text-align: center;

    &::before {
        content: "";
        width: 100%;
        border-bottom: 1px solid $dove-gray;
        position: absolute;
        top: 50%;
        left: 0;
    }

    span {
        position: relative;
        padding: 0 10px;
        background-color: $white;
        z-index: 2;
        color: black;
        font-size: 14px;
        font-weight: 700;
    }
}

.social-icons-title {
    font-family: $opensans-bold;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    color: black;
}

.social-icons-list {
    /* .social-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        font-size: 30px;
        line-height: 50px;
        text-align: center;
        color: $white;
        background-color: $picton-blue;
    } */

    .social-icon {
        display: inline-block;
        width: 191px;
        height: 45px;
        background-repeat: no-repeat;
        box-shadow: none;
        border: 0;

        &.oauth-twitter {
            background-position: center;
            background-image: url("../images/twitter.svg");
        }

        &.oauth-facebook {
            background-position: center;
            background-image: url("../images/facebook-login.svg");
        }

        &.oauth-google {
            background-position: center;
            background-image: url("../images/google-login.svg");
            width: 191px;
            height: 45px;
        }

        i {
            visibility: hidden;
        }
    }
}

/** --Login Modal styles-- **/
.CRO_generic_color {
    .login-modal-link {
        color: $primarybtn-color;

        &:hover {
            color: $primarybtn-color;
        }
    }
}

.password-div {
    position: relative;

    .secureView {
        position: absolute;
        top: 40px;
        right: 15px;

        .fa {
            font-size: 18px;
            color: black;
        }
    }
}
