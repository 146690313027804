.auction-top-links-wrap {
    .container {
        background: $white;
        white-space: nowrap;
        text-align: center;
    }

    @include media-breakpoint-down(sm) {
        .container {
            overflow: auto;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
        }

        /* Hide scrollbar for Chrome, Safari and Opera */
        .container::-webkit-scrollbar {
            display: none;
        }

        .horizontal-scroll-menu {
            overflow: auto;
            padding: 0;
        }
    }

    &.is-sticky {
        .container {
            box-shadow: 0 12px 20px -12px rgb(0 0 0 / 31%);
        }

        .auction-top-link {
            padding: 10px 5px;
        }
    }

    .auction-top-link {
        padding: 10px 5px;

        @include media-breakpoint-down(sm) {
            padding: 10px 5px 0;
        }

        .nav-link {
            border-radius: 4px;
            text-align: center;
            font-size: 14px;
            display: block;
            color: $night-rider;
            border: 1px solid $alto;
            padding: 9px 0;
            font-family: $opensans-bold;
            line-height: 1.4;

            @include media-breakpoint-down(md) {
                font-size: 12px;
            }

            &:hover {
                text-decoration: none;
                border: 1px solid $lilac-purple;
            }

            &.watch-list.registered.blue {
                background: $lilac-purple;
                color: $white;
            }

            &.pay-now.registered.green,
            &.payButton.registered.green {
                background: $light-green;
                color: $white;
            }

            &.how-it-works {
                color: $night-rider;
            }

            .autopay-off {
                border-radius: 60px;
                padding: 1px 8px 2px;
                background-color: $silver;
                font-size: 10px;
                color: $white;
                margin-left: 3px;

                @include media-breakpoint-down(md) {
                    font-size: 8px;
                }
            }

            .autopay-on {
                border-radius: 60px;
                padding: 1px 8px 2px;
                background: $light-green;
                color: $white;
                font-size: 10px;
                margin-left: 3px;

                @include media-breakpoint-down(md) {
                    font-size: 8px;
                }
            }

            &.autopay-cta-link {
                padding: 9px 6px !important;
                line-height: 1.4;
            }

            .watchlist-items-quantity {
                color: $white;
            }
        }

        .auction-paynow-items,
        .watchlist-items-quantity {
            position: absolute;
            top: 0;
            right: 0;
            background: $red-coupon-label-color;
            padding: 1px 6px;
            border-radius: 4px;
        }
    }
}
