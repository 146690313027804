$minicart-width: 350px;

.minicart {
    .popover,
    .popover-ra {
        display: block;
        position: fixed;
        top: 0;
        right: -420px;
        max-width: $minicart-width;
        min-width: $minicart-width;
        height: 100%;
        transition: all 0.5s ease-in;
        z-index: 1050;
        border: none;
        box-shadow: 0 5px 6px rgba(0, 0, 0, 0.3);
        margin-top: 0;
        margin-right: 0;
        padding: 0;

        &.show {
            right: 0;
        }

        @include media-breakpoint-down(sm) {
            max-width: 320px;
            min-width: 320px;
        }

        .approaching-discount {
            padding-left: 45px;
            margin: 0 8px;

            .js-free-shipping-banner-inner {
                font-size: 12px;

                @include media-breakpoint-down(sm) {
                    font-size: 11px;
                }
            }

            .approaching-free-shipping {
                height: 10px;
                width: 245px;
            }

            &::before {
                background-size: 45px;
                width: 45px;
                height: 45px;
            }

            &.in-progress {
                &::before {
                    background-size: 45px;
                    width: 45px;
                    height: 45px;
                }
            }
        }
    }

    .minicart-placeholder {
        @keyframes PlaceholderPulse {
            0% { background-position: 0% 50% }
            50% { background-position: 100% 50% }
            100% { background-position: 0% 50% }
        }

        @keyframes DotPulse {
            0% { opacity: 1 }
            50% { opacity: 0 }
            100% { opacity: 1 }
        }

        .pulse {
            background: linear-gradient(270deg, #e2e2e2, #bebebe);
            background-size: 400% 400%;
            animation: PlaceholderPulse 2s ease infinite;
        }

        .dot {
            animation: DotPulse 2s ease infinite;

            &.delay-1 {
                animation-delay: 333ms;
            }

            &.delay-2 {
                animation-delay: 666ms;
            }
        }

        .timer {
            background: linear-gradient(270deg, #f8e2e6, #fdc2cd);
            background-color: #f8e2e6;
            border: 1px solid #eb254b;
            border-radius: 3px;
            height: 69px;
            background-size: 400% 400%;
            animation: PlaceholderPulse 2s ease infinite;
        }

        .checkout-btn {
            width: 130px;
            height: 35px;
            font-size: 15px;
            color: black;
        }

        .item,
        &.item {
            .image {
                width: 5.625rem;
                height: 5.625rem;
                border-radius: 0.5rem;
            }

            .text {
                height: 1rem;
                margin-top: 1rem;
                border-radius: 0.5rem;
            }
        }

        &.empty .minicart-main-info,
        &.raising .timer-container {
            display: none;
        }
    }

    .cart {
        padding: 0;
        background-color: $white;
    }

    .sub-total-label {
        font-family: $opensans-bold;
        font-size: 16px;
        margin-bottom: 10px;
    }

    .checkout-btn {
        margin-bottom: 5px;
    }

    .product-summary {
        max-height: calc(100vh - 200px);
        padding: 10px 0;
        padding-bottom: 40px;
        margin-right: 0;

        &.budget-pay-space {
            max-height: calc(100vh - 175px);
        }

        .minicart-checkout-sticky {
            @include media-breakpoint-down(sm) {
                position: fixed;
                background-color: $white;
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
                top: auto;
                bottom: 0;
                max-width: 320px;
                min-width: 320px;
                padding: 10px;
            }

            .subtotal-label {
                justify-content: space-between;
                font-size: 13px;
                font-family: $opensans-bold;
                padding: 0 10px;
            }

            .minicart-checkoutbtn-sticky {
                background-color: $lilac-purple;
                width: 100%;
                padding: 7px 0;
                color: $white;
                text-transform: uppercase;
                border-radius: 4px;
            }

            .minicart-moneyback-gurantee {
                font-size: 10px;
                font-family: $opensans-bold;
                color: $black;
                text-align: center;
            }
        }
    }

    .product-summary:has(.minicart-checkout-sticky) {
        @include media-breakpoint-down(sm) {
            padding-bottom: 120px;
        }
    }

    .product-summary::-webkit-scrollbar {
        width: 6px;
    }

    .product-summary::-webkit-scrollbar-track {
        background-color: $gray-4;
    }

    .product-summary::-webkit-scrollbar-thumb {
        background-color: $gray;
    }

    .amount-text {
        font-family: $opensans-bold;
        font-size: 9px;
        margin-bottom: 0;

        @include media-breakpoint-up(sm) {
            font-size: 14px;
        }
    }

    .amount {
        font-size: 14px;
    }

    .budgetpay-text {
        width: 100%;
        display: inline-block;
        font-size: 12px;
        font-style: italic;
        margin-bottom: 0;
    }

    .out-of-stock {
        display: inline-block;
        font-family: $opensans-bold;
        margin: 10px 0;

        + .remove-btn {
            margin-bottom: 0;
        }
    }

    .card {
        border-width: 0 0 1px 0;
    }

    .card-body {
        padding: 0;
    }

    .item-image {
        width: 30%;
        margin-right: 0;
        padding: 5px;
    }

    .main-info-container {
        width: 70%;
    }

    .price-container {
        align-items: center;

        .old-value {
            display: none;
        }
    }

    .price-item {
        font-family: $opensans-bold;
        font-size: 13px;
        margin-top: 10px;
        margin-bottom: 5px;
    }

    .quantity-label {
        font-size: 13px;
    }

    .line-item-name {
        font-family: $opensans-regular;
        font-size: 12px;
        margin: 0 0 20px;
        font-weight: 400;
        text-decoration-line: none;
        white-space: normal;

        a:hover {
            text-decoration-line: none;
        }
    }

    .line-item-unit-price {
        p {
            margin: 0;
        }
    }

    .quantity-form {
        &::after {
            position: relative;
            pointer-events: none;
            content: "▼";
            font-size: 8px;
            right: 20px;
            top: -6px;
        }
    }

    .quantity {
        width: auto;
        height: 25px;
        padding-top: 0;
        padding-bottom: 0;
        font-size: 14px;
        border-color: $black;
        border-radius: 0;
        min-width: 50px;

        &.custom-select {
            font-size: 14px;
            color: #1e1e2c;
            border-color: $dove-gray;
            width: auto;
            height: 25px;
            padding: 0 1.4rem 0 0.5rem;
            background-image: none;
        }
    }

    .remove-btn {
        float: none;
        font-family: $opensans-regular;
        font-weight: 400;
        text-decoration: underline;
        margin: 10px 0;
        position: initial;
    }

    .red-label {
        color: $persian-red;
        font-size: 12px;
    }

    .budget-form {
        color: $dark-gunmetal;
        padding-top: 7px;

        label {
            font-size: 12px;

            input {
                transform: translateY(3px);
            }
        }

        .form-group {
            margin-bottom: 0;
        }
    }

    .live-tv-label {
        margin-bottom: 20px;
    }
}

.product-line-item {
    padding: 0 10px;

    .bp-logo {
        width: 82px;
        height: 21px;
    }
}

.product-line-item-details {
    overflow: hidden;

    &.innactive {
        .product-line-item-block {
            opacity: 0.5;
        }
    }
}

.minicart-close {
    position: absolute;
    top: 0;
    left: -37px;
    padding: 9px 15px;
    background-color: $black;
    color: $white;

    &:hover {
        cursor: pointer;
    }

    .fa {
        font-size: 20px;
    }
}

.minicart-header-block {
    padding: 0;
}

.minicart-dynamic-link {
    display: block;
    padding: 10px 0;
    font-family: $opensans-regular;
    font-size: 14px;
    line-height: 20px;
    color: $black;
    background-color: $alice-blue;

    @include media-breakpoint-up(sm) {
        font-size: 13px;
        line-height: 18px;
    }

    &:hover {
        text-decoration: none;
    }

    &.active {
        background-color: #3d4498;
        color: $white;
    }

    &.disabled {
        background-color: $alto;
        opacity: 0.65;
        pointer-events: none;
    }
}

.minicart-text-container {
    padding: 10px 15px;
    font-family: $opensans-regular;
    font-size: 9px;
    line-height: 13px;
    color: $night-rider;

    @include media-breakpoint-up(sm) {
        padding: 10px 20px;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
    }

    .link {
        color: $navy-blue;
        text-decoration: none;

        &:hover {
            color: $venice-blue;
        }
    }
}

.minicart-subtotal {
    padding: 10px 0;
    background-color: $curious-blue;
    color: $white;

    .text-center {
        a {
            color: $black;
            opacity: 1;
            width: 138px;
            height: 34px;
            font-size: 15px;

            @include media-breakpoint-up(sm) {
                width: 130px;
                height: 35px;
            }

            span {
                font-size: 14px;
            }
        }
    }
}

/** --mini cart styles-- **/
.CRO_generic_color {
    .minicart-close {
        background-color: $primarybtn-color;
    }

    .minicart-dynamic-link {
        &.active {
            background-color: $primarybtn-color;
            color: $white;
        }
    }

    .minicart-text-container {
        .link {
            color: $primarybtn-color;
            text-decoration: none;

            &:hover {
                color: $primarybtn-color;
            }
        }
    }

    .minicart-subtotal {
        background-color: $white;
        color: $primarybtn-color;
        box-shadow: 0 0 10px rgb(0 0 0 / 10%);

        .text-center {
            a {
                color: $white;
                background: $primarybtn-color;
                opacity: 1;
            }
        }
    }

    /** --cart styles-- **/
    input[type="radio"]:checked {
        -webkit-appearance: none;
        border: 1px solid $primarybtn-color;
    }

    input[type="radio"]:checked::before {
        background: $primarybtn-color;
    }

    .save-for-later-wrapper {
        margin-left: 6px;

        @include media-breakpoint-up(md) {
            margin-left: 20px;
        }

        .save-for-later {
            font-family: $opensans-bold;
            font-size: 11px;
        }

        .save-for-later-icon {
            font-size: 12px;
            color: $primarybtn-color;
        }
    }

    .coupons-and-promos {
        .promo-code-btn {
            font-family: $opensans-bold;
            height: 30px;
            border-color: $primarybtn-color;
            padding: 0 15px;
            font-size: 14px;
            border-radius: 0;
            text-transform: uppercase;

            &:active,
            &:focus {
                background-color: $primarybtn-color !important;
                box-shadow: none !important;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    body.show-cart-popover {
        overflow-y: hidden;
    }
}
