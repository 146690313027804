@import "../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "../base/fonts";
$min-width-iphone-six: 375px;
$max-width-iphone-six: 667px;
$max-width-iphone-x: 812px;
$min-device-width-old-iphones: 414px;
$max-device-width-old-iphones: 736px;
$max-device-width-latest-iphones: 896px;

#productSpecifications {
    margin: 0 auto;
    font-size: 16px;
    // margin-left: -49px;
  
    @include media-breakpoint-down(sm){
        margin-left: -2px;    
    }
    .product-details-container {
        font-size: 14px;
        color: $night-rider;

        .col-md-12 {
            .row {
                flex-wrap: nowrap;
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }
    }

    .gemstone-details-container {
        width: 50%;

        @include media-breakpoint-up(md) {
            padding-left: 10px;
        }
    }

    .specifications {
        margin-left: 5px;
        margin-right: 0;
        flex-direction: column;

        @include media-breakpoint-down(sm) {
            margin-left: 0;    
        }
    }

    a.icon-display {
        cursor: pointer;

        &::after {
            content: "\f05a";
            display: inline-block;
            font-family: "FontAwesome", serif !important;
            font-size: 14px;
            margin-left: 5px;
            font-weight: 400;
            color: $navy-blue;
        }
    }

    .dropdown-item:hover {
        background-color: $white;
    }

    .dropdown-menu {
        border: none;
        position: unset;
        width: 100%;
    }

    .panel-title {
        font-size: 16px;
        font-family: $opensans-bold;
    }

    .product-name {
        color: $night-rider;
        font-size: 20px;
        margin: 20px 0;
        text-align: center;
        margin-top: 0;
        font-weight: 600;
        border-bottom: 1px solid $gainsboro;
        padding-bottom: 15px;

        @include media-breakpoint-down(md) {
            font-size: 16px;
            line-height: 1.5;
        }
    }

    a.dropdown-button {
        display: flex;
        justify-content: space-between;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    th {
        padding: 12px 20px;
        text-align: left;
        background-color: $alto-secondary;
        color: $black;
        font-family: $opensans-bold;
    }

    tr:nth-child(even) {
        background-color: $concrete;
    }

    table,
    tr,
    td {
        border: none;
        padding: 8px;
    }

    .info-icon {
        color: $navy-blue;
        font-weight: 400;
        padding-left: 5px;
    }

    td:last-of-type p {
        text-align: right;

        span.tooltip-body {
            text-align: left !important;
        }
    }

    .productSpecsInnerWrap,
    p {
        width: 95%;
    }

    .productSpecsInnerWrap p span strong {
        font-family: $opensans-bold;
        font-size: 16px;
    }

    table {
        width: 100%;
        border: 0 !important;

        tbody td {
            padding-left: 8px;
        }
    }

    .header-title {
        text-align: left;
        font-size: 24px;
        margin-bottom: 10px;
        color: $night-rider;
        display: block;
        margin-top: -15px;
        white-space: break-spaces;
    }

    .tooltip-popup {
        display: block;
        max-width: 309px;
        position: absolute;
    }

    .tooltip-body {
        text-align: left !important;
        margin-bottom: 0;
        font-size: 14px;
        white-space: break-spaces;
    }

    .dropdown-toggle::after {
        display: none;
    }

    .productSpecsInnerWrap table {
        @include media-breakpoint-down(xl) {
            width: 100% !important;
        }
    }
}

.icon-holder {
    width: 23px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 11px;
    margin-top: 5px;
}

#productSpecificationsDropdown.dropdown-button {
    &[aria-expanded=true] {
        .fa-plus::before {
            content: "\FF0D";
        }
    }
}

#productSpecificationsDropdown.dropdown-button.collapsed {
    &[aria-expanded=false] {
        .fa-plus::before {
            content: "\FF0B";
            font-weight: 700;
            font-family: $opensans-regular;
            font-size: 24px !important;
        }
    }
}

.popover-header {
    font-family: Arial, Helvetica, sans-serif;
    line-height: 22px;
    color: $night-rider;
    border-bottom: none;
    margin-top: -15px;
    text-align: left;
    font-size: 24px;
    margin-bottom: 10px;
    margin-left: -5px;
    padding: 0.5rem 0;
    background-color: $white;
}

.popover-body {
    padding: 0;
    margin-left: -5px;
    font-size: 14px;
    font-family: $opensans-regular;
}

.popover,
.popover-ra {
    background: $white;
    border: 1px solid $silver;
    padding: 15px 10px 10px;
    box-shadow: 0 0 5px 0 $night-rider;
    margin-top: 12px;
    z-index: unset;
    max-width: 435px;
    margin-right: 20px;
    margin-left: 27px;
}

.activePopover {

    // iPhone 6, 6S, 7 and 8 (potrait and landscape)
    @media only screen and (min-width: $min-width-iphone-six) and (max-width: $max-width-iphone-six) and (-webkit-min-device-pixel-ratio: 2) {
        z-index: -100 !important;

        .popover,
        .popover-ra {
            z-index: 10 !important;
        }
    }

    @supports (-webkit-touch-callout: none) {

        // CSS specific to iOS devices
        .popover,
        .popover-ra {
            z-index: 10 !important;
        }
    }

    //  iPhone 6+, 7+ and 8+ (Portrait and Landscape_)
    @media screen and (min-width: $min-device-width-old-iphones) and (max-width: $max-device-width-old-iphones) and (-webkit-min-device-pixel-ratio: 3) {
        z-index: -100px !important;

        .popover,
        .popover-ra {
            z-index: 10 !important;
        }
    }

    // iPhone X /* Portrait and Landscape
    @media only screen and (min-width: $min-width-iphone-six) and (max-width: $max-width-iphone-x) and (-webkit-min-device-pixel-ratio: 3) {
        z-index: -100 !important;

        .popover,
        .popover-ra {
            z-index: 10 !important;
        }
    }

    // iPhone 11 (test) /* Portrait and Landscape
    @media only screen and (min-width: $min-device-width-old-iphones) and (max-height: $max-device-width-latest-iphones) and (-webkit-device-pixel-ratio: 3) {
        z-index: -100 !important;

        .popover,
        .popover-ra {
            z-index: 10 !important;
        }
    }
}

.popover.bs-popover-bottom .arrow::after {
    border-bottom-color: $black;
}

.popover.bs-popover-top .arrow::after {
    border-top-color: $black;
}

.popover-ra.bs-popover-bottom .arrow::after {
    border-bottom-color: $black;
}

.popover-ra.bs-popover-top .arrow::after {
    border-top-color: $black;
}

.tooltip-key {
    img {
        order: 1;
        margin: 10px auto;
        max-width: 96%;
    }

    .tooltip-key-text {
        order: 0;
    }
}

/** --product spacification styles-- **/
.CRO_generic_color {
    #productSpecifications {
        margin: 0 auto;
        font-size: 16px;
       
        a.icon-display {
            &::after {
                color: $primarybtn-color;
            }
        }
        .specifications {
            flex-direction: column;
        }
    }
}
