@import "~@sfra/scss/components/common";

body {
    background-color: $white;
    color: $dark-gunmetal;

    a {
        color: $dark-gunmetal;
    }
}

.header-container {
    z-index: 10000;
}

.page {
    overflow: hidden;
}

#maincontent {
    position: relative;
}

.red-msg {
    color: $red;
}

.color-gray {
    color: $dark-gray;
}

.lazyload,
.lazyloading {
    opacity: 0;
}

.lazyloaded {
    opacity: 1;
    transition: opacity 500ms;
}

.link {
    color: $astral;
    text-decoration: underline;

    &:hover {
        color: $black;
        text-decoration: none;
    }
}

.link--black {
    color: $black;

    &:hover {
        text-decoration: underline;
    }
}

.img {
    max-width: 100%;
}

.arrow-right {
    font-size: 14px;
    color: $lochmara;

    &::before {
        content: "";
        border: 1px solid #da3f3f;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 4px;
        transform: rotate(-45deg);
    }
}

.container-secondary {
    max-width: 100%;

    @include media-breakpoint-up(lg) {
        max-width: 960px;
    }

    @include media-breakpoint-up(xl) {
        max-width: 1280px;
    }
}

// iPhone - font issue is fixed by adding more selectors
#maincontent .container a.more-recomandations-txt.btn {
    border-radius: 5px;
    background-color: $night-rider;
    color: $white;
    border: none;
    font-weight: bold;
    min-width: 200px;
    padding: 10px 40px;
    text-transform: uppercase;

    :hover {
        color: $white;
    }
}

// This will be deleted when we will have other content if needed
.carousel-padding {
    margin-top: 35px;
}

.bold {
    font-family: $opensans-bold;
}

.red-text-label {
    color: $persian-red2;
}

.blue-text-label {
    color: $smart-blue;
}

.fixed-elem {
    top: 0;
    width: 100%;
    z-index: 100;
    left: 0;
}

.sticky-scroll {
    position: static;
    top: 0;
    width: 100%;
    z-index: 100;
    left: 0;

    &.is-sticky {
        position: fixed;
    }
}

.logo-home img {
    max-width: 100%;
    width: 109px;
    height: 31px;
}

.link-blue {
    font-family: $opensans-bold;
    font-size: 14px;
    color: $lilac-purple;

    &--regular {
        font-family: $opensans-regular;
    }

    &:hover {
        color: $lilac-purple;
    }

    &.underlined {
        text-decoration: underline;
    }
}

.link-blue-secondary {
    font-family: $opensans-regular;

    &:hover {
        color: $venice-blue;
        text-decoration: none;
    }
}

.link-red {
    color: $persian-red2;

    &:hover {
        color: $sanguine-brown;
        text-decoration: none;
    }
}

.pdp-redirect {
    color: $black;
    font-size: 14px;
}

.pay-later {
    color: $primary;
}

.aspect-ratio-box::before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: 591.44px / 1127.34px * 100%;
}

.aspect-ratio-box::after {
    /* to clear float */
    content: "";
    display: table;
    clear: both;
}

.opacity-3 {
    opacity: 0.3;
}

#updateCreditcard {
    .error-message {
        margin: 10px 0 0;
        color: $persian-red2;
    }

    .modal-footer {
        margin-top: 20px;
    }
}

.add-to-cart-messages {
    display: none;
}

// .five9-frame .five9-frame-full .five9-header {
//     display: none;
// }

// .five9-frame {
//     @include media-breakpoint-down(xs) {
//         display: none !important;
//     }
// }

.budget-pay-container {
    .bpblue-logo-desktop {
        .svg-img {
            width: 90px !important;
            aspect-ratio: 300/83 auto;
        }
    }

    .bpblack-logo {
        .svg-img {
            width: 30px !important;
        }
    }

    .budge-pay-content {
        .svg-img {
            width: 30px !important;
        }
    }
}

@include media-breakpoint-down(xs) {
    .pr-xs-0 {
        padding-right: 0;
    }
}

/******************* Chat now style *********************/

// #boxHere {
//     position: absolute;
//     right: 0;
//     bottom: 4.5em;
// }

// div.five9-frame {
//     position: fixed !important;
//     bottom: 0 !important;
//     right: 0 !important;
//     left: auto !important;
// }

.page[data-action*=Cart-Show] + div.five9-frame {
    position: fixed !important;
    bottom: 0 !important;
    right: 0 !important;
    left: auto !important;
}

// .chatnow-btn-wrapper {
//     right: 0;
//     cursor: pointer;
//     position: fixed;
//     bottom: 17%;
//     z-index: 999;

//     &.box {
//         position: fixed !important;
//     }
// }

.shipping-address.contain-restricted-states {
    display: block;
}

.disableclick {
    pointer-events: none;
}

.shipping-address-error-message {
    display: none;
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
}

// .chatBtn-wrapper-black {
//     display: none !important;
// }

// .chatBtn-wrapper-blue {
//     display: block !important;
// }

.product-grid .product-tile-wrapper .product-tile .budget-pay-container img {
    width: 92px;
}

.bpblack-logo {
    display: none !important;
}

.bpblue-logo-desktop {
    display: block !important;
}

.bpblue-logo-mobile {
    display: block !important;
}

/** --CRO common styles-- **/
.CRO_generic_color {
    .link-blue,
    .pay-later {
        color: $primarybtn-color !important;

        &:hover {
            color: $primarybtn-color;
        }
    }

    // .chatBtn-wrapper-black {
    //     display: block !important;
    // }

    // .chatBtn-wrapper-blue {
    //     display: none !important;
    // }

    .bpblack-logo {
        display: block !important;
    }

    .bpblue-logo-desktop {
        display: none !important;
    }

    .bpblue-logo-mobile {
        display: none !important;
    }

    .refinement-budgetpay {
        .filter-show-value {
            width: 100%;
            display: flex;
            align-items: center;
            flex-grow: 1;

            img {
                width: 34px;
                margin: 0 8px 6px;
            }
        }

        .values {
            button {
                display: flex;
                align-items: center;
            }

            li .fa-check-square {
                color: $primarybtn-color !important;
            }
        }
    }

    .refinements .card .card-body .values li .fa-check-square {
        color: $primarybtn-color !important;
    }

    .acc-card-body {
        .wishlist-header .wishlist-owner {
            color: $primarybtn-color;
            font-family: $opensans-regular;
        }

        .facet-group .mywishlist-header {
            color: $primarybtn-color;
        }
    }

    #quickViewModal {
        .number-spinner {
            display: flex !important;

            .input-group-btn .btn {
                color: $primarybtn-color;
            }
        }

        .product-quickview {
            .estimated-values .save-wrapper .red-text {
                color: $primarybtn-color !important;
            }

            .full-pdp-link {
                color: $primarybtn-color;
            }
        }

        .actions-row .fastbuy-wrapper a {
            padding: 0;
        }
    }
}

.varnotify-background {
    width: 100%;
    padding: 10px;
    background: $trans-black;
    position: fixed;
    height: 100%;
    top: 0;
    z-index: 2147483647;
    left: 0;

    .notify-mobile {
        display: block;
        position: fixed;
        height: 80px;
        width: 470px;
        top: 100px;
        left: 50%;
        transform: translate(-50%, -50%);
        right: 470px;
        background: $white;
        padding-top: 5px;
        padding-left: 15px;
        padding-bottom: 5px;
        padding-right: 20px;
        border-radius: 4px;
        @include media-breakpoint-down(sm) {
            max-width: 350px;
        }
    }

    .notify-success-msg {
        font-size: 14px;
        color: $black;
        margin-left: 10px;
        margin-top: 15px;
        text-align: left;
        font-family: $opensans-regular;
        text-transform: none;

        @include media-breakpoint-down(sm) {
            margin-top: 0;
        }
    }
}

// Live Tv
.live-tv-pdp-section {
    &.right {
        padding-right: 0;
    }

    .product-pricing {
        gap: 10px;

        &.currentlyOnAir {
            .product-current-price {
                align-items: center;
                justify-content: center;
                background: #f7f8ff;
                border: 1px solid rgba(51, 51, 51, 0.1);
                border-radius: 3px;
                max-width: calc(50% - 10px);
                flex: 0 0 calc(50% - 10px);
                padding: 10px 4px !important;
                text-align: center;
                min-height: 71px;

                input {
                    &:checked + div {
                        &::before {
                            position: absolute;
                            left: 0;
                            height: calc(100% + 2px);
                            width: calc(100% + 2px);
                            border: 1px solid #eb254b;
                            content: "";
                            top: -1px;
                            border-radius: 3px;
                        }
                    }

                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    z-index: 9;
                    opacity: 0;
                }
            }

            .interest-fee-label {
                font-size: 8px;
                color: $night-rider !important;
                opacity: 1;
                line-height: 13px;
                position: relative;
                top: 5px;
                margin-left: 3px;
                font-style: normal;
                font-weight: 700;
            }

            .product-current-price.bp-price {
                > label {
                    .d-flex.flex-column {
                        flex-direction: unset !important;
                        flex-wrap: wrap;
                        justify-content: center;
                        text-align: center;

                        span.bpblue-logo-mobile {
                            width: 100%;

                            img.bp-logo.lazyloaded {
                                display: table;
                                margin: 0 auto;
                                float: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .shoplc-price {
        .current-price-label {
            background-repeat: no-repeat;
            font-size: 0 !important;
            height: 11px;
            width: 58px;
            margin: 0 auto 5px !important;
            background-position: center;
            line-height: 11px;
        }

        .current-price-value {
            font-style: normal;
            font-weight: 600 !important;
            font-size: 16px !important;
            line-height: 22px;
            text-align: center;
            text-transform: capitalize;
            color: $night-rider !important;
        }
    }

    .bp-price {
        .bp-value {
            font-style: normal;
            font-weight: 600 !important;
            font-size: 16px !important;
            line-height: 22px !important;
            text-align: center;
            color: $night-rider !important;

            .live-tv-pdp-section .product-pricing {
                gap: 10px;
                font-style: normal;
                font-weight: 600 !important;
                font-size: 16px !important;
                line-height: 22px !important;
                text-align: center;
                color: $night-rider !important;
            }
        }
    }

    .product-quantity-inner {
        &.currentlyOnAir {
            width: 100%;

            .product-qty-control {
                width: calc(100% - 30px);
                justify-content: space-between;
                background: #f0f0f0;
                border: 1px solid rgba(51, 51, 51, 0.12);
                border-radius: 3px;

                .quantity-input {
                    border: none;
                    background: #f0f0f0;
                }

                .quantity-btn {
                    background: #f0f0f0;
                }
            }
        }
    }

    .product-attributes .form-control {
        flex: 1;
        flex-basis: 45%;
        margin-right: 5px;
    }
}

@include media-breakpoint-down(sm) {
    .embeddedServiceSidebarMinimizedDefaultUI {
        top: 50%;
        right: 0 !important;
        padding: 10px 11px !important;
        margin: 0 !important;

        &.helpButton {
            min-width: auto !important;
            height: 38px !important;
        }

        .content {
            padding: 0 !important;

            .messageContent {
                margin: 0 !important;

                .minimizedText {
                    display: none;
                }
            }
        }
    }

    .embeddedServiceHelpButton {
        .helpButton {
            top: 50% !important;
            right: 0 !important;

            .uiButton {
                font-size: 12px !important;
                padding: 10px 11px !important;
                border-radius: 100px !important;
                height: auto !important;
                margin: auto !important;
                min-width: auto !important;

                .embeddedServiceIcon {
                    display: flex !important;
                }

                .helpButtonLabel {
                    font-family: $opensans-regular;
                    font-weight: 400;
                    display: none;
                }
            }
        }
    }
}