.channel-finder-page {
    .breadcrumb {
        padding-left: 0;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    h1.page-title {
        &::before {
            display: none;
        }

        color: black;
        margin-bottom: 25px;
        line-height: 26px;
        font-size: 30px;
        text-transform: uppercase;
        font-weight: 700;
        left: 0;
        background: transparent;
        margin-top: 0;
        padding: 0;
        display: flex;
        justify-content: center;

        @include media-breakpoint-down(sm) {
            font-size: 23px;
            margin-top: 20px;
        }
    }

    .channelFinderContainer {
        position: relative;
        text-align: center;

        .channelContent {
            position: absolute;
            top: 75%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 10px 0;
            justify-content: center;
            width: 500px;
            border-radius: 10px;

            @include media-breakpoint-only(md) {
                top: 75%;
                left: 52%;
                width: 380px;
            }

            @include media-breakpoint-only(sm) {
                left: 55%;
                width: 360px;
                top: 77%;
            }

            @include media-breakpoint-down(xs) {
                left: 55%;
                width: 360px;
                top: 80%;
            }

            .mrgT5 {
                margin-top: 5px;
                font-size: 32px;

                @include media-breakpoint-down(md) {
                    max-width: 100%;
                    flex: 100%;
                }

                #zccFormText {
                    font-size: 28px;
                    margin-bottom: 20px;
                    line-height: 1.6em;
                    text-align: center;
                    font-weight: 600;

                    @include media-breakpoint-down(md) {
                        font-size: 18px;
                        margin-bottom: 15px;
                    }
                }
            }

            .channelInputText {
                display: flex;
                justify-content: center;

                .zccInputContainer {
                    @include media-breakpoint-down(md) {
                        max-width: 60%;
                        flex: 50%;
                    }

                    .form-control {
                        border-radius: 0;
                        box-shadow: none;
                        display: block;
                        height: 34px;
                        padding: 6px 12px;
                        font-size: 14px;
                        line-height: 1.42857143;
                        color: $emperor;
                        background-color: $white;
                        background-image: none;
                        border: 1px solid $gray-4;
                        width: 320px;

                        @include media-breakpoint-down(md) {
                            width: 250px;
                        }
                    }

                    input:focus::placeholder {
                        color: transparent;
                    }
                }

                .channelFinderButton {
                    @include media-breakpoint-down(md) {
                        max-width: 41.66666667%;
                        flex: 41.66666667%;
                    }
                }

                .channel-finder-button {
                    background-color: black;
                    font-size: 13px;
                    color: $white;
                    font-weight: 700;
                    padding: 7.25px 15px;
                    border-radius: 0;
                    border: 0;
                    text-decoration: none !important;
                    width: 100px;
                    max-width: 173px;

                    @include media-breakpoint-down(md) {
                        max-width: none;
                    }
                }
            }
        }
    }
}

#channelfinder {
    padding-left: 15px;
    padding-right: 15px;

    @include media-breakpoint-down(sm) {
        padding-top: 10px;
    }

    .zcc-head {
        font-weight: bold;
        font-size: 18px;
        padding: 0;
        margin: 20px 0;
        display: flex;
        justify-content: center;
    }

    .secondary-heading {
        font-size: 20px;
        margin-bottom: 15px;
        text-transform: capitalize;
        color: $night-rider;
        font-weight: 500;
    }

    .zcc-provider-cable,
    .zcc-provider-satellite {
        display: table;
        width: 100%;
        margin-bottom: 25px;
    }

    .zcc-providers-info,
    .zcc-station-channel-list {
        border-top: 1px solid $alto-secondary;
        line-height: 1.42857;
        padding: 8px;
        vertical-align: top;
        font-size: 14px;
    }

    .zcc-providers {
        display: flex;

        .zcc-providers-info {
            width: 60%;
        }

        .zcc-station-channel-list {
            width: 40%;
        }
    }

    .zcc-providers:nth-child(odd) {
        background-color: $light-grey-bg;
    }

    .error-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: $opensans-regular;

        .missing-pin-message {
            font-size: 18px;
            color: #d83c50;
            justify-content: center;
            margin: 25px;
            font-weight: 600;

            @include media-breakpoint-down(sm) {
                font-size: 16px;
                margin: 10px;
                text-align: center;
            }
        }

        .trouble-text {
            font-size: 20px;
            margin: 20px;
            color: $black;
            font-weight: 600;

            @include media-breakpoint-down(sm) {
                font-size: 18px;
                margin: 15px 5px;
                text-align: center;
            }
        }

        .chat-support {
            color: white;
            background-color: #7980d8;
            border-radius: 20px;
            font-size: 16px;
            border: #7980d8;
            padding: 10px 24px;
            line-height: 22px;
        }
    }

    .channelFinderTable {
        display: flex;
        flex-direction: column;
        align-items: center;

        .TFtable {
            width: 80%;
            margin: 30px;

            @include media-breakpoint-down(sm) {
                width: 100%;
                margin: 20px;
            }

            td {
                padding-left: 15px;
                font-size: 14px;
                font-family: $opensans-regular;
                height: 30px;
                color: #1e1e2b;
                font-weight: 600;
            }

            .channel-type,
            .broadcaster-type {
                width: 50%;
                padding-left: 0 !important;
                font-size: 20px;
                font-weight: 600;

                @include media-breakpoint-down(sm) {
                    font-size: 16px;
                    width: 60%;
                    padding-left: 10px !important;
                }
            }

            tr:nth-child(even) {
                background: $concrete;
            }

            tr:nth-child(1) {
                background: white;
            }
        }

        hr {
            border: 1px solid $black;
            width: 80%;
            margin: 0;

            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }
    }
}

/** --CRO Channel Finder styles-- **/
.CRO_generic_color {
    .channel-finder-page {
        h1.page-title {
            color: $primarybtn-color;
        }
    }
}
