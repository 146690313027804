.fast-buy-login-modal {
    .modal-header {
        padding: 15px 0;
        border-bottom: none;
    }

    .modal-body {
        .login-section {
            border-right: none;
        }

        @include media-breakpoint-up(sm) {
            padding: 0;
        }
    }

    .header-title {
        padding: 0 15px 20px;
        font-size: 18px;
        text-transform: uppercase;
        color: $dark-gunmetal;
        line-height: 1.5;
        font-weight: 400;
        border-color: $mercury;
    }

    .close {
        font-size: 20px;
        top: 6px;
    }

    .fast-buy-login-modal__top-info {
        margin-bottom: 20px;
    }

    .fast-buy-login-modal__top-item {
        font-size: 13px;
    }

    .login-card {
        padding: 0 15px;

        .card-title {
            text-transform: uppercase;
            font-size: 18px;
            font-weight: 700;
            color: $dark-gunmetal;
            margin: 20px 0 40px;

            @include media-breakpoint-up(lg) {
                margin: 15px 0 20px;
            }
        }

        .btn-picton-blue {
            max-width: 180px;
        }

        .login-note {
            font-size: 13px;

            @include media-breakpoint-up(sm) {
                margin-top: 30px;
            }
        }

        .card-separator {
            border: none;
        }
    }

    .social-wrapper {
        border: 1px solid $mercury;
        max-width: 83%;
        margin: 0 auto 20px;
        padding: 10px 10px 10px 8px;

        @include media-breakpoint-up(sm) {
            max-width: 100%;
        }
    }

    .guest-section {
        max-width: 90%;
        margin: 0 auto 30px;

        .card-title {
            text-transform: uppercase;
            font-size: 18px;
            font-weight: 700;
            color: $dark-gunmetal;
            margin-bottom: 30px;

            @include media-breakpoint-up(sm) {
                margin-bottom: 10px;
            }
        }

        .checkout-as-guest {
            max-width: 180px;

            @include media-breakpoint-up(sm) {
                margin: 0;
                max-width: 200px;
            }
        }

        @include media-breakpoint-up(sm) {
            margin-top: 40px;
            max-width: 100%;

            .card-text {
                margin-bottom: 20px;
            }
        }

        @include media-breakpoint-up(lg) {
            margin-top: 20px;
        }
    }

    @include media-breakpoint-up(lg) {
        .modal-content {
            padding: 0;
        }

        .close {
            right: 15px;
        }
    }
}

.fast-buy-budget-pay-modal {
    padding-left: 17px;
    top: 20px;
    max-width: 350px;
    left: 50%;
    z-index: 2000;
    transform: translateX(-50%);

    .fast-buy-option-input {
        position: relative;
        top: 3px;
    }

    .modal-title {
        font-weight: 600;
        font-size: 18px;
        text-transform: uppercase;
        color: $navy-blue;
    }

    .modal-footer {
        padding: 10px 0;

        .modal-title {
            display: block;
            width: 100%;
        }

        .modal-footer-text-note {
            margin: 10px 0;
            color: $grey3;
            font-size: 12px;
            text-align: center;
            display: block;
            width: 100%;
        }

        .budget-modal-button {
            text-shadow: 0 1px 0 $white;
            background-image: linear-gradient(to bottom, $white 0%, #e0e0e0 100%);
            background-repeat: repeat-x;
            border-color: $grey3;
            box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%), 0 1px 1px rgb(0 0 0 / 8%);
            color: #333;
            background-color: #fff;
        }
    }

    .modal-footer--simplified {
        border: none;

        .modal-footer-text-note {
            font-size: 14px;
            color: $black;
        }
    }

    .modal-dialog {
        .modal-content {
            .modal-body {
                .d-flex {
                    label {
                        padding-top: 2px;
                    }
                }
            }
        }
    }
}

/** --CRO Fast buy Modal styles styles-- **/
.CRO_generic_color {
    .fast-buy-budget-pay-modal {
        .fast-buy-budget-pay-modal {
            .modal-title {
                color: $primarybtn-color;
            }

            .modal-footer {
                .budget-modal-button {
                    background: $primarybtn-color;
                }
            }
        }

        .modal-title {
            color: $primarybtn-color;
        }

        .modal-footer {
            .budget-modal-button {
                background: $primarybtn-color;
                text-shadow: none;
                color: $white;
                font-weight: 500;
                border-radius: 0;
                border: none;
                font-size: 14px;
            }
        }
    }
}
