@mixin input-custom {
    height: 50px;
    padding: 10px 12px 10px;
    border-radius: 0;
    font-size: 14px;
    line-height: 20px;
    color: $emperor;
    box-shadow: none;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

    ~ .form-control-label {
        position: absolute;
        top: 2px;
        transform: translateY(12px);
        left: 12px;
        width: calc(100% - 24px);
        font-family: $opensans-regular;
        font-size: 14px;
        line-height: 19px;
        text-transform: uppercase;
        color: $nobel-light;
        cursor: text;
        transition: transform 0.2s ease-out, color 0.2s ease-out, -webkit-transform 0.2s ease-out;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        .thin {
            font-family: $opensans-light;
            font-style: italic;
        }
    }
}

@mixin form-control-label {
    top: 2px;
    width: calc(100% + 24px);
    transform: translateY(2px) scale(0.8);
    transform-origin: 0 0;
}

.input-custom {
    @include input-custom;

    &.prefilled {
        color: $night-rider;
        font-size: 12px;
        font-family: $opensans-regular;

        ~ .form-control-label {
            @include form-control-label;
        }
    }
}

.input-custom-orange {
    @include input-custom;

    font-family: $opensans-regular;

    &:focus {
        background-color: $seashell-peach;
        border-color: $terracotta;
        box-shadow: none;

        ~ .form-control-label {
            color: $terracotta;
        }
    }
}

.form-group {
    position: relative;
    margin-bottom: 20px;

    &.active {
        .input-custom {
            padding-top: 10px;
        }

        .form-control-label {
            @include form-control-label;
        }
    }

    &.required .form-control-label::before {
        content: "";
    }
}

.form-control[readonly] {
    background-color: $grey2;
    padding-top: 10px;

    &:focus {
        border-color: $cornflower-blue;
    }
}

select {
    &.form-control {
        border: 1px solid $gray2;
        border-radius: 2px;
        margin: 0 0 10px 0;
        height: 38px;
        text-transform: uppercase;
        padding: 0 25px 0 10px;
        width: 100%;
        font-size: 12px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image: linear-gradient(45deg, transparent 50%, $gray 50%), linear-gradient(135deg, $gray 50%, transparent 50%), linear-gradient(to right, $grey3, $grey3);
        background-position: calc(100% - 12px) calc(1em + 2px), calc(100% - 7px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
        background-size: 5px 5px, 5px 5px, 0 1.5em;
        background-repeat: no-repeat;

        &:focus {
            background-image: linear-gradient(45deg, $gray 50%, transparent 50%), linear-gradient(135deg, transparent 50%, $gray 50%), linear-gradient(to right, $grey3, $grey3);
            background-position: calc(100% - 7px) 1em, calc(100% - 12px) 1em, calc(100% - 2.5em) 0.5em;
        }

        ~ .form-control-label {
            @include form-control-label;
        }
    }
}

.radio-custom {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    margin-left: -20px;

    ~ span {
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        height: 25px;
        font-size: 10px;
        line-height: 25px;
        transition: 0.28s ease;

        &::before,
        &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            margin: 4px;
            width: 16px;
            height: 16px;
            transition: 0.28s ease;
        }
    }

    &:checked {
        ~ span {
            &::before {
                border: 2px solid $night-rider;
                border-radius: 50%;
            }

            &::after {
                transform: scale(0.5);
                background-color: $night-rider;
                border: 2px solid $night-rider;
                border-radius: 50%;
            }
        }
    }

    &:not(:checked) {
        ~ span {
            &::before {
                border: 2px solid $night-rider;
                border-radius: 50%;
            }

            &::after {
                transform: scale(0);
            }
        }
    }
}

.custom-checkbox-container {
    input {
        position: absolute;
        opacity: 0;
        pointer-events: none;
        margin: 0;

        ~ span {
            &::before {
                top: 1px;
                left: 0;
                width: 8px;
                height: 12px;
                border-top: 2px solid transparent;
                border-left: 2px solid transparent;
                border-right: 2px solid $white;
                border-bottom: 2px solid $white;
                -webkit-transform: rotateZ(37deg);
                transform: rotateZ(37deg);
                -webkit-transform-origin: 100% 100%;
                transform-origin: 100% 100%;
            }

            &::after {
                top: 0;
                z-index: 0;
            }
        }

        &:checked {
            ~ span {
                &::before {
                    border-right: 2px solid $white;
                    border-bottom: 2px solid $white;
                }
            }
        }
    }

    span {
        position: relative;
        padding-left: 25px;
        cursor: pointer;
        display: inline-block;
        font-family: $opensans-bold;
        font-size: 15px;
        line-height: 22px;
        color: $lilac-purple;

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            margin-top: 2px;
            transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s, height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;
            z-index: 1;
            border-radius: 2px;
        }

        &::before {
            width: 0;
            height: 0;
            border: 3px solid transparent;
            left: 6px;
            top: 10px;
        }

        &::after {
            width: 16px;
            height: 16px;
            border: 2px solid $silver-chalice;
        }
    }
}

.custom-checkbox-container-secondary {
    input {
        &:checked {
            ~ span {
                &::after {
                    border-color: $silver-chalice;
                    background-color: $silver-chalice;
                }
            }
        }
    }

    span {
        font-family: $opensans-regular;
        font-size: 13px;
        line-height: 19px;
        color: $night-rider;
    }
}

.protection-plan-checkbox {
    accent-color: $lilac-purple;
}
