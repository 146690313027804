html {
    // scroll-behavior: smooth;
}

.xmas-campaign-wrap {
    h1,
    h3 {
        color: #1e1e2c;
        font-family: sans-serif !important;
    }

    h1 {
        font-size: 24px;
        font-weight: bold;
    }

    .req-text {
        font-size: 14px;
        font-family: sans-serif !important;
    }

    .xmas-campaign-form {
        .form-group {
            font-family: sans-serif !important;
            position: relative;
        }

        .form-control.input-field {
            border-width: 0 0 2px 0;
            border-radius: 0;
            padding-left: 0;
            transition: 0.25s ease;

            &:focus {
                box-shadow: none !important;
                border-bottom-width: 2px;
                border-color: #868ce0;
            }
        }

        .form-control-label {
            transform: translate(0, 14px);
            font-size: 14px;
        }

        .tgl {
            display: none;

            ::after,
            ::before,
            *,
            *::after,
            *::before,
            + .tgl-btn {
                box-sizing: border-box;

                &::selection {
                    background: none;
                }
            }

            + .tgl-btn {
                outline: 0;
                display: block;
                width: 4em;
                min-width: 4em;
                height: 2em;
                position: relative;
                cursor: pointer;
                user-select: none;

                &::after,
                &::before {
                    position: relative;
                    display: block;
                    content: "";
                    width: 50%;
                    height: 100%;
                }

                &::after {
                    left: 0;
                }

                &::before {
                    display: none;
                }
            }

            &:checked + .tgl-btn::after {
                left: 50%;
                background-color: #000;
            }
        }

        .tgl-ios {
            + .tgl-btn {
                background: #fff;
                border-radius: 2em;
                padding: 2px;
                transition: all 0.4s ease;
                border: 2px solid #e8eae9;
                display: inline-block;

                &::after {
                    border-radius: 2em;
                    background: #cacaca;
                    transition: all 0.4s ease;
                    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08);
                }

                &:hover::after {
                    will-change: padding;
                }

                &:active {
                    box-shadow: inset 0 0 0 2em #e8eae9;

                    &::after {
                        padding-right: 0.8em;
                    }
                }
            }

            &:checked + .tgl-btn {
                background: #cacaca;

                &:active {
                    box-shadow: none;

                    &::after {
                        margin-left: -0.8em;
                    }
                }
            }
        }

        button.btn.btn-block.create-submit {
            width: 230px;
            background-color: #868ce0;
            margin: 0 auto;
            text-transform: uppercase;
            color: #fff;
            font-weight: bold;
            margin-top: 20px;

            &:focus {
                box-shadow: none;
            }
        }

        #certify-18-above,
        #agreeterms {
            transform: translate(5px, -11px);
            margin-top: 10px;
        }
    }

    .xmas-hr {
        border-width: 3px;
    }

    .tc-p {
        padding-left: 10px;
        font-size: 14px;
        position: absolute;
        display: inline-block;
        top: 8px;
    }

    a.text-underline {
        text-decoration: underline;
    }

    .req {
        color: #f00;
        font-size: 15px;
        font-weight: bold;
        font-style: normal;
    }

    sup {
        left: -2px;
    }

    .submit-success-msg {
        font-weight: bold;
    }

    .select-one {
        margin-bottom: 15px;
    }
}

label.radio-card {
    cursor: pointer;
    outline: 1px solid #d3d3d3;
    outline-offset: -10px;
    border-radius: 12px;
    transition: 200ms linear;

    &.selected {
        outline: 3px solid #868ce0;
    }

    .card-content-wrapper {
        background: #fff;
        border-radius: 5px;
        padding: 20px 15px 35px;
        display: grid;
        box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.04);
        transition: 200ms linear;
    }

    .check-icon {
        width: 20px;
        height: 20px;
        display: inline-block;
        border: solid 2px #e3e3e3;
        border-radius: 50%;
        transition: 200ms linear;
        position: relative;
        transform: translate(-5px, -10px);

        &::before {
            content: "";
            position: absolute;
            inset: 0;
            background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93552 4.58423C0.890286 4.53718 0.854262 4.48209 0.829309 4.42179C0.779553 4.28741 0.779553 4.13965 0.829309 4.00527C0.853759 3.94471 0.889842 3.88952 0.93552 3.84283L1.68941 3.12018C1.73378 3.06821 1.7893 3.02692 1.85185 2.99939C1.91206 2.97215 1.97736 2.95796 2.04345 2.95774C2.11507 2.95635 2.18613 2.97056 2.2517 2.99939C2.31652 3.02822 2.3752 3.06922 2.42456 3.12018L4.69872 5.39851L9.58026 0.516971C9.62828 0.466328 9.68554 0.42533 9.74895 0.396182C9.81468 0.367844 9.88563 0.353653 9.95721 0.354531C10.0244 0.354903 10.0907 0.369582 10.1517 0.397592C10.2128 0.425602 10.2672 0.466298 10.3112 0.516971L11.0651 1.25003C11.1108 1.29672 11.1469 1.35191 11.1713 1.41247C11.2211 1.54686 11.2211 1.69461 11.1713 1.82899C11.1464 1.88929 11.1104 1.94439 11.0651 1.99143L5.06525 7.96007C5.02054 8.0122 4.96514 8.0541 4.90281 8.08294C4.76944 8.13802 4.61967 8.13802 4.4863 8.08294C4.42397 8.0541 4.36857 8.0122 4.32386 7.96007L0.93552 4.58423Z' fill='white'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-size: 12px;
            background-position: center center;
            transform: scale(1.6);
            transition: 200ms linear;
            opacity: 0;
        }
    }

    input[type="radio"] {
        appearance: none;

        + .card-content-wrapper {
            // box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.5), 0 0 0 2px #868ce0;

            .check-icon {
                background: #ccc;
                border-color: #ccc;
                transform: scale(1.2) translate(-5px, -10px);

                &::before {
                    transform: scale(1);
                    opacity: 1;
                }
            }
        }

        &:checked {
            opacity: 0;

            + .card-content-wrapper {
                .check-icon {
                    background: #868ce0;
                    border-color: #868ce0;
                    transform: scale(1.2) translate(-5px, -10px);

                    &::before {
                        transform: scale(1);
                        opacity: 1;
                    }
                }
            }
        }

        &:focus {
            + .card-content-wrapper {
                .check-icon {
                    box-shadow: 0 0 0 4px rgba(48, 86, 213, 0.2);
                    border-color: #3056d5;
                }
            }
        }
    }

    .card-content {
        img {
            margin-bottom: 10px;
        }

        h4 {
            font-size: 16px;
            letter-spacing: -0.24px;
            text-align: center;
            color: #1f2949;
            margin-bottom: 10px;
        }

        h5 {
            font-size: 14px;
            line-height: 1.4;
            text-align: center;
            color: #686d73;
        }
    }
}
