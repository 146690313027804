@import "../../../variables";
@import "bootstrap/scss/mixins/breakpoints";
$fonts-relative-path-prefix: "../../../";

.bannergroup-wrap {
    background-size: cover;
    padding: 30px 35px;
    border-style: solid;
    border-width: 10px 0 10px 0;
    @include media-breakpoint-down(xs) {
        padding: 30px 15px;
    }

    h2.section-heading {
        font-size: 65px;
        font-weight: 900;
        font-family: "OpenSans-Bold", arial, sans-serif;
        margin-bottom: 20px;
        @include media-breakpoint-down(sm) {
            font-size: 2rem;
        }
    }
}
