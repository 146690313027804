@import "../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "./components/commerceAssets/shopLink.scss";
@import "./components/commerceAssets/imageWithText.scss";
@import "./components/commerceAssets/contentTile.scss";
@import "./components/commerceAssets/promotionBanner.scss";
@import "./components/commerceLayouts/bannergroup.scss";
@import "./components/commerceLayouts/fullwidthbanner.scss";
@import "./components/commerceLayouts/purchasefeedbanner.scss";
@import "./components/commerceLayouts/customGRid1r2c.scss";
@import "../homePage.scss";

.storepage {
    width: 100%;
}

.main-hero-carousel .experience-component-slider {
    //ax-height: 365px;
}

.exclusive-brands {
    .ITC-container {
        height: 125px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }

    .carouselHeadline {
        font-size: 23px;
        text-align: center;
        text-transform: uppercase;
        transform: translateY(-5px);
        margin-bottom: 20px;

        @include media-breakpoint-down(sm) {
            margin-top: -18px;
        }

        @include media-breakpoint-up(md) {
            font-size: 36px;
            margin-bottom: 0;
            margin-top: -6px;
        }
    }

    .slick-slide {
        padding: 0 35px;
    }

    .slick-track {
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }

    .slick-arrow {
        font-size: 30px;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.75);
        background-color: transparent;
    }

    .arrow-prev {
        left: 0;
    }

    .arrow-next {
        right: 0;
    }
}

[class*="experience-commerce_layout-"],
[class*="experience-commerce_layouts-"] {
    padding-top: 35px;
    padding-bottom: 35px;

    @include media-breakpoint-down(sm) {
        margin: 0 -15px;
    }

    @include media-breakpoint-up(xl) {
        max-width: 1530px;
        margin: 0 auto;
    }
}

.experience-component {
    .experience-commerce_assets-liveTV {
        @include media-breakpoint-up(sm) {
            background-color: #d9d9f2;
        }
    }

    .container {
        .live-tv-main-sections {
            .fa-circle {
                font-size: 10px;
                color: $live-tv-red;
            }
        }
    }
}

.support-img-wrapper img {
    max-height: 70px;
}

.tile-banner {
    @include media-breakpoint-down(sm) {
        margin-bottom: -78px;
    }
}

.stylesquad {
    @include media-breakpoint-down(sm) {
        margin-top: -55px;
        padding-left: 20px;
        padding-right: 20px;
    }

    @include media-breakpoint-up(sm) {
        margin-top: -30px;

        .region.col-left {
            padding-right: 0 !important;
            transform: translateX(1px);
        }

        .region.col-right {
            padding-left: 0 !important;
        }
    }
}

.sf-dy-hpslider2 {
    @include media-breakpoint-down(sm) {
        margin-top: -100px;
    }

    @include media-breakpoint-up(sm) {
        margin-top: -40px;
    }
}

#sf-dy-hpslider3 {
    @include media-breakpoint-up(sm) {
        margin-top: -40px;
    }
}
