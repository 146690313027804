@import "../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.thumbtxt {
    font-size: 8px !important;
    line-height: 1.1;
    display: block;
    margin-top: 4px;
    position: absolute !important;
    bottom: 0;
    width: 100%;
    max-width: 70px;
    text-align: center;
    color: #fff !important;
    background: #1e1e2c !important;
    padding: 2px 0 !important;
    left: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.subMenu .dropdown-toggle::after {
    display: none !important;
    color: transparent;
}

.dropdown-toggle::after {
    display: none !important;
    color: transparent;
}

.certificate-holder {
    height: 70px;
}

.thumbtxtCertificate {
    font-size: 8px !important;
    line-height: 1.1;
    display: block;
    margin-top: 4px;
    position: absolute !important;
    bottom: 0;
    text-align: center;
    color: #fff !important;
    background: #1e1e2c !important;
    padding: 2px 2px !important;
    left: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.thumbtxtCert {
    font-size: 8px !important;
    line-height: 1.1;
    display: block;
    margin-top: 4px;
    position: absolute !important;
    bottom: 0;
    text-align: center;
    color: #fff !important;
    background: #1e1e2c !important;
    padding: 2px 2px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 10px;
}

.checkUL {
    left: 100%;
    top: 0;
    min-width: 0;
    height: 72px;
    margin-top: -1px;

    @include media-breakpoint-down(sm) {
        margin-left: -154px;
        margin-top: -75px;
    }
}

.displayUl {
    display: flex;
    margin-top: -8px;
}

.image1 {
    width: 70px;
    height: auto;
}

.image {
    display: block;
    width: 70px;
    height: auto;
}

div.dropdown-vertically {
    position: relative !important;
    width: 70px !important;

    ul li img {
        max-width: none;
    }
}

div.dropdown-vertically ul {
    padding-left: 0;
    list-style: none;
}

div.dropdown-vertically a {
    display: block;
    text-decoration: none;
}

div.dropdown-vertically li {
    padding-right: 16px;
}

div.dropdown-vertically li:hover {
    border-color: #868ce0;
}

div.dropdown-vertically ul ul li a img:hover {
    border: 1px solid purple;
}

.primary-images-container {
    min-height: 500px;
    position: relative;

    @include media-breakpoint-up(md) {
        display: flex;
        justify-content: space-between;

        #imageExpandModal .modal-body .primary-images > & {
            display: flex;
            justify-content: space-between;
        }
    }

    @include media-breakpoint-up(xl) {
        width: 95%;
    }

    &.horizontal-images-nav {
        flex-flow: column;

        @include media-breakpoint-up(xl) {
            width: 100%;
        }

        .pdp-main-carousel-container {
            order: 0;
            z-index: 1;

            @include media-breakpoint-up(sm) {
                width: 100%;
            }

            @include media-breakpoint-up(lg) {
                width: 100%;
            }
        }

        .pdp-nav-carousel {
            order: 0;

            @include media-breakpoint-up(sm) {
                width: 100%;
            }

            @include media-breakpoint-up(lg) {
                width: 100%;
            }
        }
    }
}

.pdp-main-carousel-container {
    aspect-ratio: 1/1;

    img {
        aspect-ratio: 1/1 auto;
    }

    @include media-breakpoint-up(md) {
        width: calc(100% - 48px);
        order: 2;
    }

    @include media-breakpoint-up(lg) {
        width: calc(100% - 90px);
    }
}

.pdp-nav-carousel {
    z-index: 99;
    padding: 10px 0;
    border-bottom: 1px solid $alto;
    margin-bottom: 15px;

    @include media-breakpoint-up(md) {
        position: relative;
        width: 48px;
        padding: 0;
        margin: 0;
        border-bottom: 0;
        order: 1;
    }

    @include media-breakpoint-up(lg) {
        width: 90px;
    }

    .image1 {
        @include media-breakpoint-up(sm) {
            max-width: 46px;
        }

        @include media-breakpoint-up(lg) {
            max-width: 70px;
        }
    }

    .video-thumbnail-image {
        padding: 10px;
    }

    .img {
        max-width: 70px;
        border: 1px solid $alto;
        aspect-ratio: 1/1 auto;

        @include media-breakpoint-up(md) {
            max-width: 46px;

            &:hover {
                border-color: $venice-blue;
            }
        }

        @include media-breakpoint-up(lg) {
            max-width: 70px;
        }
    }

    .highlighted {
        .img {
            border-color: $curious-blue;
        }
    }

    .nav-item {
        width: 95px !important;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }

    .video-360-container-second {
        width: 95px !important;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }

    .bx-next,
    .bx-prev {
        left: 30px;
        color: $black !important;
    }

    .bx-next {
        bottom: -50px;
    }

    .bx-prev {
        top: -50px;
    }
}

.zoom-container {
    @include media-breakpoint-up(xl) {
        max-width: 600px;
    }
}

#imageExpandModal {
    .modal-dialog {
        max-width: 68em;

        .close {
            text-align: right;
            padding-right: 8px;
            padding-top: 6px;
        }

        .pdp-nav-carousel {
            .bx-wrapper {
                max-width: 125px !important;

                .bx-viewport {
                    height: 100% !important;
                }
            }

            .highlighted {
                border-right: 1px solid $navy-blue;
                width: 90% !important;

                &::after {
                    left: 100%;
                    top: 50%;
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                    border-color: $white;
                    border-left-color: $navy-blue;
                    border-width: 10px;
                    margin-top: -10px;
                }

                .img {
                    border: unset;
                }
            }
        }

        .zoom-container {
            margin: 0 auto;
        }

        .social-icons .social-icon {
            width: 28px;
        }
    }
}

.pdp.auction-pdp {
    .nav-item {
        .img {
            border: 1px solid #f7f7f7 !important;
        }

        &.highlighted {
            .img {
                border: 1px solid #3190e8 !important;
            }
        }
    }
}

.livetv-pdp-carousel-modal {
    .modal-dialog {
        .modal-content {
            border: none !important;

            .livetv-modal-header {
                display: flex !important;
                justify-content: space-between !important;
                background-color: #f7f7f7;

                p {
                    font-size: 20px;
                    font-weight: bold;
                    padding: 7px 10px;
                    color: $black;
                }

                button {
                    i {
                        padding-right: 7px;
                    }
                }
            }

            .video-section {
                .video-js {
                    width: 100% !important;
                    left: 0;
                }
            }
        }
    }

    @include media-breakpoint-down(xs) {
        margin: 0 !important;
        padding-right: 0 !important;
    }
}

/** --CRO Imagecarousel styles-- **/
.CRO_generic_color {
    .pdp-nav-carousel {
        .img {
            @include media-breakpoint-up(sm) {
                &:hover {
                    border-color: $primarybtn-color;
                }
            }
        }
    }

    .pdp.auction-pdp {
        .nav-item {
            &.highlighted {
                .img {
                    border: 1px solid $primarybtn-color !important;
                }
            }
        }
    }
    // .zoom-container {
    //     max-width: 100% !important;
    //     width: 100% !important;
    // }

    #imageExpandModal {
        .modal-dialog {
            .pdp-nav-carousel {
                .highlighted {
                    border-right: 1px solid $primarybtn-color;

                    &::after {
                        border-left-color: $primarybtn-color;
                    }
                }
            }
        }
    }
}
