@import "../../../variables";
@import "bootstrap/scss/mixins/breakpoints";
$fonts-relative-path-prefix: "../../../";

@import "../../../base/fonts";

$kinda-purple: #333;

.content-tile-container {
    @include media-breakpoint-up(md) {
        margin-right: 10px;
    }

    @include media-breakpoint-up(lg) {
        margin-right: 20px;
    }

    span {
        font-size: 14px;
        line-height: 20px;
        color: $black;

        @include media-breakpoint-up(sm) {
            font-size: 13px;
        }

        @include media-breakpoint-up(md) {
            font-size: 18px;
        }
    }

    a {
        &:hover {
            text-decoration: none;
        }
    }
}

.content-tile-logo {
    width: 80%;
    margin: 0 auto;

    @include media-breakpoint-up(sm) {
        width: 50%;
    }

    @include media-breakpoint-up(md) {
        width: 55%;
    }

    @include media-breakpoint-up(lg) {
        width: 60%;
    }
}

.content-text-wrapper {
    text-align: center;
    padding: 5px;
    margin: 15px 0 10px 0;

    @include media-breakpoint-up(sm) {
        margin: 5px 0;
    }

    @include media-breakpoint-up(md) {
        padding: 0 10px;
        margin: 10px 0;
    }
}

.content-tile-text {
    text-align: center;

    .highlighted {
        display: block;
        font-size: 35px;
        line-height: 45px;
        color: $kinda-purple;
        font-family: $opensans-bold;

        @include media-breakpoint-up(sm) {
            font-size: 24px;
        }

        @include media-breakpoint-up(md) {
            font-size: 28px;
        }

        @include media-breakpoint-up(lg) {
            font-size: 35px;
        }

        @include media-breakpoint-up(xl) {
            font-size: 40px;
        }
    }
}

.btn-white-secondary {
    min-width: 170px;
    width: 30%;
    padding: 8px 12px;
    font-size: 16px;
    line-height: 20px;
    font-family: $opensans-regular;
    border-radius: 3px;
    border-color: $light-gray;

    @include media-breakpoint-up(sm) {
        line-height: 1.6em;
    }
}
