@import "~@sfra/scss/components/quickView";
@import "../product/video360.scss";
@import "../product/zoomModal.scss";
@import "../product/auctionProduct.scss";
@import "../product/productSpecifications.scss";

.list-unstyled {
    margin-bottom: 0;
}

.modal-overflow {
    overflow: auto;
}

.close-modal {
    overflow: hidden;
}

.wishlistcontent {
    width: 93%;
}

#quickViewModal {
    z-index: 1043 !important;

    .specification-scroll {
        font-size: 13px;
        font-weight: 600;
        max-height: 115px;

        .border-bottom {
            border: none !important;
        }
    }

    .product-quickview {
        color: $dark-gunmetal;
        margin: 0;

        &:not(.auction-quickview) {
            .main-info-container {
                max-height: 760px;
                overflow-x: hidden;
                overflow-y: auto;

                @include media-breakpoint-up(lg) {
                    padding: 0;
                }

                @include media-breakpoint-only(md) {
                    padding-left: 0;
                    padding-right: 0;
                }

                @include media-breakpoint-down(sm) {
                    padding-left: 0;
                }

                &::-webkit-scrollbar {
                    width: 4px;
                }

                &::-webkit-scrollbar-track {
                    background: $white-smoke;
                    border-radius: 6px;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $dark-gray;
                    border-radius: 6px;
                }
            }
        }

        .quickview-close {
            position: absolute;
            top: -12px;
            right: 0;
            margin-top: -5px;

            @include media-breakpoint-only(md) {
                top: -11px;
                right: 3px;
            }
        }

        button.close {
            font-size: 21px !important;
            color: $night-rider;
            opacity: 1;
            z-index: 10;
        }

        .yotpo-widget-clear {
            cursor: pointer !important;
        }

        .certificate-badge-container,
        .zoom-hint,
        .social-container {
            display: none;
        }

        .quickview-images {
            .primary-images-container {
                display: block;
            }

            .pdp-main-carousel {
                width: 100%;
            }

            .pdp-nav-carousel {
                width: 100%;
                margin-top: 10px;

                .nav-item {
                    width: 83px !important;

                    @include media-breakpoint-only(md) {
                        width: 65px !important;
                    }
                }

                img {
                    border: 1px solid $grey-border;
                    border-radius: 4px;

                    @include media-breakpoint-only(md) {
                        max-width: 60px;
                    }
                }
            }

            .owl-nav {
                .owl-next {
                    color: $night-rider;
                    margin-right: 20px;
                }

                .owl-prev {
                    color: $night-rider;
                    margin-left: 20px;
                }

                ::before {
                    font-size: 28px;
                }
            }

            .text-center {
                display: none !important;
            }
        }

        .product-id {
            font-size: 16px;
            font-family: $opensans-regular;
            font-weight: 600;
            margin-bottom: 8px;
            color: $night-rider;

            @include media-breakpoint-only(md) {
                font-size: 14px;
            }

            @include media-breakpoint-down(sm) {
                padding-left: 0;
            }
        }

        .product-name {
            font-size: 18px;
            font-family: $opensans-bold;
            font-weight: 700;
            color: $night-rider;
            margin-bottom: 8px;

            @include media-breakpoint-only(md) {
                font-size: 16px;
                padding-right: 11px;
            }
        }

        .expand-product {
            color: $night-rider;
            font-family: $opensans-bold;
            font-size: 16px;
            padding: 0 0 10px 14px;
            vertical-align: middle;
            margin-top: -2px;

            &:focus {
                box-shadow: none;
            }

            @include media-breakpoint-only(md) {
                padding-left: 15px;
                font-size: 12px;
            }

            .fa {
                color: $night-rider;
                margin-right: 2px;
            }
        }

        .prices {
            font-weight: bold;

            @include media-breakpoint-only(md) {
                //margin-bottom: 22px;
            }

            .price {
                text-decoration: unset;
                margin-bottom: 8px;

                .price-container {
                    display: block;

                    .clearance-price-label {
                        display: block;
                        position: relative;
                        width: fit-content;
                        margin: 0 0 8px 15px;
                        padding: 2px 8px;
                        font-family: $opensans-bold;
                        font-size: 12px;
                        background-color: $red2;
                        color: $white;

                        &::after {
                            position: absolute;
                            content: "";
                            right: -10%;
                            top: 0;
                            height: 100%;
                            width: 15px;
                            background-color: inherit;
                            -webkit-transform: skewX(-15deg);
                            -moz-transform: skewX(-15deg);
                            -ms-transform: skewX(-15deg);
                            transform: skewX(-15deg);
                        }
                    }

                    .price-label {
                        color: $price-grey;
                        text-transform: uppercase;
                    }

                    .price-item {
                        font-size: 13px;
                        font-weight: normal;
                        color: $night-rider;
                        text-align: left;

                        &.sales {
                            font-size: 18px;
                            font-family: $opensans-bold;
                            color: $night-rider;
                            text-align: left;
                            padding-right: 12px !important;
                        }

                        &.strike-through {
                            font-size: 12px;
                            text-align: left;
                        }
                    }

                    .old-value {
                        font-size: 13px;
                        color: $gray;
                        padding-top: 5px;
                        padding-left: 9px;
                        text-decoration: line-through;
                    }
                }
            }

            .salesprice-promotion-free-shipping {
                position: relative;
                display: flex;

                &:not(.freeshippingproduct) {
                    .promotion-callout-wrapper {
                        left: 75px;
                        margin-left: 42px;

                        @include media-breakpoint-only(md) {
                            top: 26px;
                            left: 0;
                        }
                    }
                }

                .promotion-callout {
                    &-wrapper {
                        position: absolute;
                        left: 300px;
                        top: 3px;
                        font-size: 13px;
                        font-weight: 600;
                        font-family: $opensans-regular;
                        color: $lc-red !important;

                        @include media-breakpoint-only(md) {
                            top: 30px;
                            left: 0;
                        }
                    }

                    &-label {
                        display: none;
                    }
                }

                .free_shipping_badge {
                    background-color: $blue-primary;
                    position: absolute;
                    left: 95px;
                    top: 2px;
                    padding: 3px 10px;
                    color: $white;
                    font-size: 10px;
                    font-family: $opensans-bold;
                    text-transform: uppercase;
                    margin-left: 25px;

                    img {
                        margin-right: 8px;
                    }
                }

                &.clearanceproductqv {
                    .free_shipping_badge {
                        top: 30px;
                        margin-left: 70px;
                    }

                    .promotion-callout-wrapper {
                        top: 32px;

                        @include media-breakpoint-only(md) {
                            top: 60px;
                        }
                    }
                }

                &:not(.clearanceproductqv) {
                    .free_shipping_badge {
                        top: 2px;
                    }

                    .promotion-callout-wrapper {
                        top: 2px;

                        @include media-breakpoint-only(md) {
                            top: 30px;
                        }
                    }
                }
            }
        }

        .promo-badge-container {
            position: relative;
            border: 1px dashed $promo-badge;
            padding: 18px 18px 12px 18px;
            border-radius: 4px;
            display: inline-block;
            margin-top: 20px;

            .promotinal-code-arrow {
                position: absolute;
                top: -12px;
                left: 18px;

                &::before {
                    content: "";
                    width: 6px;
                    height: 6px;
                    border-radius: 5px;
                    border: 1px solid $promo-badge;
                    position: absolute;
                    right: 0;
                    z-index: 10;
                    top: 38%;
                }

                .arrow-box {
                    position: relative;
                    background: $white;
                    border: 1px solid $promo-badge;
                    padding: 3px 15px 3px 9px;
                    border-right: none;
                    border-radius: 2px;
                    font-size: 10px;
                    font-weight: 700;
                    letter-spacing: 1px;
                    color: $promo-badge;
                    text-transform: uppercase;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;

                    &::before,
                    &::after {
                        left: 100%;
                        top: 50%;
                        border: solid transparent;
                        content: " ";
                        height: 0;
                        width: 0;
                        position: absolute;
                        pointer-events: none;
                    }

                    &::before {
                        border-color: rgba(173, 153, 121, 0);
                        border-left-color: $promo-badge;
                        border-width: 11px;
                        margin-top: -11px;
                    }

                    &::after {
                        border-color: rgba(255, 255, 255, 0);
                        border-left-color: $white;
                        border-width: 10px;
                        margin-top: -10px;
                    }
                }
            }

            .promotional-code-inner {
                font-weight: 600;
                font-size: 12px;
            }
        }

        .estimated-values {
            font-family: $opensans-regular;
            font-size: 10px;
            font-weight: 600;
            display: flex;
            flex-direction: row;
            margin: 20px 0 8px;

            .strike-through-wrapper {
                display: none;
            }

            .estprice-wrapper {
                margin-right: 10px;
                text-decoration: none;
                color: $night-rider;
            }

            .save-wrapper {
                padding-left: 10px;
                border-left: 1px solid $night-rider;
                color: $black;
            }

            .pdp-savings {
                color: $night-rider;
            }

            &.value-check-ra {
                margin: 0 6px;
            }
        }

        .quantity-availdays {
            display: flex;
            margin-bottom: 20px;

            @include media-breakpoint-only(md) {
                margin-bottom: 40px;
            }

            .attribute {
                &.quantity {
                    margin-top: 0 !important;
                }
            }

            .availDays {
                padding: 0;

                @include media-breakpoint-up(lg) {
                    display: flex;
                }

                .product-availability {
                    margin-top: auto;

                    .col-12 {
                        padding-right: 0;
                    }

                    .availability-text {
                        text-align: left !important;
                        display: none;
                    }

                    .in-stock {
                        display: none;
                    }

                    .low-stock {
                        border-radius: 100px;
                        padding: 4px 8px;
                        color: $white;
                        background-color: $lc-red;
                        margin: 8px -13px 8px 8px;

                        @include media-breakpoint-only(md) {
                            width: 41%;
                        }

                        @include media-breakpoint-up(lg) {
                            //margin: auto 22px 0 0;
                        }

                        img {
                            margin: 0 0 4px 4px;
                        }
                    }

                    .not-available {
                        display: none;
                    }
                }

                .avail-desc-qv {
                    font-size: 12px;
                    font-weight: 600;
                    font-family: $opensans-regular;
                    color: $night-rider;
                    margin: auto 0 auto 35px;
                    padding-left: 0;

                    @include media-breakpoint-only(md) {
                        position: absolute;
                        left: -146px;
                        top: 48px;
                    }
                }
            }
        }

        .addtocart-wishlist {
            margin-bottom: 9px;
        }

        .fastbuy-whatisfastbuy {
            margin-bottom: 20px;
        }

        .attributes {
            font-weight: 600;
        }

        .full-pdp-link {
            font-weight: bold;
            font-size: 16px;
            font-family: $opensans-regular;
            color: $blue-primary;
            text-transform: uppercase;
            border-bottom: 1px solid $blue-primary;
            padding: 0 0 4px 0;
            width: 32%;
            display: flex;
            text-decoration: none;

            @include media-breakpoint-only(md) {
                width: 47%;
            }

            i.fa {
                margin-left: 4px;
                margin-top: 2px;
            }
        }

        .actions-row {
            margin-left: 0;
            margin-right: 0;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            &.false {
                .cart-wrapper {
                    pointer-events: none;
                    opacity: 0.7;
                }

                .main-fast-buy-wrapper,
                .js-fast-buy-what-is-this {
                    display: none !important;
                }
            }

            .cart-wrapper {
                padding-left: 0;
                padding-right: 0;

                a {
                    color: $white;
                    font-size: 18px;
                    font-weight: bold;
                    text-transform: uppercase;
                    text-align: center;
                    text-decoration: unset;
                    cursor: pointer;
                    padding: 9px;
                    margin: auto;
                    background-color: $lilac-purple;
                    width: 100%;
                    display: block;

                    i.fa {
                        margin: 0 2px;
                    }
                }

                .fa-shopping-cart {
                    display: none;
                }
            }

            .fastbuy-wrapper {
                a {
                    color: $white;
                    font-size: 18px;
                    font-weight: bold;
                    text-transform: uppercase;
                    text-align: center;
                    text-decoration: unset;
                    cursor: pointer;
                    padding: 9px;
                    margin: auto;
                    background-color: #329f5c;
                    width: 100%;
                    display: block;

                    i.fa {
                        margin: 0 2px;
                    }

                    span.fast-buy-button-inner {
                        font-size: 18px !important;
                    }
                }

                .fa-angle-double-right {
                    display: none;
                }
            }

            .what-is-this {
                margin: auto auto auto 5px;

                @include media-breakpoint-only(md) {
                    margin: auto;
                }

                span {
                    color: $lilac-purple;
                    cursor: pointer;
                }

                span:hover {
                    color: $lilac-purple;
                    text-decoration: none;
                }

                .fa-question-circle {
                    color: $light-grey1;
                    font-size: 18px;
                }
            }
        }

        .deliveryDate {
            float: left;
            width: 100%;
            margin: 24px 0;
            font-size: 13px;

            .delivery-disclaimer-first {
                color: $lc-red;
                font-size: 14px;
                text-align: left;
                font-weight: bold;
            }

            .delivery-disclaimer-second {
                color: $lc-red;
                font-size: 12px;
                text-align: left;
                font-weight: bold;
                margin-top: 5px;
            }
        }

        .wishlistcontent {
            display: flex;

            @include media-breakpoint-up(lg) {
                width: 92.5%;
            }

            @include media-breakpoint-only(md) {
                padding-left: 9px;
            }
        }

        .qv-wishlist-add,
        .remove-wishlist-btn,
        .qvm-wishlist-add {
            .btn {
                padding: 0 !important;

                .fa {
                    color: $wishlist-grey;
                    font-size: 26px;
                    border: 1px solid $night-rider;
                    background: $white;
                    border-radius: 3px;
                    padding: 6px;

                    @include media-breakpoint-up(lg) {
                        margin-left: 5px;
                    }

                    @include media-breakpoint-only(md) {
                        padding: 5px;
                    }
                }

                span {
                    text-transform: uppercase;
                    color: $price-grey;
                    font-weight: bold;
                }

                &:active {
                    background: transparent !important;
                }
            }
        }

        .budget-pay-container {
            text-align: left;
            display: flex;
            margin-bottom: 3px;
            margin-left: 0;

            .bpblack-logo {
                .svg-img {
                    margin-top: -12px;
                }
            }

            .budget-pay {
                display: flex;
                color: $blue-dark2;
                font-size: 14px;
                font-weight: 600;
                font-family: $opensans-regular;
                margin-left: 0;
            }

            .bpblue-logo-desktop {
                .svg-img {
                    margin-top: -7px;
                    margin-right: 5px;
                }
            }

            .contentAssettooltipPDP {
                &.fa-info-circle {
                    color: #8d8d8d;
                    font-size: 16px;
                    margin-left: 5px;
                }
            }

            .tooltip-popup {
                &.show {
                    top: -68px !important;
                    box-shadow: 3px 1px 10px #0006;
                    padding: 7px 10px;
                    max-width: 345px;
                    width: 100% !important;
                }

                .tooltip-popup-msg {
                    width: 90%;

                    @include media-breakpoint-down(md) {
                        font-size: 12px;
                    }
                }
            }
        }

        .video-360-container {
            position: absolute;
            right: 35px;
            top: 0;

            a.video-360-btn {
                &:hover {
                    text-decoration: none;
                }

                .video-icon {
                    margin-left: -5px;
                    float: left;
                    width: 24px;
                    height: 24px;
                    color: $dark-gray3;

                    .video-circle {
                        color: $curious-blue;
                        line-height: 2;
                    }

                    .video-play {
                        font-size: 0.5em;
                        margin-left: 1px;
                        color: $black;

                        &.fa-play::before {
                            border-radius: 50%;
                            border: 1px solid black;
                            padding: 3.2px 4px 3.5px 4px;
                        }
                    }
                }

                .video-label {
                    color: $night-rider;
                    font-weight: 600;
                    font-size: 15px;
                    vertical-align: middle;
                    margin-left: 2px;
                    position: relative;
                    top: 2px;

                    @include media-breakpoint-only(md) {
                        font-size: 12px;
                    }
                }
            }
        }

        .row {
            .offset-1 {
                padding-left: 0 !important;
                margin-top: 60px;
            }
        }

        #fastBuyModal {
            margin: 0 auto;
            top: 10%;

            .modal-content {
                box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
                border: 1px solid rgba(0, 0, 0, 0.2);
            }

            .modal-dialog {
                width: 300px;
            }

            .fast-buy-text,
            .fast-buy-detail {
                font-size: 13px;
                line-height: 1.6;
                text-align: justify;
            }
        }

        .out-of-stock-badge {
            display: none;
        }

        .secure-payment-options-quickview {
            padding: 10px 0 20px;

            @include media-breakpoint-only(md) {
                padding: 12px 0 24px;
            }

            .secure-payment-options-label {
                margin-bottom: 10px;
                font-size: 13px;
                font-family: "OpenSans-Bold", arial, sans-serif;
                letter-spacing: 0;
                color: $night-rider;
            }

            img {
                @include media-breakpoint-only(md) {
                    max-width: 300px;
                }
            }
        }

        .yotpo-widget-instance {
            .yotpo-headline {
                align-self: center !important;
            }
        }

        .product-number-rating {
            padding: 10px 0 10px 0;

            @include media-breakpoint-down(sm) {
                display: block;
            }
        }

        .buy-it-now-pdp {
            background-color: #f9d84c;
            width: 385px !important;
            height: 48px;
            font-weight: 700;
            font-family: $opensans-bold;
            font-size: 13px;
            display: flex;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            padding: 0;
            text-decoration: none;
            border: none;
            color: #212529;
            border-radius: 4px;

            @include media-breakpoint-down(sm) {
                width: 90% !important;
                margin: 0 15px;
            }

            @include media-breakpoint-only(sm) {
                width: 365px !important;
                margin: 25px 0 0 0;
            }
        }

        .maxBid {
            margin: 0 0 0 40px;
        }
    }

    .flex-qty {
        flex-direction: column;

        .wl-quantity-label {
            font-size: 12px;
            font-weight: $font-wt-regular;
        }
    }

    .number-spinner {
        border: 1px solid $night-rider;
        border-radius: 0;
        padding: 3px;

        .quantity {
            color: $night-rider;
            background-color: $white !important;
            padding: 5px 0;
        }

        .form-control,
        .input-group-btn .btn {
            background: $white;
            display: inline-block;
            font-size: 14px;
            border: none !important;

            &:focus {
                box-shadow: unset;
            }
        }

        .input-group-btn {
            .btn {
                color: $blue-dark1;
                padding: 0;
            }

            .prdMax {
                color: gray;
            }

            .prdMax:hover {
                color: $navy-blue !important;
                cursor: not-allowed;
            }

            .btn:hover {
                color: $night-rider;
            }

            .btn:active,
            .btn:focus {
                color: $night-rider;
            }

            .fa {
                font-weight: 300;
                font-size: 12px;
                padding: 12px 8px;
            }

            .fa-plus,
            .fa-minus {
                &::before {
                    -webkit-text-stroke: 0.5px white;
                }
            }
        }
    }

    .product-specification {
        width: 100%;

        h3 {
            color: $night-rider;
            font-size: 13px;
            text-transform: uppercase;
            padding: 10px 0;
            font-weight: bold;
            margin: 0;
            font-family: $opensans-bold;
        }

        .product-des {
            border-top: 1px solid $incredible-gray;
            border-bottom: 1px solid $incredible-gray;
        }
    }

    .show-buttons-wrap {
        padding: 0 0 10px;
        border-bottom: 1px solid $alto;
        margin: 10px 0 15px;
    }

    .show-zoom-btn {
        cursor: pointer;
        padding-left: 10px;

        .glyphicon-search {
            font-size: 16px;
            color: $lilac-purple;
            z-index: 1;
        }
    }

    .auction-quickview {
        @include auction-product-details();

        button.close {
            font-family: $opensans-bold;
            font-size: 30px !important;
            line-height: 2.5rem;
            padding-bottom: 0;
        }

        // .current-bid-value,
        // .highest-bidder-name {
        //     @include media-breakpoint-up(sm) {
        //         font-size: 25px;
        //     }
        // }

        .modal-header {
            padding: 0 15px 15px;
            margin-bottom: 15px;

            @include media-breakpoint-down(sm) {
                border-bottom: none !important;
            }
        }

        .product-name {
            font-family: $opensans-regular;
            font-size: 13px;
        }

        .primary-images-container {
            padding: 0 10px;
        }

        .auction-details-left {
            @include media-breakpoint-up(sm) {
                padding: 10px 0 40px;
            }
        }

        .quickview-images .owl-nav .owl-next {
            color: $dusty-gray;
        }

        .estimated-values .strike-through {
            font-weight: normal;
            margin: 0;
            text-decoration: line-through;
        }

        .bid-history-product-details-wrap .pdp-panels-wrapper {
            border: none;
            border-top: 2px solid $solitude;
        }

        #product-details-panel .product-short-desc {
            margin-bottom: 20px;
        }

        .show-zoom-mobile {
            display: none;
        }

        .play-video-btn {
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }

    .on-air-quickview {
        .live-tv-pdp-section {
            .start-price {
                .start-price-label {
                    text-transform: none !important;
                    font-size: 10px !important;
                    color: $night-rider !important;
                    margin-bottom: 2px;
                }

                .start-price-value {
                    font-size: 13px;
                    color: $night-rider;
                }
            }

            .addtocart-wishlist-livetvqv {
                #continueToCheckout {
                    max-width: 100%;
                }

                .livetv-add-to-cart {
                    font-size: 18px !important;
                    line-height: 2.2;

                    span {
                        color: $white !important;
                        background: #868ce0 !important;
                        cursor: pointer;
                    }
                }

                .wishlistcontent {
                    .qv-wishlist-add,
                    .remove-wishlist-btn,
                    .qvm-wishlist-add {
                        margin-left: 0;
                        padding: 0;
                        border: none;

                        @include media-breakpoint-down(sm) {
                            margin-left: -6px !important;
                        }
                    }

                    .btn-block {
                        font-size: 18px;

                        i {
                            margin-right: 0;
                        }
                    }

                    span {
                        display: none;
                    }
                }
            }

            .product-short-desc,
            .specifications {
                font-size: 13px;

                .border-bottom {
                    border: none;
                }
            }

            .estimated-price,
            .save-value {
                font-size: 13px;
                text-decoration: underline;

                .link-modal {
                    font-size: 13px;
                }

                .list {
                    text-decoration: none;
                    font-weight: $font-wt-thin;
                }
            }

            .estimated-price {
                color: black;
            }

            .save-value {
                color: $persian-red;
            }

            .pdp-nav-carousel {
                border: none;
                padding: 0;
            }

            .product-pricing {
                margin-bottom: 20px;
                flex-wrap: nowrap;
                justify-content: flex-start;

                .form-group {
                    label {
                        margin: 0 auto !important;
                    }
                }

                .product-current-price {
                    background: $white-light1;
                    border-radius: 4px;
                    padding: 6px 12px !important;

                    input {
                        &:checked + div {
                            &::before {
                                position: absolute;
                                left: 0;
                                height: calc(100% + 2px);
                                width: calc(100% + 2px);
                                border: 1px solid $lilac-purple;
                                content: "";
                                top: 0;
                                border-radius: 3px;
                            }
                        }

                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 100%;
                        z-index: 9;
                        opacity: 0;
                    }

                    &.bp-price {
                        margin-left: 10px;
                    }
                }
            }

            .shoplc-price {
                .current-price-label {
                    font-size: 16px !important;
                    margin: 0 auto 5px !important;
                    background-position: center;
                    line-height: 11px;
                }

                .current-price-value {
                    font-style: normal;
                    font-weight: 600 !important;
                    font-size: 16px !important;
                    line-height: 22px;
                    text-align: center;
                    text-transform: capitalize;
                    color: $night-rider !important;
                }
            }

            .bp-price {
                .bp-value {
                    font-style: normal;
                    font-weight: 600 !important;
                    font-size: 14px !important;
                    line-height: 22px !important;
                    text-align: center;
                    color: $night-rider !important;

                    .live-tv-pdp-section .product-pricing {
                        gap: 10px;
                        font-style: normal;
                        font-weight: 600 !important;
                        font-size: 16px !important;
                        line-height: 22px !important;
                        text-align: center;
                        color: $night-rider !important;
                    }
                }
            }

            .product-shipping-wrapper {
                margin-top: 20px;
            }

            .secure-payment-options-quickview {
                padding: 18px 0 20px;
            }

            &.right {
                max-height: 680px;
                overflow-x: hidden;
                overflow-y: hidden;

                .estimated-values {
                    padding: 10px 0;
                }

                .fastbuy-livetvqv {
                    margin-top: 8px;

                    .fastbuy-wrapper {
                        @include media-breakpoint-only(md) {
                            line-height: 1.3;
                        }

                        a {
                            color: $white;
                            text-transform: uppercase;
                            text-align: center;
                            background-color: $green-light1 !important;
                            text-decoration: unset;
                            cursor: pointer;
                            padding: 9px;
                            width: 100%;
                            display: block;

                            i.fa {
                                margin: 0 2px;
                            }

                            .fast-buy-button-inner {
                                font-size: 18px !important;
                            }
                        }

                        &.cart-wrapper {
                            background-color: $curious-blue;
                        }

                        &.fastbuy-wrapper {
                            background-color: $green-light1;
                            border-radius: 2px;
                        }
                    }

                    .live-tv-what-is-fast-buy {
                        margin: 12px 0 0 20px;

                        @include media-breakpoint-only(md) {
                            margin: 16px 0 0 9px;
                        }

                        @include media-breakpoint-down(sm) {
                            margin: 12px 0 0 10px;
                        }

                        .fa-question-circle {
                            color: $light-grey1;
                            font-size: 18px;
                        }

                        .live-tv-what-is-fast-buy-info {
                            position: absolute;
                            left: -240px;
                            top: 10px;
                            width: 285px;

                            &::before {
                                display: none;
                            }
                        }
                    }
                }
            }

            .product-options {
                margin: 0 0 20px;

                @include media-breakpoint-up(lg) {
                    display: flex !important;
                }

                .product-quantity {
                    .product-qty-control {
                        border: 1px solid $night-rider;
                        padding: 7px 5px;

                        .glyphicon {
                            color: $blue-dark1;
                        }

                        .fa {
                            font-weight: 300;
                            font-size: 12px;
                            padding: 10px 8px;
                            color: $blue-dark1;

                            &.fa-plus,
                            &.fa-minus {
                                -webkit-text-stroke: 0.5px white;
                            }
                        }
                    }

                    .quantity-input {
                        border-left: none;
                        border-right: none;
                    }
                }

                .availDays {
                    @include media-breakpoint-up(lg) {
                        margin-left: 5px;
                    }

                    @include media-breakpoint-only(md) {
                        margin: 5px 0;
                        padding: 0;
                    }

                    @include media-breakpoint-down(sm) {
                        padding-left: 0;
                        margin-top: 10px;
                    }

                    .avail-desc-qv {
                        font-size: 12px;
                        font-weight: 600;
                    }
                }
            }

            .full-pdp-link {
                font-weight: bold;
                font-size: 16px;
                font-family: $opensans-regular;
                color: $blue-primary;
                text-transform: uppercase;
                border-bottom: 1px solid $blue-primary;
                padding: 0 0 4px 0;
                width: 34%;
                display: flex;
                text-decoration: none;

                @include media-breakpoint-only(md) {
                    width: 47%;
                }

                @include media-breakpoint-down(sm) {
                    width: 155px;
                }

                i.fa {
                    margin-left: 4px;
                    margin-top: 2px;

                    @include media-breakpoint-only(md) {
                        margin-top: 3px;
                    }
                }
            }

            .video-circle {
                color: transparent !important;
            }
        }

        .ratings {
            margin-bottom: 20px;

            .TTteaser__rating {
                color: #fbbb20;
            }

            .TTteaser__read-reviews,
            .TTteaser__write-review {
                font-size: 12px;
                font-family: $opensans-bold;
                color: #333;
            }
        }
    }

    &.pre-purchase-upsell-widget-modal {
        .header-wrapper {
            padding-left: 60px;
            padding-right: 60px;
            position: relative;
        }

        .pre-purchase-upsell-header {
            padding: 10px 30px;
            text-align: center;
            border-radius: 30px;
            margin-top: 15px;
            position: relative;

            &::before {
                content: "";
                border-bottom: none;
                position: absolute;
                width: 23%;
                left: 0;
                bottom: 50%;

                @include media-breakpoint-up(md) {
                    border-bottom: 3px solid #F5F5F5;
                }
            }

            &::after {
                content: "";
                border-bottom: none;
                position: absolute;
                width: 23%;
                right: 0;
                bottom: 50%;

                @include media-breakpoint-up(md) {
                    border-bottom: 3px solid #F5F5F5;
                }
            }

            .upsell-timer-text {
                font-size: 20px;
                font-family: $opensans-bold;

                &-main {
                    margin-right: 20px;
                    text-transform: uppercase;
                }
            }

            .upsell-timer {
                font-size: 20px;
                font-family: $opensans-bold;
                color: #ED544A;
                padding: 4px 10px;
                background-color: #F5F5F5;
                border-radius: 20px;
            }
        }

        .quickview-close {
            position: absolute;
            right: 10px;
            top: 5px;
        }

        .quick-view-dialog {
            .modal-content {
                .modal-body {
                    padding-left: 60px !important;
                    padding-right: 60px !important;
                }
            }
        }

        .decline-offer-button {
            color: $black;
            font-size: 16px;
            text-align: center;
            text-decoration: unset;
            cursor: pointer;
            padding: 9px;
            margin: auto;
            background-color: #E6E8FF;
            width: 100%;
            display: block;
        }

        .pdp-link-wrapper {
            margin: 0 auto;
            padding-top: 10px;

            .full-pdp-link {
                font-size: 13px;
                color: #1F1F1F;
                border-bottom: 1px solid #1F1F1F;
                padding-bottom: 0;
            }
        }

        .pre-purchase-upsell-widget-nav {
            position: absolute;
            top: 50%;

            .owl-nav {
                &.owl-nav--secondary {
                    button {
                        width: 41px;
                        height: 81px;
                        background-color: #EFEFEF;

                        &::before {
                            color: #525252;
                        }
                    }
                }
            }
        }

        .upsell-promo-text {
            position: absolute;
            left: 240px;
            top: 2px;
            text-transform: uppercase;
            font-family: $opensans-bold;
            font-size: 15px;
            color: #EB254B;
        }
    }
}

.quick-view-dialog {
    max-width: 926px !important;

    @include media-breakpoint-only(md) {
        max-width: 704px !important;
    }

    .modal-content {
        margin-top: 90px;
        border-radius: 10px;

        @include media-breakpoint-down(sm) {
            margin-top: 130px;
        }

        .modal-body {
            padding: 22px !important;
            overflow-x: hidden;
            max-height: none;

            @include media-breakpoint-down(xs) {
                max-height: 750px;
                padding-bottom: 100px !important;
            }

            @include media-breakpoint-only(md) {
                padding: 22px 11px !important;
            }

            .js-live-tv-product {
                padding: 0 15px;
            }
        }
    }

    .modal-header {
        background-color: $white !important;
        border-bottom: 1px solid $mercury !important;
        padding: 13px;

        .close-icon {
            font-size: 20px;
            color: $black;
            opacity: 0.2;
        }

        .close-icon:hover {
            color: $black;
            opacity: 0.5;
            cursor: pointer;
        }

        .close {
            opacity: 0.2;

            &:hover {
                opacity: 0.5;
            }
        }
    }

    .modal-title {
        font-weight: $font-wt-bold;
        font-size: 18px !important;
        text-transform: uppercase;
        color: $lilac-purple;
    }

    .price-container {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $opensans-regular;
        font-size: 24px;
    }

    .modal-footer {
        .prices .price {
            font-size: 11px;
            text-align: center;

            .budget-pay-container {
                text-align: start;
            }
        }
    }
}

.product-quickview {
    .selected-size-detail {
        display: none;
    }
}

.live-tv-out-stock {
    .out-of-stock {
        color: $persian-red2;
        font-family: $opensans-bold;
        text-transform: uppercase;
        margin-top: 90px;
        font-size: 15px;
        padding: 10px 0;
    }
}

body.modal-open .isquickview {
    display: inline-block !important;
}

.auction-quickview .estimated-price-label {
    font-weight: 600 !important;
    font-size: 16px !important;
}

.quick-view-dialog .swatch-circle,
.choose-bonus-product-dialog .swatch-circle {
    border-radius: 0 !important;

    &.color-value.selected::after {
        opacity: 0;
    }
}

/** --CRO Qucickview styles-- **/
.CRO_generic_color {
    #quickViewModal {
        .product-quickview {
            .actions-row {
                .cart-wrapper,
                .fastbuy-wrapper {
                    &.cart-wrapper {
                        background-color: $primarybtn-color;
                    }

                    &.fastbuy-wrapper {
                        background-color: $primarybtn-color;
                    }
                }

                .what-is-this {
                    span {
                        color: $primarybtn-color;
                    }

                    span:hover {
                        color: $primarybtn-color;
                    }
                }
            }

            .video-360-container {
                a.video-360-btn {
                    .video-icon {
                        .video-circle {
                            color: $primarybtn-color;
                        }
                    }
                }
            }

            .prices {
                .price {
                    .price-container {
                        .price-item {
                            &.clearance {
                                color: $lc-red-new;
                                font-family: $opensans-regular;
                            }
                        }
                    }
                }
            }

            .estimated-values .clearance-estimated {
                color: $primarybtn-color;
                text-decoration: none;
                font-weight: 600;
            }

            .availDays .product-availability {
                color: $primarybtn-color;
            }

            .number-spinner {
                .input-group-btn .btn {
                    &:focus {
                        outline: none;
                        background: transparent;
                    }
                }

                .form-control {
                    min-width: 60px;
                    flex: 0 0 auto !important;

                    &:focus {
                        border-color: #ced4da;
                    }
                }
            }
        }

        .show-zoom-btn {
            .glyphicon-search {
                color: $primarybtn-color;
            }
        }

        .on-air-quickview {
            .live-tv-pdp-section {
                .estimated-price {
                    color: $primarybtn-color;
                }

                .save-value {
                    color: $primarybtn-color;
                }
            }
        }

        .auction-quickview {
            .save-value-label,
            .estimated-price-label {
                font-weight: 600 !important;
                text-decoration: none;
                color: $primarybtn-color;
                font-size: 16px !important;
            }

            .play-video-btn .video-label {
                color: $primarybtn-color;
            }

            .auction-user-innteraction {
                .btn-max-bid,
                .btn-bid-now {
                    background: $primarybtn-color;
                    border-color: $primarybtn-color !important;
                    color: $white;
                }
            }
        }
    }

    .quick-view-dialog {
        .modal-title {
            color: $primarybtn-color;
        }
    }

    .product-quickview {
        .live-tv-pdp-section + .right {
            .fastbuy-wrapper {
                &.fastbuy-wrapper {
                    background-color: $primarybtn-color;
                }
            }
        }
    }

    #quickViewModal .auction-quickview .current-bid-value,
    #quickViewModal .auction-quickview .highest-bidder-name,
    #quickViewModal .auction-quickview .save-value-value,
    .order-list-container .budgetpay-order-details .order-details {
        color: $primarybtn-color;
    }

    .history-heading .budgetpay-title {
        color: $primarybtn-color;
    }

    .order-list-container .budgetpay-order-details .upcoming-payments .pay-btn .pay-now.pay-now,
    .order-list-container .budgetpay-order-details .upcoming-payments .pay-btn .pay-all.pay-all {
        background: $primarybtn-color;
        color: $white;
    }

    .save-payment-creditcard .modal-header .modal-title {
        color: $primarybtn-color;
    }

    #quickViewModal .product-quickview .actions-row .fastbuy-wrapper a {
        padding: 0 6px;
    }
}

.notify-me-btn {
    width: 100%;
    height: 40px;

    button {
        color: $white;

        &:hover,
        &:focus,
        &:active {
            color: $white;
        }
    }
}

#quickViewModal .notifyme-attr {
    background-image: url("../../images/red-bell.svg");
    display: block;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    position: absolute;
    right: -5px;
    top: -10px;
    z-index: 1;
    pointer-events: visible;
}

#quickViewModal .varnotify-background {
    width: 100%;
    padding: 10px;
    background: $trans-black;
    position: fixed;
    height: 100%;
    top: 0;
    z-index: 2147483647;
    left: 0;

    .notify-mobile {
        display: block;
        position: fixed;
        height: 80px;
        width: 470px;
        top: 150px;
        left: 50%;
        transform: translate(-50%, -50%);
        right: 470px;
        background: $white;
        padding-top: 5px;
        padding-left: 15px;
        padding-bottom: 5px;
        padding-right: 20px;
        border-radius: 4px;

        @include media-breakpoint-down(sm) {
            max-width: 350px;
        }
    }

    .check-mark-attr {
        display: block;
        width: 40px;
        height: 40px;
        top: 12px;
        left: 0;
        @include media-breakpoint-down(sm) {
            width: 60px;
            height: 60px;
        }
    }

    .notify-success-msg {
        font-size: 14px;
        margin-left: 10px;
        color: $black;
        margin-top: 15px;
        text-align: left;
        @include media-breakpoint-down(sm) {
            margin-top: 0;
        }
    }
}

.check-mark-success {
    background-image: url("../../images/checkmark1.svg");
    display: block;
    width: 50px;
    height: 40px;
    background-repeat: no-repeat;
    position: relative;
    top: 15px;
}

.check-mark-error {
    background-image: url("../../images/errorIcon.svg");
    display: block;
    width: 60px;
    height: 40px;
    background-repeat: no-repeat;
    position: relative;
    top: 15px;
}

#quickViewModal .check-mark-attr {
    background-image: url("../../images/checkmark1.svg");
    display: block;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    position: absolute;
    top: -10px;
    right: -5px;
    z-index: 1;
    pointer-events: visible;
}

#quickViewModal .exclamation {
    background-image: url("../../images/exclamation.svg");
    display: block;
    width: 23px;
    height: 20px;
    background-repeat: no-repeat;
}

#quickViewModal .out-of-stock-label {
    color: $out-of-stock-red;
    font-family: $opensans-bold;
    text-transform: uppercase;
    margin: 15px 0 20px;
}

#quickViewModal .out-of-stock-message {
    font-size: 14px;
    color: $black;
    font-family: $opensans-bold;
    text-decoration: none;
    text-transform: none;
    font-weight: 600;
    margin-top: 6px;
}

#notifyMePopup {
    background: $trans-black;

    .notify-me-content {
        width: 85%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 50%);
        background: $modal-background;
        margin: 0 !important;

        .modal-header {
            border-bottom: 0 !important;
            background-color: $modal-background !important;

            .close {
                padding: 0.5rem 0.5rem;
            }
        }

        .modal-body {
            padding-left: 35px;
            padding-right: 35px;
            @include media-breakpoint-down(sm) {
                padding-left: 20px;
                padding-right: 20px;
            }

            .notify-message {
                font-family: $opensans-regular;
                color: $inline-gray;
                font-weight: 600;
            }

            .form-group,
            .notify-message {
                margin-bottom: 30px;
            }

            .form-control {
                font-size: 15px;
            }

            .accepting-terms-conditions {
                font-size: 10px;
                color: $dove-gray;

                a {
                    text-decoration: underline;
                }
            }
        }

        .modal-footer {
            padding: 0 35px 3rem 35px;
            border: none !important;
            background-color: $modal-background !important;
            @include media-breakpoint-down(sm) {
                padding: 0 20px 3rem 20px;
            }

            .modal-notify-me {
                background-color: $lilac-purple;
                border-radius: 5px;
                color: $white;
                padding: 0;
                font-size: 18px;
                height: 48px;
                font-family: $opensans-bold;
                margin-bottom: 20px;
            }

            .logout-notify-wrapper {
                margin-top: 0;
            }
        }
    }
}

.on-air,
#liveTvShopRecent25home {
    .livetv-submit-notifyme {
        font-size: 12px;
        width: 100%;
        padding: 0;
        height: 27px;
        border-radius: 0;
    }

    .notify-me-btn {
        border: none;
        font-size: 12px;
    }
}

#quickViewModal .submit-notify-me,
.submit-notify-me-sticky,
.livetv-submit-notifyme,
.modal-notify-me {
    width: 93%;
    background-color: $lilac-purple;
}

.shop-items {
    .livetv-submit-notifyme {
        font-weight: 700;
        width: 100%;
        height: 27px;
        border-radius: 0;
        cursor: pointer;
        font-size: 13px;
        background-color: $lilac-purple;
    }

    .notify-me-btn {
        margin: 0 5px;
    }
}

.product-grid .livetv-submit-notifyme {
    width: 100%;
    padding: 0;
}

.shippinginfo-quickview {
    .bold {
        display: none;
    }

    .product-shipping-wrapper {
        padding-left: 15px;

        .product-shipping-card {
            padding-left: 0;

            .product-shipping-txt {
                box-shadow: 0 3px 4px $grey-border-box;
                border: 1px solid $grey-border;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: left;
                padding: 8px 4px 8px 6px;
                height: 44px;

                @include media-breakpoint-down(sm) {
                    height: 105px;
                }

                @include media-breakpoint-only(md) {
                    padding: 8px 8px 8px 9px;
                    margin-bottom: 8px;
                }

                span {
                    color: $night-rider;
                    font-weight: 600;
                    margin-left: 3px;
                    font-size: 9px;

                    @include media-breakpoint-down(sm) {
                        //font-weight: 700;
                    }
                }

                .image-holder {
                    align-items: center;
                    background: #e2e3f6;
                    border-radius: 100%;
                    display: flex;
                    flex-shrink: 0;
                    height: 36px;
                    justify-content: center;
                    margin: 0;
                    width: 36px;
                }
            }
        }
    }
}

.free-shippingbadge-quickview {
    background-color: $lilac-purple;
}

.ring-size {
    &.modal {
        overflow-y: hidden;
    }

    .modal-dialog {
        display: flex;
        justify-content: center;
        max-width: none;
        position: absolute !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) !important;
        margin: 0 !important;
    }

    .modal-body {
        padding: 15px 10px 10px;
    }

    .modal-content {
        width: auto;
    }

    .modal-header {
        border: none;
        display: inline-block;
        width: 100%;
        text-align: center;
        padding: 10px 10px 0;
        margin-bottom: 0;

        .popover-header {
            margin-right: -1rem;
            font-family: $opensans-bold;
            font-size: 20px;
            line-height: 22px;
            color: $night-rider;
        }

        .header-title {
            margin-right: -1rem;
            color: $black;
            font-family: $opensans-regular;
            font-size: 20px;
            font-weight: bold;
        }
    }

    #user-content-sizeguidepdp {
        margin: 0 auto;
        font-size: 16px;

        td {
            padding: 5px 20px;
            text-align: center;
        }

        th {
            padding: 12px 20px;
            text-align: left;
            background-color: $alto-secondary;
            color: $black;
            font-family: $opensans-regular;
        }

        tr:nth-child(odd) {
            background-color: $concrete;
        }
    }

    .sizechart-printbtn {
        display: block;
        width: 150px;
        margin: 1em auto 0;
        font-size: 16px;
        color: $white;
        background-color: $lilac-purple;
        line-height: 2.5em;
        padding: 0 1em;
        border-radius: 5px;
        text-align: center;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    .close {
        color: $gray2;
        opacity: 1;
        padding: 12px 16px;
    }
}
