.max-quantity-modal {
    .modal-dialog {
        max-width: 600px;
    }
}

.max-quantity-modal__body {
    border-bottom: 1px solid $mercury;
    margin-bottom: 33px;
    padding-bottom: 40px;
}

.max-quantity-modal__title {
    font-family: $opensans-regular;
    text-transform: uppercase;
    color: $navy-blue;
    font-weight: $font-wt-semibold;
    font-size: 18px;
}

.max-quantity-modal__title__text {
    font-size: 13px;
}
