@import "../../../variables";
@import "bootstrap/scss/mixins/breakpoints";
$fonts-relative-path-prefix: "../../../";

.full-width-banner {
    &.fluid-true {
        margin-left: -15px;
        margin-right: -15px;
        overflow: hidden;

        @include media-breakpoint-up(xl) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &.livetv-wrap {
        background-color: #fff;
        margin-top: 0;

        @include media-breakpoint-down(sm) {
            margin-bottom: 0;
        }

        @include media-breakpoint-up(sm) {
            background-color: #d9d9f2;
            padding: 45px 0 50px;
        }

        @include media-breakpoint-up(lg) {
            .container {
                max-width: 1280px;
            }
        }

        .fa-circle {
            font-size: 10px;
            color: #e53636;
            transform: translateY(-3px);
        }
    }
}

.livetv-mobile-header {
    text-transform: uppercase;

    @include media-breakpoint-down(sm) {
        padding: 0 20px;
    }

    .title-left {
        font-size: 22px;
    }

    .title-right {
        font-size: 13px;
    }
}

.ITC-figure {
    margin-bottom: 0;
}
