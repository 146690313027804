@import "../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
$fonts-relative-path-prefix: "../";

@import "../base/fonts";

.product-detail {
    .attributes {
        padding: 0;

        .size-selection-error {
            margin-top: 10px;
        }

        .attribute {
            margin: 10px 0 0;

            .attribute-label {
                margin: 0;
                font-size: 13px;
                font-family: $opensans-regular;

                .selected-variant-value {
                    margin-left: 8px;
                    font-family: $opensans-bold;
                }

                .ring-size-chart-modal {
                    padding: 0;
                    margin-left: 5px;

                    a {
                        color: $curious-blue;
                        font-weight: normal;

                        &:hover,
                        &:visited,
                        &:focus {
                            text-decoration: none;
                        }
                    }
                }
            }

            .variation-attributes-container,
            .variation-attributes-carousel {
                .item.swatchable {
                    margin: 0 10px;
                    padding: 0;
                    background-color: transparent;
                    border: none;
                    position: relative;

                    .swatch-value {
                        margin: 0;
                        padding: 3px 8px;
                        border: 1px solid $dark-gray;
                        border-radius: 0;
                        width: 44px;
                        height: 44px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &.weight-value {
                            font-family: $opensans-bold;
                        }

                        &.size-value {
                            min-width: 44px;
                            width: fit-content;
                            max-width: 75px;
                            height: fit-content;
                            line-height: normal;
                            font-size: 14px;

                            &:hover,
                            &.selected {
                                border-width: 1px;
                                background-color: $lilac-purple;
                                color: $white;
                            }
                        }

                        &.selected {
                            border: 3px solid $lilac-purple;
                        }

                        &.unselectable {
                            opacity: 0.5;

                            &::before {
                                background: $dark-gray;
                                bottom: 0;
                                content: "";
                                left: 0;
                                margin: auto;
                                pointer-events: none;
                                position: absolute;
                                right: 0;
                                top: 0;
                                transform: rotate(52deg);
                                width: 1px;
                            }
                        }
                    }

                    .display-value {
                        display: block;
                        font-size: 10px;
                    }
                }
            }

            .variation-attributes-container {
                margin: 10px -10px 0;
            }

            .variation-attributes-carousel {
                margin: 0 -10px;

                &:has(.owl-nav:not(.disabled)) {
                    padding: 0 20px;
                    margin: 0;
                }

                .owl-stage-outer {
                    z-index: 1;

                    .owl-stage {
                        margin-top: 10px;
                        margin-bottom: 2px;
                    }

                    .owl-item {
                        line-height: 0;
                    }
                }

                .owl-nav:not(.disabled) {
                    position: absolute;
                    width: 100%;
                    top: 0;
                    right: 0;
                    margin: 0;
                    padding-top: 10px;
                    display: flex;
                    justify-content: space-between;
                    height: 100%;

                    button {
                        border: 0.5px solid $dark-gray;
                        border-radius: 0;
                        background-color: $white;
                        width: 20px;
                        color: #2d2b2b;
                        font-size: 20px;
                        height: 100%;
                        margin: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &.disabled {
                            opacity: 1;
                            color: $alto-secondary;
                        }

                        span {
                            position: relative;
                            top: -2px;
                        }
                    }
                }
            }
        }

        .quality-info {
            border: 1px solid $dark-gray;
            border-radius: 15px;
            background-color: $solitude2;
            width: 21px;
            height: 21px;
            padding: 2px;
            line-height: 1;
            margin: 8px 10px;
            position: relative;
            top: 2px;
            display: block;

            .quality-mark {
                transform: scale(0.8);
                opacity: 0.7;

                &:hover + .qualtiy-info-box {
                    display: block;
                }
            }

            .qualtiy-info-box {
                border: 1px solid $dark-gray;
                padding: 7px 15px;
                position: relative;
                top: -35px;
                right: -25px;
                border-radius: 4px;
                font-size: 10px;
                max-height: 55px;
                box-shadow: 0 1px 1px;
                width: 126px;
                text-align: left;
                display: none;
                @include media-breakpoint-down(md) {
                    top: -77px;
                    right: 55px;
                }
            }
        }
    }

    .quantity-select {
        width: 60px;
        height: 43px;
        border-color: $black;
        border-radius: 0;
        padding-right: 16px;
        padding-left: 10px;
        display: inline;

        @include media-breakpoint-up(md) {
            width: 50px;
        }

        &.custom-select {
            font-size: 14px;
            border-color: $dove-gray;
            width: auto;
            min-width: 50px;
            height: 44px;
            padding: 0 1.5rem 0 0.5rem;
            background-image: url("../../images/custom-icons/custom-select-up-down-arrows.svg");
            background-position: right 0.4em top 50%, 0 0;
            background-size: 0.6em 60%;
        }
    }
}

#wishlist-swatchas {
    height: 32px;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
    width: 90px;
    border-radius: 0;
    overflow: hidden;
    position: relative;

    .wishlist-item-sizes {
        padding: 3px 49px 5px 8px;
        width: 100%;
        border: none;
        box-shadow: none;
        background-color: transparent;
        background-image: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        height: 30px;
        outline: none;
    }

    &::before {
        border-left: 1px solid #e3e3e3;
        width: 30px;
        height: 30px;
        right: 0;
        top: 0;
        background-color: #f9f9f9;
        content: "";
        position: absolute;
        pointer-events: none;
    }

    &::after {
        position: absolute;
        pointer-events: none;
        color: #000;
        content: "\25BC";
        font-size: 8px;
        right: 10px;
        top: 10px;
    }
}

#wishlistModal {
    .add-to-wish-list {
        text-decoration: none;
        background: #868ce0;
        font-size: 13px;
        color: #fff;
        font-weight: bold;
        padding: 8px 15px;
    }

    .wishlist-title {
        font-family: $opensans-regular;
        color: #868ce0;
        font-weight: bold;
        font-size: 18px;
    }

    .quick-view-dialog {
        width: 570px;
        margin: auto;
        top: 30px;

        @include media-breakpoint-down(sm) {
            width: 95%;
        }
    }

    .modal-header {
        border-bottom: none !important;
        padding-bottom: 0;
        display: block;
    }

    .wishlist-popup-image {
        img {
            border: 2px solid #e9e9e9;
            max-width: 100%;
        }
    }
}

/** --attribute styles-- **/
.CRO_generic_color {
    .product-detail {
        .attributes {
            .swatch-circle {
                &.ringSize-value {
                    &.selected,
                    &:hover {
                        background-color: $primarybtn-color;
                    }
                }
            }
        }

        .attribute-label {
            .ring-size-chart-modal {
                a {
                    color: $primarybtn-color;
                    text-decoration: underline;
                }
            }
        }
    }

    #wishlistModal {
        .add-to-wish-list {
            background: $primarybtn-color;
        }

        .wishlist-title {
            color: $primarybtn-color;
        }
    }

    .ring-size .sizechart-printbtn {
        background-color: $primarybtn-color;
    }
}
