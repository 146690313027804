@import "../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "../base/fonts";

.zoom-modal {
    .glyphicon-remove {
        color: $navy-blue;
    }

    .zoom-modal-nav {
        display: flex;
        flex-wrap: wrap;
        padding-right: 40px;
        margin: 10px 0;
    }

    .modal-body {
        padding: 0;
    }

    .nav-item {
        width: 25%;
        display: block;
        flex: 0 0 25%;
        max-width: 25%;
        position: relative;

        &.active {
            border-bottom: 1px solid $navy-blue;

            &::after {
                content: "";
                border: solid transparent;
                border-top-color: $navy-blue;
                border-width: 10px;
                margin-top: -10px;
                left: 50%;
                bottom: -20px;
                top: auto;
                margin-left: -10px;
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                z-index: 1;
            }
        }

        .img {
            padding: 0 10px;
        }
    }

    .zoom-modal-img {
        margin: 20px 0;
    }
}

.zoom-hint {
    font-size: 11px;
    font-family: $opensans-regular;
    font-style: italic;
    line-height: 16px;
    color: $dove-gray;

    @include media-breakpoint-up(sm) {
        margin-top: 5px;
    }
}
.CRO_generic_color {
    .zoom-modal .nav-item.active:after{
        border-top-color: $primarybtn-color;
    }
    .zoom-modal .nav-item.active{
        border-bottom: 1px solid $primarybtn-color;
    }
    .zoom-modal {
        .glyphicon-remove {
            color: $primarybtn-color;
        }
    }
}