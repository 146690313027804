@import "~@sfra/scss/components/productTiles";
@import "budgetPay";
@import "../variables";
@import "components/whatisfastbuymodal";

$kinda-purple: #333;

.product-tile-component-image {
    height: auto;
}

.search-results {
    .product-grid {
        .product-tile-wrapper {
            .image-container {
                overflow-x: hidden;

                a {
                    position: relative;
                    width: 100%;
                    height: auto;
                    display: flex;
                    aspect-ratio: 1 / 1;
                
                    &::after {
                        content: '';
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        position: absolute;
                        background: #fff;
                        background-image: none;
                        opacity: .03;
                        width: 100%;
                        height: auto;
                        z-index: 2;
                        pointer-events: none;
                        aspect-ratio: 1 / 1;
                    }

                    .tile-image {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.live-tv-shop-last {
    background-color: $alabaster;
    padding: 11px 10px 16px 10px;

    @include media-breakpoint-up(sm) {
        padding: 19px 5px 0 5px;
    }

    @include media-breakpoint-up(lg) {
        padding: 19px 15px 0 15px;
    }

    .nav-item {
        padding: 10px;

        .nav-link.active {
            border-bottom: 0;
        }
    }

    .product-grid {
        .product-tile-wrapper {
            .image-container {
                overflow-x: hidden;

                a .tile-image {
                    width: 160px !important;
                }
            }

            .product-tile {
                .tile-body {
                    .actions {
                        .quickview {
                            margin: 0 4px;
                            opacity: 1 !important;
                        }

                        .actions-row {
                            .cart-wrapper {
                                margin: 0 0 0 5px !important;

                                a {
                                    padding: 0;
                                }
                            }

                            .fastbuy-wrapper {
                                margin-right: 0 !important;

                                a {
                                    padding: 10px 0 !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .show-more-button-container {
        text-align: center;

        @include media-breakpoint-up(sm) {
            margin-bottom: 20px;
        }

        .show-more-button {
            display: inline-block;
            width: 137px;
            height: 30px;
            border: 1px solid #000;
            border-radius: 4px;
            text-decoration: none;

            .btn-text {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 13px;
                text-align: center;
                letter-spacing: 0;
                color: #000;
                font-weight: bold;
                text-transform: uppercase;
            }
        }
    }
}

.container {
    .on-air {
        .product-grid {
            .product-tile {
                .image-container {
                    overflow-x: hidden;
                    min-height: 186px;

                    @include media-breakpoint-down(sm) {
                        min-height: 242px;
                    }

                    a .tile-image {
                        width: 239px !important;
                    }
                }

                .tile-body {
                    margin-top: 32%;
                }
            }
        }
    }
}

.product-grid,
.recommendations {
    .product-tile-wrapper {
        padding: 10px 10px 0;

        @include media-breakpoint-up(lg) {
            padding: 10px 5px;
        }

        .product-tile {
            font-family: $opensans-regular;
            margin-bottom: 10px;
            border: 1px solid $light-grey;
            min-height: 500px;

            @include media-breakpoint-up(lg) {
                min-height: 632px;
            }

            @include media-breakpoint-down(xs) {
                margin-bottom: 0;
                min-height: 530px;
            }

            &.general-tile {
                &:hover {
                    box-shadow: 0 0 10px $dark-gray;

                    .actions {
                        visibility: visible !important;
                    }
                }

                .actions {
                    visibility: hidden;

                    @include media-breakpoint-down(sm) {
                        display: none;
                    }

                    &.quickview-box {
                        @include media-breakpoint-down(sm) {
                            display: block;
                            visibility: visible !important;
                        }
                    }
                }

                .wishlist-air-container {
                    .wishlist-icon {
                        right: 0;
                    }
                }
            }

            .product-image-tile-body {
                padding: 10px;
                margin-top: 5px;
            }

            .tile-body {
                padding: 0;

                .swatches {
                    margin-bottom: 16px;
                }

                .update-variation-tile {
                    display: inline-block;

                    &.active {
                        border: 2px solid $lilac-purple;
                    }
                }

                .swatch-circle {
                    width: 42px;
                    height: 42px;
                    border: none;
                    border-radius: 0;
                    margin: 0;

                    @include media-breakpoint-up(lg) {
                        width: 46px;
                        height: 46px;
                    }
                }

                .actions {
                    .row {
                        margin-right: 0;
                        margin-left: 0;
                    }

                    .quickview {
                        width: 100%;
                        text-align: center;
                        background: $dark-gunmetal;
                        opacity: 0.7 !important;
                        color: $white;
                        margin: 0;
                        padding: 4px;
                        font-size: 15px;
                        text-transform: uppercase;
                        font-weight: bold;
                        text-decoration: unset;
                        cursor: pointer;
                    }

                    .actions-row {
                        margin-left: -8px;
                        margin-right: -8px;

                        .cart-wrapper,
                        .fastbuy-wrapper {
                            padding: 5px;
                            z-index: 100;
                            display: flex;
                            align-items: center;

                            a {
                                color: $white;
                                font-size: 13px;
                                font-weight: bold;
                                text-transform: uppercase;
                                text-align: center;
                                text-decoration: unset;
                                cursor: pointer;
                                display: block;
                                width: 100%;
                                padding: 6px;

                                @include media-breakpoint-up(lg) {
                                    font-size: 12px;
                                }

                                .fast-buy-button-inner {
                                    @include media-breakpoint-up(lg) {
                                        font-size: 12px !important;
                                    }
                                }
                            }

                            &.cart-wrapper {
                                padding-right: 2px !important;

                                a {
                                    background-color: $lilac-purple;
                                }
                            }

                            &.fastbuy-wrapper {
                                padding-left: 2px !important;

                                a {
                                    background-color: $light-green;
                                }
                            }
                        }
                    }
                }

                .price {
                    font-size: 11px;
                }

                .ratings {
                    min-height: 28px;
                    display: flex;
                }

                .strike-through-wrapper {
                    display: none;
                }
            }

            .tile-pricing-section {
                display: flex;
                flex-direction: column;
            }

            .price-container {
                font-size: 16px;
                display: block;
                margin-bottom: 10px;

                @include media-breakpoint-up(sm) {
                    font-size: 18px;
                }

                .clearance-price-label {
                    display: block;
                    position: relative;
                    width: fit-content;
                    margin: 10px 0 0 15px;
                    padding: 2px 5px 4px 8px;
                    font-family: $opensans-bold;
                    font-size: 13px;
                    background-color: #d92a1c;
                    color: $white;

                    &::after {
                        position: absolute;
                        content: "";
                        right: -10%;
                        top: 0;
                        height: 100%;
                        width: 12px;
                        background-color: inherit;
                        -webkit-transform: skewX(-15deg);
                        -moz-transform: skewX(-15deg);
                        -ms-transform: skewX(-15deg);
                        transform: skewX(-15deg);
                    }
                }

                .plp-extra-note {
                    color: $kinda-purple;
                    font-size: 11px;
                    font-weight: $font-wt-bold;
                    font-family: $opensans-bold;
                }

                .price-item {
                    font-size: 13px;
                    font-weight: normal;
                    color: $dark-gunmetal;
                    text-align: center;
                    font-family: $opensans-regular;

                    &.sales {
                        font-size: 19px;
                        font-family: $opensans-bold;
                        color: $dark-gunmetal;
                        text-align: left;
                        margin-top: 5px;

                        @include media-breakpoint-down(sm) {
                            font-size: 16px;
                        }

                        .shoplc-price-label {
                            color: $dark-gunmetal;
                        }

                        .sale-price-label {
                            color: $lc-red;
                        }

                        .plp-price-now {
                            color: $gray10;
                            font-size: 16px;
                            font-family: $opensans-bold;

                            @include media-breakpoint-up(md) {
                                font-size: 19px;
                            }
                        }

                        .plp-price-was {
                            margin-left: 5px;
                            font-size: 13px;
                            color: $night-rider;
                            font-family: $opensans-light;
                            margin-top: 5px;
                        }
                    }

                    &.clearance {
                        color: $gray10;
                    }

                    &.strike-through {
                        font-size: 12px;
                        text-align: left;
                        text-decoration: none;

                        .shoplc-price-label {
                            font-weight: $font-wt-regular;
                        }

                        .js-formatted-price {
                            font-weight: $font-wt-bold;
                            text-decoration: line-through;
                        }
                    }
                }

                .old-value {
                    font-size: 13px;
                    color: $gray;
                    padding-top: 7px;
                    padding-left: 8px;
                    text-decoration: line-through;
                }
            }

            .promotion-callout {
                &-wrapper {
                    width: fit-content;
                    padding: 7px 0;
                    order: 3;
                }

                &-label {
                    font-family: $opensans-regular;
                    font-size: 13px;
                    color: $green-light-1;
                    padding-bottom: 5px;
                }
            }

            .image-container {
                text-align: center;

                &.ra-image {
                    display: flex;
                    justify-content: center;
                    width: 100%;

                    .ra-image-link {
                        align-content: center;
                    }
                }

                @include media-breakpoint-down(sm) {
                    margin-bottom: 1rem;
                }

                a .tile-image {
                    width: 100%;
                    object-fit: cover;
                    aspect-ratio: 1/1;
                }
            }

            .pdp-link {
                font-size: 12px;
                color: $night-rider;
            }

            .more-size {
                padding-bottom: 4px;
                min-height: 20px;
                font-size: 11px;

                a.link {
                    font-size: 11px;
                    text-align: left;
                    font-weight: 700;
                }
            }

            .promotion-msg {
                font-weight: 700;
            }

            .product-name {
                margin-bottom: 5px;
                height: 3rem;
            }

            .budget-pay-container {
                min-height: 25px;
                order: 2;

                @include media-breakpoint-down(xs) {
                    display: block;
                }

                @include media-breakpoint-down(sm) {
                    display: grid;
                }

                @include media-breakpoint-down(lg) {
                    display: grid;
                }

                img {
                    max-width: 60px;
                }

                .budget-pay {
                    margin-top: 4px;
                    white-space: normal;

                    @include media-breakpoint-down(sm) {
                        font-size: 11px;
                        margin-top: 6px;
                        margin-left: 0;
                    }
                }
            }

            .wishlist-air-container {
                position: relative;
                min-height: 24px;
                text-align: center;

                .remove-wishlist-btn.wl-toggle.d-none {
                    display: none !important;
                }

                .remove-wishlist-btn.wl-toggle {
                    display: block !important;
                    top: -10px;
                }

                .wl-toggle {
                    float: left;
                    padding: 0;
                    border: 0;

                    .wishlist-icon {
                        position: absolute;

                        .fa {
                            color: $grey3;
                        }
                    }
                }

                .on-air {
                    font-weight: 800;
                    font-size: 10px;
                    color: $white;
                    background: linear-gradient(90deg, $picton-blue 0%, #21619d 100%);
                    text-align: center;
                    padding: 5px 8px;

                    .on-air-icon {
                        padding-right: 2px;
                    }
                }
            }

            .estimated-values {
                font-family: $opensans-regular;
                font-size: 11px;
                min-height: 15px;
                display: table;

                @include media-breakpoint-up(sm) {
                    font-size: 12px;
                    display: flex;
                }

                .strike-through {
                    font-weight: 600;
                    margin-right: 10px;
                }

                .separator {
                    display: none;
                }
            }

            &.search-tile {
                padding-bottom: 0;

                .price-container {
                    .sales {
                        font-size: 15px;
                        font-weight: 600;

                        .shoplc-price-label {
                            color: $night-rider;
                        }

                        .sale-price-label {
                            color: $persian-red2;
                        }
                    }

                    .old-value {
                        text-align: center;

                        .price-item.strike-through {
                            margin: 0 auto;
                        }
                    }
                }

                .more-size {
                    text-align: center;

                    a.link {
                        color: $grey5;
                        font-size: 12px;
                    }
                }

                .estimated-values {
                    margin-left: -10px;
                    margin-right: -10px;
                    min-height: 3.5rem;

                    &.value-check {
                        border-top: 1px solid $whisper;
                    }

                    .estprice-wrapper {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 50%;
                        padding: 8px;

                        @include media-breakpoint-down(lg) {
                            padding: 4px;
                        }
                    }

                    .estimated-price,
                    .save-extra,
                    .save-value {
                        display: flex;
                        flex-direction: column;
                    }

                    .savewrapper-border {
                        border-left: 1px solid $whisper;
                    }
                }

                .actions {
                    position: absolute;
                    width: 100%;
                    bottom: -74px;
                    opacity: 0;
                    display: none;
                    transition: all 0.3s ease-in;
                    cursor: pointer;
                    z-index: 10;
                    padding-right: 2rem;

                    .quickview {
                        background-color: $alto-secondary;
                        color: $night-rider;
                    }

                    .cart-wrapper,
                    .fastbuy-wrapper {
                        padding: 0 !important;

                        a.link {
                            display: flex;
                            flex-direction: column;
                        }
                    }
                }

                .budget-pay-container {
                    min-height: 40px !important;
                }

                .video-360-container {
                    .video-icon {
                        margin-top: -4px;
                        float: right;
                        width: 24px;
                        height: 24px;

                        .video-circle {
                            color: $curious-blue;
                            line-height: 2;
                        }

                        .video-play {
                            font-size: 0.5em;
                            margin-left: 1px;
                            color: $white;
                        }
                    }
                }

                &:hover {
                    .actions {
                        bottom: 10px;
                        opacity: 1;
                        display: block;
                    }
                }
            }

            &.ra-product-tile {
                flex-grow: 1;
                min-height: 0;
                border: 1px solid $incredible-gray;
                justify-content: space-between;

                @include media-breakpoint-up(md) {
                    padding-bottom: 10px;
                }

                .image-container {
                    @include media-breakpoint-down(sm) {
                        margin-bottom: 10px;
                    }
                }

                .tile-body {
                    text-align: center;
                    font-size: 12px;
                    color: $night-rider;
                    width: 100%;
                    min-height: 325px;

                    @include media-breakpoint-up(md) {
                        padding: 0 10px;
                        min-height: 310px;
                    }

                    .quickview {
                        width: 70%;
                        text-align: center;
                        background: $concrete;
                        color: black !important;
                        margin: 0;
                        padding: 4px 12px;
                        border-radius: 5px;
                        height: 30px;
                        font-size: 15px;
                        text-transform: uppercase;
                        font-weight: 600;
                        text-decoration: unset;
                        font-family: $opensans-regular;
                        cursor: pointer;
                        line-height: 23px;

                        @include media-breakpoint-down(sm) {
                            width: 110px;
                            font-size: 12px;
                            padding: 4px;
                            line-height: 21px;
                        }
                    }

                    .ra-tile-name-anchor {
                        font-size: 12px;
                        color: $dark-gunmetal;
                        overflow: hidden;
                        font-weight: normal;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;

                        @include media-breakpoint-down(sm) {
                            font-size: 12px;
                            padding: 0 10px;
                        }
                    }
                }

                .product-name {
                    height: auto;

                    a.link {
                        color: $black !important;
                        text-decoration: none;
                        margin-top: 5px !important;
                        min-height: 28px;

                        &:hover {
                            color: $navy-blue !important;
                        }
                    }
                }

                .ra-tile-size {
                    font-size: 12px;
                    font-weight: 400;
                    margin-bottom: 5px;
                    min-height: 17px !important;
                }

                .ra-tile-est-rt-anchor {
                    display: block;
                    font-size: 10px;
                    color: $dark-gunmetal !important;
                    text-decoration: underline;
                    margin-bottom: 10px;
                }

                .ra-tile-bid-details-div {
                    margin-bottom: 24px;
                    position: relative;
                    top: 9px;
                    font-size: 18px;

                    @include media-breakpoint-down(xs) {
                        font-size: 14px;
                    }
                }

                .ra-tile-current-bid-value-span {
                    font-family: $opensans-bold;
                    margin-right: 5px;
                    font-size: 18px;

                    @include media-breakpoint-down(xs) {
                        font-size: 14px;
                    }
                }

                .ra-tile-send-bid-div {
                    font-size: 12px;
                    margin-bottom: 10px;
                    width: 100%;
                    align-items: center;

                    .veil {
                        top: 120px;
                        height: 48%;
                    }
                }

                .ra-tile-enter-bid-div {
                    margin-bottom: 10px;
                }

                .ra-tile-enter-bid-span {
                    display: inline-block;
                    vertical-align: middle;
                    font-size: 13px;
                }

                .ra-tile-enter-bid-input {
                    width: 48px;
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0 0 0 5px;
                    border-radius: 0;
                    border: 1px solid $dusty-gray;

                    &[type=number] {
                        color: $dark-gunmetal;
                        font-family: $opensans-bold;
                        text-align: center;
                        -moz-appearance: textfield;

                        &:disabled {
                            color: $night-rider;
                            margin: 0;
                            border: none;
                            background: transparent;
                        }

                        &::-webkit-outer-spin-button,
                        &::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                    }
                }

                .ra-auction-estimated-saving-info-span {
                    display: block;
                    width: 100%;
                    font-family: $opensans-bold;
                    padding-bottom: 20px;

                    .est-val {
                        color: $alert-red;
                    }
                }

                .ra-tile-send-bid-button {
                    width: 100%;
                    font-family: $opensans-bold;
                    font-size: 18px;
                    line-height: 2.3em;
                    padding: 0;
                    color: $white;
                    border: 1px solid $picton-blue;
                    background-color: $picton-blue;
                    border-radius: 5px;

                    @include media-breakpoint-down(md) {
                        width: 90%;
                        margin: 0 10px;
                    }

                    &.guest-btn:hover {
                        border: 1px solid $picton-blue;
                        background-color: $picton-blue;
                    }

                    &.logged-in-btn:hover {

                        @include media-breakpoint-up(sm) {
                            border: 1px solid $la-palma;
                            background-color: $la-palma;
                        }
                    }

                    &.sold-closed-btn {
                        cursor: not-allowed;
                        opacity: 0.7;
                        border: 1px solid $dusty-gray;
                        background-color: $white;
                        color: $navy-blue;

                        &:hover {
                            opacity: 1;
                            border: 1px solid $dusty-gray;
                            background-color: $white;
                            color: $navy-blue;
                        }
                    }

                    &.pay-now-btn {
                        background: $la-palma;
                        border-color: $la-palma;
                        color: $white;
                        max-width: 180px;
                        margin: 0 auto;

                        &:hover {
                            background: $la-palma;
                            border-color: $la-palma;
                            color: $white;
                        }

                        .pay-now-button-green {
                            color: $white;

                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                }

                .ra-tile-winner-span,
                .ra-tile-timer-span {
                    display: block;
                    width: 100%;
                }

                .ra-tile-winner-span {
                    font-size: 13px;
                    text-transform: uppercase;
                    min-height: 20px;

                    .ra-closed-winner-text-span {
                        color: $black;
                        font-family: $opensans-bold;
                        text-transform: uppercase;
                        display: inline-block;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 17ch;
                        vertical-align: middle;
                    }

                    .fa-star {
                        margin: 0 3px;
                        color: $ratings-orange;
                    }

                    &.no-winner {
                        color: $dusty-gray;
                    }
                }

                .ra-tile-timer-span {
                    font-family: $opensans-regular;
                    font-size: 20px;
                    color: $night-rider;

                    b {
                        font-family: $opensans-bold;
                    }

                    &[data-is-expired=true] {
                        color: $very-light-grey2;
                    }

                    .veil {
                        z-index: 100;
                    }

                    .ra-tile-closed-auction-text {
                        color: $sky-blue;
                        font-weight: 900;
                        padding: 7px 0;
                        font-size: 24px;
                    }
                }

                &[data-is-expired=true] {
                    .ra-tile-timer-span {
                        color: $very-light-grey2;
                    }
                }
            }
        }

        &.new-plp-auction-expired {
            .ra-product-tile {
                justify-content: start;
            }
        }

        .product-listItem {
            display: flex;
            flex-wrap: wrap;
            height: 100%;

            .product-tile {
                width: 100%;
                min-height: inherit;
            }
        }
    }

    &.shop-last-grid {
        .shop-last-tile {
            width: 20%;
            padding: 0 10px;
            margin-bottom: 20px;

            .auction-timer-section {
                font-size: 13px;
                text-align: center;
                font-weight: bold;
                border: 0.5px solid #878ed5;
                padding: 5px;
                background-color: #e6e8ff;
                display: flex;
                justify-content: center;

                .countdown {
                    padding-left: 8px;
                }
            }

            .wishlistTile,
            .remove-wishlist-btn {
                position: absolute;
                right: 0;
                top: -10px;
            }

            .fast-buy-present {
                line-height: 40px;
                max-width: 50%;
            }

            .fastbuy-wrapper {
                max-width: 49%;

                .fa-forward {
                    display: none;
                }
            }

            @include media-breakpoint-down(sm) {
                width: 100%;
                padding: 0;
                margin-bottom: 10px;
            }

            .product-tile {
                flex-flow: column;
                margin: 0;
                min-height: auto !important;
                height: 100%;
                border: 1px solid $alabaster;

                @include media-breakpoint-down(sm) {
                    flex-flow: row;
                    border-top-color: transparent;
                    border-left-color: transparent;
                    border-right-color: transparent;
                    padding: 0;
                }

                .tile-badges-image {
                    margin: 0 0 10px;
                }

                .wishlist-air-container {
                    z-index: 1;

                    .on-air {
                        display: inline-block;
                        margin: 0;
                    }
                }

                .leading-value-badge {
                    display: none;
                    position: absolute;
                    margin-top: -5px;
                    left: 0;
                    top: 100%;
                    width: 5.6em;
                    padding: 0.7em 0 0.5em;
                    border-radius: 5.6em;
                    background-color: $venetian-red;
                    color: $white;
                    text-transform: uppercase;
                    text-align: center;
                    font-size: 9px;
                    font-weight: bold;
                    word-break: break-word;
                    z-index: 1;
                }

                .sold-out-badge {
                    display: none;
                    position: absolute;
                    left: 0;
                    top: 0;
                    margin: -10px 0 0 -10px;
                    padding: 3px 3px;
                    background-color: $venetian-red;
                    color: $white;
                    text-transform: uppercase;
                    text-align: center;
                    font-size: 10px;
                    font-weight: bold;
                    z-index: 2;
                }

                .tile-body {
                    text-align: center;
                    position: relative;

                    @include media-breakpoint-down(sm) {
                        flex-basis: 70%;
                    }
                }

                .tile-details {
                    align-self: center;

                    @include media-breakpoint-up(sm) {
                        margin-top: 25px;
                    }

                    @include media-breakpoint-up(lg) {
                        margin-top: 0;
                    }
                }

                .product-name {
                    border: none;
                    height: auto;
                    min-height: auto;
                    margin: 0;
                    justify-content: center;

                    a.product-name-link {
                        height: 3em;
                        font-size: 12px;
                        font-weight: bold;
                        color: $night-rider !important;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }

                .view-item {
                    display: none;
                }

                .product-id {
                    display: none;
                    font-size: 12px;
                    margin: 0 0 10px;
                    color: $smart-gray;
                }

                .more-options-msg {
                    visibility: hidden;
                    text-transform: uppercase;
                    font-size: 11px;
                    font-family: $opensans-bold;
                    color: $night-rider;
                    margin: 0 0 5px;
                }

                .product-prices {
                    flex-flow: column;
                }

                .shoplc-price {
                    color: $night-rider;
                    font-size: 15px;
                }

                .shoplc-price-label {
                    display: none;
                    text-transform: uppercase;
                    margin-right: 5px;
                }

                .shoplc-price-value {
                    font-family: $opensans-bold;
                }

                .bp-price {
                    cursor: pointer;
                    position: relative;
                    justify-content: center;
                    margin-bottom: 10px;

                    .bp-logo {
                        width: auto;
                        height: 20px;
                        margin: -3px 5px 0 0;
                    }

                    .bp-value {
                        font-weight: bold;
                        text-decoration: none;
                        font-size: 11px;
                        line-height: 20px;
                        color: $reached-green;
                    }

                    .item-installments-amount {
                        text-transform: uppercase;
                    }

                    .tooltip-popup {
                        position: absolute;
                        margin: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        top: 100%;
                        width: 100%;
                        max-width: 170px;
                        padding: 10px 25px 5px 5px;
                        background: $white;
                        box-shadow: 0 0 10px $dark-gray;
                        font-size: 10px;
                        text-align: left;

                        p {
                            margin: 0;
                        }

                        .close.budget-pay-button {
                            padding: 0 5px;
                            color: $black;
                            font-size: 18px;
                        }
                    }
                }

                .estimated-values {
                    display: none;
                    font-size: 11px;
                }

                .estprice-wrapper {
                    margin-left: 0;
                }

                .action-buttons-wrap {
                    display: none;
                }

                .action-link {
                    text-transform: uppercase;
                    font-size: 13px;
                    background: $white;
                    color: $navy-blue !important;
                    cursor: pointer;

                    .fa {
                        margin-right: 5px;
                        font-size: 16px;
                        vertical-align: middle;
                    }

                    &.add-to-cart {
                        color: $white !important;
                        background: $lilac-purple;
                        padding: 7px 21px;
                        border: 1px solid $lilac-purple;

                        &:hover {
                            background: $lilac-purple;
                            color: $white !important;
                            text-decoration: none;
                        }
                    }
                }

                .actions-wrapper {
                    height: 42px;

                    .add-to-cart {
                        line-height: 34px;
                    }

                    .fast-buy-button {
                        line-height: 13px;
                    }
                }

                .actions {
                    width: 100%;
                    bottom: -10px;
                    left: -10px;
                    background-color: rgba(0, 0, 0, 0.7);

                    .quickview {
                        font-size: 13px;
                        background: $white;
                        color: $night-rider !important;
                        height: 35px;
                        line-height: 30px;
                        align-items: center;
                        display: flex;
                        justify-content: center;
                    }

                    .actions-row {
                        margin: 5px 0;
                        width: 100%;

                        .cart-wrapper {
                            width: 100%;
                            margin: 0 5px;
                            padding: 0;

                            @include media-breakpoint-down(sm) {
                                padding-top: 2px;
                                height: 32px !important;
                                margin-left: 11px;
                            }
                        }

                        a {
                            font-size: 13px;
                            white-space: nowrap;
                            display: block;
                            padding: 4px;
                            color: $white !important;

                            &.fast-buy-button {
                                height: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                    }
                }
            }

            &:hover {
                .actions {
                    display: flex;
                }
            }
        }

        .auction-timer {
            padding-bottom: 20px !important;

            @include media-breakpoint-down(sm) {
                padding-bottom: 28px !important;
            }
        }
    }

    &[data-grid-type=grid-type-list] {
        @include media-breakpoint-up(md) {
            .shop-last-tile {
                width: 100%;
                margin-bottom: 10px;

                .fastbuy-wrapper {
                    background: $white;
                    color: $green;
                    border: 1px solid $green;
                    line-height: 12px;
                    max-width: 140px;
                    margin-left: 20px;

                    &:hover {
                        background: $green;

                        a,
                        .fa {
                            color: $white;
                        }
                    }

                    a {
                        color: $green;
                        font-size: 13px;
                        text-transform: uppercase;
                        text-align: center;
                        text-decoration: unset;
                        cursor: pointer;
                        display: block;
                        width: 100%;
                        padding: 10px;

                        .fa {
                            display: block;
                            color: $white;
                            position: absolute;
                            top: 11px;
                        }
                    }
                }

                .product-tile {
                    flex-flow: row;
                    padding: 15px 10px;

                    .wishlist-air-container {
                        display: flex;

                        .on-air {
                            order: 1;
                            margin-left: 5px;
                        }

                        .wl-toggle {
                            left: 0;
                            right: auto;
                            position: static;

                            .wishlist-icon {
                                position: static;
                            }
                        }
                    }

                    &:hover {
                        .actions {
                            display: none;
                        }
                    }

                    .tile-badges-image {
                        margin: 0 15px 0 0;
                    }

                    .tile-body {
                        flex-basis: 145%;
                        text-align: left;
                    }

                    .tile-details {
                        align-self: flex-start;
                    }

                    .product-name {
                        margin: 0 0 10px;
                        justify-content: space-between;

                        a.product-name-link {
                            height: auto;
                            font-family: $opensans-regular;
                            text-decoration: none;
                            display: block;
                            font-size: 13px;
                        }
                    }

                    .action-buttons {
                        &.fast-buy-active {
                            width: 100%;
                            display: flex;
                            align-items: flex-start;
                            justify-content: flex-end;
                        }

                        .fastbuy-wrapper {
                            background: $light-green;
                            border: 1px solid $light-green;

                            a {
                                color: $white !important;
                            }
                        }
                    }

                    .view-item {
                        display: block;
                        font-size: 13px;

                        &:hover {
                            text-decoration: none;
                        }
                    }

                    .product-id {
                        display: block;
                    }

                    .product-prices {
                        flex-flow: row;
                    }

                    .product-current-prices {
                        flex-basis: 26%;
                    }

                    .product-current-price {
                        margin: 0 0 10px;
                    }

                    .shoplc-price {
                        display: flex;
                        margin-top: 10px;
                    }

                    .shoplc-price-label {
                        display: block;
                    }

                    .shoplc-price-value {
                        font-family: $opensans-bold;
                        color: $navy-blue;
                    }

                    .bp-price {
                        justify-content: left;

                        .bp-logo {
                            height: 1em;
                            margin: 0 5px 0 0;
                        }

                        .bp-value {
                            font-size: 14px;
                            font-weight: normal;
                            text-decoration: underline;
                        }

                        .item-installments-amount {
                            text-transform: none;
                        }

                        .tooltip-popup {
                            left: 0;
                            transform: none;
                        }
                    }

                    .estimated-values {
                        display: flex;
                        flex-basis: 30%;
                    }

                    .estprice-wrapper {
                        margin-left: 15px;
                    }

                    .action-buttons-wrap {
                        display: flex;
                    }

                    .checkBtn {
                        margin-top: -5%;
                    }

                    .checkAddtoCart {
                        margin-top: -2%;
                        margin-right: -52%;
                        margin-left: 53%;
                    }

                    .checkfastBuy {
                        margin-top: 13%;
                        margin-right: 40%;
                    }

                    .checkShoplc {
                        margin-top: -9%;
                    }

                    .saveBtn {
                        margin-left: 18%;
                    }

                    .saveBtnval {
                        margin-left: 18%;
                    }
                }
            }
        }
    }
}

.search-bar-results {
    .product-grid {
        .product-tile-wrapper {
            .wishlist-air-container {
                .wishlist-icon {
                    position: inherit !important;
                }
            }
        }
    }
}

.on-air {
    .products {
        .tile-body {
            .price {
                .strike-through {
                    color: $black;
                }
            }

            .estimated-values {
                font-size: 11px;

                .strike-through-wrapper {
                    display: none;
                }
            }
        }
    }
}

.tab-content {
    .product-grid {
        .product-tile-wrapper {
            .product-tile {
                .estimated-values {
                    font-size: 13px;

                    @include media-breakpoint-only(xs) {
                        font-size: 11px;
                    }

                    .estimated-price {
                        color: $gray;
                    }
                }

                del {
                    text-decoration: none;
                }

                .price {
                    &.fpc-saveextra-container {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                    }
                }

                .saveextra-price {
                    margin-top: 3px;

                    &.fpc-saveextra-price {
                        margin-left: 6px;
                        margin-bottom: 10px;
                    }

                    .save-wrapper {
                        .save-value {
                            font-size: 10px;
                            color: $grey-light-border;
                            font-weight: 800;
                            background: $grey-background 0% 0% no-repeat padding-box;
                            border: 1px solid $grey-light-border;
                            border-radius: 3px;
                            opacity: 1;
                            padding: 4px 10px;
                            display: inline;

                            @include media-breakpoint-down(sm) {
                                .link-modal {
                                    color: $grey-light-border;
                                    font-weight: 800;
                                }
                            }
                        }

                        .save-extra {
                            font-size: 10px;
                            color: $grey-light-border;
                            font-weight: 800;
                            background: $grey-background 0% 0% no-repeat padding-box;
                            border: 1px solid $grey-light-border;
                            border-radius: 3px;
                            opacity: 1;
                            padding: 4px 10px;
                            display: inline;

                            .pdp-savings {
                                color: $grey-light-border;
                            }
                        }
                    }
                }

                .budget-pay-container {
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: flex-end;

                    @include media-breakpoint-up(sm) {
                        gap: 4px;
                    }

                    .budget-pay {
                        color: $black;
                        margin-left: 0;
                        margin-top: 9px;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                    }
                }
            }
        }
    }
}

.product-quickview {
    .detail-panel {
        .estimated-values {
            .shoplc-title {
                color: $price-grey;
            }
        }
    }
}

.product-carousel.bxslider {
    .product-tile {
        .image-container {
            overflow: hidden;
            padding: 0 0 100%;
        }

        img.tile-image {
            position: absolute;
            top: 0;
            left: 0;
        }

        .price-container {
            text-align: center;

            .old-value,
            .shoplc-price-label {
                display: none;
            }
        }

        .product-name {
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            max-height: 50px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin: 5px auto;

            a.link {
                color: $night-rider;
            }
        }
    }
}

.rating-reviews-title-out h2 {
    font-size: 22px;
    margin-top: 8px;
}

.product-tile .tile-body a.link,
.product-tile .tile-body a.search-link {
    font-size: 13px;
    color: $dark-gunmetal;
    overflow: hidden;
    font-weight: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.product-tile .tile-body a.search-link:hover {
    color: $navy-blue !important;
}

.link-modal {
    cursor: pointer;

    @include media-breakpoint-only(xs) {
        color: $night-rider;
        font-weight: normal;
    }
}

.slick-product-tile .slick-slide {
    .product-tile-body {
        overflow: hidden;
        height: 130px;
        background: $white;
        font-family: $opensans-regular;
        text-align: center;
        font-size: 14px;
    }

    .ratings {
        width: 100%;
        text-align: center;
    }

    .rating-stars {
        display: inline-block;
        position: relative;
        font-size: 16px;
        text-align: left;
        height: 22px;
        overflow: hidden;

        .rating-stars-active {
            color: $light-yellow;
            position: relative;
            z-index: 1;
            white-space: nowrap;
            overflow: hidden;
        }

        .rating-stars-inactive {
            color: $emperor;
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
        }
    }

    .rating-number {
        display: none;
    }

    .product-name-link a {
        color: $night-rider !important;
        line-height: 1.5em;
        max-height: 3em;
        height: 3em;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .product-price {
        margin-top: 10px;

        .price-label {
            font-weight: normal;
        }

        .price-label,
        .price-value {
            display: inline-block;
            vertical-align: middle;
        }
    }
}

// .yotpo_rating {
//     display: inline-block;
//     margin-right: 0.5em;

//     .yotpo_star {
//         padding-top: 3px;

//         .yotpo_icon-- {
//             &full {
//                 height: 1em;
//                 width: 1em;
//                 fill: #f5bf17;
//             }

//             &half {
//                 height: 1em;
//                 width: 1em;
//                 stroke: #f5bf17;
//                 fill: transparent;
//                 color: #f5bf17;
//             }

//             &empty {
//                 height: 1em;
//                 width: 1em;
//                 stroke: #f5bf17;
//             }
//         }
//     }
// }

.soldout-wishlist-wrapper {
    font-size: 13px;
    line-height: 1.6em;
    text-align: justify;
    padding: 15px;

    .soldout-wishlist-content {
        margin-bottom: 20px;
    }
}

#liveTvShopLast24 {
    .product-current-prices {
        .fastbuy-wrapper {
            max-width: 100%;
        }
    }

    .action-link.add-to-cart {
        padding: 7px 18px;
    }
}

.liveTvShopLast24GridWrapper .product-grid.shop-last-grid .shop-last-tile .product-tile .actions .quickview,
.liveTvShopLast25GridWrapper .product-grid.shop-last-grid .shop-last-tile .product-tile .actions .quickview {
    text-align: center;
    padding: 0;
}

.product-grid {
    .bx-wrapper {
        .bx-controls-direction {
            a {
                top: 15px;
                width: 20px;
                height: 20px;
                transform: rotate(270deg);

                &::before {
                    font-size: 35px;
                    color: #000;
                }
            }

            .bx-next {
                left: auto;
                right: -8px;
            }

            .bx-prev {
                left: -25px;

                @include media-breakpoint-down(md) {
                    left: -25px;
                }
            }
        }
    }
}

button:disabled,
button.unselectable {
    user-select: none !important;
    pointer-events: none !important;
    cursor: default !important;
}

.custom-add-wishlist-btn:focus {
    box-shadow: none;
}

span.pdp-red.pdp-savings {
    color: $kinda-purple;
}

.red-text-alert {
    color: #eb254b !important;
    display: inline-flex;
    align-items: center;
    padding: 10px 16px;
    background-color: #fdd;
    justify-content: center;
    border-radius: 5px;

    i {
        margin-right: 5px;
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
    }
}

/** RA timer CSS start **/
.jst-days,
.jst-hours,
.jst-minutes,
.jst-seconds {
    float: left;
    font-family: OpenSans-Regular, arial, sans-serif;
    font-size: 20px;
    color: #333;
    padding: 3px;
}

.new-plp-auction-expired .jst-days,
.new-plp-auction-expired .jst-hours,
.new-plp-auction-expired .jst-minutes,
.new-plp-auction-expired .jst-seconds {
    float: left;
    font-family: OpenSans-Regular, arial, sans-serif;
    font-size: 20px;
    color: #cbcbcb;
    padding: 3px;
}

.jst-clearDiv,
.quick-view-clearDiv,
.watch-list-clearDiv,
.bid-history-clearDiv,
.auction-cart-clearDiv,
.auction-checkout-clearDiv {
    clear: both;
}

.jst-timeout,
.quick-view-timeout,
.watch-list-timeout,
.bid-history-timeout,
.auction-cart-timeout,
.auction-checkout-timeout {
    color: #cbcbcb;
}

.simple-timer {
    text-align: center;
    display: inline-flex;
}

.quick-view-days,
.quick-view-hours,
.quick-view-minutes,
.quick-view-seconds {
    float: left;
    font-family: OpenSans-Regular, arial, sans-serif;
    font-size: 18px;
    padding: 3px;
    color: #d0021b;
    font-weight: 700;
}

.watch-list-days,
.watch-list-hours,
.watch-list-minutes,
.watch-list-seconds {
    float: left;
    font-family: OpenSans-Regular, arial, sans-serif;
    font-size: 12px;
    padding: 2px;
    color: #d0021b;
    font-weight: 700;
}

.bid-history-days {
    text-align: center;
    font-family: $opensans-regular;
    font-size: 22px;
    letter-spacing: 0;
    color: $night-rider;
    opacity: 1;
    // padding: 5px 0px 5px 5px;
    margin: 5px;

    b {
        padding-right: 10px !important;
        border-right: 1px solid #cdcdcd;
        height: 10px;
    }
}

.bid-history-seconds {
    text-align: center;
    font-family: $opensans-regular;
    font-size: 22px;
    letter-spacing: 0;
    color: #ea244c;
    opacity: 1;
    // padding: 5px 5px 5px 0;
    margin: 5px;
    // width: 40px;

    span {
        font-size: 14px !important;
        margin: 0;

        b {
            padding: 0 !important;
            border-right: 0;
        }
    }
}

.bid-item-timer {
    color: #ea244c;

    .bid-history-hours,
    .bid-history-minutes {
        text-align: center;
        font-family: $opensans-regular;
        font-size: 22px;
        letter-spacing: 0;
        color: #ea244c;
        opacity: 1;
        margin: 0 4px 0 2px;

        span {
            font-size: 14px !important;
            margin: 0;

            b {
                padding: 0 !important;
                border-right: 0;
            }
        }

        b {
            padding-right: 10px !important;
            border-right: 1px solid #cdcdcd;
            height: 10px;
        }
    }
}

.bid-history-hours,
.bid-history-minutes {
    text-align: center;
    font-family: $opensans-regular;
    font-size: 22px;
    letter-spacing: 0;
    color: #ea244c;
    opacity: 1;
    // padding: 5px 5px 5px 0;
    margin: 5px;
    // width: 49px;

    span {
        font-size: 14px !important;
        margin: 0;

        b {
            padding: 0 !important;
            border-right: 0;
        }
    }

    b {
        padding-right: 10px !important;
        border-right: 1px solid #cdcdcd;
        height: 10px;
    }
}

.auction-cart-days,
.auction-cart-hours,
.auction-cart-minutes,
.auction-cart-seconds {
    float: left;
    font-family: OpenSans-Regular, arial, sans-serif;
    font-size: 14px;
    padding: 2px;
    color: $caution-red;
    font-weight: 700;
}

.auction-checkout-days,
.auction-checkout-hours,
.auction-checkout-minutes,
.auction-checkout-seconds {
    float: left;
    font-family: OpenSans-Regular, arial, sans-serif;
    font-size: 14px;
    padding: 2px;
    color: $caution-red;
    font-weight: 600;
}

.timer-quick-view {
    margin-bottom: -10px;
}

/** RA timer CSS end **/

/** -- CRO Product tile styles-- **/
.CRO_generic_color {
    .product-grid,
    .recommendations {
        &.shop-last-grid {
            .shop-last-tile {
                .product-tile {
                    .bp-price {
                        .bp-logo {
                            -webkit-filter: grayscale(100%);
                            filter: grayscale(100%);
                        }

                        .bp-value {
                            color: $primarybtn-color;
                            text-decoration: none;
                            font-weight: 600;
                        }
                    }

                    .action-link {
                        &.add-to-cart {
                            background: $primarybtn-color;
                            border: 1px solid $primarybtn-color;

                            &:hover {
                                background: $primarybtn-color;
                                color: $white !important;
                            }
                        }
                    }

                    .actions {
                        background-color: rgba(207, 207, 207, 0.7);
                    }
                }
            }

            &[data-grid-type=grid-type-list] {
                @include media-breakpoint-up(md) {
                    .shop-last-tile {
                        .product-tile {
                            .action-buttons {
                                .fastbuy-wrapper {
                                    background: $primarybtn-color;
                                    border: 1px solid $primarybtn-color;

                                    a {
                                        color: $white !important;
                                    }
                                }
                            }

                            .shoplc-price-value {
                                color: $primarybtn-color;
                            }
                        }
                    }
                }
            }
        }

        .product-tile-wrapper {
            .product-tile {
                .tile-body {
                    .update-variation-tile {
                        &.active {
                            border: 2px solid $primarybtn-color;
                        }
                    }

                    .actions {
                        .actions-row {
                            .cart-wrapper,
                            .fastbuy-wrapper {
                                &.cart-wrapper {
                                    a {
                                        background-color: $primarybtn-color;

                                        @include media-breakpoint-down(lg) {
                                            font-size: 12px !important;
                                        }
                                    }
                                }

                                &.fastbuy-wrapper {
                                    a {
                                        background-color: $primarybtn-color;

                                        @include media-breakpoint-down(lg) {
                                            font-size: 12px !important;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .quickview {
                        @include media-breakpoint-down(lg) {
                            font-size: 12px !important;
                        }
                    }

                    .pdp-link a:hover {
                        text-decoration: underline;
                    }
                }

                .price-container {
                    .price-item {
                        &.sales {
                            .plp-price-now {
                                color: $gray10;
                            }
                        }
                    }

                    .old-value {
                        text-align: left;
                        margin: 8px 0;
                    }

                    .price-item.clearance {
                        color: $lc-red-new;
                        font-family: $opensans-regular;
                    }
                }

                &.search-tile {
                    .video-360-container {
                        .video-icon {
                            .video-circle {
                                color: $primarybtn-color;
                            }
                        }
                    }
                }

                &.ra-product-tile {
                    .ra-tile-send-bid-button {
                        border: 1px solid $primarybtn-color;
                        background-color: $primarybtn-color;
                        color: $white;

                        &.guest-btn,
                        &.logged-in-btn {
                            max-width: 221px;
                            margin: 0 auto;
                            font-size: 18px;
                            height: 34px;
                            line-height: 1.5;

                            &:hover {
                                border: 1px solid $primarybtn-color;
                                background-color: $primarybtn-color;
                                color: $white;
                            }
                        }

                        &.sold-closed-btn {
                            opacity: 0.5;
                        }
                    }

                    .ra-tile-bid-details-div {
                        position: relative;
                        top: 9px;
                    }

                    .product-name {
                        a.link {
                            color: $black !important;
                            text-decoration: none;

                            &:hover {
                                color: $primarybtn-color;
                                text-decoration: underline;
                            }
                        }
                    }
                }

                .budget-pay-container {
                    .budget-pay {
                        margin-top: 8px;
                    }

                    img {
                        width: 32px;
                    }
                }
            }
        }
    }
}

.live-tv-shop-recent-section .sold-out-badge {
    @include media-breakpoint-down(xs) {
        position: absolute;
        left: 10px !important;
        top: 8px !important;
    }

    @include media-breakpoint-up(sm) {
        margin: 0 !important;
    }
}

.producttag-automation-image {
    margin-top: -1px;

    &.ra-badge-container {
        position: absolute;
        top: 1px;
        left: 0;

        @include media-breakpoint-down(md) {
            top: 0;
        }
    }

    &.ra-badge-container-qv {
        margin-left: 15px;
        margin-top: -3px;
    }

    &.ra-badge-container-pdp {
        margin-left: 15px;
    }

    @include media-breakpoint-down(sm) {
        margin-top: -3px;
    }

    .producttag-automation {
        display: inline-block;

        @include media-breakpoint-down(sm) {
            margin-top: -3px;
        }

        .plpbadge {
            clip-path: polygon(0% 0%, 100% 0%, calc(100% - 7px) 50%, 100% 100%, 0% 100%);

            @include media-breakpoint-down(sm) {
                clip-path: polygon(0% 0%, 100% 0%, calc(100% - 5px) 50%, 100% 100%, 0% 100%);
            }

            .plpbadge-name {
                font-size: 13px;
                opacity: 1;
                letter-spacing: 0.13px;
                color: $white;
                font-family: $opensans-bold;
                padding: 4px 18px 4px 10px;

                @include media-breakpoint-down(sm) {
                    font-size: 10px;
                    letter-spacing: 0.1px;
                    padding: 3px 14px 3px 6px;
                }
            }
        }

        .pdpbadge {
            clip-path: polygon(0% 0%, 100% 0%, calc(100% - 12px) 50%, 100% 100%, 0% 100%);

            @include media-breakpoint-down(sm) {
                clip-path: polygon(0% 0%, 100% 0%, calc(100% - 9px) 50%, 100% 100%, 0% 100%);
            }

            .pdpbadge-name {
                font-size: 13px;
                opacity: 1;
                letter-spacing: 0.13px;
                color: $white;
                font-family: $opensans-bold;
                padding: 4px 20px 4px 10px;

                @include media-breakpoint-down(sm) {
                    font-size: 10px;
                    letter-spacing: 0.1px;
                    padding: 3px 16px 3px 6px;
                }
            }
        }
    }

    .wishlist-air-container {
        float: right;
        margin: 5px 10px 0 0;

        @include media-breakpoint-down(sm) {
            margin: 5px 10px 0 0;
        }
    }
}
